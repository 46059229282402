import React from "react";

import { Permissions, TwoStep } from "./elements";

const RemoveAccount = ({ toggleModal, active, handleActivate }) => (
  <Permissions
    style={{
      paddingTop: "16px"
    }}
  >
    <h3 className="title">Remove staff account</h3>

    <div>
      <TwoStep>
        <h3 className="title">Deactivate staff account</h3>

        <div className="two-step-desc">
          Deactivated staff accounts will no longer have access to your store.
          You can re-activate staff accounts at any time.
        </div>

        <button
          onClick={() => {
            active && toggleModal("deactivationModal");
            !active && handleActivate();
          }}
          className="btn"
        >
          {!active ? "Activate staff account" : "Deactivate staff account"}
        </button>
      </TwoStep>

      <TwoStep>
        <h3 className="title">Delete staff account</h3>

        <div className="two-step-desc">
          Deleted staff accounts will be permanently removed from your store.
          This action cannot be reversed.
        </div>

        <button
          onClick={() => {
            toggleModal("deletionModal");
          }}
          className="btn danger"
        >
          Delete staff account
        </button>
      </TwoStep>
    </div>
  </Permissions>
);

export default RemoveAccount;
