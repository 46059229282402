import React, { Fragment } from "react";
import { connect } from "react-redux";
import qs from "qs";
import { isEqual } from "lodash";
import { Redirect } from "react-router-dom";

import {
  Header,
  Body,
  Card,
  HeaderRight,
  HeaderLeft,
  HeaderText,
  HeaderTextBox,
  CreateBtn
} from "../Payouts/components/common";
import { CContainer, CCardBody } from "./components/elements";
import CustomersLoader from "./components/CustomersLoader";
import Paginator from "../../common/Paginator";
import { fetchCustomerRecentTransactions } from "../../../actions/customer";
import { fetchCustomer } from "../../../actions/customer";
import Transactions from "./components/Transactions";

class Customers extends React.Component {
  state = {
    totalEntries: 1,
    perPage: 20,
    searchQuery: "",
    waitTime: 500
  };

  async componentDidMount() {
    document.title = "Customers";

    const { fetchCustomerRecentTransactions, fetchCustomer } = this.props;

    const { page = 1 } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    const customerId = this.props.match.params.customerId;

    const [customerResponse, transactionResponse] = await Promise.all([
      fetchCustomer(customerId),
      fetchCustomerRecentTransactions(customerId, page)
    ]);

    if (transactionResponse) {
      this.setState({
        totalEntries: transactionResponse.Meta.total_entries_size,
        perPage: transactionResponse.Meta.per_page
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      const { fetchCustomerRecentTransactions } = this.props;
      const customerId = this.props.match.params.customerId;

      const { page = 1 } = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
      fetchCustomerRecentTransactions(customerId, page).then(_d => {
        this.setState({
          totalEntries: _d.Meta.total_entries_size,
          perPage: _d.Meta.per_page
        });
      });
    }
  }

  render() {
    const {
      customer: { fetchingOtherInfo, fetching, customer },
      user: { payload }
    } = this.props;

    const { totalEntries, perPage } = this.state;

    return (
      <>
        {payload && !payload.permissions.includes("Customers") && (
          <Redirect to="/dashboard" />
        )}
        <CContainer>
          {fetchingOtherInfo || fetching ? (
            <CustomersLoader />
          ) : (
            customer && (
              <Fragment>
                <Header>
                  <HeaderLeft>
                    <HeaderTextBox>
                      <HeaderText>{`${customer.profile.first_name} ${
                        customer.profile.last_name
                      }'s Transactions`}</HeaderText>
                    </HeaderTextBox>
                  </HeaderLeft>
                  <HeaderRight>
                    <CreateBtn
                      to={`/customers/${this.props.match.params.customerId}`}
                    >
                      View customer
                    </CreateBtn>
                  </HeaderRight>
                </Header>
                <Body>
                  <Card>
                    <CCardBody>
                      {customer.recent_transactions && (
                        <Transactions
                          transactions={customer.recent_transactions.Data}
                        />
                      )}
                    </CCardBody>
                  </Card>
                </Body>
                <Paginator
                  totalEntries={totalEntries}
                  perPage={perPage}
                  {...this.props}
                />
              </Fragment>
            )
          )}
        </CContainer>
      </>
    );
  }
}

function mapSatetoProps({ customer, user }) {
  return { customer, user };
}

export default connect(
  mapSatetoProps,
  { fetchCustomerRecentTransactions, fetchCustomer }
)(Customers);
