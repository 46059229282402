import React from "react";
import { Link } from "react-router-dom";
import Case from "case";
import format from "date-fns/format";

import Table from "../../../../blocks/Table";
import { moneyFormatter } from "../../../../helpers/util";
import { ReactComponent as BackSVG } from "../images/back.svg";

function PayoutsTable({ payouts: { transactions }, type }) {
  return (
    <Table>
      <Table.Head>
        <Table.HeadRow>
          <Table.HeadData>
            <span>Reference</span>
          </Table.HeadData>
          <Table.HeadData>
            <span>name</span>
          </Table.HeadData>
          <Table.HeadData>
            <span>Date</span>
          </Table.HeadData>
          <Table.HeadData>
            <span>Status</span>
          </Table.HeadData>
          <Table.HeadData className="align-right">
            <span>Amount</span>
          </Table.HeadData>
        </Table.HeadRow>
      </Table.Head>
      <Table.Body>
        {(transactions || []).map(payout => (
          <Table.BodyRow key={payout.id}>
            <Table.BodyData>
              <Link to={`/payouts/${type}/${payout.id}`}>
                <Table.BodyDataBox>
                  <span>{payout.reference}</span>
                </Table.BodyDataBox>
              </Link>
            </Table.BodyData>
            <Table.BodyData>
              <Table.BodyDataBox>
                {payout.user ? (
                  <span className="has-customer-link">
                    <Link to={`/payouts/${type}/${payout.id}`}>
                      {Case.capital(
                        `${payout.user.profile.first_name} ${payout.user.profile.last_name}`
                      )}
                    </Link>
                    <span className="customer-view-icon">
                      <span className="tool-tip">View customer </span>
                      <Link to={`/customers/${payout.user.id}`}>
                        <BackSVG />
                      </Link>
                    </span>
                  </span>
                ) : (
                  <span style={{ color: "#A9A9A9" }}>No User</span>
                )}
              </Table.BodyDataBox>
            </Table.BodyData>
            <Table.BodyData>
              <Link to={`/payouts/${type}/${payout.id}`}>
                <Table.BodyDataBox>
                  <span>
                    {format(new Date(payout.created_at), "MMM D, h:mm a")}
                  </span>
                </Table.BodyDataBox>
              </Link>
            </Table.BodyData>

            <Table.BodyData>
              <Link to={`/payouts/${type}/${payout.id}`}>
                <Table.BodyDataBox>
                  <span className={`status-indicator ${payout.status}`}>
                    {Case.capital(payout.status)}
                  </span>
                </Table.BodyDataBox>
              </Link>
            </Table.BodyData>
            <Table.BodyData>
              <Link to={`/payouts/${type}/${payout.id}`}>
                <Table.BodyDataBox className="align-right">
                  {type === "fiat" ? (
                    <span>{moneyFormatter.format(payout.amount)}</span>
                  ) : (
                    <span>{`${payout.amount} ${payout.asset}`}</span>
                  )}
                </Table.BodyDataBox>
              </Link>
            </Table.BodyData>
          </Table.BodyRow>
        ))}
      </Table.Body>
    </Table>
  );
}

export default PayoutsTable;
