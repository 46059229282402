import React from "react";

import { Label } from "./elements";
import { ReactComponent as CheckIcon } from "./checkmark.svg";

const Checkbox = ({ label, name, checked, onChange, className }) => (
  <Label className={className}>
    <input
      name={name}
      className="input"
      type="checkbox"
      onChange={onChange}
      checked={checked}
    />
    <span className="checkmark">
      <CheckIcon />
    </span>

    <span className="label">{label}</span>
  </Label>
);

export default Checkbox;
