import React, { Fragment } from "react";
import { connect } from "react-redux";
import { ReactComponent as ExportSVG } from "./images/export.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import qs from "qs";

import {
  Container,
  Header,
  Body,
  Card,
  SortBox,
  SortLabel,
  SortDropdownBox,
  CDropdownSelect,
  FilterSearchBox,
  FilterAndSortBox,
  HeaderRight,
  HeaderLeft,
  HeaderText,
  CardHeader,
  HeaderTextBox,
  CreateBtn,
  TabsBtnBox,
  TabBtnList,
  TabBtnItem,
  TabBtnLink,
  CardBody,
  SearchAndFilterBox,
  SearchBox,
  SearchIconBox,
  SearchInput,
  TableBox
} from "./components/common";
import { getAds } from "./Ads.action";
import PayoutsTable from "./components/PayoutsTable";
import FullTableLoader from "../../common/SVGLoaders/FullTableLoader";
import { isEqual } from "lodash";
import Paginator from "../../common/Paginator";
import { isActive, getQueryString } from "../../../helpers/util";
import { getCountries } from "../../../actions/countries";

class Ads extends React.Component {
  state = {
    totalEntries: 1,
    perPage: 20,
    searchQuery: ""
  };

  componentDidMount() {
    document.title = "Market Place";
    const { side, country, asset, status } = qs.parse(
      this.props.location.search,
      {
        ignoreQueryPrefix: true
      }
    );

    if (side && country && asset && status) {
      this.getAllAds();
      this.props.getCountries();
    } else {
      this.props.history.push(
        "/marketplace/ads?side=buy&country=NG&asset=BTC&status=active"
      );
    }
  }

  getAllAds = () => {
    const { side, country, asset, status } = qs.parse(
      this.props.location.search,
      {
        ignoreQueryPrefix: true
      }
    );

    const queryString = { side, country, asset, status };

    this.props.getAds(queryString);
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  handleFilter = ({ target: { name, value } }) => {
    const { side, country, asset, status } = qs.parse(
      this.props.location.search,
      {
        ignoreQueryPrefix: true
      }
    );
    const query = getQueryString({
      side,
      country,
      asset,
      status,
      [name]: value
    });
    this.props.history.push(`/marketplace/ads${query}`);
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      this.getAllAds();
    }
  }

  render() {
    const {
      ads,
      countries,
      history: {
        location: { search }
      }
    } = this.props;
    const { totalEntries, perPage } = this.state;
    const { country, asset, status } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    return (
      <Container>
        {ads.fetching_ads || countries.fetching ? (
          <FullTableLoader />
        ) : (
          <Fragment>
            <Header>
              <HeaderLeft>
                <HeaderTextBox>
                  <HeaderText>Ads</HeaderText>
                </HeaderTextBox>
              </HeaderLeft>
            </Header>
            <Body>
              <Card>
                <CardHeader>
                  <TabsBtnBox>
                    <TabBtnList>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/marketplace/ads?side=buy&country=NG&asset=BTC&status=active"
                          className={
                            isActive("buy", search, "side") && "active-tab"
                          }
                        >
                          Buy
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/marketplace/ads?side=sell&country=NG&asset=BTC"
                          className={
                            isActive("sell", search, "side") && "active-tab"
                          }
                        >
                          Sell
                        </TabBtnLink>
                      </TabBtnItem>
                    </TabBtnList>
                  </TabsBtnBox>
                </CardHeader>
                <CardBody>
                  <FilterAndSortBox>
                    <FilterSearchBox>
                      <SearchAndFilterBox>
                        <SearchBox>
                          <SearchIconBox>
                            <FontAwesomeIcon icon="search" />
                          </SearchIconBox>
                          <SearchInput
                            value={this.state.searchQuery}
                            onChange={this.handleChange}
                            name="searchQuery"
                            placeholder="Search Customers"
                          />
                        </SearchBox>
                      </SearchAndFilterBox>
                    </FilterSearchBox>
                    <SortBox>
                      <SortLabel>
                        <span>Status</span>
                      </SortLabel>
                      <SortDropdownBox>
                        <CDropdownSelect
                          value={status}
                          name="status"
                          onChange={this.handleFilter}
                        >
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </CDropdownSelect>
                      </SortDropdownBox>
                    </SortBox>
                    <SortBox>
                      <SortLabel>
                        <span>Assets</span>
                      </SortLabel>
                      <SortDropdownBox>
                        <CDropdownSelect
                          value={asset}
                          name="asset"
                          onChange={this.handleFilter}
                        >
                          <option value="">All</option>
                          <option value="BTC">BTC</option>
                          <option value="ETH">ETH</option>
                        </CDropdownSelect>
                      </SortDropdownBox>
                    </SortBox>
                    <SortBox>
                      <SortLabel>
                        <span>Country</span>
                      </SortLabel>
                      <SortDropdownBox>
                        <CDropdownSelect
                          value={country}
                          name="country"
                          onChange={this.handleFilter}
                        >
                          {countries.countries.map(_c => (
                            <option value={_c.alpha2Code}>{_c.name}</option>
                          ))}
                          {/* <option value="">All</option>
                          <option value="NG">NG</option> */}
                        </CDropdownSelect>
                      </SortDropdownBox>
                    </SortBox>
                  </FilterAndSortBox>
                  <TableBox>
                    <PayoutsTable type="fiat" ads={ads.list} />
                  </TableBox>
                </CardBody>
              </Card>
            </Body>

            <Paginator
              totalEntries={totalEntries}
              perPage={perPage}
              {...this.props}
            />
          </Fragment>
        )}
      </Container>
    );
  }
}

function mapStateToProps({ ads, countries }) {
  return {
    ads,
    countries
  };
}
Ads.defaultProps = {
  getAllAds: {
    list: {
      data: [],
      meta: {
        page: 1,
        per_page: 20,
        offset: 0,
        total_entries_size: 0,
        current_entries_size: 0,
        total_pages: 0
      }
    }
  }
};
export default connect(
  mapStateToProps,
  { getAds, getCountries }
)(Ads);
