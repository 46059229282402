import React, { Component } from "react";

import { Redirect } from "react-router-dom";
import { Content, Header } from "../elements";
import { SettingsContainer, SettingsCard } from "./elements";
import { ReactComponent as SettingsIcon } from "../settings.svg";
import { ReactComponent as AccountIcon } from "./accounts.svg";
import { ReactComponent as AssetsIcon } from "./asset-settings.svg";

const ALL_SETTINGS = ({ permissions }) => [
  {
    titles: "General",
    more: "View and update your store details",
    path: "/settings",
    icon: <SettingsIcon />,
    blur: permissions.includes("General") ? false : true
  },
  {
    titles: "Accounts",
    more: "Manage your accounts and permissions",
    path: "/settings/accounts",
    icon: <AccountIcon />,
    blur: permissions.includes("Accounts") ? false : true
  },
  {
    titles: "Assets",
    more: "Manage crypto assets",
    path: "/settings/assets",
    icon: <AssetsIcon />,
    blur: permissions.includes("Assets") ? false : true
  },
  {
    titles: "Payout Providers",
    more: "Manage payout providers",
    path: "/settings/providers/payouts",
    icon: <AssetsIcon />,
    blur: permissions.includes("Assets") ? false : true
  },
  {
    titles: "Deposit Providers",
    more: "Manage deposit providers",
    path: "/settings/providers/deposits",
    icon: <AssetsIcon />,
    blur: permissions.includes("Assets") ? false : true
  }
];
class Settings extends Component {
  componentDidMount() {
    document.title = "Settings";
  }

  goto = path => this.props.history.push(path);

  render() {
    const { permissions } = this.props;
    return (
      <Content>
        <Header>
          <div style={{ height: "15px" }} />
          <h1 className="title">Settings</h1>
          <SettingsContainer>
            {ALL_SETTINGS({ permissions }).map(
              ({ icon, path, more, titles, blur }, key) => (
                <SettingsCard
                  key={key}
                  blur={blur}
                  onClick={this.goto.bind(this, path)}
                >
                  <div className="icon">{icon}</div>
                  <div className="card-content">
                    <h3 className="title">{titles}</h3>
                    <p className="subtitle">{more}</p>
                  </div>
                </SettingsCard>
              )
            )}
          </SettingsContainer>
        </Header>
      </Content>
    );
  }
}

export default Settings;
