import axios from "axios";
import { toast } from "react-toastify";

import { apiBaseURL } from "../config";

const getInviteInfo = (jwt, callback) => async () => {
  try {
    const { data } = await axios.get(`${apiBaseURL}/v1/invite?jwt=${jwt}`);

    callback(data);
  } catch (err) {
    callback({
      first_name: "Anonymous",
      fetching: false,
      fetched: true,
      expired: true,
      error: err.response.data.message
    });

    toast.error(err.response.data.message);
  }
};

export default getInviteInfo;
