import styled, { css, keyframes } from "styled-components";
import { Link } from "react-router-dom";

const fadeIn = keyframes`
  from {
   opacity:0
  }

  to {
    opacity:1
  }
`;
export const Nav = styled.nav`
  width: 100%;
`;
export const Button = styled.button`
  color: #7e8c97;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  border: transparent;
  background-color: transparent;
  display: flex;
  align-items: center;
  line-height: 9px;
  padding: 0;
  outline: white;
  .close-btn {
    width: 12px;
    height: auto;
    margin-right: 4px;
  }
`;

export const Container = styled.article`
  width: 100%;
  background-color: #ffffff;
  border-radius: 6px;
  max-width: 540px;
  animation: ${fadeIn} 0.6s linear;
`;

export const Body = styled.section`
  padding-top: 39px;
  display: flex;
  justify-content: center;
`;

export const ChildContainer = styled.section`
  padding: 28px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
  padding-bottom: 34px;
`;

export const StepContainer = styled.section`
  margin: auto;
  padding: 32px 0px;
  width: 100%;
  border-radius: 6px;
`;

export const Title = styled.h2`
  color: #67c462;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-top: 0;
`;

// export const SubTitle = styled.h3`
//   color: #222c36;
//   font-size: 18px;
//   font-weight: 500;
//   line-height: 20px;
//   text-align: center;
//   ${({ special }) =>
//     special &&
//     css`
//       margin: 20px 0;
//     `}
// `;
export const Content = styled.p`
  color: #7e8c97;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-bottom: 32px;
`;

export const SpecialButton = styled(Link)`
  display: block;
  color: #67c462;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  border: none;
  background-color: #67c462;
  border-radius: 6px;
  width: 348px;
  height: 60px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  input {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
  &:hover {
    color: #ffffff;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
`;

export const AlternateButton = styled.button`
  display: block;
  color: #67c462;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  border: none;
  background-color: #67c462;
  border-radius: 6px;
  width: 348px;
  height: 60px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  input {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
  &:hover {
    color: #ffffff;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
`;

export const DocumentTypeContainer = styled.section`
  padding: 24px 64px 47px 64px;
  ${({ special }) =>
    special &&
    css`
      padding: 24px;
      padding-bottom: 0;
    `}
  ${({ forDisplay }) =>
    forDisplay &&
    css`
      background-color: #ffffff;
      border-radius: 6px;
      max-width: 447px;
      width: 100%;
    `}
`;
export const Documents = styled.section`
  padding: 0px 25px 0px 25px;
  ${({ noPad }) =>
    noPad &&
    css`
      padding: 0;
    `}
  ${({ forDisplay }) =>
    forDisplay &&
    css`
      background-color: #ffffff;
      border-radius: 6px;
      max-width: 447px;
      width: 100%;
    `}
`;
export const H2 = styled.h2`
  color: #637381;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 34px;
  text-align: center;
`;

export const Types = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
  li {
    padding: 0;
    display: flex;
    align-items: center;
    a {
      padding: 0;
      display: flex;
      align-items: center;
      margin-bottom: 34px;

      img {
        height: 31px;
        width: 40px;
      }
      span {
        color: #212b36;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-align: left;
        margin-left: 20px;
      }
    }
  }
`;

export const DocumentList = styled.ul`
  padding: 0;
  height: 260px;
  margin-top: 0;
  list-style: none;
  overflow-y: scroll;
  li {
    padding: 0;
    height: 82px;
    display: flex;
    padding: 0 25px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    button {
      padding: 0;
      display: flex;
      align-items: center;
      background: none;
      border: none;
      img {
        height: 31px;
        width: 41px;
      }
      span {
        color: #212b36;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-align: left;
        margin-left: 20px;
      }
    }
    .right {
      .date {
        color: #637381;
        font-size: 15px;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }
`;
export const StatusCapsule = styled.div`
  background-color: rgba(245, 166, 35, 0.25);
  border-radius: 100px;
  padding: 5px 18px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 10px;
  margin-top:6px;
  display: inline-flex;
  ${({ status }) =>
    status === "pending" &&
    css`
      color: #48446f;
      background-color: #e1e7eb;
    `}
    ${({ status }) =>
      status === "failed" &&
      css`
        color: #d0021b;
        background-color: #ffeeee;
      `}
    ${({ status }) =>
      status === "accepted" &&
      css`
        color: #16b312;
        background-color: #bbe5b3;
        color: #414f3e;
      `}
`;

export const UploadFormContainer = styled.section`
  padding: 28px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
  padding-bottom: 34px;
  .img {
    height: 116.93px;
    width: auto;
  }
  .title {
    color: #637381;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: right;
    margin-bottom: 30px;
  }
  .content {
    color: #637381;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    max-width: 280px;
    width: 100%;
    text-align: center;
    margin-top: 38px;
    margin-bottom: 27px;
  }
`;

export const CircledTick = styled.span`
  width: 13px;
  height: 13px;
  background-color: #007ace;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border: 3px solid #ebeef0;
  box-sizing: content-box !important;
  /* box-shadow: 0px 0px 0px 2px #67c462; */
`;

export const UncheckedCircle = styled.span`
  width: 24px;
  height: 24px;
  background-color: #637381;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  box-shadow: 0px 0px 0px 2px #637381;
`;

export const VerificationStatus = styled.span`
  color: #637381;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  margin-bottom: 5px;
`;
export const VerificationCont = styled.span`
  display: flex;
  flex-direction: column;
`;
export const EachDescription = styled.span`
  color: #637381;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  display: block;
`;

export const CustomLink = styled(Link)`
  color: #67c462;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 10px;
  display: block;
`;

export const VerifiedCont = styled.span`
  .tick {
    color: #67c462;
    margin-right: 6px;
  }
  margin-top: 10px;
  display: block;
  color: #637381;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

export const DescCont = styled.article`
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  .event {
    max-width: calc(100% - 100px);
  }
  justify-content: space-between;
  align-items: flex-start;
  ${({ last }) =>
    last &&
    css`
      border-bottom: 0px solid #ced3da;
      padding-bottom: 0;
    `}
`;

export const LevelTwoContainer = styled.article`
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 6px;
  max-width: 447px;
  width: 100%;
  padding-bottom: 25px;
`;

export const SpecialLink = styled(Link)`
  display: block;
  color: #67c462;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  width: 348px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: #67c462;
  }
  ${({ boxed }) =>
    boxed &&
    css`
      background-color: #67c462;
      border-radius: 6px;
      box-shadow: 0 1px 4px 0 rgba(63, 63, 68, 0.12);
      width: 348px;
      height: 60px;
      color: #ffffff;
      font-size: 17px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        color: #ffffff;
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
          0 3px 6px rgba(0, 0, 0, 0.08);
      }
    `}
`;

export const DocumentEmptyState = styled.p`
  color: #637381;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  text-align: center;
  min-height: 275px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

export const SubTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 20px;
  border-bottom: 1px solid #d3dbe2;
  padding: 20px 5px;
`;
