import styled, { css } from "styled-components";

const CollapsableContainer = styled.section`
  max-height: 2000px;
  transition: all 0.45s ease-out;
  ${({ expand }) =>
    !expand &&
    css`
      height: 80px;
      max-height: 80px;
      overflow: hidden;
    `}
  .btn-cont {
    display: flex;
    justify-content: space-between;
    padding: 0 1px;
    padding-top: 30px;
    .fa-comment-dots {
      color: #43487c;
      font-size: 19px;
    }
    button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 43px;
      background-color: #ffffff;
      box-shadow: none;
      border: none;
      box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
        0 1px 3px 0 rgba(63, 63, 68, 0.15);

      color: #212b36;
      font-size: 0.7rem;
      font-weight: 400;
      padding: 0 15px;
      cursor: pointer;
      border-radius: 4px;
    }
  }
`;

export default CollapsableContainer;
