import { GET_PRODUCTS } from "../actions/types";

const initialState = {
  fetching: false,
  fetched: false,
  error: "",
  payload: {},
  products: []
};

const products = (state = initialState, { type, payload }) => {
  const cases = {
    [GET_PRODUCTS.fulfilled]: () => ({
      ...state,
      fetching: false,
      fetched: true,
      payload,
      products: payload.data
    }),
    [GET_PRODUCTS.pending]: () => ({
      ...state,
      fetched: false,
      fetching: true
    }),
    [GET_PRODUCTS.rejected]: () => ({
      ...state,
      fetched: false,
      fetching: false,
      error: payload
    }),
    DEFAULT: () => state
  };

  return cases[type] ? cases[type]() : cases["DEFAULT"]();
};

export default products;
