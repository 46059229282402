import axios from "axios";

import types from "./types";
import { apiBaseURL } from "../config";

const getPermissionsFulfilled = payload => ({
  type: types("get_permissions").fulfilled,
  payload
});

const getPermissionsRejected = payload => ({
  type: types("get_permissions").rejected,
  payload
});

const getPermissionsPending = () => ({
  type: types("get_permissions").pending
});

const getPermissions = () => async dispatch => {
  try {
    dispatch(getPermissionsPending());

    const { data } = await axios.get(`${apiBaseURL}/v1/auth/permissions`);

    dispatch(getPermissionsFulfilled(data));
  } catch (err) {
    dispatch(getPermissionsRejected());
  }
};

export default getPermissions;
