import types from "../actions/types";

export default function(
  state = {
    fetching: false,
    fetched: false,
    deposit: null,
    account: null,
    error: null
  },
  action
) {
  switch (action.type) {
    case types("fetch_deposit_info").pending:
      return {
        ...state,
        fetching: true
      };
    case types("fetch_deposit_info").fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        deposit: action.payload
      };
    case types("fetch_deposit_info").rejected:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload
      };
    case types("fetch_deposit_account").pending:
      return {
        ...state,
        fetching: true
      };
    case types("fetch_deposit_account").fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        account: action.payload
      };
    case types("fetch_deposit_account").rejected:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload
      };
    default:
      return state;
  }
}
