import React, { Fragment, Component } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { updateLiveData } from "../../../actions/liveData";
import getStats from "../../../actions/coinbarter/getStats";

import { Content, Header } from "../elements";
import orders from "./orders.svg";
import transfers from "./transfers.svg";
// import down from "./down.svg";
import money from "./money.svg";
import sessions from "./sessions.svg";
import { webSocketURL } from "../../../config";
import CustomizableLoader from "../../common/CustomizableLoader";

import {
  Section,
  CountContainer,
  // CardsContainer,
  // Report,
  // ShowAll,
  Summaries
} from "./elements";

class Home extends Component {
  state = {
    fetching: false,
    transferStats: { count: 90, total: 32339094 },
    tradeStats: { count: 86, total: 32339094 }
  };

  _webSocket = new WebSocket(`${webSocketURL}/streams/`);

  _onOpen = e => {};

  _onMessage = e => {
    const { updateLiveData } = this.props;
    const data = JSON.parse(e.data);
    updateLiveData(data);
  };

  _onError = e => {};

  _onClose = e => {};

  componentDidMount() {
    document.title = "Dashboard - Busha Admin";

    this.props.getStats();

    this._webSocket.onopen = this._onOpen;
    this._webSocket.onmessage = this._onMessage;
    this._webSocket.onerror = this._onError;
    this._webSocket.onclose = this._onClose;
  }

  pluralize = (count, word) => {
    if (count > 1 || count === 0) {
      return `${word}s`;
    }

    return word;
  };

  render() {
    const { payload, fetched } = this.props.user;
    const { liveData } = this.props;

    return (
      <Content>
        <Section>
          <Header>
            {fetched && (
              <Fragment>
                <h1 className="greeting">
                  Good afternoon, {payload.first_name}.
                </h1>
                <p className="subGreeting">
                  Here's what's happening on Busha today.
                </p>
              </Fragment>
            )}
          </Header>

          <Summaries>
            <div className="summary">
              <section className="one">
                <div>
                  <p className="icon">
                    <SVG src={money} />
                  </p>

                  <p className="text">No sales yet</p>
                </div>
              </section>
              <section className="one">
                <div>
                  <p className="icon">
                    <SVG src={orders} />
                  </p>

                  <p className="text">No orders yet</p>
                </div>
              </section>
              <section className="one">
                <div>
                  <p className="icon">
                    <SVG src={sessions} />
                  </p>

                  <p className="text">No sessions yet</p>
                </div>
              </section>
            </div>

            <div className="liveCounter">
              <div className="online">
                <span className="figure">0</span>{" "}
                <span className="live">
                  <span className="pulser" />
                  LIVE
                </span>
              </div>

              <p className="text noMarginPadding">Visitors right now</p>

              <a className="liveViewBtn" href="#sdfjdsf">
                See live view
              </a>
            </div>
          </Summaries>

          <CountContainer>
            {/* <div className="counter">
              <Link to="/transactions">
                <SVG src={orders} /> */}
            {/* {liveData.live_stats.count === undefined ? (
                  <CustomizableLoader height="10px" width="90%" />
                ) : (
                    <span
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        marginRight: "30px"
                      }}
                    >
                      <span className="dark">
                        {Number(liveData.live_stats.count)}{" "}
                        {this.pluralize(
                          Number(liveData.live_stats.count),
                          "transaction"
                        )}
                        {" fulfilled"}
                      </span>
                    </span>
                  )} */}
            {/* </Link>
            </div> */}
            <div className="counter">
              <Link to="/coinbarter/trades">
                <SVG src={transfers} />
                {this.props.coinBarterStats.fetching ? (
                  <CustomizableLoader height="10px" width="90%" />
                ) : (
                  <span
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      marginRight: "30px"
                    }}
                  >
                    <span className="dark">
                      {Number(
                        this.props.coinBarterStats.stats.tradeStats.count
                      )}{" "}
                      {this.pluralize(
                        Number(
                          this.props.coinBarterStats.stats.tradeStats.count
                        ),
                        " Coinbarter trade"
                      )}
                    </span>
                    {/* {` NGN ${this.state.transferStats.total}`} */}
                  </span>
                )}
              </Link>
            </div>

            <div className="counter">
              <Link to="/coinbarter/transfers">
                <SVG src={transfers} />
                {this.props.coinBarterStats.fetching ? (
                  <CustomizableLoader height="10px" width="90%" />
                ) : (
                  <span
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      marginRight: "30px"
                    }}
                  >
                    <span className="dark">
                      {Number(
                        this.props.coinBarterStats.stats.transferStats.count
                      )}{" "}
                      {this.pluralize(
                        Number(
                          this.props.coinBarterStats.stats.transferStats.count
                        ),
                        " Coinbarter transfer"
                      )}
                    </span>
                    {/* {` NGN ${this.state.transferStats.total}`} */}
                  </span>
                )}
              </Link>
            </div>
          </CountContainer>

          {/* <CardsContainer>
            {[1, 2].map((_, index) => (
              <div key={index} className="card">
                <header>
                  <h2>Acquisition</h2>
                </header>

                <p>
                  Increase visitor engagement by knowing where your visitors are
                  coming from and measuring the success of your campaigns.
                </p>

                <h3 className="subhead">Sessions last 30 days</h3>

                <div className="valueAndGraph">
                  <span className="figure">3</span>
                </div>

                <h3 className="subhead">Reports</h3>

                <Report>
                  <a href="sldjfsldjf">Sessions over time</a>
                  <span
                    style={{
                      color: "#637381"
                    }}
                  >
                    1 session
                  </span>
                </Report>

                <Report>
                  <a href="sldjfsldjf">Sessions over time</a>
                  <span
                    style={{
                      color: "#637381"
                    }}
                  >
                    1 session
                  </span>
                </Report>

                <ShowAll>
                  <a href="sldfjds">
                    Show all <img height="20" src={down} alt="caret down" />
                  </a>
                </ShowAll>
              </div>
            ))}
          </CardsContainer> */}
        </Section>
      </Content>
    );
  }
}

const mapStateToProps = ({ liveData, coinBarterStats }) => ({
  liveData,
  coinBarterStats
});

export default connect(
  mapStateToProps,
  { updateLiveData, getStats }
)(Home);
