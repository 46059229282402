import axios from "axios";
import { toast } from "react-toastify";

import types from "./types";
import { apiBaseURL } from "../config";

const action = ({ id }) => ({
  type: types("resend").default,
  payload: axios.post(`${apiBaseURL}/v1/users/${id}/email_verification`)
});

const resendVerification = ({ id, cb }) => async dispatch => {
  dispatch(action({ id }))
    .then(res => {
      if (cb) {
        cb();
        toast.success(res.value.data.message);
      }
    })
    .catch(err => {
      toast.error(err.response.data.message);
    });
};

export default resendVerification;
