import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import avatar from "../images/avatar.png";

// import { EmptyBox } from "./common";
// import { ReactComponent as EmptyBoxSVG } from "../svgs/empty-box.svg";

export const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 27px;
  border-radius: 3px;
  min-height: 100px;
`;

export const Section = styled.div`
  width: 100%;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
  padding: 20px;
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
`;
export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #212b36;
    font-size: 0.6rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;

export const SectionBody = styled.div`
  padding: 10px 0px;
`;

export const Info = styled.div`
  span.action {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span.action:hover {
    text-decoration: underline;
  }
  span.card-number {
    margin-left: 5px;
  }
  p {
    font-size: 0.7rem;
    color: #212b36;
    padding: 5px 0px;
    a {
      text-decoration: none;
    }
  }
`;

export default class CustomerCard extends React.Component {
  render() {
    const { customer } = this.props;
    return (
      <Container>
        <Section>
          <MainHeader>
            <h3>Customer</h3>
            <Link to={`/customers/${customer.id}`}>
              <img src={avatar} alt="customer" />
            </Link>
          </MainHeader>
          <SectionBody>
            <Info>
              <p>
                <Link to={`/customers/${customer.id}`}>
                  <span className="action">
                    {customer.profile.first_name} {customer.profile.last_name}
                  </span>
                </Link>
              </p>
            </Info>
          </SectionBody>
        </Section>
        <Section borderTop>
          <SubHeader>
            <h3>Contact</h3>
          </SubHeader>
          <SectionBody>
            <Info>
              <p>{customer.email}</p>
              <p>
                {customer.phone.number
                  ? customer.phone.number
                  : "Phone number not available"}
              </p>
            </Info>
          </SectionBody>
        </Section>
      </Container>
    );
  }
}
