import React from "react";
import { Switch, Route } from "react-router-dom";

import LiveView from "./LiveView";
import Dashboard from "./Dashboard";
import Swaps from "./Swaps";

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/live-view`} component={LiveView} />
      <Route exact path={`${match.path}/swaps`} component={Swaps} />
      <Route path={`${match.path}`} component={Dashboard} />
    </Switch>
  );
};
