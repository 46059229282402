import axios from "axios";

import { GET_COUNTRIES } from "./types";

export function getCountries() {
  return dispatch =>
    dispatch({
      type: GET_COUNTRIES.default,
      payload: axios.get(
        "//restcountries.eu/rest/v2/all?fields=name;alpha2Code",
        {
          transformRequest: [
            (data, headers) => {
              delete headers.common.Authorization;
              return data;
            }
          ]
        }
      )
    });
}
