import React from "react";
import styled from "styled-components";

import { moneyFormatter } from "../../../../helpers/util";
import { ReactComponent as SucessSVG } from "../svgs/success.svg";
import { ReactComponent as PendingSVG } from "../svgs/pending.svg";
import { BtnCont } from "./CustomerCard";

const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border-radius: 3px;
  min-height: 100px;
  padding: 10px 0px 20px;
`;

const Section = styled.div`
  div.line {
    padding-top: 5px;
  }
`;

export const ImageBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  span {
    text-transform: capitalize;
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 500;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 0px;
  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
  }
  span.bold {
    font-weight: 700;
  }
  hr {
    width: 100%;
    color: #e6e6e6;
    background-color: #e6e6e6;
    height: 1px;
    padding: 0;
    border: 0;
  }
`;

const ButtonContainer = styled(BtnCont)`
  padding-top: 10px;
  padding-right: 20px;
`;

const DepositInfo = ({ deposit, account }) => {
  const fees = deposit.fees || deposit.fee;
  const total = Number(deposit.amount) - Number(fees);
  return (
    <Container>
      <Section>
        <ImageBox>
          {(deposit.status === "success" || deposit.status === "completed") && (
            <SucessSVG />
          )}
          {(deposit.status === "created" || deposit.status === "pending") && (
            <PendingSVG />
          )}
          <span>{deposit.status}</span>
        </ImageBox>
        <InfoItem>
          <span>Amount</span>
          {account.type === "digital" && (
            <span>{`${deposit.amount} ${deposit.asset}`.toUpperCase()}</span>
          )}
          {account.type === "fiat" && !deposit.country && (
            <span>{moneyFormatter.format(deposit.amount).toUpperCase()}</span>
          )}
          {deposit.country === "NG" && (
            <span>{moneyFormatter.format(deposit.amount).toUpperCase()}</span>
          )}
        </InfoItem>
        <InfoItem>
          <span>Fee</span>
          {account.type === "digital" && (
            <span>{`${deposit.fee} ${deposit.asset}`.toUpperCase()}</span>
          )}
          {account.type === "fiat" && !deposit.country && (
            <span>
              {moneyFormatter.format(deposit.fee || deposit.fees).toUpperCase()}
            </span>
          )}
          {deposit.country === "NG" && (
            <span>
              {moneyFormatter.format(deposit.fee || deposit.fees).toUpperCase()}
            </span>
          )}
        </InfoItem>
        <InfoItem>
          <span className="bold">Total</span>
          {account.type === "digital" && (
            <span className="bold">
              {`${total} ${deposit.asset}`.toUpperCase()}
            </span>
          )}

          {deposit.channel === "card" && (
            <span className="bold">
              {moneyFormatter.format(total).toUpperCase()}
            </span>
          )}
          {/* {deposit.country === 'NG' && <span>{moneyFormatter.format(total).toUpperCase()}</span>} */}
        </InfoItem>
        {/* <InfoItem>
					<span>Profit</span>
					<span>Profit goes here</span>
				</InfoItem> */}

        <InfoItem className="line">
          <hr />
        </InfoItem>
        <InfoItem>
          <span>Paid by Customer</span>
          {account.type === "digital" && (
            <span>{`${deposit.amount} ${deposit.asset}`.toUpperCase()}</span>
          )}
          {account.type === "fiat" && !deposit.country && (
            <span>{moneyFormatter.format(deposit.amount).toUpperCase()}</span>
          )}
          {deposit.country === "NG" && (
            <span>{moneyFormatter.format(deposit.amount).toUpperCase()}</span>
          )}
        </InfoItem>

        {/* <ButtonContainer>
					<button>Process</button>
				</ButtonContainer> */}
      </Section>
    </Container>
  );
};

export default DepositInfo;
