import axios from "axios";

import { GET_ASSETS } from "./types";
import { apiBaseURL } from "../config";

const getAssetsAction = () => ({
  type: GET_ASSETS.default,
  payload: axios.get(`${apiBaseURL}/v1/assets`)
});

export const getAssets = () => async dispatch => dispatch(getAssetsAction());
