import React from 'react';
import styled from 'styled-components';

import { moneyFormatter } from '../../../../helpers/util';
import { ReactComponent as SucessSVG } from '../svgs/success.svg';
import { ReactComponent as PendingSVG } from '../svgs/pending.svg';

const Container = styled.div`
	background: #fff;
	box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 20px;
	border-radius: 3px;
	min-height: 100px;
	padding: 10px 0px 20px;
`;

const Section = styled.div`
	div.line {
		padding-top: 5px;
	}
`;

const ImageBox = styled.div`
	display: flex;
	align-items: center;
	padding: 10px;
	svg {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}
	span {
		text-transform: capitalize;
		color: #212b36;
		font-size: 0.8rem;
		font-weight: 500;
	}
`;

const InfoItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 20px 0px;
	span {
		color: #212b36;
		font-size: 0.7rem;
		font-weight: 400;
	}
	span.bold {
		font-weight: 500;
	}
	hr {
		width: 100%;
		color: #e6e6e6;
		background-color: #e6e6e6;
		height: 1px;
		padding: 0;
		border: 0;
	}
`;

export default ({ payout, type }) => {
	const total = payout.fee + payout.amount;
	return (
		<Container>
			<Section>
				<ImageBox>
					{payout.status === 'success' || (payout.status === 'completed' && <SucessSVG />)}
					{(payout.status === 'created' || payout.status === 'pending') && <PendingSVG />}
					<span>{payout.status}</span>
				</ImageBox>
				<InfoItem>
					<span>Amount</span>
					{type !== 'fiat' && <span>{`${payout.amount.toFixed(8)} ${payout.asset}`}</span>}
					{type === 'fiat' && <span>{moneyFormatter.format(payout.amount)}</span>}
				</InfoItem>
				<InfoItem>
					<span>Fee</span>
					{type !== 'fiat' && <span>{`${payout.fee.toFixed(8)} ${payout.asset}`}</span>}
					{type === 'fiat' && <span>{moneyFormatter.format(payout.fee)}</span>}
				</InfoItem>
				<InfoItem>
					<span className="bold">Total</span>
					{type !== 'fiat' && <span className="bold">{`${total.toFixed(8)} ${payout.asset}`}</span>}
					{type === 'fiat' && <span className="bold">{moneyFormatter.format(total)}</span>}
				</InfoItem>
				<InfoItem className="line">
					<hr />
				</InfoItem>
				<InfoItem>
					<span>Paid out</span>
					{type !== 'fiat' && <span>{`${total.toFixed(8)} ${payout.asset}`}</span>}
					{type === 'fiat' && <span>{moneyFormatter.format(total)}</span>}
				</InfoItem>
			</Section>
		</Container>
	);
};
