import React from "react";
import styled from "styled-components";

// import { Card, CardBody } from "../../Payouts/components/common";
import CustomizableLoader from "../../common/CustomizableLoader";

import { Permissions, AccountInfo, Form } from "./elements";

const Circle = styled(CustomizableLoader)`
  margin-right: 20px;
`;

export default () => (
  <Permissions>
    <h3 className="title">Account information</h3>

    <div>
      <AccountInfo>
        <h3 className="title">Staff profile</h3>

        <Form>
          <div className="topForm">
            <div className="avatarCont">
              <Circle borderRadius="100%" height="32px" width="32px" />

              <div className="uploadWrapper">
                <button className="btn">Upload photo</button>

                <input type="file" accept="image/*" />
              </div>

              <button className="btn" disabled>
                Delete photo
              </button>
            </div>

            <div className="formGroup">
              <div className="inputGroup">
                <label>
                  <CustomizableLoader
                    borderRadius="3px"
                    height="10px"
                    width="80px"
                  />
                </label>
                <CustomizableLoader
                  borderRadius="3px"
                  height="20px"
                  width="100%"
                />
              </div>

              <div className="inputGroup">
                <label>
                  <CustomizableLoader
                    borderRadius="3px"
                    height="10px"
                    width="80px"
                  />
                </label>
                <CustomizableLoader
                  borderRadius="3px"
                  height="20px"
                  width="100%"
                />
              </div>
            </div>

            <div className="inputGroup">
              <label>
                <CustomizableLoader
                  borderRadius="3px"
                  height="10px"
                  width="80px"
                />
              </label>
              <CustomizableLoader
                borderRadius="3px"
                height="20px"
                width="100%"
              />
            </div>
          </div>

          <div className="bigBio">
            <p className="bio-details">
              <CustomizableLoader
                borderRadius="3px"
                height="10px"
                width="80px"
              />
            </p>

            <div className="textareaTitle">
              <CustomizableLoader
                borderRadius="3px"
                height="10px"
                width="80px"
              />
              <CustomizableLoader
                borderRadius="3px"
                height="80px"
                width="100%"
              />

              <CustomizableLoader
                borderRadius="3px"
                height="10px"
                width="100%"
              />

              <br />
              <CustomizableLoader
                borderRadius="3px"
                height="10px"
                width="79px"
              />
            </div>
          </div>
        </Form>
      </AccountInfo>
    </div>
  </Permissions>
);
