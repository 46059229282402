import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";

import {
  Content,
  SubHead,
  Header,
  CollectAccountDetails,
  Footer,
  Button
} from "./elements";
import isEqual from "lodash/isEqual";
import SVG from "react-inlinesvg";
import back from "../back.svg";
import Checkbox from "../../common/Checkbox";
import { Redirect } from "react-router-dom";
import { Spinner } from "react-activity";
import getPermissions from "../../../actions/getPermissions";
import createStaff from "../../../actions/createStaff";

class AddAccount extends Component {
  state = {
    fullPermissions: true,
    permissionsState: {},
    formIsDirty: false,
    loading: false
  };

  handleCheck = ({ target: { name, checked } }) => {
    this.setState({
      formIsDirty: true,
      [name]: checked
    });
  };

  handleInputChange = ({ target: { name, value } }) => {
    this.setState({
      formIsDirty: true,
      [name]: value
    });
  };

  tokenizePermissions = permissions => {
    const tokenizedPermissions = {};

    permissions.forEach(permission => {
      tokenizedPermissions[permission] = false;
    });

    return tokenizedPermissions;
  };

  handlePermissionsCheck = ({ target: { name, checked } }) => {
    this.setState({
      formIsDirty: true,
      permissionsState: { ...this.state.permissionsState, [name]: checked }
    });
  };

  preparePayload = ({ permissionsState, first_name, last_name, email }) => {
    const selectedPermissions = Object.keys(permissionsState).filter(
      permission => permissionsState[permission] && permission
    );

    let payload;

    if (this.state.fullPermissions) {
      payload = {
        first_name,
        last_name,
        email,
        full_access: this.state.fullPermissions
      };
    } else {
      payload = {
        first_name,
        last_name,
        email,
        permissions: selectedPermissions
      };
    }

    return payload;
  };

  onLoading = () => {
    this.setState(prevState => ({
      loading: !prevState.loading
    }));
  };
  handleSubmit = () => {
    const { createStaff } = this.props;
    const payload = this.preparePayload(this.state);
    console.log(payload);

    createStaff(payload, this.props.history.push, this.onLoading);
  };

  componentDidMount() {
    document.title = "Add a new user";
    const {
      general_permissions,
      settings_permissions
    } = this.props.permissions.payload;

    if (general_permissions && settings_permissions) {
      const permissions = [...settings_permissions, ...general_permissions];
      const tokenizedPermissions = this.tokenizePermissions(permissions);

      this.setState({
        permissionsState: tokenizedPermissions
      });
    } else {
      this.props.getPermissions();
    }
  }
  permissions = () => {};
  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.permissions.payload, prevProps.permissions.payload)
    ) {
      if (
        this.props.permissions.payload.general_permissions &&
        this.props.permissions.payload.settings_permissions
      ) {
        const permissions = [
          ...this.props.permissions.payload.general_permissions,
          ...this.props.permissions.payload.settings_permissions
        ];
        const tokenizedPermissions = this.tokenizePermissions(permissions);

        this.setState({
          permissionsState: tokenizedPermissions
        });
      }
    }
  }

  render() {
    const { fullPermissions, permissionsState, loading } = this.state;
    const {
      permissions: { fetching, fetched, payload }
    } = this.props;
    console.log({ fetching, fetched, payload });
    return (
      <div
        style={{
          width: "100%",
          marginTop: "30px"
        }}
      >
        <Content>
          <ToastContainer
            closeButton={false}
            autoClose={2000}
            hideProgressBar={true}
          />
          <Header>
            <nav>
              <a
                className="back-button"
                href="#goback"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                <SVG src={back} className="svg" />
                <span>Accounts</span>
              </a>
            </nav>
          </Header>

          <SubHead>
            <h1>Add staff account</h1>

            <Button
              onClick={this.handleSubmit}
              disabled={!this.state.formIsDirty}
            >
              {loading ? <Spinner color="#ffffff" size={10} /> : "Send invite"}
            </Button>
          </SubHead>

          {fetched && (
            <CollectAccountDetails>
              <div className="instructions">
                <h3>Staff account</h3>

                <p
                  style={{
                    lineHeight: "20px"
                  }}
                >
                  Give staff access to your store by sending them an invitation.
                  If you’re working with a designer, developer, or marketer,
                  find out how to give collaborator access to your store.
                </p>
              </div>

              <form onSubmit={this.handleSubmit} className="accountDetailsForm">
                <div className="formGroup">
                  <div className="inputGroup">
                    <label>First name</label>
                    <input
                      type="text"
                      name="first_name"
                      onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="inputGroup">
                    <label>Last name</label>
                    <input
                      type="text"
                      name="last_name"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div className="inputGroup">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    onChange={this.handleInputChange}
                  />
                </div>

                <div className="foot">
                  <Checkbox
                    onChange={this.handleCheck}
                    name="fullPermissions"
                    checked={fullPermissions}
                    label="This staff account will have full permissions"
                  />
                </div>

                {!fullPermissions && (
                  <div className="permissions">
                    <h3>Edit permissions</h3>
                    <div className="all-boxes">
                      <div className="boxes">
                        <p className="permission-title">General</p>

                        {payload.general_permissions.map(permission => (
                          <p className="checks" key={permission}>
                            <Checkbox
                              label={permission}
                              onChange={this.handlePermissionsCheck}
                              name={permission}
                              checked={permissionsState[permission]}
                            />
                          </p>
                        ))}
                      </div>
                      <div className="boxes">
                        <p className="permission-title">Settings</p>

                        {payload.settings_permissions.map(permission => (
                          <p className="checks" key={permission}>
                            <Checkbox
                              label={permission}
                              onChange={this.handlePermissionsCheck}
                              name={permission}
                              checked={permissionsState[permission]}
                            />
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </CollectAccountDetails>
          )}

          <Footer>
            <Button cancle>Cancle</Button>

            <Button
              onClick={this.handleSubmit}
              disabled={!this.state.formIsDirty}
            >
              {loading ? <Spinner color="#ffffff" size={13} /> : "Send invite"}
            </Button>
          </Footer>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  permissions: state.permissions
});

export default connect(
  mapStateToProps,
  { createStaff, getPermissions }
)(AddAccount);
