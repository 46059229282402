import styled, { css } from "styled-components";

export const Container = styled.aside`
  .overlay {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000;
    background: rgba(0, 0, 0, 0.5);
  }

  .modalContainer {
    width: ${({ width }) => width};
    background: #fff;
    border-radius: 3px;
    position: fixed;
    top: 100px;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    margin: 0 auto;
  }

  .header,
  .body,
  .footer {
    width: 100%;
    padding: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    height: 61px;

    svg {
      width: 20px;
      fill: #2f2f2f;
    }

    button {
      background: transparent;
      padding: 0;
      margin: 0;
      border: none;
      cursor: pointer;
    }

    h2 {
      margin: 0;
      padding: 0;
      font-size: 20px;
      color: rgb(33, 43, 54);
      font-weight: 400;
    }
  }

  .body {
    border: 1px solid #e7ebee;
    border-left: none;
    border-right: none;
    color: rgb(33, 43, 54);
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    line-height: 20px;
  }

  .footer {
    text-align: right;

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      min-width: 120px;
      border-radius: 3px;
      font-size: 14px;
      padding: 0 20px;
      color: #fff;
      cursor: pointer;

      ${({ buttonType }) =>
        buttonType === "blue"
          ? css`
              border: 1px solid #3f4eae;
              background-image: linear-gradient(
                rgb(99, 113, 199),
                rgb(85, 99, 193)
              );
            `
          : css`
              border: 1px solid #b02b13;
              background-image: linear-gradient(
                rgb(230, 57, 26),
                rgb(213, 52, 23)
              );
            `}
    }
  }
`;
