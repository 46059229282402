import styled, { css } from "styled-components";

export const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-top: 27px; */
  border-radius: 3px;
  min-height: 100px;
  padding-top: 16px;
`;

export const Section = styled.div`
  width: 100%;
  padding: 10px;
  ${props =>
    props.row &&
    css`
      padding: 0px 10px;
    `};

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
`;

export const FormGroup = styled.div`
  padding: 10px;
  label {
    display: block;
    color: #212b36;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 7px;
  }

  label.checkbox {
    display: flex;
  }

  input,
  textarea {
    width: 100%;
    display: block;
    min-height: 34px;
    border: 1px solid #c4cdd5;
    box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
    border-radius: 3px;
    padding: 0 8px 1px;
    font-size: 14px;
    color: #212b36;
    outline: none;
  }
  textarea {
    padding: 5px 8px;
  }
  input::placeholder,
  textarea::placeholder {
    color: #939ea9;
  }
  input:focus,
  textarea:focus {
    border: 1px solid #5c6ac4;
    box-shadow: 0 0 0 1px #5c6ac4;
  }
`;
