import React from "react";

import Select from "../../../common/Select";
import { FormSection, FormSectionInfo } from "./common";
import { Container, Section, FormGroup } from "./formElements";

const BasicInfoForm = ({ form, handleInputChange, providerType }) => {
  return (
    <FormSection>
      <FormSectionInfo>
        <h3>Basic</h3>
      </FormSectionInfo>
      <Container>
        <Section>
          <FormGroup>
            <label htmlFor="name">Name</label>
            <input
              name="name"
              id="name"
              value={form.name}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="code">Code</label>
            <input
              name="code"
              id="code"
              value={form.code}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="currency">Currency</label>
            <input
              name="currency"
              id="currency"
              value={form.currency}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Select
              name="channel"
              label="Channel"
              options={
                providerType === "desposits"
                  ? ["Card", "Bank", "Offline"]
                  : ["Bank", "Offline"]
              }
              value={form.channel}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="description">Description</label>
            <textarea
              name="description"
              rows="3"
              id="description"
              value={form.description}
              onChange={handleInputChange}
            />
          </FormGroup>
          {providerType === "deposits" && (
            <FormGroup>
              <label htmlFor="full_description">Full description</label>
              <textarea
                name="full_description"
                rows="5"
                id="full_description"
                value={form.full_description}
                onChange={handleInputChange}
              />
            </FormGroup>
          )}
        </Section>
      </Container>
    </FormSection>
  );
};

export default BasicInfoForm;
