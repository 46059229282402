import styled from "styled-components";

export const Form = styled.form`
  width: 340px;
  text-align: center;
  margin: auto;

  h1,
  h2 {
    margin: 0;
  }

  h1 {
    margin-top: 90px;
    font-size: 28px;
    font-weight: 500;
  }

  h2 {
    font-size: 20px;
    font-weight: 400;
    color: #637381;
    margin-bottom: 20px;
  }

  input {
    display: inline-block;
    width: 100%;
    border: none;
    height: 100%;
    outline: none;
    padding: 0 10px;
    font-size: 14px;
  }

  .div {
    width: 100%;
    height: 44px;
    border: 1px solid rgb(196, 205, 213);
    display: flex;
    justify-content: space-between;
    background: #fff;
  }

  .first {
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
  }

  .last {
    border-radius: 0 0 3px 3px;
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    background-image: linear-gradient(rgb(99, 113, 199), rgb(85, 99, 193));
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-top: 13px;
    border: 1px solid rgb(63, 78, 174);
    border-radius: 3px;
    cursor: pointer;
    outline: none;
  }

  section {
    display: flex;
    align-items: center;
    padding: 0 10px;

    svg {
      height: 20px;
      width: 20px;
      fill: #7d8c9a;
      color: #fff;
    }
  }
`;

export const DevPanel = styled.div`
  display: flex;
  margin-top: 20px;
  box-shadow: inset 0 3px 0 0 #eec200, inset 0 0 0 0 transparent,
    0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  background-color: #fdf7e3;
  padding: 1rem;
  border-radius: 0 0 3px 3px;

  section {
    display: block;
  }

  section:first-child {
    span {
      font-weight: bold;
    }
  }

  span {
    font-size: 14px;
    text-align: left;
    display: block;
    margin-top: 5px;
  }
`;
