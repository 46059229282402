import types from '../actions/types';

export default function(
	state = {
		fetching: false,
		fetched: false,
		searching: false,
		searched: false,
		customers: {
			meta: {},
			users: [],
		},
		error: null,
	},
	action
) {
	switch (action.type) {
		case types('fetch_customers').pending:
			return {
				...state,
				customers: {},
				fetching: true,
			};
		case types('search_customers').pending:
			return {
				...state,
				customers: {
					meta: {},
					users: [],
				},
				fetching: false,
				fetched: false,
				searching: true,
				searched: false,
			};
		case types('search_customers').fulfilled:
			return {
				...state,
				searching: false,
				searched: true,
				customers: action.payload,
			};
		case types('fetch_customers').fulfilled:
			return {
				...state,
				fetching: false,
				fetched: true,
				customers: action.payload,
			};
		case types('fetch_customers').rejected:
			return {
				...state,
				fetching: false,
				fetched: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
