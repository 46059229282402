import { UPDATE_MEMO } from "./types";
import axios from "axios";

import { apiBaseURL } from "../config";
import { toast } from "react-toastify";

const action = payload => ({
  type: UPDATE_MEMO.default,
  payload
});

const updateMemo = ({ id, memo, cb, onError }) => dispatch => {
  const url = `${apiBaseURL}/v1/payouts/crypto/${id}/memo`;
  const data = { memo };
  const payload = axios.put(url, data);

  dispatch(action(payload))
    .then(() => cb && cb())
    .catch(err => {
      onError && onError();
      err.response &&
        err.response.data &&
        err.response.data.message &&
        toast.error(err.response.data.message);
    });
};

export default updateMemo;
