import Moment from "moment";
import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Case from "case";

import { Container, MainHeader, ActionBox } from "./elements";
import DatePicker from "./DatePicker";
import ProductsOverview from "./ProductsOverview";
import AssetsOverview from "./AssetsOverview";
import Select from "../../../common/Select";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const currentOverview = props.location.pathname
      .split("/")
      .splice(-1)
      .toString();
    this.state = {
      startDate: Moment(),
      endDate: Moment(),
      dateFormat: "YYYY-MM-DD",
      currentOverview:
        currentOverview === "assets" ? currentOverview : "products"
    };
  }

  // componentDidMount() {
  //   document.title = "Transfers Analytics";
  //   const { startDate, endDate, dateFormat } = this.state;
  //   const { getTradeAnalytics } = this.props;
  //   getTradeAnalytics(
  //     startDate.format(dateFormat),
  //     endDate.format(dateFormat),
  //     true
  //   );
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   const { startDate, endDate, dateFormat } = this.state;
  //   const { getTradeAnalytics } = this.props;
  //   if (startDate !== prevState.startDate || endDate !== prevState.endDate) {
  //     console.log("make call");
  //     getTradeAnalytics(
  //       startDate.format(dateFormat),
  //       endDate.format(dateFormat),
  //       true
  //     );
  //   }
  // }

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  onCurrentOverviewChanged = e => {
    const value = e.target.value;
    const { history } = this.props;
    this.setState({ currentOverview: value }, () =>
      history.push(
        `/analytics/dashboard${value === "products" ? "" : "/assets"}`
      )
    );
  };

  render() {
    const { startDate, endDate, dateFormat, currentOverview } = this.state;
    const { match } = this.props;
    return (
      <Container>
        <div>
          <MainHeader>
            <h2>{Case.capital(currentOverview)} dashboard</h2>
          </MainHeader>
          <ActionBox>
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              onDatesChange={this.onDatesChange}
            />

            <div className="select-box">
              <Select
                name="currentOverview"
                options={["Products", "Assets"]}
                value={currentOverview}
                onChange={this.onCurrentOverviewChanged}
              />
            </div>
          </ActionBox>
          <Switch>
            <Route
              exact
              path={`${match.path}/dashboard`}
              render={routerProps => (
                <ProductsOverview
                  {...routerProps}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat={dateFormat}
                />
              )}
            />
            <Route
              exact
              path={`${match.path}/dashboard/assets`}
              render={routerProps => (
                <AssetsOverview
                  {...routerProps}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat={dateFormat}
                />
              )}
            />
          </Switch>
        </div>
      </Container>
    );
  }
}

export default Dashboard;
