import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
	margin: 65px 32px;
	height: 100%;
	width: 100%;
	min-height: calc(100vh - 65px);
`;

export const Header = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 15px;
`;

export const HeaderLeft = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const HeaderTextBox = styled.div``;
export const HeaderText = styled.h1`
	font-size: 1.6rem;
	/* padding-left: 8px; */
	padding: 0;
	margin: 0;
	font-weight: 600;
	color: #212b36;
`;
export const HeaderExportBtnBox = styled.div`
	min-width: 80px;
	${props =>
		props.smallBox &&
		css`
			width: 80px;
		`};
	margin-top: 10px;
`;
export const HeaderExportBtn = styled.a`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: transparent;
	border-radius: 3px;
	color: #637381;
	padding: 8px 8px 8px 0px;
	&:not(:first-child) {
		padding: 8px;
	}
	span {
		font-size: 0.7rem;
	}
	svg {
		color: transparent;
		fill: #637381;
		height: 20px;
		width: 20px;
		margin-right: 5px;
	}
	&:hover {
		color: #212b36;
		text-decoration: none;
	}
`;
export const HeaderRight = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	min-height: 100%;
	height: 100%;
`;

export const Body = styled.div`
	width: 100%;
	padding-top: 20px;
`;

export const Card = styled.div`
	background-color: white;
	box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
	width: 100%;
	border-radius: 3px;
	/* min-height: 200px; */
`;

export const CardHeader = styled.div`
	background-color: transparent;
	border-bottom: 1px solid #dfe3e8;
	border-radius: 0;
`;

export const CardBody = styled.div`
	min-height: 200px;
	margin: 20px 0px;
	padding: 0px 20px;
`;

////////////////
// CARD HEADER TABS BUTTON
////////////////
export const TabsBtnBox = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;

export const TabBtnList = styled.ul`
	height: 100%;
	margin: 0;
	padding: 0px;
	list-style: none;
	display: flex;
`;

export const TabBtnItem = styled.li`
	height: 100%;
	& > a {
		margin: 0px 16px;
		padding: 16px 0px;
	}
	&:not(:first-child) {
		& > a {
			padding-left: 0px;
			margin-left: 0;
		}
	}
	& > a.active-tab {
		color: #212b36;
		border-bottom-color: #5c6ac4;
	}
`;

export const TabBtnLink = styled(Link)`
	display: flex;
	justify-content: center;
	border-bottom: 3px solid transparent;
	cursor: pointer;
	min-width: 50px;
	text-align: center;
	font-size: 0.7rem;
	font-weight: 400;
	color: #637381;
	text-decoration: none;
	&:hover {
		border-bottom-color: #c4cdd5;
		color: #212b36;
	}
`;

///////////////
// SEARCH AND FILTER BOX
//////////////
export const SearchAndFilterBox = styled.div`
	display: flex;
	align-items: stretch;
	height: 36px;
	/* margin: 20px 0px;
  padding: 0px 20px; */
`;
export const FilterBox = styled.div`
	flex: ${props => (props.flex ? props.flex : '6%')};
	display: flex;
	align-items: stretch;
`;
export const SearchBox = styled.div`
	flex: 94%;
	display: flex;
	padding: 0px 10px;
	border: 1px solid #c4cdd5;
	border-radius: 3px;
	box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
`;

export const FilterBtn = styled.button.attrs({
	type: 'button',
})`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	background: linear-gradient(to bottom, white, #f9fafb);
	outline: none;
	cursor: pointer;
	box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
	border: 1px solid #c4cdd5;
	color: #212b36;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	transition-property: background, border, box-shadow, -webkit-box-shadow;
	transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
	transition-duration: 200ms;
	-webkit-tap-highlight-color: transparent;
	font-size: 0.7rem;

	&:hover {
		background: linear-gradient(to bottom, #f9fafb, #f4f6f8);
	}
`;

export const SearchIconBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.7rem;
	padding-right: 5px;
	color: #95a7b7;
`;
export const SearchInput = styled.input`
	width: 100%;
	border: none;
	outline: none;
	font-size: 0.7rem;
	color: #31373d;
	&::placeholder {
		color: #95a7b7;
	}
`;

/////////////////////
// TABLE
////////////////////
export const TableBox = styled.div`
	width: 100%;
	padding: 20px 0px;
`;

export const Table = styled.table`
	width: 100%;
	color: #212b36;
	table-layout: auto;
`;

export const TableHead = styled.thead``;
export const TableHeadRow = styled.tr`
	/* height: 51px; */
	& > th.align-right {
		text-align: right;
	}
`;
export const TableHeadData = styled.th`
	font-weight: 500;
	font-size: 0.7rem;
	text-align: left;
	color: #212b36;
	padding: 14px 10px;
	border-bottom: 1px solid #ebeef0;
	line-height: 1.5rem;
	& > span {
	}
	& > label {
		margin: 0;
	}
	${props =>
		props.hasSelect &&
		css`
			width: 55px;
			/* max-width: 65px; */
			padding-left: 0px;
		`};
`;
export const TableHeadCheckboxBox = styled.div`
	display: flex;
`;
export const CheckboxLeft = styled.div`
	padding: 5px 5px;
	background: linear-gradient(to bottom, white, #f9fafb);
	outline: none;
	cursor: pointer;
	box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
	border: 1px solid #c4cdd5;
	color: #212b36;
	border-radius: 3px;
	transition-property: background, border, box-shadow, -webkit-box-shadow;
	transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
	transition-duration: 200ms;
	-webkit-tap-highlight-color: transparent;
	font-size: 0.7rem;

	height: 30px;
	display: flex;
	align-items: center;

	& > label {
		margin-bottom: 0;
	}

	& > svg {
		color: #939ea9;
	}

	${props =>
		props.selectedAll &&
		css`
			background: #f4f6f8;
			border-color: #c4cdd5;
			color: #919eab;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		`};
`;
export const CheckboxRight = styled.div`
	padding: 5px 10px;
	background: linear-gradient(to bottom, white, #f9fafb);
	outline: none;
	cursor: pointer;
	box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
	border: 1px solid #c4cdd5;
	color: #212b36;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	border-left: none;
	transition-property: background, border, box-shadow, -webkit-box-shadow;
	transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
	transition-duration: 200ms;
	-webkit-tap-highlight-color: transparent;
	font-size: 0.7rem;
	width: 100px;

	height: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const TableBody = styled.tbody``;
export const TableRow = styled.tr`
	/* height: 50px; */
	span.customer-view-icon {
		margin-left: 5px;
		display: flex;
		height: 12px;
		width: 12px;
		border-radius: 50%;
		background: #95a7b7;
		align-items: center;
		opacity: 0;
		a {
			padding: 2px 0px 1px;
		}
		svg {
			width: 12px;
			height: 12px;
			transform: rotate(180deg);
		}
	}
	&:hover {
		background-color: #f9fafb;
		cursor: pointer;
		span.customer-view-icon {
			opacity: 1;
		}
	}
`;
export const TableData = styled.td`
	font-weight: 400;
	font-size: 0.7rem;
	text-align: left;
	color: #212b36;
	height: 50px;
	box-sizing: border-box;
	padding: 8px 10px;
	border-bottom: 1px solid #ebeef0;
	line-height: 1.2rem;
	${props =>
		props.width &&
		css`
			width: ${props.width};
		`};

	${props =>
		props.hasSelect &&
		css`
			width: 55px;
			max-width: 55px;
		`};

	& > label {
		margin: 0;
	}

	& div.align-right {
		text-align: right;
	}

	& div.padding-right {
		padding-right: 20px;
	}
	& > a {
		text-decoration: none;
	}
`;
export const TableDataBox = styled.div`
	strong {
		font-weight: 600;
	}

	& > span {
		background-color: transparent;
		font-size: 0.7rem;
		font-weight: 400;
		color: #212b36;
	}
	& > span.has-customer-link {
		display: flex;
		align-items: center;
		a {
			text-decoration: none;
			font-size: 0.7rem;
			font-weight: 400;
			color: #212b36;
		}
	}
	& > span.status-indicator {
		padding: 3px 10px;
		border: 2px solid white;
		border-radius: 15px;
	}

	& > span.success {
		color: #576675;
		background-color: #dfe3e8;
	}
	& > span.pending {
		background-color: #ffc58b;
		color: #212b36;
	}

	& > span.created {
		background-color: #ffea8a;
		color: #212b36;
	}
	& > span.cancelled {
		color: #d83e3e;
	}

	& > span.strikeout {
		text-decoration: line-through;
	}
`;

const statuses = {
	created: '#DFE3E8',
	success: '#BAE5B3',
	processing: '#FFC48B',
	pending: '#FFEA8A',
	status: '#EE6D51',
	fulfilled: '#DEE3E7',
	completed: '#BAE5B3',
	unfulfilled: '#FFEA8A',
};

export const Status = styled.span`
	display: inline-flex;
	align-items: center;
	height: 24px;
	border-radius: 24px;
	padding: 0 10px;
	border: 2px solid #fff;
	background: ${({ status }) => statuses[status]} !important;
`;

export const ActiontBtnBox = styled.button`
	cursor: pointer;
	background: transparent;
	display: flex;
	border-color: transparent;
	border-radius: 3px;
	color: #637381;
	padding: 8px;
	&:hover {
		color: #212b36;
		text-decoration: none;
	}
	&:active {
		background-color: rgba(33, 43, 54, 0.1);
		text-decoration: none;
	}
	span {
		font-size: 0.7rem;
	}
	svg {
		margin-right: 5px;
		width: 20px;
		height: 20px;
		fill: #637381;
	}
`;

export const NextPrev = styled.a`
	height: 15px;
	color: #637381;
	margin-left: 15px;

	svg.arrow-left {
		transform: rotate(-90deg);
	}

	svg.arrow-right {
		transform: rotate(90deg);
	}

	svg {
		height: 16px;
		width: 16px;
		path {
			fill: #637381;
		}
	}
`;

export const PayoutActionBtnsBox = styled.header`
	margin-top: 5px;
	display: flex;
	background-color: transparent;
	margin-left: -10px;
	& > button:first-child {
		margin-right: 15px;
	}
`;

export const ReferenceAndDateBox = styled.div`
	display: flex;
	align-items: flex-end;
	h1 {
		font-size: 1.4rem;
		font-weight: 500;
		margin-right: 10px;
		line-height: 23px;
	}
	span {
		color: #212b36;
		font-size: 0.7rem;
		font-weight: 400;
	}
`;
