import React, { Fragment } from "react";
import { connect } from "react-redux";
import { ReactComponent as ExportSVG } from "./images/export.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import qs from "qs";

import {
  Container,
  Header,
  Body,
  Card,
  HeaderRight,
  HeaderLeft,
  HeaderText,
  HeaderExportBtn,
  CardHeader,
  HeaderTextBox,
  HeaderExportBtnBox,
  CreateBtn,
  TabsBtnBox,
  TabBtnList,
  TabBtnItem,
  TabBtnLink,
  CardBody,
  SearchAndFilterBox,
  FilterBox,
  SearchBox,
  SearchIconBox,
  SearchInput,
  TableBox,
  CContainer,
  UploadBtnsBox,
  FilterAndSortBox,
  FilterSearchBox,
  SortBox,
  SortLabel,
  SortDropdownBox,
  CDropdownSelect
} from "./components/common";
import PayoutsTable from "./components/PayoutsTable";
import FilterDropdown from "../../common/Dropdowns/FilterDropdown";
import FullTableLoader from "../../common/SVGLoaders/FullTableLoader";
import { fetchFiatPayouts } from "../../../actions/payouts";
import { isEqual } from "lodash";
import Paginator from "../../common/Paginator";
import { isActive } from "../../../helpers/util";

class Fiat extends React.Component {
  state = {
    totalEntries: 1,
    perPage: 20
  };

  componentDidMount() {
    document.title = "Fiat Payouts";

    const { page = 1, status = "all" } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    this.props.fetchFiatPayouts(page, status, totalEntries => {
      this.setState({
        totalEntries
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { page = 1, status = "all" } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      this.props.fetchFiatPayouts(page, status, totalEntries => {
        this.setState({
          totalEntries
        });
      });
    }
  }

  render() {
    const {
      payouts,
      history: {
        location: { search }
      }
    } = this.props;
    const { totalEntries, perPage } = this.state;
    console.log({ payouts });
    return (
      <Container>
        {payouts.fetching ? (
          <FullTableLoader />
        ) : (
          <Fragment>
            <Header>
              <HeaderLeft>
                <HeaderTextBox>
                  <HeaderText>Fiat</HeaderText>
                </HeaderTextBox>
                {/* <HeaderExportBtnBox smallBox>
									<HeaderExportBtn>
										<ExportSVG />
										<span>Export</span>
									</HeaderExportBtn>
								</HeaderExportBtnBox> */}
              </HeaderLeft>
              <HeaderRight>
                <CreateBtn>Create Payout</CreateBtn>
              </HeaderRight>
            </Header>
            <Body>
              <Card>
                <CardHeader>
                  <TabsBtnBox>
                    <TabBtnList>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/payouts/fiat"
                          className={
                            isActive(undefined, search) && "active-tab"
                          }
                        >
                          All
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/payouts/fiat?status=created"
                          className={
                            isActive("created", search) && "active-tab"
                          }
                        >
                          Created
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/payouts/fiat?status=pending"
                          className={
                            isActive("pending", search) && "active-tab"
                          }
                        >
                          Pending
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/payouts/fiat?status=success"
                          className={
                            isActive("success", search) && "active-tab"
                          }
                        >
                          Success
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/payouts/fiat?status=failed"
                          className={isActive("failed", search) && "active-tab"}
                        >
                          Failed
                        </TabBtnLink>
                      </TabBtnItem>
                    </TabBtnList>
                  </TabsBtnBox>
                </CardHeader>
                <CardBody>
                  <SearchAndFilterBox>
                    <FilterBox>
                      <FilterDropdown />
                    </FilterBox>
                    <SearchBox>
                      <SearchIconBox>
                        <FontAwesomeIcon icon="search" />
                      </SearchIconBox>
                      <SearchInput placeholder="Search Payouts" />
                    </SearchBox>
                  </SearchAndFilterBox>

                  <TableBox>
                    <PayoutsTable type="fiat" payouts={payouts.fiat} />
                  </TableBox>
                </CardBody>
              </Card>
            </Body>

            <Paginator
              totalEntries={totalEntries}
              perPage={perPage}
              {...this.props}
            />
          </Fragment>
        )}
      </Container>
    );
  }
}

function mapStateToProps({ payouts }) {
  return {
    payouts
  };
}

export default connect(
  mapStateToProps,
  { fetchFiatPayouts }
)(Fiat);
