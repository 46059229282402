import React from "react";

import { FormSection, FormSectionInfo } from "./common";

import { Container, Section, FormGroup } from "./formElements";

const FeesForm = ({ form, handleInputChange }) => {
  return (
    <FormSection>
      <FormSectionInfo>
        <h3>Fees</h3>
      </FormSectionInfo>
      <Container>
        <Section>
          <FormGroup>
            <label htmlFor="deposit_fee">Deposit Fee</label>
            <input
              name="deposit_fee"
              value={form.deposit_fee}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="deposit_fee_description">
              Deposit Fee Description
            </label>
            <textarea
              name="deposit_fee_description"
              rows="2"
              id="deposit_fee_description"
              value={form.deposit_fee_description}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="payout_fee">Payout Fee</label>
            <input
              name="payout_fee"
              value={form.payout_fee}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="payout_fee_description">
              Payout Fee Description
            </label>
            <textarea
              name="payout_fee_description"
              rows="2"
              id="payout_fee_description"
              value={form.payout_fee_description}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Section>
      </Container>
    </FormSection>
  );
};

export default FeesForm;
