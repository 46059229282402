import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import sortSVGSrc from "../images/sort.svg";

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  flex: 1;
`;

export const FilterBtn = styled.button.attrs({
  type: "button"
})`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: linear-gradient(to bottom, white, #f9fafb);
  outline: none;
  cursor: pointer;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border: 1px solid #c4cdd5;
  color: #212b36;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition-property: background, border, box-shadow, -webkit-box-shadow;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-duration: 200ms;
  -webkit-tap-highlight-color: transparent;
  font-size: 0.7rem;

  &:hover {
    background: linear-gradient(to bottom, #f9fafb, #f4f6f8);
  }
`;

export const DropdownBox = styled.div`
  padding: 10px 0px;
  box-sizing: border-box;
  min-height: 100px;
  z-index: 2;

  position: absolute;
  top: 45px;
  left: 0px;
  transform: scale(0);
  margin: 0;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05),
    0 2px 7px 1px rgba(39, 44, 48, 0.16);
  border-radius: 3px;
  min-width: 300px;
  ${props =>
    props.smallDropdownBox &&
    css`
      min-width: 250px;
    `};
  opacity: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  ${props =>
    props.show &&
    css`
      opacity: 1;
      transform: scale(1);
    `}
`;
export const DropdownLabel = styled.div`
  padding: 5px 20px;
  & > span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
  }
`;

export const DropdownSelectBox = styled.div`
  height: 36px;
  display: flex;
`;
export const DropdownSelect = styled.select`
    margin: 0px 20px;
  padding: 0px 10px 0px 20px;
  background: linear-gradient(to bottom, white, #f9fafb);
  border: 1px solid;
  border-color: #c4cdd5;
  border-radius: 4px;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  height: 100%;
  outline: none;
  flex: 90%;
  appearance: none;
    background: transparent url("${sortSVGSrc}") no-repeat 220px center;
    ${props =>
    props.smallDropdownBox &&
    css`
    background: transparent url("${sortSVGSrc}") no-repeat 175px center;
    `};
    background-size: 40px 20px;
  flex: 100%;
  color: #222;
  font-size: 0.7rem;
`;
export const DropdownSelectIconBox = styled.div`
  margin-left: -25px;
  font-size: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #798c9c;
`;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDropdown: false
    };
    this.selectRef = React.createRef();
  }

  _toggleDropdown = () =>
    this.setState(state => ({ openDropdown: !state.openDropdown }));

  _openSelect = e => {
    const target = e.target;
    if (target.nodeName.toLowerCase() === "select") return;
    if (this.selectRef.current) {
      this.selectRef.current.click();
    }
  };

  render() {
    const { openDropdown } = this.state;
    const { smallDropdownBox } = this.props;

    return (
      <Container>
        <FilterBtn onClick={this._toggleDropdown}>
          <span>Filter</span>
          <FontAwesomeIcon icon="caret-down" />
        </FilterBtn>
        <DropdownBox show={openDropdown} smallDropdownBox={smallDropdownBox}>
          <DropdownLabel>
            <span>Show all payouts where:</span>
          </DropdownLabel>
          <DropdownSelectBox onClick={this._openSelect}>
            <DropdownSelect
              ref={this.selectRef}
              smallDropdownBox={smallDropdownBox}
            >
              <option>Select a filter....</option>
              <option>Status</option>
              <option>Fulfillment</option>
            </DropdownSelect>
          </DropdownSelectBox>
        </DropdownBox>
      </Container>
    );
  }
}
