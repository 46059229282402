import {
  GET_ADS,
  GET_ADS_DETAIL,
  SET_AD_STATUS,
  GET_ADS_PRODUCTS
} from "./types";

const initialState = {
  list: [],
  status: {},
  error: {},
  detail: {},
  products: [],
  fetched: false,
  fetching: false,
  fetched_ads: false,
  fetching_ads: false,
  fetched_status: false,
  fetching_status: false,
  fetched_detail: false,
  fetching_detail: false,
  fetching_products: false,
  fetched_products: false
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ADS.fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetching_ads: false,
        fetched_ads: true,
        list: payload.data
      };
    case GET_ADS_PRODUCTS.fulfilled:
      return {
        ...state,
        fetching_products: false,
        fetched_products: true,
        products: payload.data
      };
    case GET_ADS_DETAIL.fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetching_detail: false,
        fetched_detail: true,
        detail: payload.data
      };
    case SET_AD_STATUS.fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetching_status: false,
        fetched_status: true,
        status: payload.data
      };
    case SET_AD_STATUS.pending:
      return {
        ...state,
        fetching: true,
        fetched: false,
        fetching_status: true,
        fetched_status: false
      };
    case GET_ADS.pending:
      return {
        ...state,
        fetching: true,
        fetched: false,
        fetching_ads: true,
        fetched_ads: false
      };

    case GET_ADS_DETAIL.pending:
      return {
        ...state,
        fetching: true,
        fetched: false,
        fetching_detail: true,
        fetched_detail: false
      };
    case GET_ADS_PRODUCTS.pending:
      return {
        ...state,
        fetching_products: true,
        fetched_products: false,
        products: []
      };

    case SET_AD_STATUS.rejected:
      return {
        ...state,
        fetching_status: false,
        fetched_status: false,
        error: payload
      };

    case GET_ADS_PRODUCTS.rejected:
      return {
        ...state,
        fetching_products: false,
        fetched_products: false,
        products: []
      };

    case GET_ADS.rejected:
    case GET_ADS_DETAIL.rejected:
      return {
        ...state,
        fetching_status: false,
        fetched_status: false,
        fetching_detail: false,
        fetched_detail: false,
        fetching_ads: false,
        fetched_ads: false,
        error: payload,
        fetching: false,
        fetched: false
      };

    default:
      return state;
  }
};

export default reducer;
