import styled, { css } from "styled-components";
import Card from "../../../../common/Card";

export const Charts = styled.div`
  display: flex;
  /* ${props =>
    props.oneThirdFlex &&
    css`
      flex: 33%;
    `}; */
`;

export const ChartCard = styled(Card)`
  margin: 10px;

  padding: 20px 10px;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const ChartTitle = styled.div`
  padding: 0px 10px 10px;
  display: flex;

  &.title {
    padding: 0px 10px 16px;
  }

  h3.title {
    font-weight: 600;
    font-size: 16px;
    border-bottom: 2px dotted #c4cdd5;
    display: inline;
    margin: 0;
  }

  ${props =>
    props["metric-title"] &&
    css`
      padding: 0px 10px;
      margin-top: 24px;
    `};

  h3.metric {
    font-size: 20px;
    font-weight: bold;
    margin: 0px 10px 5px 0px;
  }

  h3.metric-title {
    text-transform: uppercase;
    font-size: 12px;
    margin: 0;
  }
  /* justify-content: space-between; */
`;

export const ChartBox = styled.div`
  /* height: 400px; */
  margin-top: 10px;
  padding: 0px 10px;
  .chart-tooltip {
    background-color: black;
    /* padding: 0px 5px; */
    /* width: 100%; */
    color: white;
    .date {
      padding: 5px;
      font-weight: bold;
      text-align: center;
    }
    .data-point {
      padding: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      span {
        margin-right: 5px;
      }
    }
    span.point-color {
      height: 15px;
      width: 15px;
      border-radius: 2px;
    }
    .total {
      font-weight: bold;
      display: flex;
      margin-top: 5px;
      padding: 10px 5px;
      border-top: 0.1px solid #f5f5f5;
      justify-content: center;
      span {
        margin-right: 5px;
      }
    }
  }
`;
