import styled, { css } from "styled-components";

export const Content = styled.main`
  width: 934px;
  margin: 0 auto;
  margin-top: 56px;
  position: relative;
  height: calc(100vh - 56px);

  .bold {
    font-weight: 700;
  }

  .blue {
    color: #1a68c3;
  }

  a {
    color: #007ace;
    text-decoration: none;
  }

  .little-gap {
    height: 5px;
  }
`;

export const Container = styled.section`
  display: flex;
  justify-content: space-between;

  .side-boxes {
    width: 291px;
  }
`;

export const Header = styled.header`
  margin-top: 30px;
  margin-bottom: 8px;
  width: 100%;

  nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .back-button {
    font-weight: 400;
    line-height: 14px;
    color: #637381;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    line-height: 0;

    span {
      font-size: 14px;
    }

    .svg {
      svg {
        height: 15px;
        width: 15px;
      }
    }
  }
`;

export const SubHead = styled.div`
  display: flex;
  justify-content: flex-start;
  line-height: 0;
  align-items: center;
  color: rgb(33, 43, 54);
  margin-top: 5px;
  padding-bottom: 8px;
  justify-content: space-between;

  h1 {
    font-size: 28px;
    font-weight: 600px;
  }

  p {
    font-size: 14px;
  }
`;

export const SubLinks = styled.div`
  display: flex;
  color: #667380;
  font-size: 14px;
  justify-content: flex-start;
  margin-top: 11px;

  .printer-link,
  .more-actions {
    display: inline-flex;
    align-items: center;
    margin-right: 25px;
    svg {
      margin-top: 3px;
    }
  }

  .printer-link {
    svg {
      margin-right: 3px;
      width: 20px;
      height: 20px;
      fill: #667380;
    }
  }

  .more-actions {
    svg {
      margin-left: 3px;
      width: 14px;
      height: 14px;
      fill: #667380;
    }
  }
`;

export const CollectAccountDetails = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #212b36;
  margin-top: 25px;
  justify-content: space-between;

  .instructions {
    color: #637381;
    width: 245px;

    h3 {
      padding: 0;
      margin: 0;
      color: #212b36;
      font-weight: 600;
      font-size: 16px;
      margin: 16px 0;
    }

    p {
      margin: 0;
      padding: 0;
      font-weight: 400;
      letter-spacing: normal;
    }
  }

  .accountDetailsForm {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15);
    padding: 20px 0;
    padding-bottom: 0;
    min-width: 623px;
    font-size: 14px;

    input {
      display: block;
      width: 100%;
      padding: 0 10px;
      font-size: inherit;

      :focus {
        border: 2px solid #5c6ac4 !important;
      }
    }

    input[type="checkbox"] {
      display: inline;
      height: initial;
      width: initial;
    }

    .formGroup {
      display: flex;
      padding: 0 20px;
      margin-bottom: 20px;

      .inputGroup {
        padding: 0;

        :not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    .inputGroup {
      flex: 1;
      padding: 0 20px;

      label {
        display: block;
        margin-bottom: 6px;
      }

      input {
        border-radius: 3px;
        height: 36px;
        box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
        border: 1px solid #c4cdd5;
        outline: none;
      }
    }

    .foot {
      border-top: 1px solid #dfe3e8;
      display: flex;
      align-items: center;
      height: 61px;
      padding: 0 20px;
      margin-top: 20px;
    }

    .permissions {
      padding: 20px;
      padding-top: 0;

      p {
        margin: 0;
        padding: 0;
        margin-left: 10px;
      }

      .permission-title {
        font-weight: 700;
        margin-top: 25px;
        margin-bottom: 10px;
      }

      .checks:not(:last-child) {
        margin-bottom: 10px;
      }

      h3 {
        margin: 0;
        padding: 0;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  .all-boxes {
    display: flex;
    .boxes {
      flex: 1;
    }
  }
`;

export const Footer = styled.footer`
  margin-top: 16px;
  border-top: 1px solid #dfe3e8;
  padding-top: 16px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Button = styled.button`
  font-size: 14px;
  color: #fff;
  padding: 8px 15px;
  border-radius: 3px;
  outline: none;
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  color: #fff;
  border-color: #3f4eae;
  max-height: 35px;
  min-width: 103.5px;
  :not(:last-child) {
    margin-right: 10px;
  }
  cursor: pointer;
  &:hover {
    background: linear-gradient(to bottom, #5c6ac4, #4959bd);
    border-color: #3f4eae;
  }
  :disabled {
    background: #bac0e6;
    border: 1px solid #a7aedf;
    cursor: not-allowed;
    border-color: #fff;
  }
  ${({ cancle }) => {
    return (
      cancle &&
      css`
        background: #fff;
        color: #000;
        border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
      `
    );
  }}
`;
