import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Spinner } from "react-activity";
import { ToastContainer } from "react-toastify";

import InfoLoader from "../../../common/SVGLoaders/InfoLoader";

import {
  Container,
  Header,
  ReferenceAndDateBox,
  Body
} from "../components/infoElements";
import { MainContainer, Title, FormFooter, ActionBtn } from "./elements";
import { ReactComponent as BackSVG } from "../svgs/back.svg";
import { TransactionDetailForm } from "./TransactionDetailForm";
import AmountForm from "./AmountForm";
import CustomerForm from "./CustomerForm";

import { getProducts } from "../../../../actions/products";
import { makeNewTransaction } from "../../../../actions/newTransaction";
import { priceFormatter } from "../../../../helpers/util";

class NewTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      editPrice: false,
      editFee: false,
      price: "",
      fee: "0",
      productId: "",
      side: "buy",
      base: "0",
      quote: "0",
      customer: null,
      baseCurrency: "",
      quoteCurrency: "",
      activeProduct: null
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case "productId":
        this.setState(state => ({
          [name]: value,
          ...this.getPriceFeeAndProduct(value, state.side),
          ...this.getBaseAndQuoteCurrencies(value),
          base: "0",
          quote: "0",
          editPrice: false
        }));
        break;
      case "side":
        this.setState(state => ({
          [name]: value,
          ...this.getPriceFeeAndProduct(state.productId, value),
          base: "0",
          quote: "0",
          editPrice: false
        }));
        break;
      case "base":
        this.setState(state => {
          if (state.editPrice) {
            return {
              [name]: value,
              price: this.getPriceFromQuoteAndBase(state.quote, value)
            };
          } else {
            return {
              [name]: value,
              quote: this.getCalculatedQuote(
                value,
                state.price,
                state.activeProduct
              )
            };
          }
        });
        break;
      case "quote":
        this.setState(state => {
          if (state.editPrice) {
            return {
              [name]: value,
              price: this.getPriceFromQuoteAndBase(value, state.base)
            };
          } else {
            return {
              [name]: value,
              base: this.getCalculatedBase(value, state.price)
            };
          }
        });
        break;
      case "price":
        this.setState(state => ({
          [name]: value,
          // base: this.getCalculatedBase(state.quote, value),
          quote: this.getCalculatedQuote(state.base, value, state.activeProduct)
        }));
        break;
      case "editPrice":
        this.setState(state => {
          const priceAndOrProduct = !value
            ? this.getPriceFeeAndProduct(state.productId, state.side)
            : { price: "0" };
          return {
            [name]: value,
            ...priceAndOrProduct,
            base: "0",
            quote: "0"
          };
        });
        break;
      default:
        this.setState({
          [name]: value
        });
        break;
    }
  }

  onCustomerChanged = customer => {
    this.setState({ customer });
  };

  componentDidMount() {
    this.props.getProducts().then(d => {
      const initialProduct = d.value.data[0];
      // console.log({ initialProduct });
      const price = this.getPrice(initialProduct, this.state.side);
      this.setState({
        productId: initialProduct.name,
        fee: "0", // initialProduct.fee,
        price,
        ...this.getBaseAndQuoteCurrencies(initialProduct.name),
        activeProduct: initialProduct
      });
    });
  }

  getActiveProduct = productId =>
    this.props.products.products.find(
      p => p.name.toLowerCase() === productId.toLowerCase()
    );

  getPriceFeeAndProduct = (productId, side) => {
    const activeProduct = this.getActiveProduct(productId);
    return {
      fee: "0", // activeProduct.fee,
      price: this.getPrice(activeProduct, side),
      activeProduct
    };
  };

  getPrice = (product, side) =>
    side === "buy" ? product.ask.rate : product.bid.rate;

  getBaseAndQuoteCurrencies = productId => {
    const currencies = productId.split("-");
    return {
      baseCurrency: currencies[0],
      quoteCurrency: currencies[1]
    };
  };

  getCalculatedBase = (quote, price) => {
    let base;
    try {
      base = Number(quote) / Number(price);
    } catch (error) {
      base = 0;
    }
    return priceFormatter(base, this.state.baseCurrency);
    // return base.toString();
  };

  getCalculatedQuote = (base, price, product) => {
    let quote;
    try {
      quote = Number(base) * Number(price);
    } catch (error) {
      quote = 0;
    }
    if (product.type === "fiat") {
      return priceFormatter(quote);
    } else {
      return priceFormatter(quote, this.state.baseCurrency);
    }
    // return quote.toString();
  };

  getPriceFromQuoteAndBase = (quote, base) => {
    let price;
    try {
      price = Number(quote) / Number(base);
    } catch (error) {
      price = 0;
    }
    // return price;
    return priceFormatter(price, this.state.quoteCurrency);
  };

  formIsValid = () => {
    const { price, fee, productId, side, base, quote, customer } = this.state;
    return (
      !isEmpty(customer) &&
      !isEmpty(productId) &&
      !isEmpty(side) &&
      this.isValidValue(base) &&
      this.isValidValue(quote) &&
      this.isValidValue(price)
      //  &&
      // this.isValidValue(fee)
    );
  };

  isValidValue(val) {
    return !isNaN(Number(val)) && Number(val) !== 0;
  }

  onCreateBtnClicked = () => {
    if (!this.formIsValid()) return;
    const { makeNewTransaction, history } = this.props;
    const { price, fee, productId, side, base, quote, customer } = this.state;
    makeNewTransaction(
      {
        price,
        fee: this.isValidValue(fee) ? fee : "0",
        product_id: productId.toUpperCase(),
        side,
        base,
        quote,
        user_id: customer.id
      },
      () => history.push("/transactions")
    );
  };

  render() {
    const {
      isLoading,
      editPrice,
      price,
      fee,
      productId,
      side,
      editFee,
      base,
      quote,
      baseCurrency,
      quoteCurrency
    } = this.state;
    const { products, transaction } = this.props;
    return (
      <MainContainer>
        {products.fetching ? (
          <InfoLoader />
        ) : (
          <Container>
            <ToastContainer
              closeButton={false}
              autoClose={1000}
              hideProgressBar={true}
            />
            <Header>
              <nav>
                <a
                  className="back-button"
                  href="#goback"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  <BackSVG className="svg" />
                  <span>Transactions</span>
                </a>
              </nav>
            </Header>
            <ReferenceAndDateBox>
              <Title>
                <h1>Create Transaction</h1>
              </Title>
            </ReferenceAndDateBox>
            <Body>
              <div>
                <TransactionDetailForm
                  form={{
                    isLoading: isLoading,
                    editPrice: editPrice,
                    price: price,
                    fee: fee,
                    productId: productId,
                    side: side,
                    editFee: editFee
                  }}
                  products={products.products || []}
                  baseCurrency={baseCurrency}
                  quoteCurrency={quoteCurrency}
                  handleInputChange={this.handleInputChange}
                />
                <AmountForm
                  form={{
                    base: base,
                    quote: quote
                  }}
                  baseCurrency={baseCurrency}
                  quoteCurrency={quoteCurrency}
                  handleInputChange={this.handleInputChange}
                />
              </div>
              <div>
                <CustomerForm onCustomerChanged={this.onCustomerChanged} />
              </div>
            </Body>
            <FormFooter>
              <ActionBtn
                disabled={!this.formIsValid()}
                onClick={this.onCreateBtnClicked}
              >
                {transaction.fetching ? (
                  <Spinner size={15} color="#ffffff" speed={2} />
                ) : (
                  "Create Transaction"
                )}
              </ActionBtn>
            </FormFooter>
          </Container>
        )}
      </MainContainer>
    );
  }
}

export default connect(
  ({ products, transaction }) => ({ products, transaction }),
  {
    getProducts,
    makeNewTransaction
  }
)(NewTransaction);
