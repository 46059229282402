import React from "react";

import Card from "../../../common/Card";

import { CardContainer, CardHeader, InputGroup } from "./elements";
import Select from "../../../common/Select";
import Input from "./Input";
import Checkbox from "../../../common/Checkbox";

export class TransactionDetailForm extends React.Component {
  render() {
    const { editPrice, price, fee, productId, side } = this.props.form;
    const {
      handleInputChange,
      products,
      baseCurrency,
      quoteCurrency
    } = this.props;
    return (
      <CardContainer>
        <Card>
          <CardHeader>
            <h1>Transaction Details</h1>
          </CardHeader>
          <InputGroup halfFlex>
            <div>
              <Select
                label="Product"
                options={products.map(p => p.name)}
                name="productId"
                onChange={handleInputChange}
                value={productId}
              />
            </div>
            <div>
              <Select
                label="Side"
                options={["Buy", "Sell"]}
                name="side"
                onChange={handleInputChange}
                value={side}
              />
            </div>
          </InputGroup>
          <InputGroup halfFlex>
            <div>
              <Input
                prefix={quoteCurrency.toUpperCase()}
                suffix={`/${baseCurrency.toUpperCase()}`}
                label="Price"
                value={price}
                name="price"
                onChange={handleInputChange}
                readOnly={true}
              />
              <Checkbox
                className="checkbox"
                label="Set Custom Price"
                name="editPrice"
                onChange={handleInputChange}
                value={editPrice}
              />
            </div>
            <div>
              <Input
                suffix={quoteCurrency.toUpperCase()}
                label="Fee"
                value={fee}
                name="fee"
                onChange={handleInputChange}
                // readOnly={!editFee}
              />
              {/* <Checkbox
                className="checkbox"
                label="Set Custom Fee"
                name="editFee"
                onChange={handleInputChange}
                value={editFee}
              /> */}
            </div>
          </InputGroup>
        </Card>
      </CardContainer>
    );
  }
}
