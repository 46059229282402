import {
  GET_PROVIDERS,
  GET_PROVIDER,
  CREATE_PROVIDER,
  UPDATE_PROVIDER
} from "../actions/types";

const initialState = {
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  creating: false,
  created: false,
  error: "",
  payload: {},
  meta: {},
  providers: [],
  selectedProvider: null
};

const providers = (state = initialState, { type, payload }) => {
  const cases = {
    [GET_PROVIDERS.fulfilled]: () => ({
      ...state,
      fetching: false,
      fetched: true,
      payload: payload,
      meta: payload.data.meta,
      providers: payload.data.providers
    }),
    [GET_PROVIDERS.pending]: () => ({
      ...state,
      fetched: false,
      fetching: true
    }),
    [GET_PROVIDERS.rejected]: () => ({
      ...state,
      fetched: false,
      fetching: false,
      error: payload
    }),
    // get
    [GET_PROVIDER.fulfilled]: () => ({
      ...state,
      fetching: false,
      fetched: true,
      selectedProvider: payload.data
    }),
    [GET_PROVIDER.pending]: () => ({
      ...state,
      fetched: false,
      fetching: true
    }),
    [GET_PROVIDER.rejected]: () => ({
      ...state,
      fetched: false,
      fetching: false,
      error: payload
    }),
    // create
    [CREATE_PROVIDER.fulfilled]: () => ({
      ...state,
      creating: false,
      created: true,
      payload: payload.data
    }),
    [CREATE_PROVIDER.pending]: () => ({
      ...state,
      created: false,
      creating: true
    }),
    [CREATE_PROVIDER.rejected]: () => ({
      ...state,
      created: false,
      creating: false,
      error: payload
    }),
    // update
    [UPDATE_PROVIDER.fulfilled]: () => ({
      ...state,
      updating: false,
      updated: true,
      selectedProvider: payload.data
    }),
    [UPDATE_PROVIDER.pending]: () => ({
      ...state,
      updated: false,
      updating: true
    }),
    [UPDATE_PROVIDER.rejected]: () => ({
      ...state,
      updated: false,
      updating: false,
      error: payload
    }),
    DEFAULT: () => state
  };

  return cases[type] ? cases[type]() : cases["DEFAULT"]();
};

export default providers;
