import { connect } from "react-redux";
import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Ads from "./Ads";
import Trades from "./Trades";
import AdsDetail from "./AdsDetail";
import TradeDetail from "./TradeDetail";
import Summary from "./Summary";
class Payouts extends Component {
  render() {
    const {
      user: { payload },
      match
    } = this.props;

    return (
      <Switch>
        {payload && !payload.permissions.includes("Ads") && (
          <Redirect to="/dashboard" />
        )}
        <Route exact path={`${match.path}/ads`} component={Ads} />
        <Route exact path={`${match.path}/trades`} component={Trades} />
        <Route exact path={`${match.path}/summary`} component={Summary} />
        <Route
          exact
          component={TradeDetail}
          path={`${match.path}/trades/:tradeId`}
        />
        <Route exact path={`${match.path}/ads/:adId`} component={AdsDetail} />
      </Switch>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(Payouts);
