import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Fiat from './Fiat';
import Crypto from './Crypto';

import DepositDetail from '../DepositDetail';
import Bank from './Bank';

class Deposits extends Component {
	render() {
		const {
			user: { payload },
			match,
		} = this.props;

		return (
			<Switch>
				{payload && !payload.permissions.includes('Deposits') && <Redirect to="/dashboard" />}
				<Route exact path={`${match.path}/fiat`} component={Fiat} />
				<Route exact path={`${match.path}/crypto`} component={Crypto} />
				<Route exact path={`${match.path}/bank`} component={Bank} />
				<Route exact path={`${match.path}/:type/:payoutId`} component={DepositDetail} />
			</Switch>
		);
	}
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(Deposits);
