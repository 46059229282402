import axios from "axios";
import { toast } from "react-toastify";

import { apiBaseURL, instantApi } from "../config";
import types, { GET_TRADE_ANALYTICS, GET_ASSET_ANALYTICS } from "./types";
import { getProducts } from "./products";
import { getAssets } from "./assets";

const getAnalyticsSuccessful = payload => ({
  type: types("get_analytics").fulfilled,
  payload
});

const getAnalyticsPending = () => ({
  type: types("get_analytics").pending
});

export const getAnalytics = (period, start, end) => async dispatch => {
  try {
    dispatch(getAnalyticsPending());

    const {
      data: { data: prices }
    } = await axios.get(`${instantApi}/v1/prices/NGN`);

    const payoutsRequests = prices.map(price => {
      return axios.get(
        `${apiBaseURL}/v1/analysis/report/${
          price.base
        }/payout?period=${period}&start=${start}&end=${end}`
      );
    });

    const depositsRequests = prices.map(price => {
      return axios.get(
        `${apiBaseURL}/v1/analysis/report/${
          price.base
        }/deposit?period=${period}&start=${start}&end=${end}`
      );
    });

    const [payoutsData, depositsData] = await Promise.all([
      Promise.all(payoutsRequests),
      Promise.all(depositsRequests)
    ]);

    const payoutsAnalytics = {};
    const depositsAnalytics = {};

    payoutsData.forEach(({ data }) => {
      const { unit } = data;

      payoutsAnalytics[unit] = data;
    });

    depositsData.forEach(({ data }) => {
      const { unit } = data;

      depositsAnalytics[unit] = data;
    });

    dispatch(
      getAnalyticsSuccessful({
        payoutsAnalytics,
        depositsAnalytics,
        period
      })
    );
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

const getSwapAnalyticsSuccessful = payload => ({
  type: types("get_swap_analytics").fulfilled,
  payload
});

const getSwapAnalyticsPending = () => ({
  type: types("get_swap_analytics").pending
});

export const getSwapAnalytics = (period, start, end) => async dispatch => {
  try {
    dispatch(getSwapAnalyticsPending());

    const { data: products } = await axios.get(`${apiBaseURL}/v1/products`);

    const buyRequests = products.map(product =>
      axios.get(
        `${apiBaseURL}/v1/analysis/report/swap/${
          product.id
        }/buy?period=${period}&start=${start}&end=${end}`
      )
    );

    const sellRequests = products.map(product =>
      axios.get(
        `${apiBaseURL}/v1/analysis/report/swap/${
          product.id
        }/sell?period=${period}&start=${start}&end=${end}`
      )
    );

    const [buySwapsData, sellSwapsData] = await Promise.all([
      Promise.all(buyRequests),
      Promise.all(sellRequests)
    ]);

    const buyAnalytics = {};
    const sellAnalytics = [];

    buySwapsData.forEach(({ data }, index) => {
      buyAnalytics[products[index].id] = data;
    });

    sellSwapsData.forEach(({ data }, index) => {
      sellAnalytics[products[index].id] = data;
    });

    dispatch(
      getSwapAnalyticsSuccessful({
        period,
        sellAnalytics,
        buyAnalytics,
        products
      })
    );
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const getTradeAnalytics = (
  start,
  end,
  initialDispatch = false
) => async (dispatch, getState) => {
  try {
    if (initialDispatch) dispatch({ type: GET_TRADE_ANALYTICS.pending });

    let {
      products: { products }
    } = getState();

    if (products.length === 0) {
      const productsDispatch = await dispatch(getProducts());
      products = productsDispatch.value.data;
    }

    const tradeRequests = products.map(product =>
      axios.get(
        `${apiBaseURL}/v1/analytics/products/${
          product.name
        }/volume?start=${start}&end=${end}&group=both`
      )
    );

    const tradeVolumeRequests = products.map(product =>
      axios.get(
        `${apiBaseURL}/v1/analytics/products/${
          product.name
        }/volume?start=${start}&end=${end}&group=single`
      )
    );

    const tradeMetricsRequests = products.map(product =>
      axios.get(
        `${apiBaseURL}/v1/analytics/products/${
          product.name
        }/metrics?start=${start}&end=${end}`
      )
    );

    const [
      tradeRequestsData,
      tradeVolumeRequestsData,
      tradeMetricsRequestsData
    ] = await Promise.all([
      Promise.all(tradeRequests),
      Promise.all(tradeVolumeRequests),
      Promise.all(tradeMetricsRequests)
    ]);

    console.log({
      tradeRequestsData,
      tradeVolumeRequestsData,
      tradeMetricsRequestsData
    });
    const tradeAnalytics = {
        period: tradeRequestsData[0].data.period,
        type: tradeRequestsData[0].data.type
      },
      tradeVolumeAnalytics = {
        period: tradeVolumeRequestsData[0].data.period,
        type: tradeVolumeRequestsData[0].data.type
      },
      tradeMetricsAnalytics = tradeMetricsRequestsData.map(t => t.data);
    tradeAnalytics.data = tradeRequestsData.map(t => t.data);
    tradeVolumeAnalytics.data = tradeVolumeRequestsData.map(t => t.data);

    dispatch({
      type: GET_TRADE_ANALYTICS.fulfilled,
      payload: {
        tradeAnalytics,
        tradeVolumeAnalytics,
        tradeMetricsAnalytics
      }
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const getAssetAnalytics = (
  start,
  end,
  initialDispatch = false
) => async (dispatch, getState) => {
  try {
    if (initialDispatch) dispatch({ type: GET_ASSET_ANALYTICS.pending });

    let {
      assets: { assets }
    } = getState();

    if (assets.length === 0) {
      const assetsDispatch = await dispatch(getAssets());
      assets = assetsDispatch.value.data;
    }

    const assetsRequests = assets.map(asset =>
      axios.get(
        `${apiBaseURL}/v1/analytics/assets/${
          asset.code
        }?start=${start}&end=${end}`
      )
    );

    const assetsRequestsData = await Promise.all(assetsRequests);

    console.log({
      assetsRequestsData
    });
    const assetAnalytics = {
      period: assetsRequestsData[0].data.period,
      type: assetsRequestsData[0].data.type
    };
    assetAnalytics.data = assetsRequestsData.map(t => t.data);

    dispatch({
      type: GET_ASSET_ANALYTICS.fulfilled,
      payload: assetAnalytics
    });
  } catch (err) {
    if (err.response) toast.error(err.response.data.message);
  }
};
