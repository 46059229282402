import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const CellCont = styled(Link)`
  display: inline-flex;
  height: 35px;
  width: 35px;
  font-weight: 600;
  border: 1px solid #5a68c3;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: #fff;
  text-decoration: none;
  color: #5a68c3;

  &.active {
    background: #5a68c3;
    color: #fff;
    pointer-events: none;
  }

  &:hover {
    background: #f4f6f8;
  }

  :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  :last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  :not(:last-child) {
    border-right: none;
  }
`;
