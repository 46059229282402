import axios from "axios";
import { toast } from "react-toastify";

import {
  GET_CURRENCIES,
  GET_CURRENCY,
  CREATE_CURRENCY,
  UPDATE_CURRENCY
} from "./types";
import { apiBaseURL } from "../config";

const getCurrenciesAction = () => ({
  type: GET_CURRENCIES.default,
  payload: axios.get(`${apiBaseURL}/v1/currencies`)
});

const getCurrencyAction = currencyCode => ({
  type: GET_CURRENCY.default,
  payload: axios.get(`${apiBaseURL}/v1/currencies/${currencyCode}`)
});

const updateCurrencyAction = (currencyCode, data) => ({
  type: UPDATE_CURRENCY.default,
  payload: axios.put(`${apiBaseURL}/v1/currencies/${currencyCode}`, data)
});

const createCurrencyAction = data => ({
  type: CREATE_CURRENCY.default,
  payload: axios.post(`${apiBaseURL}/v1/currencies/`, data) //Promise.resolve({ data: { message: "success" } })
});

export const getCurrencies = () => async dispatch =>
  dispatch(getCurrenciesAction());

export const getCurrency = currencyCode => dispatch =>
  dispatch(getCurrencyAction(currencyCode));

export const updateCurrency = (currencyCode, data, cb) => dispatch =>
  dispatch(updateCurrencyAction(currencyCode, data))
    .then(res => {
      toast.success(res.value.data.message, {
        onClose: () => (cb ? cb(res) : null)
      });
    })
    .catch(err => {
      toast.error(err.response.data.message);
    });

export const createCurrency = (data, cb) => dispatch =>
  dispatch(createCurrencyAction(data))
    .then(res => {
      // console.log({ res });
      return toast.success(res.value.data.message, {
        onClose: () => (cb ? cb(res) : null)
      });
    })
    .catch(err => {
      console.log({ err });
      toast.error(err.response.data.message);
    });
