import styled from "styled-components";

import { Container as CContainer } from "../components/common";
import Card from "../../../common/Card";

export const MainContainer = styled(CContainer)`
  margin: 65px 0px;
  font-size: 20px;
`;

export const Title = styled.div`
  margin-top: 10px;
  h1 {
    font-weight: bold;
  }
`;

export const CardContainer = styled.div`
  margin-top: 20px;
`;

export const CardHeader = styled.div`
  padding: 20px 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 0.8em;
    font-weight: 600;
  }

  button.action {
    padding: 5px;
    background: transparent;
    border: 0;
    outline: 0;
    box-shadow: none;
    color: #007ace;
    text-decoration: none;

    &:active,
    &:focus {
      background: rgba(33, 43, 54, 0.05);
      border: 0;
      outline: 0;
      box-shadow: none;
      border-radius: 3px;
    }
    svg {
      color: transparent;
      fill: #007ace;
      height: 15px;
      width: 15px;
    }
  }
`;

export const InputGroup = styled.div`
  padding: 20px;
  display: flex;
  & > div {
    flex: 1;
    margin: 0 10px;
  }

  & > div:first-child {
    flex: ${props => (props.halfFlex ? 1 : 2)};
    /* display: flex; */
  }

  .checkbox {
    margin-top: 20px;
    font-size: 15px;
  }
`;

export const SearchBox = styled.div`
  padding: 20px;
  position: relative;
`;

export const SearchResultBox = styled.div`
  min-height: 60px;
  position: absolute;
  top: 75px;
  right: 0;
  width: 100%;
  padding: 0px 20px;
  z-index: 10;
`;

export const SearchResultBoxCard = styled(Card)`
  margin: 0;
  min-height: 90px;
`;

export const SearchCenterBox = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 17px;
  }
`;

export const CustomerResults = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  padding: 10px 0px;
`;

export const CustomerResultItem = styled.div`
  display: flex;
  padding: 8px 20px;
  div:first-child {
    margin-right: 10px;
  }
  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    span:first-child {
      margin-bottom: 5px;
    }
  }
  span {
    font-size: 15px;
  }

  span.email {
    color: #637381;
    font-size: 13px;
  }
  img {
    height: 30px;
    width: 30px;
    border-radius: 100%;
  }

  &:hover {
    background: #5c6ac4;
    color: white;
    cursor: pointer;
    span {
      color: white;
    }
  }
`;

export const CustomerDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  img {
    height: 60px;
    width: 60px;
    margin: 5px 0px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    margin: 5px 0px;
    font-size: 14px;
  }
`;

export const FormFooter = styled.div`
  border-top: 1px solid #dfe3e8;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 20px;
`;

export const ActionBtn = styled.button`
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  border: 1px solid #3f4eae;
  box-shadow: inset 0 1px 0 0 #6f7bcb;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 0.7rem;
  border-radius: 3px;
  text-decoration: none;
  min-width: 147px;
  height: 38px;
  &:last-child {
    margin-left: 16px;
  }

  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
    background: #f6f8f9;
    box-shadow: none;
    color: initial;
    border: 1px solid #c3cdd5;
    pointer-events: none;
  }

  &:hover {
    background: linear-gradient(to bottom, #5c6ac4, #4959bd);
    border-color: #3f4eae;
    color: white;
  }
`;
