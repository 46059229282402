import { UPDATE_ADDRESS } from "./types";
import axios from "axios";

import { apiBaseURL } from "../config";
import { toast } from "react-toastify";

const action = payload => ({
  type: UPDATE_ADDRESS.default,
  payload
});

const updateAddress = ({ id, address, cb, onError }) => dispatch => {
  const url = `${apiBaseURL}/v1/payouts/crypto/${id}/address`;
  const data = { address };
  const payload = axios.put(url, data);

  dispatch(action(payload))
    .then(() => cb && cb())
    .catch(err => {
      onError && onError();
      err.response &&
        err.response.data &&
        err.response.data.message &&
        toast.error(err.response.data.message);
    });
};

export default updateAddress;
