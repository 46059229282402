import React from "react";
import styled from "styled-components";

const PageFooter = ({ toggleModal }) => (
  <Footer>
    <BlueBtn onClick={toggleModal}>Edit order</BlueBtn>
  </Footer>
);

const RedBtn = styled.button`
  height: 36px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-image: linear-gradient(rgb(230, 57, 26), rgb(213, 52, 23));
  color: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(176, 43, 19);
  border-image: initial;
  border-radius: 3px;
  padding: 0px 15px;
`;

const BlueBtn = styled.button`
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  border: 1px solid #3f4eae;
  box-shadow: inset 0 1px 0 0 #6f7bcb;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 0.7rem;
  border-radius: 3px;
  -webkit-text-decoration: none;
  text-decoration: none;
`;

const Footer = styled.footer`
  margin-top: 40px;
  border-top: 1px solid rgb(223, 227, 232);
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
`;

export default PageFooter;
