import styled from "styled-components";

export const Content = styled.main`
  width: 998px;
  margin: 0 auto;
  margin-top: 56px;
  position: relative;
  min-height: calc(100vh - 56px);
  line-height: 20px;

   .btn {
    border-radius: 3px;
    padding: 9px 15px;
    linear-gradient(to bottom, white, #f9fafb);
    border: 1px solid #c4cdd5;
    font-size: 14px;
    font-weight: 400;
    color: #212b36;

    :disabled {
      cursor: not-allowed;
      background: #F4F5F8;
      color: #919DAB;
    }
  }

  .bold {
    font-weight: 700;
  }

  .blue {
    color: #1a68c3;
  }

  a {
    color: #007ace;
    text-decoration: none;
  }

  .little-gap {
    height: 5px;
  }
`;

export const Container = styled.section`
  display: flex;
  justify-content: space-between;

  .side-boxes {
    width: 291px;
  }
`;

export const Header = styled.header`
  margin-top: 20px;
  margin-bottom: 8px;
  width: 100%;
  margin-left: 32px;

  nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .back-button {
    font-weight: 400;
    line-height: 14px;
    color: #637381;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    line-height: 0;

    span {
      font-size: 14px;
    }

    .svg {
      svg {
        height: 15px;
        width: 15px;
      }
    }
  }
`;

export const SubHead = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgb(33, 43, 54);
  margin-top: 5px;
  padding-bottom: 8px;
  margin-left: 32px;

  h1 {
    font-size: 28px;
    font-weight: 600 !important;
  }

  p {
    font-size: 14px;
  }
`;

export const SubLinks = styled.div`
  display: flex;
  color: #667380;
  font-size: 14px;
  justify-content: flex-start;
  margin-top: 11px;

  .printer-link,
  .more-actions {
    display: inline-flex;
    align-items: center;
    margin-right: 25px;
    svg {
      margin-top: 3px;
    }
  }

  .printer-link {
    svg {
      margin-right: 3px;
      width: 20px;
      height: 20px;
      fill: #667380;
    }
  }

  .more-actions {
    svg {
      margin-left: 3px;
      width: 14px;
      height: 14px;
      fill: #667380;
    }
  }
`;

export const Permissions = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfe3e8;

  .title {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
  }

  .desc {
    color: #637381;
    font-size: 14px;
    margin-top: 20px;
    width: 247px;
    line-height: 20px;
  }
`;

export const AccountCard = styled.div`
  margin-radius: 3px;
  padding: 20px;
  width: 622px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);

  :not(:last-child) {
    margin-bottom: 20px;
  }

  .addStaff {
    background: linear-gradient(to bottom, #6371c7, #5563c1);
    display: inline-block;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    border-color: #3f4eae;
    box-shadow: inset 0 1px 0 0 #6f7bcb;
    margin-top: 20px;
  }

  .owner {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .links {
      font-size: 14px;
      font-weight: 400;

      a:first-child {
        margin-right: 20px;
      }
    }
  }

  .account {
    :not(:last-child) {
      border-bottom: 1px solid #dfe3e8;
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
  }

  .details {
    display: flex;

    .avatar {
      border-radius: 50%;
      min-width: 32px;
      height: 32px;
      background: #b662ff;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }

    .account-info {
      font-size: 14px;
      font-weight: 400;
      color: #212b36;
      display: flex;
      justify-content: space-between;
      width: 100%;
      line-height: 20px;
    }
  }
`;

export const AccountInfo = styled.div`
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  width: 623px;
  border-radius: 3px;
  padding-top: 20px;
  background: #fff;

  .title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 0 20px 20px;
  }
`;

export const Form = styled.form`
  font-size: 14px;

  .topForm {
    padding: 0 20px 20px;
    border-bottom: 1px solid #dfe3e8;
    margin-bottom:  16px;
    padding-bottom: 0;
  }

  .btn {
    border-radius: 3px;
    padding: 9px 15px;
    linear-gradient(to bottom, white, #f9fafb);
    border: 1px solid #c4cdd5;
    font-size: 14px;
    font-weight: 400;
    color: #212b36;

    :disabled {
      cursor: not-allowed;
      background: #F4F5F8;
      color: #919DAB;
    }
  }

  .uploadWrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-right: 16px;

    input[type="file"] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;

    }
  }

  .avatarCont {
    margin-bottom: 20px;
    display: flex;

    .avatar {
      background: #b763ff;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 16px;

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }

  input {
    display: block;
    width: 100%;
    padding: 0 10px;

    :focus {
      border: 2px solid #5c6ac4 !important;
    }
  }


 .formGroup {
    display: flex;
    margin-bottom: 20px;

    .inputGroup {
      padding: 0;

      :not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .passwordFormGroup {
    margin-bottom: 0;
    margin-top: 20px;
  }

  .inputGroup {
    flex: 1;

    label {
      display: block;
      margin-bottom: 6px;
    }

    input {
      border-radius: 3px;
      height: 36px;
      box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
      border: 1px solid #c4cdd5;
      outline: none;
      font-size: inherit;
    }
  }

  .bigBio {
    padding: 20px;
    padding-top: 0;
    font-weight: 600;
    color: #212b36;
    font-weight: 400;
    border-bottom: 1px solid #dfe3e8;

    .bio-details {
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      font-size: 12px;
      margin-bottom: 16px;
      font-weight: 600;
    }
  
    .textareaTitle {
      margin-bottom: 4px;
    }

    .textarea {
      width: 100%;
      height: 106px;
      border-radius: 3px;
      box-shadow: inset 0 1px 0 0 rgba(63,63,68,0.05);
      border-color: #c4cdd5;
      outline: none;
      padding: 4px 8px;
      font-size: inherit;

      :focus {
        border: 2px solid #5c6ac4 !important;
      }
    }

    .underTextarea {
      color: #637381;
      margin-bottom: 16px;
    }
  }

  .notifications {
    padding: 20px;

    :not(:last-child) {
      border-bottom: 1px solid #dfe3e8;
    }

    .notif-title {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    .notif-desc {
      color: #637381;
      margin-left: 23px;
      margin-top: -5px;
    }
  }
`;

export const LoginHistory = styled.div`
  padding: 20px;
  min-width: 623px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  width: 623px;
  border-radius: 3px;

  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
    margin-bottom: 16px;

    td {
      padding: 8px 10px;
    }

    thead {
      border-bottom: 1px solid #dfe3e8;
      td {
        font-weight: 500;
      }
    }

    tbody {
      tr:not(:last-child) {
        border-bottom: 1px solid #dfe3e8;
      }
    }
  }
`;

export const TwoStep = styled.div`
  padding: 20px;
  min-width: 623px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  width: 623px;
  border-radius: 3px;
  font-size: 14px;
  color: #212b36;

  :last-child {
    margin-top: 16px;
  }

  .title {
    padding: 0;
    margin: 0;
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 600;
  }

  .two-step-desc {
    font-size: inherit;
    margin-bottom: 20px;
  }

  .danger {
    background: linear-gradient(to bottom, #e6391a, #d53417);
    color: #fff;
    border-color: #b02b13;
  }
`;

export const Footer = styled.footer`
  padding: 16px 32px;
  text-align: right;
  margin-bottom: 50px;

  button {
    background: linear-gradient(180deg, #6371c7, #5563c1);
    border: 1px solid #3f4eae !important;
    box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05),
      0 0 0 0 transparent;
    color: #fff !important;
    fill: #fff;
  }

  button:disabled {
    background: #bac0e6 !important;
    color: #fff !important;
    box-shadow: none;
    border: 1px solid #a7aedf !important;
  }
`;
