import React from "react";

// import Checkbox from "../../../common/Checkbox";
import Select from "../../../common/Select";
import { FormSection, FormSectionInfo } from "./common";

import { Container, Section, FormGroup } from "./formElements";

const LimitForm = ({ form, handleInputChange }) => {
  return (
    <FormSection>
      <FormSectionInfo>
        <h3>Limits</h3>
      </FormSectionInfo>
      <Container>
        <Section>
          <FormGroup>
            <label htmlFor="minimum_payout_amount">Minimum Payout Amount</label>
            <input
              name="minimum_payout_amount"
              value={form.minimum_payout_amount}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="daily_payout_limit">Daily payout limit</label>
            <input
              name="daily_payout_limit"
              value={form.daily_payout_limit}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Section>
      </Container>
    </FormSection>
  );
};

export default LimitForm;
