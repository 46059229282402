import React from "react";
import { Switch, Route } from "react-router-dom";

import Assets from "./Assets";
import AssetInfo from "./AssetInfo";

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={Assets} />
      <Route
        exact
        path={`${match.path}/new`}
        render={routerProps => <AssetInfo {...routerProps} mode="create" />}
      />
      <Route
        exact
        path={`${match.path}/:assetId`}
        render={routerProps => <AssetInfo {...routerProps} mode="edit" />}
      />
    </Switch>
  );
};
