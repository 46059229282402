import types from "../actions/types";

export default function(
  state = {
    fetching: false,
    fetched: false,
    card: {
      transactions: [],
      meta: {}
    },
    crypto: { transactions: [], meta: {} },
    bank: { transactions: [], meta: {} },
    error: null
  },
  action
) {
  switch (action.type) {
    case types("fetch_card_deposits").pending:
      return {
        ...state,
        fetching: true
      };
    case types("fetch_card_deposits").fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        card: action.payload
      };
    case types("fetch_card_deposits").rejected:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload
      };
    case types("fetch_crypto_deposits").pending:
      return {
        ...state,
        fetching: true
      };
    case types("fetch_crypto_deposits").fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        crypto: action.payload
      };
    case types("fetch_crypto_deposits").rejected:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload
      };
    case types("fetch_bank_deposits").pending:
      return {
        ...state,
        fetching: true
      };
    case types("fetch_bank_deposits").fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        bank: action.payload
      };
    case types("fetch_bank_deposits").rejected:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload
      };
    default:
      return state;
  }
}
