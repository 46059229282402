import React from "react";
import styled from "styled-components";

import errorImage from "./images/404Image.svg";
import { CreateBtn } from "../Payouts/components/common";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  /* align-items: center; */
  padding-top: 56px;
  box-sizing: border-box;

  & > div {
    width: 60%;
    height: calc(100vh - 96px);
    margin: 0 auto;
  }
`;

const Body = styled.div`
  /* height: 100%; */
  margin: 20px 0 0;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  & > div {
    flex: 1;
  }

  & > div:last-child {
    img {
      margin-left: -80%;
      width: 180%;
      /* height: 100%; */
    }
  }
`;

const MessageBox = styled.div`
  font-size: 20px;
  padding-right: 25%;

  & > .title {
    padding: 10px 0px;
    h3 {
      font-size: 1.3em;
      color: #212b36;
      line-height: 35px;
    }
  }
  & > .sub-title {
    h3 {
      font-size: 1.1em;
      color: #637381;
      line-height: 30px;
    }
  }

  & > .btn-box {
    margin: 20px 0px;
    /* font-size: 1.1em !important; */
  }

  @media (max-width: 1028px) {
    font-size: 15px;
    & > .title > h3,
    & > .sub-title > h3 {
      line-height: 25px;
    }
  }
`;

const ErrorPage = () => (
  <Container>
    <div>
      <Body>
        <div>
          <MessageBox>
            <div className="title">
              <h3>The Page you're looking for couldn't be found</h3>
            </div>
            <div className="sub-title">
              <h3>
                Check the web address and try again, or try navigating to the
                page from Home.
              </h3>
            </div>
            <div className="btn-box">
              <CreateBtn to="/">Go Home</CreateBtn>
            </div>
          </MessageBox>
        </div>
        <div>
          <img src={errorImage} alt="404" />
        </div>
      </Body>
    </div>
  </Container>
);

export default ErrorPage;
