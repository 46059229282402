import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import getUserProfile from "../../actions/getUserProfile";

import { Container } from "./elements";
import Home from "./Home";
import Customers from "./Customers";
import CustomerDetails from "./CustomerDetails";
import Settings from "./Settings";
import AccountSettings from "./AccountSettings";
import AssetSettings from "./AssetSettings";
import AddAccount from "./AddAccount";
import UserProfile from "./UserProfile";
import Sidebar from "./Sidebar";
import TopNav from "./TopNav";
import Payouts from "./Payouts";
import Deposits from "./Deposits";
import StaffProfile from "./StaffProfile";
import Analytics from "./Analytics";
import Transactions from "./Transactions";
import Coinbarter from "./Coinbarter";
import Verification from "./Verification";
import VerificationDetails from "./VerificationDetails";
import CustomerTransactions from "./CustomerTransactions";
import ProviderSettings from "./ProviderSettings";
import MarketPlace from "./MarketPlace";
import ErrorPage from "./ErrorPage";

class Dashboard extends Component {
  state = {
    profileDropdownActive: false
  };

  toggleProfileDropdown = () => {
    this.setState(prevState => ({
      profileDropdownActive: !prevState.profileDropdownActive
    }));
  };

  componentDidMount() {
    document.title = "Dashboard";

    this.props.getUserProfile();
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.payload !== prevProps.user.payload) {
      if (!this.props.user.payload) {
        this.props.getUserProfile();
      }
    }
  }

  render() {
    const { user } = this.props;

    return (
      <Fragment>
        <Container>
          <TopNav user={user} />
        </Container>
        <Container>
          {user.payload && (
            <Sidebar
              location={this.props.location}
              permissions={user.payload ? user.payload.permissions : []}
            />
          )}
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            {!this.props.token && <Redirect to="/login" />}
            <Route
              exact
              path="/dashboard"
              render={routerProps => <Home {...routerProps} user={user} />}
            />
            <Route exact path="/customers" component={Customers} />
            <Route
              exact
              path="/settings"
              render={routerProps => (
                <Settings
                  {...routerProps}
                  permissions={user.payload ? user.payload.permissions : []}
                />
              )}
            />
            <Route path="/settings/assets" component={AssetSettings} />
            <Route path="/settings/providers" component={ProviderSettings} />
            <Route path="/payouts" component={Payouts} />
            <Route path="/deposits" component={Deposits} />
            <Route path="/transactions" component={Transactions} />
            <Route path="/coinbarter" component={Coinbarter} />
            <Route exact path="/customers/kyc" component={Verification} />
            <Route path="/customers/kyc/:id" component={VerificationDetails} />
            <Route path="/marketplace" component={MarketPlace} />
            <Route
              exact
              path="/settings/accounts"
              component={AccountSettings}
            />

            <Route exact path="/settings/accounts/new" component={AddAccount} />

            <Route exact path="/settings/profile" component={UserProfile} />
            <Route
              exact
              path="/settings/profile/:id"
              component={StaffProfile}
            />

            <Route
              exact
              path="/customers/:customerId"
              component={CustomerDetails}
            />
            <Route
              exact
              path="/customers/:customerId/transactions"
              component={CustomerTransactions}
            />
            <Route path="/analytics" component={Analytics} />
            <Route component={ErrorPage} />
          </Switch>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.token,
  user: state.user
});

export default connect(
  mapStateToProps,
  { getUserProfile }
)(Dashboard);
