import styled, { css } from "styled-components";

import { Container, CardBody, TableBox } from "../../Payouts/components/common";
import Table from "../../../../blocks/Table";
import { DropdownSelect } from "../../../common/Dropdowns/FilterDropdown";

// export const UL = styled.ul`
//   a {
//     color: #637381 !important;
//     font-size: 14px !important;
//     font-weight: 400;
//   }
// `;

// export const TBody = styled.tbody`
//   padding: 0;

//   td:last-child {
//     text-align: right;
//     width: 200px;
//   }

//   td:nth-child(2) {
//     text-align: right;
//     width: 100px;
//   }

//   .avatar {
//     display: inline-block;
//     margin-left: 16px;
//     margin-right: 16px;

//     img {
//       border-radius: 100%;
//       height: 32px;
//       width: 32px;
//       vertical-align: middle;
//     }
//   }
// `;

export const CContainer = styled(Container)`
  width: 56%;
  margin: 65px auto;
  min-height: 100%;

  & h1 {
    padding: 0;
  }
`;

export const UploadBtnsBox = styled.div`
  display: flex;
`;

export const FilterAndSortBox = styled.div`
  display: flex;
  padding: 0px 10px 0px 15px;
`;
export const FilterSearchBox = styled.div`
  flex: 3;
`;
export const SortBox = styled.div`
  flex: 1;
  padding: 0px 10px;
  display: flex;
`;
export const SortLabel = styled.div`
  flex: 1.5;
  display: flex;
  align-items: center;
  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
  }
`;

export const SortDropdownBox = styled.div`
  flex: 3;
`;

export const CDropdownSelect = styled(DropdownSelect)`
  margin: 0;
  /* padding: 0px 10px; */
  padding: 0px 0px 0px 5px;
  width: 100%;
  /* background-position: 130px; */
  background-position: 110%;
  background-size: 40px 22px;

  @media (min-width: 2000px) {
    background-position: 100%;
  }
`;

export const TableHeadData = styled(Table.HeadData)`
  padding: 10px;
  padding-left: 0px;
`;

export const HeaderCheckBoxBox = styled.div`
  padding: 10px 20px;
  display: flex;
  & > label {
    margin-bottom: 0;
  }
  & > span {
    font-weight: 400;
    font-size: 0.7rem;
    color: #212b36;
  }
`;
export const CheckboxLeft = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 140px;
  & > label {
    margin-bottom: 0;
    /* margin-right: 15px; */
  }
  & > span {
    font-weight: 400;
    font-size: 0.7rem;
    color: #212b36;
    padding-left: 15px;
  }

  ${props =>
    props.active &&
    css`
      padding: 2px 5px;
      background: linear-gradient(to bottom, white, #f9fafb);
      outline: none;
      cursor: pointer;
      box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
      border: 1px solid #c4cdd5;
      color: #212b36;
      border-radius: 3px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      /* transition-property: background, border, box-shadow, -webkit-box-shadow;
      transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
      transition-duration: 200ms;
      -webkit-tap-highlight-color: transparent; */
      font-size: 0.7rem;
      & > span {
        font-weight: 700;
        font-size: 0.7rem;
        color: #5c6ac4;
      }
    `};
`;

export const CCardBody = styled(CardBody)`
  padding: 0px;
`;

export const CTableBox = styled(TableBox)`
  padding: 10px 0px 0px;
`;

export const CheckBoxTD = styled(Table.BodyData)`
  padding: 20px;
  width: 32px;
`;

export const NameBox = styled.div`
  display: flex;
`;

export const NameText = styled.span`
  margin-right: 10px;
  font-weight: 600;
  font-size: 0.7rem;
  color: #212b36;
`;

export const LocationText = styled.span`
  font-weight: 400;
  font-size: 0.7rem;
  color: #212b36;
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ImageTD = styled(Table.BodyData)`
  width: 35px;
  /* height: 40px; */
  padding-left: 20px;
  img {
    width: 30px;
    border-radius: 100%;
  }
`;

export const InnerSpinner = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;
