import axios from "axios";
import mapKeys from "lodash/mapKeys";

import { getProducts } from "../../Ads.action";
import { apiBaseURL } from "../../../../../config";
import {
  GET_MARKETPLACE_ASSET_ADS_SUMMARY,
  GET_MARKETPLACE_ASSET_TRADES_SUMMARY
} from "../../types";
import { toast } from "react-toastify";

export function getMarketplaceADsAssetSummary() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_MARKETPLACE_ASSET_ADS_SUMMARY.pending });

      let {
        ads: { products }
      } = getState();

      if (products.length === 0) {
        const productsDispatch = await dispatch(getProducts());
        products = productsDispatch.value.data;
      }

      // console.log({ products });

      const productsRequests = products.map(asset =>
        axios.get(`${apiBaseURL}/v1/ads/${asset.code.toLowerCase()}/summary`)
      );

      const productsRequestsData = await Promise.all(productsRequests);
      const productsSummary = mapKeys(
        productsRequestsData.map(res => res.data),
        _s => _s.buys.currency
      );
      // console.log({
      //   productsSummary
      // });
      dispatch({
        type: GET_MARKETPLACE_ASSET_ADS_SUMMARY.fulfilled,
        payload: productsSummary
      });
    } catch (error) {
      console.log(error);
      error.response &&
        error.response.data &&
        error.response.data.message &&
        toast.error(error.response.data.message);
      dispatch({
        type: GET_MARKETPLACE_ASSET_ADS_SUMMARY.rejected,
        payload: error
      });
    }
  };
}

export function getMarketplaceTradeAssetsSummary() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_MARKETPLACE_ASSET_TRADES_SUMMARY.pending });

      let {
        ads: { products }
      } = getState();

      if (products.length === 0) {
        const productsDispatch = await dispatch(getProducts());
        products = productsDispatch.value.data;
      }

      // console.log({ products });

      const productsRequests = products.map(asset =>
        axios.get(`${apiBaseURL}/v1/trades/${asset.code.toLowerCase()}/summary`)
      );

      const productsRequestsData = await Promise.all(productsRequests);
      const productsSummary = mapKeys(
        productsRequestsData.map(res => res.data),
        _s => _s.awaiting_confirmations.currency
      );
      // console.log({
      //   productsSummary
      // });
      dispatch({
        type: GET_MARKETPLACE_ASSET_TRADES_SUMMARY.fulfilled,
        payload: productsSummary
      });
    } catch (error) {
      console.log(error);
      error.response &&
        error.response.data &&
        error.response.data.message &&
        toast.error(error.response.data.message);
      dispatch({
        type: GET_MARKETPLACE_ASSET_TRADES_SUMMARY.rejected,
        payload: error
      });
    }
  };
}
