import types from "../actions/types";

export default function(
  state = {
    fetching: false,
    fetched: false,
    transactions: {
      transactions: [],
      meta: {}
    },
    swaps: {
      transactions: [],
      meta: {}
    },
    error: null
  },
  action
) {
  switch (action.type) {
    case types("fetch_all_transactions").pending:
      return {
        ...state,
        fetched: false,
        fetching: true
      };
    case types("fetch_all_transactions").fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        transactions: action.payload
      };
    case types("fetch_all_transactions").rejected:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload
      };

    case types("fetch_all_swaps").pending: {
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    }
    case types("fetch_all_swaps").fulfilled: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        swaps: action.payload
      };
    }
    default:
      return state;
  }
}
