import styled from "styled-components";

const HeadRow = styled.tr`
  /* height: 51px; */
  span.customer-view-icon {
    margin-left: 5px;
    display: flex;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #95a7b7;
    align-items: center;
    opacity: 0;
    a {
      padding: 2px 0px 1px;
    }
    svg {
      width: 12px;
      height: 12px;
      transform: rotate(180deg);
    }
  }
  &:hover {
    background-color: #f9fafb;
    cursor: pointer;
    span.customer-view-icon {
      opacity: 1;
    }
  }
  & > th.align-right {
    text-align: right;
  }
`;

export default HeadRow;
