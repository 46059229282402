import styled, { css } from "styled-components";

export const SettingsContainer = styled.div`
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  max-width: calc(902px + 32px);
  padding: 16px;
  border-radius: 3px;
  color: #212b36;
  background: #fff;
  margin-top: 40px;
  box-shadow: rgba(63, 63, 68, 0.05) 0px 0px 0px 1px,
    rgba(63, 63, 68, 0.15) 0px 1px 3px 0px;
`;

export const SettingsCard = styled.div`
  width: calc(256px + 32px);
  display: flex;
  padding: 15px;
  border-radius: 3px;
  cursor: pointer;

  :hover {
    background: #f9fafb;

    .icon {
      background: #dfe3e7;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    height: 40px;
    background: #f4f6f8;
    border-radius: 3px;

    svg {
      width: 18px;
      height: 18px;

      path:first-child {
        fill: transparent;
      }
    }
  }

  .accounts {
    svg {
      fill: #919eab;
      path: {
      }
    }
  }

  .card-content {
    margin-left: 20px;
    line-height: 20px;

    .title {
      color: #007ace;
      font-size: 14px;
      font-weight: 600;
    }

    .subtitle {
      font-size: 14px;
      padding: 0;
      margin: 0;
      font-weight: 400;
    }
  }
  ${({ blur }) =>
    blur &&
    css`
      color: #919eab;
      pointer-events: none;
      opacity: 0.6;
    `}
`;
