import types from "../actions/types";

const initialState = {
  fetching: false,
  fetched: false,
  transfers: [],
  meta: {}
};

const coinBarterTransfers = (state = initialState, { type, payload }) => {
  switch (type) {
    case types("get_transfers").fulfilled:
      return {
        fetching: false,
        fetched: true,
        transfers: payload.transfers,
        meta: payload.meta
      };

    case types("get_transfers").pending:
      return {
        ...state,
        fetching: true,
        fetched: false
      };

    default:
      return state;
  }
};

export default coinBarterTransfers;
