import React from "react";
import styled from "styled-components";

const PageFooter = () => (
  <Footer>
    <RedBtn>Cancel order</RedBtn>
  </Footer>
);

const RedBtn = styled.button`
  height: 36px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-image: linear-gradient(rgb(230, 57, 26), rgb(213, 52, 23));
  color: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(176, 43, 19);
  border-image: initial;
  border-radius: 3px;
  padding: 0px 15px;
`;

const Footer = styled.footer`
  margin-top: 40px;
  border-top: 1px solid rgb(223, 227, 232);
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
`;

export default PageFooter;
