import React from "react";
import styled from "styled-components";
import Spinner from "react-activity/lib/Spinner";
import "react-activity/lib/Spinner/Spinner.css";

import { LoaderBox, Rect, Circle } from "../../../common/SVGLoaders";
import {
  Card,
  CardHeader,
  CardBody as CCBody
} from "../../../common/SVGLoaders/elements";

const Container = styled.div`
  margin-top: 25px;
`;
const CardBody = styled(CCBody)`
  margin: 0;
  padding: 0px 10px;
`;

const CardHeaderLoaderBox = styled.div`
  display: flex;
`;

const HeaderLoaderBox = styled.div`
  max-width: 5%;
  max-height: 20px;
  padding: 20px;
`;

const SpinnerBox = styled.div`
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AssetItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  & > svg {
    height: 40px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #ebeef0;
  }
`;

export default function AssetsLoader() {
  return (
    <Container>
      <Card>
        <CardHeader noBorder>
          <CardHeaderLoaderBox>
            <HeaderLoaderBox>
              <LoaderBox>
                <Rect height="5" x={10} width="30%" />
              </LoaderBox>
            </HeaderLoaderBox>
          </CardHeaderLoaderBox>
        </CardHeader>
        <CardBody>
          <AssetItemRow>
            <LoaderBox>
              <Circle r={15} cy={25} />
              <Rect height="5" y={25} x={65} width="30%" />
            </LoaderBox>
            <LoaderBox>
              <Rect height="5" y={25} x={145} width="100" />
              {/* <Rect height="5" y={25} x={270} width="30" /> */}
            </LoaderBox>
          </AssetItemRow>
          <AssetItemRow>
            <LoaderBox>
              <Circle r={15} cy={25} />
              <Rect height="5" y={25} x={65} width="30%" />
            </LoaderBox>
            <LoaderBox>
              <Rect height="5" y={25} x={145} width="100" />
              {/* <Rect height="5" y={25} x={270} width="30" /> */}
            </LoaderBox>
          </AssetItemRow>
          <AssetItemRow>
            <LoaderBox>
              <Circle r={15} cy={25} />
              <Rect height="5" y={25} x={65} width="30%" />
            </LoaderBox>
            <LoaderBox>
              <Rect height="5" y={25} x={145} width="100" />
              {/* <Rect height="5" y={25} x={270} width="30" /> */}
            </LoaderBox>
          </AssetItemRow>
        </CardBody>
      </Card>
    </Container>
  );
}
