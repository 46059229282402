import React from "react";
import styled from "styled-components";
import Select from "../../../common/Select";

const EditAccountBalanceForm = ({ amount, action, handleChange }) => (
  <div>
    <InputWrapper>
      <label className="label">
        <strong>Action</strong>
      </label>
      <Select
        containerClass="mb-16"
        name="action"
        options={[
          { value: "add", option: "Credit Available Balance" },
          { value: "deduct", option: "Debit Available Balance" },
          {
            value: "deduct_hold",
            option: "Credit Available Balance & Debit Hold"
          },
          {
            value: "add_hold",
            option: "Debit Available Balance & Credit Hold"
          }
        ]}
        value={action}
        onChange={handleChange}
      />
      <label className="label">
        <strong>Amount</strong>
      </label>
      <input
        type="number"
        name="amount"
        value={amount}
        onChange={handleChange}
      />
    </InputWrapper>
  </div>
);

export default EditAccountBalanceForm;

const Header = styled.h1`
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  strong {
    font-weight: 500;
  }
`;

const InputWrapper = styled.div`
  padding: 20px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #e7ebee;

  strong {
    font-weight: 500;
  }

  .label {
    display: block;
    margin-bottom: 6px;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  input {
    display: block;
    width: 100%;
    padding: 0 10px;
    border-radius: 3px;
    height: 36px;
    box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
    border: 1px solid #c4cdd5;
    outline: none;
    font-size: inherit;
    margin-bottom: 16px;

    :focus {
      border: 2px solid #5c6ac4 !important;
    }
  }

  .mb-16 {
    margin-bottom: 16px;
  }
`;
