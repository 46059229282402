import axios from "axios";
import { toast } from "react-toastify";

import { apiBaseURL } from "../config";
import types from "./types";

const fetchFiatPayoutsSuccessful = payload => ({
  type: types("fetch_fiat_payouts").fulfilled,
  payload
});

const fetchFiatPayoutsFailed = payload => ({
  type: types("fetch_fiat_payouts").rejected,
  payload
});

const fetchCryptoPayoutsSuccessful = payload => ({
  type: types("fetch_crypto_payouts").fulfilled,
  payload
});

const fetchCryptoPayoutsFailed = payload => ({
  type: types("fetch_crypto_payouts").rejected,
  payload
});

const fetchPayoutInfoSuccessful = payload => ({
  type: types("fetch_payout_info").fulfilled,
  payload
});

const fetchPayoutInfoFailed = payload => ({
  type: types("fetch_payout_info").rejected,
  payload
});

export const fetchFiatPayouts = (page = 1, status = "all", callback) => {
  const queryString =
    status === "all" ? `?page=${page}` : `?status=${status}&page=${page}`;

  return dispatch => {
    dispatch({ type: types("fetch_fiat_payouts").pending });
    return axios
      .get(`${apiBaseURL}/v1/payouts/fiat${queryString}`)
      .then(({ data }) => {
        dispatch(fetchFiatPayoutsSuccessful(data));
        callback(data.meta.total_entries_size);
      })
      .catch(error => {
        dispatch(fetchFiatPayoutsFailed(error));
      });
  };
};

export const fetchCryptoPayouts = (page = 1, status = "all", callback) => {
  const queryString =
    status === "all" ? `?page=${page}` : `?status=${status}&page=${page}`;

  return dispatch => {
    dispatch({ type: types("fetch_crypto_payouts").pending });
    return axios
      .get(`${apiBaseURL}/v1/payouts/crypto${queryString}`)
      .then(({ data }) => {
        dispatch(fetchCryptoPayoutsSuccessful(data));
        callback(data.meta.total_entries_size);
      })
      .catch(error => {
        dispatch(fetchCryptoPayoutsFailed(error));
      });
  };
};

export const fetchPayoutInfo = (type, payoutId) => {
  return dispatch => {
    dispatch({ type: types("fetch_payout_info").pending });
    return axios
      .get(`${apiBaseURL}/v1/payouts/${type}/${payoutId}`)
      .then(({ data }) => {
        dispatch(fetchPayoutInfoSuccessful(data));
      })
      .catch(error => {
        dispatch(fetchPayoutInfoFailed(error));
      });
  };
};

export const finalizePayout = (payoutId, type, cb) => dispatch => {
  cb && cb();
  dispatch({
    type: types("finalize_payout").fulfilled
  });
  return axios
    .put(`${apiBaseURL}/v1/payouts/${type}/${payoutId}/process`)
    .then(() => {
      window.location.reload();
    })
    .catch(err => {
      toast.error(err.response.data.message);
    });
};

export const fulfillPayout = (payoutId, type, cb) => dispatch => {
  cb && cb();
  dispatch({
    type: types("finalize_payout").fulfilled
  });
  return axios
    .put(`${apiBaseURL}/v1/payouts/${type}/${payoutId}/fulfilled`)
    .then(() => {
      window.location.reload();
    })
    .catch(err => {
      toast.error(err.response.data.message);
    });
};
