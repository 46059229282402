import React from "react";
import styled from "styled-components";

const Box = styled.div`
  padding: 5px 0px;
  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 25px;
    /* margin-bottom: 12px; */
    padding-bottom: 0px;
    padding-top: 3px;
    cursor: pointer;
    /* font-size: 22px; */
    color: #212b36;
    font-size: 14px;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #c4cdd5;
    background: linear-gradient(to bottom, white, #f9fafb);
  }

  /* On mouse-over */
  .container:hover input ~ .checkmark {
    /* background-color: #ccc; */
  }

  /* When the radio button is checked */
  .container input:checked ~ .checkmark {
    /* background-color: #fff; */
    border: 1px solid #5c6ac4;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #5c6ac4;
  }
`;

export default function RadioButton(props) {
  return (
    <Box>
      <label className="container">
        {props.label}
        <input type="radio" {...props} />
        <span className="checkmark" />
      </label>
    </Box>
  );
}
