import { GET_ASSETS } from "../actions/types";

const initialState = {
  fetching: false,
  fetched: false,
  error: "",
  payload: {},
  assets: []
};

const assets = (state = initialState, { type, payload }) => {
  const cases = {
    [GET_ASSETS.fulfilled]: () => ({
      ...state,
      fetching: false,
      fetched: true,
      payload,
      assets: payload.data
    }),
    [GET_ASSETS.pending]: () => ({
      ...state,
      fetched: false,
      fetching: true
    }),
    [GET_ASSETS.rejected]: () => ({
      ...state,
      fetched: false,
      fetching: false,
      error: payload
    }),
    DEFAULT: () => state
  };

  return cases[type] ? cases[type]() : cases["DEFAULT"]();
};

export default assets;
