import styled from "styled-components";

import caretRight from "./caret-right.svg";

export const Section = styled.section`
  width: 724px;
  margin: 0 auto;

  .greeting {
    margin-bottom: 0;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .subGreeting {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 20px;
    color: #637381;
  }
`;

export const CountContainer = styled.div`
  padding: 0;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border-radius: 3px;
  box-shadow: rgba(63, 63, 68, 0.05) 0px 0px 0px 1px,
    rgba(63, 63, 68, 0.15) 0px 1px 3px 0px;
  margin-top: 30px;

  .counter {
    height: 51px;
    display: flex;
    align-items: center;

    :not(:last-child) {
      border-bottom: 1px solid #e7ebee;
    }
  }

  a {
    display: flex;
    background: url(${caretRight}) no-repeat 100% 50%;
    background-size: 20px;
    align-items: center;
    height: 51px;
    text-decoration: none;
    line-height: 0;
    font-size: 16px;
    font-weight: 400;
    color: #212b36;
    width: 100%;

    svg {
      height: 20px;
      width: 20px;
      color: #5b6ac4;

      margin-right: 16px;
    }

    .dark {
      font-weight: 600;
    }
  }
`;

export const CardsContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;

  .card {
    border-radius: 3px;
    background: #fff;
    box-shadow: rgba(63, 63, 68, 0.05) 0px 0px 0px 1px,
      rgba(63, 63, 68, 0.15) 0px 1px 3px 0px;
    width: 455px;
    color: rgb(33, 43, 54);
    font-size: 14px;
    padding: 20px;

    header {
      h2 {
        font-size: 16px;
        font-weight: 600;
        padding: 0;
        margin: 0;
        padding-bottom: 20px;
      }
    }

    p {
      margin: 0;
      padding: 0;
      margin-bottom: 20px;
    }

    .subhead {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
    }

    .valueAndGraph {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #e7ebee;

      .figure {
        font-size: 28px;
        font-weight: 500;
      }
    }
  }
`;

export const Report = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid #e7ebee;

  :last-child {
    padding-top: 8px;
  }

  a {
    color: #007ace;
    text-decoration: none;

    :hover {
      text-decoration: underline;
      text-decoration-color: rgb(0, 122, 206);
    }
  }
`;

export const ShowAll = styled.div`
  text-align: center;
  margin-top: 16px;

  a {
    color: #007ace;
    text-decoration: none;

    img {
      vertical-align: top;
    }

    :hover {
      text-decoration: underline;
      text-decoration-color: rgb(0, 122, 206);
    }
  }
`;

export const Summaries = styled.div`
  display: flex;
  height: 136px;
  justify-content: space-between;
  margin-top: 20px;
  color: #919eab;

  .noMarginPadding {
    margin: 0 !important;
    padding: 0 !important;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #919eab;
    margin: 0;
  }

  p {
    padding: 0;
    margin: 0;
  }

  .text {
    margin-top: 8px;
    font-size: 13px;
    font-weight: 400;
  }

  .summary {
    width: 531px;
    height: 136px;
    border: 1px solid #c4cdd5;
    border-radius: 4px;
    display: flex;
    align-items: center;

    .one {
      width: 33.33%;
      text-align: center;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      :not(:last-child) {
        border-right: 1px solid #c4cdd5;
      }
    }
  }

  .liveCounter {
    height: 136px;
    width: 175px;
    border-radius: 4px;
    border: 1px solid #c4cdd5;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .liveViewBtn {
      border: 1px solid #c4cdd5;
      ${"" /* height: 30px; */} padding: 3px 0;
      width: 100%;
      font-size: 14px;
      display: block;
      text-align: center;
      text-decoration: none;
      border-radius: 3px;
      color: #637381;
    }

    .online {
      display: flex;
      align-items: center;
      height: 20px;

      .figure {
        font-size: 26px;
        font-weight: 600;
        color: rgb(33, 43, 54);
      }

      .live {
        background: #9c6ade;
        color: #fff;
        font-size: 11px;
        font-weight: 700;
        padding: 0 8px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        border-radius: 3px;
        margin-left: 8px;

        .pulser {
          display: inline-block;
          height: 4px;
          width: 4px;
          border-radius: 100%;
          background: #fff;
          margin-right: 5px;
        }
      }
    }
  }
`;
