import React, { Component, Fragment } from "react";
import Case from "case";
import { isEqual } from "lodash";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import { Spinner } from "react-activity";
import distanceInWords from "date-fns/distance_in_words";
import { ToastContainer, toast } from "react-toastify";
import countries from "../../common/countriesv2.json";
import {
  Level,
  Header,
  SubHead,
  Content,
  NextPrev,
  Container,
  CustomerInfo,
  CustomerActivity
} from "./elements";
import arrow from "../arrow.svg";
import LoginLogs from "../StaffProfile/LoginLogs";
import DropDown from "./components/ActionDropdown";
import EditCustomerPhone from "./EditCustomerPhone";
import AccountsCard from "./components/AccountsCard";
import CustomerCard from "./components/CustomerCard";
import { ReactComponent as BackSVG } from "./svgs/back.svg";
import { ReactComponent as ResetSVG } from "./svgs/refresh.svg";
import { ReactComponent as CancelSVG } from "./svgs/cancel.svg";
import resendVerification from "../../../actions/resendVerification";
import CustomerDetailLoader from "../../common/SVGLoaders/InfoLoader";
import { AccountActionBtnsBox, ActiontBtnBox } from "./components/common";
import {
  fetchCustomer,
  resetPassword,
  updateCustomer,
  loginActivities,
  fetchCustomerCards,
  fetchCustomerBanks,
  updateCustomerPhone,
  updateCustomerAddress,
  fetchCustomerAccounts,
  toggleCustomerActiveState,
  fetchCustomerRecentTransactions
} from "../../../actions/customer";
import RecentTransactions from "./components/RecentTransactions";
import Modal from "../../common/Modal";
import EditCustomerForm from "./EditCustomerForm";
import updateLevel from "../../../actions/updateLevel";
import EditCustomerContact from "./EditCustomerContact";

class CustomerDetails extends Component {
  constructor() {
    super();

    this.state = {
      isModalOpen: false,
      editModalOpen: false,
      editPhoneModal: false,
      editContactModal: false,
      loading: false,
      fetching: true,
      fetched: false,
      whichModal: "",
      selectedCountry: "",
      statesAndProvinces: [],
      modalLoading: false,
      levelModal: false,
      customer: {
        id: "",
        email: "",
        phone: {},
        year: "",
        month: "",
        day: "",
        profile: {
          avatar: "",
          city: "",
          country: "",
          country_code: "",
          date_of_birth: "",
          gender: "",
          first_name: "",
          last_name: "",
          postal_code: "",
          street_address1: "",
          street_address2: ""
        }
      }
    };
  }

  onEditPhoneModal = () => {
    this.setState(({ editPhoneModal }) => ({
      editPhoneModal: !editPhoneModal
    }));
  };
  onEditContactModal = () => {
    this.setState(({ editContactModal }) => ({
      editContactModal: !editContactModal
    }));
  };
  toggleEditModal = () => {
    this.setState(({ editModalOpen }) => ({
      editModalOpen: !editModalOpen
    }));
  };

  toggleCustomerStatus = () => {
    this.setState({
      modalLoading: true
    });

    const {
      toggleCustomerActiveState,
      match,
      customer: {
        customer: { active }
      }
    } = this.props;

    toggleCustomerActiveState(match.params.customerId, active).then(() => {
      this.toggleModal();

      this.setState({
        modalLoading: false
      });
    });
  };

  resetPassword = () => {
    this.setState({
      modalLoading: true
    });

    this.props.resetPassword(this.props.match.params.customerId).then(() => {
      this.setState({ modalLoading: false });
      this.toggleModal();
      toast.success("Password reset successful!");
    });
  };

  toggleModal = () => {
    this.setState(({ isModalOpen }) => ({
      isModalOpen: !isModalOpen
    }));
  };

  handleChange = ({ target: { name, value } }) => {
    const profileProperties = [
      "first_name",
      "last_name",
      "country",
      "state",
      "gender",
      "city",
      "postal_code",
      "street_address_1",
      "street_address_2",
      "date_of_birth"
    ];

    if (profileProperties.includes(name)) {
      this.setState({
        customer: {
          ...this.state.customer,
          profile: {
            ...this.state.customer.profile,
            [name]: value
          }
        }
      });
    } else {
      this.setState({
        customer: {
          ...this.state.customer,
          [name]: value
        }
      });
    }
  };

  handleCountryStateChange = ({ target: { name, value } }) => {
    if (name === "country_code") {
      this.setState({
        statesAndProvinces: this.getStatesAndProvinces(value),
        customer: {
          ...this.state.customer,
          profile: {
            ...this.state.customer.profile,
            [name]: value
          }
        }
      });
    } else {
      this.setState({
        customer: {
          ...this.state.customer,
          profile: {
            ...this.state.customer.profile,
            [name]: value.split("~")[0]
          }
        }
      });
    }
  };

  getStatesAndProvinces = country_code => {
    const country = countries.find(country => country.iso2 === country_code);
    return country.states;
  };

  preloadStates = country_code => {
    this.setState({
      statesAndProvinces: this.getStatesAndProvinces(country_code)
    });
  };

  chunkDate = () => {
    let year = "",
      month = "",
      day = "";
    if (this.state.customer.profile.date_of_birth !== "0001-01-01") {
      const [
        nyear,
        nmonth,
        nday
      ] = this.state.customer.profile.date_of_birth.split("-");
      year = nyear;
      month = nmonth;
      day = nday;
    }
    this.setState({
      customer: {
        ...this.state.customer,
        year,
        month,
        day
      }
    });
  };

  handleProfileUpdate = () => {
    const { customer } = this.state;
    customer.selectedCountry = this.state.selectedCountry;

    this.setState({
      modalLoading: true
    });

    const cb = () => {
      this.setState({
        editModalOpen: false,
        modalLoading: false
      });
      this.onEditContactModal();
      this.onGetCustomer();
    };
    this.props.updateCustomer(customer, cb);
  };

  onUpdateCustomerPhone = () => {
    const { customer } = this.state;
    const cb = () => {
      this.onGetCustomer();
      this.onEditPhoneModal();
    };
    this.props.updateCustomerPhone(customer, cb);
  };

  onUpdateCustomerAddress = () => {
    const { customer } = this.state;
    const cb = () => {
      this.onGetCustomer();
      this.toggleEditModal();
    };
    this.props.updateCustomerAddress(customer, cb);
  };

  componentDidMount() {
    document.title = "Customer's details";
    this.onGetCustomer();
  }

  onGetCustomer = () => {
    const {
      match,
      fetchCustomer,
      loginActivities,
      fetchCustomerCards,
      fetchCustomerBanks,
      fetchCustomerAccounts,
      fetchCustomerRecentTransactions
    } = this.props;
    const customerId = match.params.customerId;
    this.setState({ fetching: true });
    Promise.all([
      fetchCustomer(customerId),
      fetchCustomerCards(customerId),
      fetchCustomerBanks(customerId),
      fetchCustomerAccounts(customerId),
      fetchCustomerRecentTransactions(customerId),
      loginActivities(customerId)
    ]).then(() => this.setState({ fetching: false }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps, this.props) || !isEqual(prevState, this.state)) {
      if (!isEqual(prevProps, this.props)) {
        if (this.props.customer.customer) {
          this.setState({
            ...this.state,
            customer: {
              ...this.state.customer,
              ...this.props.customer.customer,
              phone: this.props.customer.customer.phone
                ? this.props.customer.customer.phone.number
                : ""
            }
          });
        }
      }
    }
  }
  resend = () =>
    this.props.resendVerification({
      id: this.props.customer.customer.id,
      cb: this.toggleModal
    });
  onUpdateLevel = () => {
    const { level } = this.state;
    const { id } = this.props.customer.customer;
    const cb = () => {
      this.toggleLevelModal();
      this.onGetCustomer();
    };
    this.props.updateLevel(id, level, cb);
  };
  toggleLevelModal = level => {
    this.setState(prevState => ({
      levelModal: !prevState.levelModal,
      level,
      whichModal: "levelUpdate"
    }));
  };
  render() {
    const { fetching } = this.state;
    const {
      customer: { customer, fetched },
      user
    } = this.props;
    const active = fetched && customer.active;
    const modalContent = {
      disableAct: {
        title: active ? "Disable account" : "Enable account",
        text: active
          ? "Are you sure you want to disable the account for this customer? Disabled customers will not be able to login to their account."
          : "Are you sure you want to enable the account for the customer?",
        action: this.toggleCustomerStatus
      },
      resetPwd: {
        title: "Reset password",
        text:
          "Are you sure you want to reset the password for this customer? The customer will be sent an email with instructions to reset their password.",
        action: this.resetPassword
      },
      reverify: {
        title: "Re-send verification",
        text:
          "Are you sure you want to re-send verification email to this customer? The customer will be sent an email with instructions to verify their account.",
        action: this.resend
      },
      levelUpdate: {
        title: "Update user level",
        text: `Are you sure you want to update ${customer &&
          customer.profile &&
          customer.profile.first_name}'s level?`,
        action: this.onUpdateLevel
      }
    };

    const options = [
      { action: () => this.toggleLevelModal(1), title: "Move to Level 1" },
      { action: () => this.toggleLevelModal(2), title: "Move to Level 2" },
      { action: () => this.toggleLevelModal(3), title: "Move to Level 3" }
    ];

    const isCustomerActive =
      customer && customer.active ? "Active" : "InActive";
    return (
      <Fragment>
        <ToastContainer
          closeButton={false}
          autoClose={2000}
          hideProgressBar={true}
        />
        {fetching ? (
          <CustomerDetailLoader />
        ) : (
          <Content>
            <Header>
              <nav>
                <a
                  className="back-button"
                  href="#goback"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  <BackSVG className="svg" />
                  <span>Customers</span>
                </a>

                <div>
                  <NextPrev>
                    <SVG src={arrow} className="arrow-left" />
                  </NextPrev>
                  <NextPrev>
                    <SVG src={arrow} className="arrow-right" />
                  </NextPrev>
                </div>
              </nav>
            </Header>
            <SubHead>
              <h1>
                {Case.capital(
                  `${customer.profile.first_name} ${customer.profile.last_name}`
                )}
                <Level>
                  <span className="dot" />
                  <span className="level">Level {customer.level}</span>
                </Level>
                <CustomerActivity active={customer && customer.active}>
                  <span className="dot" />
                  <span className="level">{isCustomerActive}</span>
                </CustomerActivity>
              </h1>
            </SubHead>
            <AccountActionBtnsBox>
              <ActiontBtnBox
                onClick={() => {
                  this.setState({
                    whichModal: "disableAct"
                  });
                  this.toggleModal();
                }}
              >
                <CancelSVG />
                <span>
                  {customer.active ? "Disable account" : "Enable account"}
                </span>
              </ActiontBtnBox>

              <ActiontBtnBox
                onClick={() => {
                  this.setState({
                    whichModal: "resetPwd"
                  });
                  this.toggleModal();
                }}
              >
                <ResetSVG />
                <span>Reset password</span>
              </ActiontBtnBox>
              <DropDown options={options} />
              {!customer.verified && (
                <ActiontBtnBox
                  onClick={() => {
                    this.setState({
                      whichModal: "reverify"
                    });
                    this.toggleModal();
                  }}
                >
                  <ResetSVG />
                  <span>Re-send verification</span>
                </ActiontBtnBox>
              )}
            </AccountActionBtnsBox>

            <Container>
              <div>
                <CustomerInfo>
                  <div className="top">
                    <div className="avatar">
                      <img alt="" src={customer.profile.avatar} />
                    </div>

                    <div className="bio">
                      <h4 className="title">
                        {Case.capital(
                          `${customer.profile.first_name} ${customer.profile.last_name}`
                        )}
                      </h4>
                      <p className="address">
                        {customer.profile.city &&
                          Case.capital(`${customer.profile.city}, `)}
                        {customer.profile.country &&
                          Case.capital(customer.profile.country)}
                      </p>
                      <p className="address">
                        Customer for about{" "}
                        {distanceInWords(
                          new Date(customer.created_at),
                          new Date()
                        )}
                      </p>
                    </div>
                  </div>

                  {/* <form className="customer-note">
                    <label>Customer Note</label>
                    <input type="text" placeholder="Add a note" />
                  </form> */}

                  {/* {customer.recent_transactions.length > 0 && (
                    <footer className="footer">
                      <div className="footer-content">
                        <p>
                          <a href="#sdfjdf">Last order</a>
                        </p>
                        <h3>5 days ago</h3>
                        <p>From Online Store</p>
                      </div>

                      <div className="footer-content">
                        <p>Lifetime spent</p>
                        <h3>₦15,780.00</h3>
                        <p>From Online Store</p>
                      </div>

                      <div className="footer-content">
                        <p>Average order</p>
                        <h3>₦717.28</h3>
                      </div>
                    </footer>
                  )} */}
                </CustomerInfo>
                <RecentTransactions
                  customer={customer}
                  transactions={customer.recent_transactions.Data}
                />
                <AccountsCard
                  accounts={customer.accounts}
                  onAccountBalanceUpdated={this.onGetCustomer}
                  userCanEditAccount={(user.payload
                    ? user.payload.permissions
                    : []
                  ).includes("Wallets")}
                />
                <CustomerInfo>
                  {this.props.customer.loginActivities.data && (
                    <LoginLogs
                      customerType={true}
                      login_activities={
                        this.props.customer.loginActivities.data.activities
                      }
                    />
                  )}
                </CustomerInfo>
              </div>

              <section className="side-boxes">
                <CustomerCard
                  toggleEditModal={() => {
                    this.chunkDate();
                    this.toggleEditModal();
                  }}
                  toggleEditPhoneModal={this.onEditPhoneModal}
                  toggleEditContactModal={this.onEditContactModal}
                  customer={customer}
                />
              </section>
            </Container>
          </Content>
        )}
        {this.state.levelModal && (
          <Modal
            meta={{
              width: "620px",
              buttonType: "blue"
            }}
            data={{
              title: modalContent[this.state.whichModal].title,
              loading: this.state.loading,
              buttonTxt:
                this.state.modalLoading || this.props.levelUpdate.fetching ? (
                  <Spinner color="white" />
                ) : (
                  "update"
                ),
              body: modalContent[this.state.whichModal].text
            }}
            actions={{
              toggleModal: this.toggleLevelModal,
              handleClick: modalContent[this.state.whichModal].action
            }}
          />
        )}
        {this.state.isModalOpen && (
          <Modal
            meta={{
              width: "620px",
              buttonType: "blue"
            }}
            data={{
              title: modalContent[this.state.whichModal].title,
              loading: this.state.loading,
              buttonTxt:
                this.state.modalLoading || this.props.resend.fetching ? (
                  <Spinner color="white" />
                ) : (
                  modalContent[this.state.whichModal].title
                ),
              body: modalContent[this.state.whichModal].text
            }}
            actions={{
              toggleModal: this.toggleModal,
              handleClick: modalContent[this.state.whichModal].action
            }}
          />
        )}

        {this.state.editModalOpen && (
          <Modal
            meta={{
              width: "620px",
              buttonType: "blue"
            }}
            data={{
              title: "Edit customer address",
              loading: this.state.loading,
              buttonTxt: this.state.modalLoading ? (
                <Spinner color="white" />
              ) : (
                "Save"
              ),
              body: (
                <EditCustomerForm
                  customer={this.state.customer}
                  countries={countries}
                  statesAndProvinces={this.state.statesAndProvinces}
                  handleCountryStateChange={this.handleCountryStateChange}
                  preloadStates={this.preloadStates}
                  handleChange={this.handleChange}
                  chunkDate={this.chunkDate}
                />
              )
            }}
            actions={{
              toggleModal: this.toggleEditModal,
              handleClick: this.handleProfileUpdate
            }}
          />
        )}
        {this.state.editPhoneModal && (
          <Modal
            meta={{
              width: "620px",
              buttonType: "blue"
            }}
            data={{
              title: "Phone",
              loading: this.state.loading,
              buttonTxt: this.props.customerUpdate.fetching ? (
                <Spinner color="white" />
              ) : (
                "Save"
              ),
              body: (
                <EditCustomerPhone
                  customer={this.state.customer}
                  handleChange={this.handleChange}
                />
              )
            }}
            actions={{
              toggleModal: this.onEditPhoneModal,
              handleClick: this.onUpdateCustomerPhone
            }}
          />
        )}
        {this.state.editContactModal && (
          <Modal
            meta={{
              width: "620px",
              buttonType: "blue"
            }}
            data={{
              title: "Phone",
              loading: this.state.loading,
              buttonTxt: this.state.modalLoading ? (
                <Spinner color="white" />
              ) : (
                "Save"
              ),
              body: (
                <EditCustomerContact
                  customer={this.state.customer}
                  handleChange={this.handleChange}
                />
              )
            }}
            actions={{
              toggleModal: this.onEditContactModal,
              handleClick: this.handleProfileUpdate
            }}
          />
        )}
        {this.state.editModalOpen && (
          <Modal
            meta={{
              width: "620px",
              buttonType: "blue"
            }}
            data={{
              title: "Edit customer address",
              loading: this.state.loading,
              buttonTxt: this.props.customerUpdate.fetching ? (
                <Spinner color="white" />
              ) : (
                "Save"
              ),
              body: (
                <EditCustomerForm
                  customer={this.state.customer}
                  countries={countries}
                  statesAndProvinces={this.state.statesAndProvinces}
                  handleCountryStateChange={this.handleCountryStateChange}
                  preloadStates={this.preloadStates}
                  handleChange={this.handleChange}
                  chunkDate={this.chunkDate}
                />
              )
            }}
            actions={{
              toggleModal: this.toggleEditModal,
              handleClick: this.onUpdateCustomerAddress
            }}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps({
  customer,
  resend,
  updateLevel,
  updateCustomer,
  user
}) {
  return {
    customer,
    resend,
    levelUpdate: updateLevel,
    customerUpdate: updateCustomer,
    user
  };
}
const mapDispatchToProps = {
  updateLevel,
  fetchCustomer,
  resetPassword,
  updateCustomer,
  loginActivities,
  fetchCustomerCards,
  fetchCustomerBanks,
  resendVerification,
  updateCustomerPhone,
  updateCustomerAddress,
  fetchCustomerAccounts,
  toggleCustomerActiveState,
  fetchCustomerRecentTransactions
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDetails);
