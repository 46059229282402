import React from "react";

import { Permissions, TwoStep } from "./elements";

const TwoStepAuth = () => (
  <Permissions
    style={{
      paddingTop: 0
    }}
  >
    <div>
      <h3 className="title">Two-step authentication</h3>

      <div className="desc">
        Make your account more secure with two-step authentication. Each time
        you log in you’ll be required to enter your password and a single-use
        authorization code.
      </div>
    </div>

    <TwoStep>
      <h3 className="title">You have not enabled two-step authentication.</h3>

      <div className="two-step-desc">
        Make your account more secure with two-step authentication. Each time
        you log in you’ll be required to enter your password and a single-use
        authorization code.
      </div>

      <button className="btn">Enable two-step authentication</button>
    </TwoStep>
  </Permissions>
);

export default TwoStepAuth;
