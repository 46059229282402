import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  /* width: 200px; */
  min-width: 220px;
  display: flex;
  align-items: center;
`;
const ActionButton = styled.button`
  /* padding: 5px 10px;
  background: transparent;
  outline: none;
  cursor: pointer;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border: 1px solid transparent;
  color: #212b36;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: none;
  transition-property: background, border, box-shadow, -webkit-box-shadow;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-duration: 200ms;
  -webkit-tap-highlight-color: transparent;
  font-size: 0.7rem;
  width: 100px; */

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: ${({ show }) => (show ? "rgba(33, 43, 54, 0.1)" : "transparent")};
  display: flex;
  border-color: transparent;
  border-radius: 3px;
  color: #637381;
  padding: 8px 8px 11px;
  &:hover {
    color: #212b36;
    text-decoration: none;
  }
  &:active {
    background-color: rgba(33, 43, 54, 0.1);
    text-decoration: none;
  }

  span {
    font-size: 0.7rem;
  }
  svg {
    margin-left: 5px;
    /* width: 20px;
    height: 20px;
    fill: #637381; */
  }
`;
const DropdownBox = styled.div`
  padding: 5px 0px;
  box-sizing: border-box;
  /* min-height: 100px; */

  position: absolute;
  top: 45px;
  left: 0px;
  transform: scale(0);
  /* visibility: hidden; */
  margin: 0;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05),
    0 2px 7px 1px rgba(39, 44, 48, 0.16);
  border-radius: 3px;
  width: 100%;
  opacity: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  /* z-index: -4; */
  ${props =>
    props.show &&
    css`
      opacity: 1;
      transform: scale(1);
      /* z-index: 1; */
    `}
`;
const DropdownItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 36px;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }

  & > span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  .level-title {
    margin-left: 8px;
  }
  &:hover {
    background: #5c6ac4;
    & > span {
      color: white;

      svg {
        fill: white;
      }
    }
  }
`;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDropdown: false
    };
  }

  _toggleDropdown = () =>
    this.setState(state => ({ openDropdown: !state.openDropdown }));

  render() {
    const { openDropdown } = this.state;
    const { onActionSelected, options } = this.props;

    return (
      <Container>
        <ActionButton onClick={this._toggleDropdown} show={openDropdown}>
          <span>Change Level</span>
          <FontAwesomeIcon icon="caret-down" />
        </ActionButton>
        <DropdownBox show={openDropdown}>
          {options.map(({ action, title }, key) => (
            <DropdownItem
              onClick={() => {
                action();
                this._toggleDropdown();
              }}
              key={key}
            >
              <span>
                <FontAwesomeIcon icon="list-ul" />{" "}
                <span className="level-title">{title}</span>
              </span>
            </DropdownItem>
          ))}
        </DropdownBox>
        {/* <DropdownBox show={openDropdown}>
          <DropdownItem>
            <span>
              <CloseIcon /> Cancel transaction
            </span>
          </DropdownItem>
          <DropdownItem>
            <span>
              <FontAwesomeIcon icon="level-up-alt" />{" "}
              <span className="level-title">Level 1</span>
            </span>
          </DropdownItem>
       
        </DropdownBox> */}
      </Container>
    );
  }
}

// const options = [
//   { action: () => alert("yh!"), title: "Move to Level 1" },
//   { action: () => alert("yh!"), title: "Move to Level 2" },
//   { action: () => alert("yh!"), title: "Move to Level 3" }
// ];
const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="p_v3ASA"
    viewBox="0 0 20 20"
    focusable="false"
    aria-hidden="true"
  >
    <path
      d="M11.414 10l6.293-6.293a.999.999 0 1 0-1.414-1.414L10 8.586 3.707 2.293a.999.999 0 1 0-1.414 1.414L8.586 10l-6.293 6.293a.999.999 0 1 0 1.414 1.414L10 11.414l6.293 6.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"
      fillRule="evenodd"
    />
  </svg>
);
