import React from "react";

import { CellCont } from "./elements";

const Cell = ({ number, className, to }) => (
  <CellCont className={className} to={to}>
    {number}
  </CellCont>
);

export default Cell;
