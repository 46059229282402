import React, { Component } from "react";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import { Spinner } from "react-activity";
import "react-activity/dist/react-activity.css";
import { ToastContainer } from "react-toastify";

import { Form, DevPanel } from "./elements";
import email from "./email.svg";
import key from "./key.svg";
import login from "../../actions/login";

class Login extends Component {
  state = {
    email: "",
    password: ""
  };

  handleChange = ({ target }) => {
    this.setState({
      [target.name]: target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    this.props.login(this.state, () => {
      this.props.history.push("/dashboard");
    });
  };

  componentDidMount() {
    document.title = "Login - Busha Admin";
  }

  render() {
    const { fetching } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <ToastContainer
          closeButton={false}
          autoClose={2000}
          hideProgressBar={true}
        />
        <h1>Busha</h1>
        <h2>Log in to manage Busha</h2>
        <div className="div first">
          <section>
            <SVG src={email} />
          </section>
          <input
            type="email"
            value={this.state.email}
            name="email"
            onChange={this.handleChange}
            placeholder="Email address"
          />
        </div>
        <div className="div last">
          <section>
            <SVG src={key} />
          </section>
          <input
            type="password"
            value={this.state.password}
            onChange={this.handleChange}
            name="password"
            placeholder="Password"
          />
        </div>
        <button onClick={this.handleSubmit} type="submit">
          {fetching ? <Spinner color="#fff" /> : "Log in"}
        </button>
        {process.env.NODE_ENV === "development" && (
          <DevPanel>
            <section>
              <span>Email: </span>
              <span>Password: </span>
            </section>
            <section>
              <span>kay@busha.co</span>
              <span>BushaDev1</span>
            </section>
          </DevPanel>
        )}
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  fetching: state.auth.fetching
});

export default connect(
  mapStateToProps,
  { login }
)(Login);
