import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";
import format from "date-fns/format";
import { ToastContainer } from "react-toastify";
import Case from "case";
import completeTransfer from "../../../../actions/coinbarter/completeTransfer";

import DepositInfo from "./components/DepositInfo";
import {
  Container,
  Body,
  Header,
  NextPrev,
  ActiontBtnBox,
  PayoutActionBtnsBox,
  ReferenceAndDateBox
} from "./components/common";
import { ReactComponent as BackSVG } from "../assets/back.svg";
import { ReactComponent as ArrowSVG } from "../assets/arrow.svg";
import { ReactComponent as PrintSVG } from "../assets/print.svg";
// import ActionDropdown from "./components/ActionDropdown";
// import CustomerCard from "./components/CustomerCard";
import TradeDetailLoader from "../../../common/SVGLoaders/InfoLoader";
import AccountCard from "./components/AccountCard";
import Modal from "../../../common/Modal";
import { getOneTransfer } from "../../../../actions/coinbarter/transfer";
import CustomerCard from "./components/CustomerCard";
import PageFooter from "./components/PageFooter";
import { Status } from "../components/common";
import SummaryCard from "./components/SummaryCard";
import { ActionBtn } from "./components/AccountCard";

class TradeDetails extends Component {
  state = {
    isLoading: true,
    modalActive: false,
    debit_account: false,
    refunding: false,
    deposit: {
      amount: 0
    }
  };

  toggleModal = () => {
    this.setState(prevState => {
      return {
        modalActive: !prevState.modalActive
      };
    });
  };

  handleChange = ({ target: { value, name } }) => {
    this.setState({
      deposit: {
        [name]: value
      }
    });
  };

  handleCheck = ({ target: { checked } }) => {
    this.setState({
      debit_account: checked
    });
  };

  componentDidMount() {
    const {
      match: {
        params: { transferID }
      },
      getOneTransfer
    } = this.props;
    let viaTrade = false;

    if (this.props.location.pathname.endsWith("trade")) {
      viaTrade = true;
    }

    getOneTransfer(transferID, viaTrade).then(() =>
      this.setState({ isLoading: false })
    );
  }

  render() {
    const { isLoading } = this.state;

    return isLoading ? (
      <TradeDetailLoader />
    ) : (
      <Container>
        <ToastContainer
          closeButton={false}
          autoClose={2000}
          hideProgressBar={true}
        />
        <Header>
          <nav>
            <Link className="back-button" to="/coinbarter/transfers">
              <BackSVG className="svg" />
              <span>All transfers</span>
            </Link>
            <div>
              <NextPrev>
                <ArrowSVG className="arrow-left" />
              </NextPrev>
              <NextPrev>
                <ArrowSVG className="arrow-right" />
              </NextPrev>
            </div>
          </nav>
        </Header>
        <ReferenceAndDateBox>
          <h1>Bank Transfer</h1>
          &nbsp;
          <Status status={this.props.coinBarterOneTransfer.transfer.status}>
            {Case.capital(this.props.coinBarterOneTransfer.transfer.status)}
          </Status>
        </ReferenceAndDateBox>
        <PayoutActionBtnsBox>
          <ActiontBtnBox>
            <PrintSVG />
            <span>Print</span>
          </ActiontBtnBox>
          {/* <ActionDropdown /> */}
        </PayoutActionBtnsBox>
        <Body>
          <div>
            <AccountCard transfer={this.props.coinBarterOneTransfer.transfer} />
          </div>
          <div>
            <CustomerCard
              transfer={this.props.coinBarterOneTransfer.transfer}
            />

            <SummaryCard
              completeTransfer={this.props.completeTransfer}
              transfer={this.props.coinBarterOneTransfer.transfer}
            />
          </div>
        </Body>
      </Container>
    );
  }
}

function mapStateToProps({ coinBarterOneTransfer }) {
  return { coinBarterOneTransfer };
}

export default connect(
  mapStateToProps,
  { getOneTransfer, completeTransfer }
)(TradeDetails);
