import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 200px;
`;

export const ActionBtn = styled.div`
  padding: 5px 10px;
  background: linear-gradient(to bottom, white, #f9fafb);
  outline: none;
  cursor: pointer;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border: 1px solid #c4cdd5;
  color: #212b36;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: none;
  transition-property: background, border, box-shadow, -webkit-box-shadow;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-duration: 200ms;
  -webkit-tap-highlight-color: transparent;
  font-size: 0.7rem;
  width: 100px;

  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DropdownBox = styled.div`
  padding: 5px 0px;
  box-sizing: border-box;
  min-height: 100px;

  position: absolute;
  top: 37px;
  right: 55px;
  transform: scale(0);
  /* visibility: hidden; */
  margin: 0;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05),
    0 2px 7px 1px rgba(39, 44, 48, 0.16);
  border-radius: 3px;
  width: 100%;
  opacity: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  /* z-index: -4; */
  ${props =>
    props.show &&
    css`
      opacity: 1;
      transform: scale(1);
      /* z-index: 1; */
    `}
`;
const DropdownItem = styled.div`
  padding: 5px 20px;
  cursor: pointer;
  & > span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
  }
  &:hover {
    background: #5c6ac4;
    & > span {
      color: white;
    }
  }
`;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDropdown: false
    };
  }

  _toggleDropdown = () =>
    this.setState(state => ({ openDropdown: !state.openDropdown }));

  render() {
    const { openDropdown } = this.state;
    // const { onActionSelected } = this.props;

    return (
      <Container>
        <ActionBtn onClick={this._toggleDropdown}>
          <span>Actions</span>
          <FontAwesomeIcon icon="caret-down" />
        </ActionBtn>
        <DropdownBox show={openDropdown}>
          <DropdownItem>
            <span>Fulfill payouts</span>
          </DropdownItem>
          <DropdownItem>
            <span>Capture payouts</span>
          </DropdownItem>
          <DropdownItem>
            <span>Archive payouts</span>
          </DropdownItem>
          <DropdownItem>
            <span>Add tags</span>
          </DropdownItem>
          <DropdownItem>
            <span>Remove tags</span>
          </DropdownItem>
        </DropdownBox>
      </Container>
    );
  }
}
