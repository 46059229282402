import React from "react";
import ContentLoader from "react-content-loader";

export const LoaderBox = ({ height, width, children, ...rest }) => (
  <ContentLoader
    height={height || "100%"}
    width={width || "100%"}
    speed={2}
    primaryColor="#E8E8E8"
    secondaryColor="#ecebeb"
    {...rest}
  >
    {children}
  </ContentLoader>
);

export const Rect = ({ x, y, width, height }) => (
  <rect
    x={x || "0"}
    y={y || "0"}
    rx="3"
    ry="3"
    width={width || "100%"}
    height={height || "6.4"}
  />
);

export const Circle = ({ cx, cy, r }) => (
  <circle cx={cx || "30"} cy={cy || "30"} r={r || "30"} />
);
