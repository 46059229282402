import React from "react";
import { Switch, Route } from "react-router-dom";

import Dashboard from "../components/Dashboard";
import Login from "../components/Login";
import AcceptInvite from "../components/AcceptInvite";

const Routes = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route exact path="/invite/accept/:token" component={AcceptInvite} />
    <Route path="" component={Dashboard} />
  </Switch>
);

export default Routes;
