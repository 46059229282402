import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Case from "case";
import format from "date-fns/format";

import {
  Table,
  TableHead,
  TableHeadRow,
  TableHeadData,
  TableBody,
  TableRow,
  TableData,
  TableDataBox,
  TableHeadCheckboxBox,
  CheckboxLeft
} from "./common";
import Checkbox from "../../../common/Checkbox";
import { moneyFormatter } from "../../../../helpers/util";
import { ReactComponent as BackSVG } from "../images/back.svg";
import ActionDropdown from "../../../common/Dropdowns/ActionDropdown";
import { withRouter } from "react-router-dom";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false
    };
  }

  _onSelectAllToggled = () =>
    this.setState(state => ({ selectAll: !state.selectAll }));

  render() {
    const { selectAll } = this.state;
    const {
      deposits: { transactions },
      type
    } = this.props;
    return (
      <Table>
        <TableHead>
          <TableHeadRow>
            {/* <TableHeadData colSpan={selectAll ? '7' : '1'} hasSelect>
							<TableHeadCheckboxBox>
								<CheckboxLeft selectedAll={selectAll}>
									<Checkbox checked={selectAll} onChange={this._onSelectAllToggled} />
									{!selectAll ? (
										<FontAwesomeIcon icon="caret-down" onClick={this._onSelectAllToggled} />
									) : (
										<span>1 payout selected</span>
									)}
								</CheckboxLeft>
								{selectAll && <ActionDropdown />}
							</TableHeadCheckboxBox>
						</TableHeadData> */}
            {!selectAll && (
              <Fragment>
                <TableHeadData>
                  <span>Reference</span>
                </TableHeadData>
                <TableHeadData>
                  <span>Customer</span>
                </TableHeadData>
                <TableHeadData>
                  <span>Date</span>
                </TableHeadData>
                <TableHeadData>
                  <span>Status</span>
                </TableHeadData>
                <TableHeadData>
                  <span>Fulfillment Status</span>
                </TableHeadData>
                <TableHeadData className="align-right">
                  <span>Amount</span>
                </TableHeadData>
              </Fragment>
            )}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {(transactions || []).map(deposit => {
            return (
              <TableRow
                key={deposit.id}
                onClick={() =>
                  this.props.history.push(`/deposits/${type}/${deposit.id}`)
                }
              >
                {/* <TableData hasSelect>
                <Checkbox />
              </TableData> */}
                <TableData>
                  <Link to={`/deposits/${type}/${deposit.id}`}>
                    <TableDataBox>
                      <span>{deposit.ref || deposit.reference}</span>
                    </TableDataBox>
                  </Link>
                </TableData>
                <TableData>
                  <TableDataBox>
                    {deposit.user ? (
                      <span className="has-customer-link">
                        <Link to={`/deposits/${type}/${deposit.id}`}>
                          {deposit.user.profile.first_name &&
                          deposit.user.profile.last_name
                            ? Case.capital(
                                `${deposit.user.profile.first_name} ${
                                  deposit.user.profile.last_name
                                }`
                              )
                            : "no name"}
                        </Link>
                        <span className="customer-view-icon">
                          <span className="tool-tip">View customer </span>
                          <Link to={`/customers/${deposit.user.id}`}>
                            <BackSVG />
                          </Link>
                        </span>
                      </span>
                    ) : (
                      <span style={{ color: "#A9A9A9" }}>No User</span>
                    )}
                  </TableDataBox>
                </TableData>
                <TableData>
                  <Link to={`/deposits/${type}/${deposit.id}`}>
                    <TableDataBox>
                      <span>
                        {format(new Date(deposit.created_at), "MMM D, h:mm a")}
                      </span>
                    </TableDataBox>
                  </Link>
                </TableData>
                <TableData>
                  <Link to={`/deposits/${type}/${deposit.id}`}>
                    <TableDataBox>
                      <span className={`status-indicator ${deposit.status}`}>
                        {Case.capital(deposit.status)}
                      </span>
                    </TableDataBox>
                  </Link>
                </TableData>
                <TableData>
                  <Link to={`/deposits/${type}/${deposit.id}`}>
                    <TableDataBox>
                      <span
                        className={`fulfillment-status-indicator ${
                          deposit.fulfilment_status
                        }`}
                      >
                        {Case.capital(
                          deposit.fulfilment_status || "Unfulfilled"
                        )}
                      </span>
                    </TableDataBox>
                  </Link>
                </TableData>
                <TableData>
                  <Link to={`/deposits/${type}/${deposit.id}`}>
                    <TableDataBox className="align-right">
                      {type === "fiat" ? (
                        <span>
                          {moneyFormatter.format(deposit.amount).toUpperCase()}
                        </span>
                      ) : (
                        <span>
                          {`${deposit.amount} ${deposit.asset}`.toUpperCase()}
                        </span>
                      )}
                    </TableDataBox>
                  </Link>
                </TableData>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

export default withRouter(App);
