import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCaretDown,
  faSearch,
  faSort,
  faListUl,
  faLevelUpAlt,
  faCommentDots
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";

import Routes from "./containers/Routes";
import store from "./store";

library.add(
  faCaretDown,
  faSearch,
  faSort,
  faLevelUpAlt,
  faListUl,
  faCommentDots
);

const App = () => (
  <Provider store={store}>
    <Router>
      <Routes />
    </Router>
  </Provider>
);

export default App;
