import { Chart } from "chart.js";
import { isEqual } from "lodash";
import React, { Component } from "react";
import numeral from "numeral";
import format from "date-fns/format";
import { currencyFormatter } from "../../../../helpers/util";

import {
  GraphCont,
  GraphTitle,
  GraphCard,
  GraphFooter,
  Bullet
} from "./elements";

class Graph extends Component {
  canvasRef = React.createRef();

  plot = ctx => {
    const { graphData, color, period } = this.props;

    let labels = graphData.x_axis;
    labels.unshift("");

    let data = graphData.y_axis;
    data.unshift(0);

    new Chart(ctx, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            backgroundColor: "transparent",
            borderColor: color,
            lineTension: 0,
            borderWidth: 2.5,
            pointRadius: 0,
            data
          }
        ]
      },
      options: {
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: label => {
                  if (labels.indexOf(label) === 0) {
                    return label;
                  }
                  return periodicDate(label, period);
                }
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: labels.length === 1 ? 1 : undefined,
                callback: label =>
                  label < 1000 ? label : numeral(label).format("0a")
              }
            }
          ]
        }
      }
    });
  };

  componentDidMount() {
    Chart.defaults.global.legend.display = false;
    const ctx = this.canvasRef.current.getContext("2d");

    this.plot(ctx);
  }

  componentDidUpdate(prevProps) {
    const ctx = this.canvasRef.current.getContext("2d");

    if (!isEqual(prevProps, this.props)) {
      this.plot(ctx);
    }
  }

  render() {
    const { graphData, domain, color, unit } = this.props;

    return (
      <GraphCont>
        <GraphCard>
          <div className="totals">
            <div>
              <p className="title">
                Total {unit} {domain}
              </p>
              <p className="amount">
                <span>
                  {currencyFormatter(graphData.total, graphData.unit)}
                </span>
                {/* <span>-</span> */}
              </p>
            </div>
          </div>

          <GraphTitle>TRANSACTIONS OVER TIME</GraphTitle>
          <canvas ref={this.canvasRef} />

          <GraphFooter>
            <Bullet color={color}>
              <span className="dot" />
              <span>
                {unit} {domain}
              </span>
            </Bullet>
          </GraphFooter>
        </GraphCard>
      </GraphCont>
    );
  }
}

export default Graph;

const periodicDate = (date, period) => {
  const periods = {
    hour: format(date, "h A"),
    day: format(date, "MMM 	D"),
    week: format(date, "MMM	D"),
    month: format(date, "MMM D"),
    year: format(date, "MMM YYYY")
  };

  return periods[period];
};
