import { GET_COUNTRIES } from "../actions/types";

const initialState = { countries: [], fetching: false, fetched: false };

function countries(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRIES.pending:
      return { ...state, fetching: true, fetched: false };
    case GET_COUNTRIES.fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        countries: action.payload.data
      };
    case GET_COUNTRIES.rejected:
      return { ...state, fetching: false, fetched: false };
    default:
      return state;
  }
}
export default countries;
