import React from "react";

// import Checkbox from "../../../common/Checkbox";
import Select from "../../../common/Select";
import { FormSection, FormSectionInfo } from "./common";

import { Container, Section, FormGroup } from "./formElements";

const LimitForm = ({ form, handleInputChange, providerType }) => {
  return (
    <FormSection>
      <FormSectionInfo>
        <h3>Limits</h3>
      </FormSectionInfo>
      <Container>
        <Section>
          {providerType === "deposits" && (
            <FormGroup>
              <label htmlFor="maximum_fee_amount">Maximum Fee Amount</label>
              <input
                name="maximum_fee_amount"
                value={form.maximum_fee_amount}
                onChange={handleInputChange}
              />
            </FormGroup>
          )}
          <FormGroup>
            <label htmlFor="maximum_amount">Maximum Amount</label>
            <input
              name="maximum_amount"
              value={form.maximum_amount}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="minimum_amount">Minimum Amount</label>
            <input
              name="minimum_amount"
              value={form.minimum_amount}
              onChange={handleInputChange}
            />
          </FormGroup>
          {providerType === "deposits" && (
            <FormGroup>
              <label htmlFor="extra_fee_minimum_amount">
                Extra Fee Minimum Amount
              </label>
              <input
                name="extra_fee_minimum_amount"
                value={form.extra_fee_minimum_amount}
                onChange={handleInputChange}
              />
            </FormGroup>
          )}
        </Section>
      </Container>
    </FormSection>
  );
};

export default LimitForm;
