import axios from "axios";

import { apiBaseURL } from "../config";
import types, { PROCESS_DEPOSIT } from "./types";
import { toast } from "react-toastify";
import { getQueryString } from "../helpers/util";

const fetchCardDepositsSuccessful = payload => ({
  type: types("fetch_card_deposits").fulfilled,
  payload
});

const fetchCardDepositsFailed = payload => ({
  type: types("fetch_card_deposits").rejected,
  payload
});

const fetchCryptoDepositsSuccessful = payload => ({
  type: types("fetch_crypto_deposits").fulfilled,
  payload
});

const fetchCryptoDepositsFailed = payload => ({
  type: types("fetch_crypto_deposits").rejected,
  payload
});

const fetchBankDepositsSuccessful = payload => ({
  type: types("fetch_bank_deposits").fulfilled,
  payload
});

const fetchBankDepositsFailed = payload => ({
  type: types("fetch_bank_deposits").rejected,
  payload
});

const fetchDepositInfoSuccessful = payload => ({
  type: types("fetch_deposit_info").fulfilled,
  payload
});

const fetchDepositInfoFailed = payload => ({
  type: types("fetch_deposit_info").rejected,
  payload
});

const fetchDepositAccountSuccessful = payload => ({
  type: types("fetch_deposit_account").fulfilled,
  payload
});

const fetchDepositAccountFailed = payload => ({
  type: types("fetch_deposit_account").rejected,
  payload
});

const processDepositAction = payload => ({
  type: PROCESS_DEPOSIT.default,
  payload
});
export const fetchCardDeposits = (page = 1, status, callback, channel) => {
  const newStatus = status === "all" ? undefined : status;
  const arg = { page, status: newStatus, channel };

  const queryString = getQueryString(arg);

  return dispatch => {
    dispatch({ type: types("fetch_card_deposits").pending });
    return axios
      .get(`${apiBaseURL}/v1/deposits/fiat${queryString}`)
      .then(({ data }) => {
        dispatch(fetchCardDepositsSuccessful(data));
        callback(data.meta.total_entries_size);
      })
      .catch(error => {
        dispatch(fetchCardDepositsFailed(error));
      });
  };
};

export const fetchCryptoDeposits = (page = 1, status, callback, channel) => {
  const newStatus = status === "all" ? undefined : status;
  const arg = { page, status: newStatus, channel };

  const queryString = getQueryString(arg);

  return dispatch => {
    dispatch({ type: types("fetch_crypto_deposits").pending });
    return axios
      .get(`${apiBaseURL}/v1/deposits/crypto${queryString}`)
      .then(({ data }) => {
        dispatch(fetchCryptoDepositsSuccessful(data));
        callback(data.meta.total_entries_size);
      })
      .catch(error => {
        dispatch(fetchCryptoDepositsFailed(error));
      });
  };
};

export const fetchBankDeposits = (page = 1, status = "all", callback) => {
  const queryString =
    status === "all" ? `?page=${page}` : `?status=${status}&page=${page}`;

  return dispatch => {
    dispatch({ type: types("fetch_bank_deposits").pending });
    return axios
      .get(`${apiBaseURL}/v1/deposits/bank${queryString}`)
      .then(({ data }) => {
        dispatch(fetchBankDepositsSuccessful(data));
        callback(data.meta.total_entries_size);
      })
      .catch(error => {
        dispatch(fetchBankDepositsFailed(error));
      });
  };
};

export const fetchDepositInfo = (type, depositId) => {
  return dispatch => {
    dispatch({ type: types("fetch_deposit_info").pending });
    return axios
      .get(`${apiBaseURL}/v1/deposits/${type}/${depositId}`)
      .then(({ data }) => {
        return dispatch(fetchDepositInfoSuccessful(data));
      })
      .catch(error => {
        dispatch(fetchDepositInfoFailed(error));
      });
  };
};

export const fetchDepositAccount = accountId => {
  return dispatch => {
    dispatch({ type: types("fetch_deposit_info").pending });
    return axios
      .get(`${apiBaseURL}/v1/accounts/${accountId}`)
      .then(({ data }) => {
        dispatch(fetchDepositAccountSuccessful(data));
      })
      .catch(error => {
        dispatch(fetchDepositAccountFailed(error));
      });
  };
};

export const refundCustomer = (payoutId, payload) => async () => {
  try {
    await axios.delete(`${apiBaseURL}/v1/deposits/card/${payoutId}`, {
      data: payload
    });
    toast.success("Customer refunded!");
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const finalizeDeposit = (depositID, type) => dispatch => {
  dispatch({
    type: types("finalize_deposit").fulfilled
  });
  return axios
    .post(`${apiBaseURL}/v1/deposits/${type}/${depositID}/finalize`)
    .then(({ data }) => {})
    .catch(err => {
      err.response &&
        err.response.data &&
        err.response.data.message &&
        toast.error(err.response.data.message);
    });
};

export const processDeposit = (body, cb, success_cb) => dispatch => {
  const request = axios.post(
    `${apiBaseURL}/v1/deposits/fiat/bank/finalize`,
    body
  );

  dispatch(processDepositAction(request))
    .then(() => success_cb && success_cb())
    .catch(err => {
      cb && cb();
      err.response &&
        err.response.data &&
        err.response.data.message &&
        toast.error(err.response.data.message);
    });
};
