import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "react-activity/lib/Spinner/Spinner";
import "react-activity/lib/Spinner/Spinner.css";
import isEmpty from "lodash/isEmpty";
import { ToastContainer } from "react-toastify";

import {
  Container,
  Body,
  Header,
  AssetTitleBox,
  FormFooter as FormActionBox
} from "./components/common";
import { ReactComponent as BackSVG } from "./svgs/back.svg";
import AssetLoader from "./components/AssetLoader";
import BasicInfoForm from "./components/BasicInfoForm";
import ActivityForm from "./components/ActivityForm";

import FeesForm from "./components/FeesForm";
import {
  getCurrency,
  updateCurrency,
  createCurrency
} from "../../../actions/currencies";
import LimitForm from "./components/LimitForm";
import { ActionBtn } from "../Transactions/NewTransaction/elements";
import TagsForm from "./components/TagsForm";
class AssetInfo extends React.Component {
  requiredFields = [
    "code",
    "daily_payout_limit",
    "deposit_fee",
    "deposit_status",
    "minimum_payout_amount",
    "name",
    "payout_fee",
    "payout_status",
    "status",
    "symbol",
    "type",
    "website",
    "description"
  ];

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    if (this.props.mode === "edit")
      this.props.getCurrency(params.assetId).then(({ value: { data } }) => {
        // console.log({ data });
        this.setState(state => ({ form: { ...state.form, ...data } }));
      });
  }

  state = {
    form: {
      code: "",
      country: [],
      daily_payout_limit: "0",
      deposit_fee: "0",
      deposit_status: true,
      description: "",
      empty_tag_message: "",
      minimum_payout_amount: "0",
      name: "",
      payout_fee: "0",
      payout_status: true,
      status: "online",
      symbol: "",
      tag_name: "",
      type: "digital",
      use_tag: false,
      website: "",
      deposit_fee_description: "",
      payout_fee_description: ""
      // buy_price: ""
    }
  };

  handleInputChange = event => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.type === "radio"
        ? target.value == "true"
        : target.value;
    const name = target.name;

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  formIsValid = () => {
    let isValid = true;
    const { form } = this.state;
    for (const key in form) {
      if (form.hasOwnProperty(key)) {
        if (this.requiredFields.indexOf(key) !== -1) {
          const formInput = form[key];
          // console.log({ [key]: formInput });
          if (isEmpty(formInput.toString().trim())) {
            isValid = false;
            break;
          }
        }
      }
    }
    if (
      Number(form.deposit_fee) > 0 &&
      isEmpty(form.deposit_fee_description.trim())
    ) {
      return false;
    }
    if (
      Number(form.payout_fee) > 0 &&
      isEmpty(form.payout_fee_description.trim())
    ) {
      return false;
    }
    return isValid;
  };

  onBtnClicked = () => {
    const {
      mode,
      currencies,
      createCurrency,
      updateCurrency,
      history,
      match: { params }
    } = this.props;
    if (currencies.creating || currencies.updating) return;
    if (mode === "edit") {
      updateCurrency(params.assetId, this.state.form, () =>
        history.push("/settings/assets/")
      );
    } else {
      createCurrency(this.state.form, () => {
        // console.log({ data });
        history.push(`/settings/assets/${this.state.form.code}`);
      });
    }
  };

  render() {
    const { currencies } = this.props;
    const { form } = this.state;

    return currencies.fetching ? (
      <AssetLoader />
    ) : (
      <Container>
        <ToastContainer
          closeButton={false}
          autoClose={1000}
          hideProgressBar={true}
        />
        <Header>
          <nav>
            <Link className="back-button" to="/settings/assets">
              <BackSVG className="svg" />
              <span>Settings</span>
            </Link>
          </nav>
        </Header>
        <AssetTitleBox>
          <h1>{form.name}</h1>
        </AssetTitleBox>
        <Body>
          <BasicInfoForm
            form={form}
            handleInputChange={this.handleInputChange}
          />
          <FeesForm form={form} handleInputChange={this.handleInputChange} />
          <LimitForm form={form} handleInputChange={this.handleInputChange} />
          <ActivityForm
            form={form}
            handleInputChange={this.handleInputChange}
          />
          <TagsForm form={form} handleInputChange={this.handleInputChange} />
          <FormActionBox>
            <ActionBtn
              disabled={!this.formIsValid()}
              onClick={this.onBtnClicked}
            >
              {currencies.creating || currencies.updating ? (
                <Spinner size={15} color="#ffffff" speed={2} />
              ) : this.props.mode === "create" ? (
                "Create Currency"
              ) : (
                "Update Currency"
              )}
            </ActionBtn>
          </FormActionBox>
        </Body>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return { currencies: state.currencies };
}

export default connect(
  mapStateToProps,
  { getCurrency, updateCurrency, createCurrency }
)(AssetInfo);
