import React from "react";
import styled from "styled-components";

import Case from "case";
import { Link } from "react-router-dom";
import format from "date-fns/format";

import {
  Table,
  TableHead,
  TableHeadRow,
  TableHeadData,
  TableBody,
  TableRow,
  TableData,
  TableDataBox
} from "./common";
import { ReactComponent as BackSVG } from "../images/back.svg";

const EmptyState = styled.div`
  width: 100%;
  padding: 40px;
  text-align: center;
`;

const header = ["Reference", "Description", "Status", "Date"];
const Head = () => (
  <TableHead>
    <TableHeadRow>
      {header.map((val, key) => (
        <TableHeadData key={key}>
          <span>{val}</span>
        </TableHeadData>
      ))}
    </TableHeadRow>
  </TableHead>
);

const Body = ({ reference, description, status, created_at, id }) => (
  <TableRow>
    <TableData>
      <TableDataBox>
        {reference ? (
          <span className="has-customer-link">
            <Link to={`/marketplace/trades/${id}`}>{reference}</Link>
            <span className="customer-view-icon">
              <span className="tool-tip">View trader </span>
              <Link to={`/marketplace/trades/${id}`}>
                <BackSVG />
              </Link>
            </span>
          </span>
        ) : (
          <span style={{ color: "#A9A9A9" }}>No Trader</span>
        )}
      </TableDataBox>
    </TableData>
    <TableData>
      <Link to={`/marketplace/trades/${id}`}>
        <TableDataBox>
          <span>{description}</span>
        </TableDataBox>
      </Link>
    </TableData>
    <TableData>
      <Link to={`/marketplace/trades/${id}`}>
        <TableDataBox>
          <span className={`status-indicator ${status}`}>
            {Case.capital(status)}
          </span>
        </TableDataBox>
      </Link>
    </TableData>
    <TableData>
      <Link to={`/marketplace/trades/${id}`}>
        <TableDataBox>
          <span>{format(new Date(created_at), "MMM D, h:mm a")}</span>
        </TableDataBox>
      </Link>
    </TableData>
  </TableRow>
);

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false
    };
  }

  _onSelectAllToggled = () =>
    this.setState(state => ({ selectAll: !state.selectAll }));

  render() {
    const {
      ads: { data }
    } = this.props;
    return (
      <>
        <Table>
          <Head />
          <TableBody>
            {(data || []).map(ad => {
              const description =
                ad.ad.side === "sell"
                  ? `${Case.capital(ad.buyer.username)} is buying ${
                      ad.buyer_amount
                    }${ad.ad.asset} from ${ad.seller.username}`
                  : `${Case.capital(ad.seller.username)} is selling ${
                      ad.seller_amount
                    }${ad.ad.asset} to ${ad.buyer.username}`;
              return (
                <Body
                  id={ad.id}
                  key={ad.id}
                  status={ad.status}
                  reference={ad.reference}
                  created_at={ad.created_at}
                  description={description}
                />
              );
            })}
          </TableBody>
        </Table>
        {data && data.length === 0 && <EmptyState>No Trades yet!</EmptyState>}
      </>
    );
  }
}
