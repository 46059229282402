import axios from "axios";
import { toast } from "react-activity";

import { apiBaseURL } from "../config";
import types from "./types";

const fetchAllTransactionsSuccessful = payload => ({
  type: types("fetch_all_transactions").fulfilled,
  payload
});

const fetchAllTransactionsFailed = payload => ({
  type: types("fetch_all_transactions").rejected,
  payload
});

const fetchTransactionInfoSuccessful = payload => ({
  type: types("fetch_transaction_info").fulfilled,
  payload
});

const fetchAllSwapsSuccessful = payload => ({
  type: types("fetch_all_swaps").fulfilled,
  payload
});

const fetchOneSwapSuccessful = payload => ({
  type: types("fetch_one_swap").fulfilled,
  payload
});

const fetchOneSwapPending = () => ({
  type: types("fetch_one_swap").pending
});

const fetchTransactionInfoFailed = payload => ({
  type: types("fetch_transaction_info").rejected,
  payload
});

export const fetchAllTransactions = (page = 1, status = "all", callback) => {
  const queryString =
    status === "all" ? `?page=${page}` : `?status=${status}&page=${page}`;

  return dispatch => {
    dispatch({ type: types("fetch_all_transactions").pending });

    return axios
      .get(`${apiBaseURL}/v1/transactions${queryString}`)
      .then(({ data }) => {
        dispatch(fetchAllTransactionsSuccessful(data));
        callback(data.meta.total_entries_size);
      })
      .catch(error => {
        dispatch(fetchAllTransactionsFailed(error));
      });
  };
};

export const fetchTransactionInfo = transactionId => {
  return dispatch => {
    dispatch({ type: types("fetch_transaction_info").pending });
    return axios
      .get(`${apiBaseURL}/v1/transactions/${transactionId}`)
      .then(({ data }) => {
        dispatch(fetchTransactionInfoSuccessful(data));
      })
      .catch(error => {
        dispatch(fetchTransactionInfoFailed(error));
      });
  };
};

export const fetchAllSwaps = (page = 1, status = "all", callback) => {
  const queryString =
    status === "all" ? `?page=${page}` : `?status=${status}&page=${page}`;

  return dispatch => {
    dispatch({ type: types("fetch_all_swaps").pending });

    return axios
      .get(`${apiBaseURL}/v1/swap${queryString}`)
      .then(({ data }) => {
        dispatch(fetchAllSwapsSuccessful(data));
        callback(data.meta.total_entries_size);
      })
      .catch(err => {
        toast.error(err.response.data.message);
      });
  };
};

export const fetchOneSwap = swapId => async dispatch => {
  try {
    dispatch(fetchOneSwapPending());

    const { data } = await axios.get(`${apiBaseURL}/v1/swap/${swapId}`);

    dispatch(fetchOneSwapSuccessful(data));
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const finalizeTransaction = (transctionID, cb, loader) => dispatch => {
  return axios
    .post(`${apiBaseURL}/v1/transactions/${transctionID}/finalize`)
    .then(({ data }) => {
      loader && loader();
      cb && cb(transctionID);
    })
    .catch(err => {
      loader && loader();
      err.response &&
        err.response.data &&
        err.response.data.message &&
        toast.error(err.response.data.message);
    });
};
