import axios from "axios";
import { toast } from "react-toastify";

import { apiBaseURL } from "../config";

const changePassword = (payload, callback) => async () => {
  try {
    await axios.put(`${apiBaseURL}/v1/me/password`, payload);

    toast.success("Password changed successfully");

    callback();
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export default changePassword;
