import styled from "styled-components";

export const EmptyBox = styled.div`
  background: transparent;
  padding: ${props => (props.padding ? props.padding : "20px 20px 10px")};
  & > div:first-child {
    padding-top: 0px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  svg {
    color: transparent;
    fill: #c3cfd8;
    width: 20px;
    height: 20px;
  }
  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
  }
`;

export const AccountActionBtnsBox = styled.div`
  margin-top: 5px;
  display: flex;
  background-color: transparent;
  margin-left: -10px;
  & > button:not(:last-child) {
    margin-right: 20px;
  }
`;
export const ActiontBtnBox = styled.button`
  cursor: pointer;
  background: transparent;
  display: flex;
  border-color: transparent;
  border-radius: 3px;
  color: #637381;
  padding: 8px;
  &:hover {
    color: #212b36;
    text-decoration: none;
  }
  &:active {
    background-color: rgba(33, 43, 54, 0.1);
    text-decoration: none;
  }
  span {
    font-size: 0.7rem;
  }
  svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    fill: #637381;
  }
`;
