import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { isEqual } from "lodash";
import styled from "styled-components";

import { Container, Body, Header } from "./components/common";
import { ReactComponent as BackSVG } from "./svgs/back.svg";
import AssetsList from "./components/AssetsList";
import AssetsLoader from "./components/AssetsLoader";

import { getCurrencies } from "../../../actions/currencies";
import Paginator from "../../common/Paginator";

/////////////
// HEADER
/////////////
export const SubHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 15px; */
`;
export const HeaderLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const HeaderTextBox = styled.div``;
export const HeaderText = styled.h1`
  font-size: 1.6rem;
  /* padding-left: 8px; */
  padding: 0;
  margin: 0;
  font-weight: 600;
  color: #212b36;
`;
export const HeaderRight = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 100%;
  height: 100%;
`;
export const CreateBtn = styled(Link)`
  text-decoration: none;
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  border: 1px solid #3f4eae;
  box-shadow: inset 0 1px 0 0 #6f7bcb;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 0.7rem;
  border-radius: 3px;
  &:hover {
    background: linear-gradient(to bottom, #5c6ac4, #4959bd);
    border-color: #3f4eae;
    color: white;
  }
`;

class Assets extends React.Component {
  componentDidMount() {
    this.props.getCurrencies();
  }
  render() {
    const { currencies } = this.props;
    return (
      <Container>
        <Header>
          <nav>
            <Link className="back-button" to="/settings">
              <BackSVG className="svg" />
              <span>Settings</span>
            </Link>
          </nav>
        </Header>
        <SubHeader>
          <HeaderLeft>
            <HeaderTextBox>
              <HeaderText>Assets</HeaderText>
            </HeaderTextBox>
          </HeaderLeft>
          <HeaderRight>
            <CreateBtn to={`${this.props.match.path}/new`}>Add Asset</CreateBtn>
          </HeaderRight>
        </SubHeader>
        <Body>
          {currencies.fetching ? (
            <AssetsLoader />
          ) : (
            <>
              <AssetsList currencies={currencies.currencies} />
              <Paginator totalEntries={10} perPage={5} {...this.props} />
            </>
          )}
        </Body>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return { currencies: state.currencies };
}

export default connect(
  mapStateToProps,
  { getCurrencies }
)(Assets);
