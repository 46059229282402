import * as firebase from "firebase";
// import "firebase/database";

var firebaseConfig = {
  apiKey: "AIzaSyB_rRCF1AKzeAAO--chkWwwrUTtQeO2PKk",
  authDomain: "busha-mobile.firebaseapp.com",
  databaseURL: "https://busha-mobile.firebaseio.com",
  projectId: "busha-mobile",
  storageBucket: "busha-mobile.appspot.com",
  messagingSenderId: "752835102306",
  appId: "1:752835102306:web:606511fa63de6945"
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
