import React, { Component } from "react";
import styled, { css } from "styled-components";
import moment from "moment";

import RangePicker from "./DatePicker/DateRangePicker";

class Popover extends Component {
  render() {
    const {
      dateRange,
      applyDate,
      customRange,
      togglePopover,
      handleDateRange,
      handleCustomRange
    } = this.props;

    const { end, start } = dateRange || {
      end: moment(),
      start: moment()
    };

    return (
      <Container>
        <Form>
          <div className="inputGroup">
            <label>Date range</label>
            <select
              value={customRange}
              name="customRange"
              onChange={handleCustomRange}
            >
              {/* <option value="today">Today</option> */}
              <option value="twoDaysAgo">Last 2 days</option>
              <option value="oneWeekAgo">Last 7 days</option>
              <option value="thirtyDaysAgo">Last 30 days</option>
              <option value="threeMonthsAgo">Last 90 days</option>
            </select>
          </div>

          <Spacer />

          <div className="formGroup">
            <div className="inputGroup">
              <label>Starting</label>
              <input type="text" value={start.format("YYYY-MM-DD")} readOnly />
            </div>

            <div className="inputGroup">
              <label>Ending</label>

              <input type="text" value={end.format("YYYY-MM-DD")} readOnly />
            </div>
          </div>
        </Form>

        <RangePicker dateRange={dateRange} handleDateRange={handleDateRange} />

        <Footer>
          <Btn onClick={togglePopover}>Cancel</Btn>{" "}
          <Btn
            bgColor="#5A68C3"
            onClick={() => {
              togglePopover();
              applyDate(dateRange);
            }}
            disabled={!dateRange}
          >
            Apply
          </Btn>
        </Footer>
      </Container>
    );
  }
}

export default Popover;

const Container = styled.div`
  width: 508px;
  position: absolute;
  top: 50px;
  z-index: 900;
  background: white;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
`;

export const Form = styled.form`
  border-top: 1px solid #ebeef0;
  padding: 20px 0;
  padding-bottom: 0;
  font-size: 14px;

  select,
  input {
    display: block;
    width: 100%;
    padding: 0 10px;

    :focus {
      border: 2px solid #5c6ac4 !important;
    }
  }

  .formGroup {
    display: flex;
    padding: 0 20px;
    margin-bottom: 20px;

    .inputGroup {
      padding: 0;

      :not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .inputGroup {
    flex: 1;
    padding: 0 20px;

    label {
      display: block;
      margin-bottom: 6px;
    }

    select,
    input {
      border-radius: 3px;
      width: 100%;
      height: 36px;
      box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
      border: 1px solid #c4cdd5;
      outline: none;
      font-size: inherit;
    }
  }

  .tandc {
    margin: 20px;
    margin-bottom: 0;
    padding: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebeef0;
  }

  .buttons {
    padding-top: 20px;
    padding-right: 20px;
    text-align: right;
  }
`;

const Spacer = styled.div`
  height: 20px;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid #dfe3e8;
  margin-top: 16px;
`;

const Btn = styled.button`
  width: 78px;
  height: 36px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  ${({ bgColor }) =>
    bgColor &&
    css`
      background: ${bgColor};
      color: #fff;
      border: 1px solid rgb(63, 78, 174);
    `}

  :disabled {
    background: #b9c0e6;
    color: #fff;
    border-color: rgb(167, 174, 223);
  }
`;
