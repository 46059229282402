import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import {
  Container,
  Header,
  Subtitle,
  Form,
  Spacer,
  Button,
  Footer
} from "./elements";
import Loader from "./Loader";
import { Spinner } from "react-activity";
import acceptInvite from "../../actions/acceptInvite";
import getInviteInfo from "../../actions/getInviteInfo";
import { ReactComponent as BushaLogo } from "./busha-dark.svg";

class AcceptInvite extends Component {
  state = {
    isFormDirty: false,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    fetching: false,
    fetched: false,
    expired: false,
    error: "",
    loading: false
  };

  handleChange = ({ target: { value, name } }) => {
    this.setState({
      isFormDirty: true,
      [name]: value
    });
  };

  updateForm = ({ first_name, last_name, email, expired, error }) => {
    this.setState({
      first_name,
      last_name,
      email,
      fetching: false,
      fetched: true,
      expired,
      error
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    if (!this.state.expired) {
      const { password, confirm_password, phone } = this.state;
      const { token } = this.props.match.params;
      this.setState({ loading: true });
      this.props.acceptInvite(
        {
          phone,
          password,
          confirm_password
        },
        token,
        () => {
          this.props.history.push("/dashboard");
        }
      );
    } else {
      toast.error(this.state.error);
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    const { token } = this.props.match.params;

    this.setState({
      fetching: true
    });

    this.props.getInviteInfo(token, this.updateForm);
  }

  render() {
    const {
      email,
      phone,
      fetched,
      loading,
      password,
      fetching,
      last_name,
      first_name,
      isFormDirty,
      confirm_password
    } = this.state;

    return (
      <Fragment>
        <ToastContainer
          closeButton={false}
          autoClose={2000}
          hideProgressBar={true}
        />

        {fetching && <Loader />}
        {fetched && (
          <Container>
            <Header>Create a staff account</Header>
            <Subtitle>
              Hi {first_name},
              <br />
              You've been invited to create a staff account with BushaBob.
              Finalize your account details to gain access to BushaBob.
            </Subtitle>

            <Form onSubmit={this.handleSubmit}>
              <div className="inputGroup">
                <label>Email</label>
                <input type="email" name="email" value={email} disabled />
              </div>
              <Spacer />
              <div className="formGroup">
                <div className="inputGroup">
                  <label>First name</label>
                  <input
                    name="first_name"
                    type="text"
                    value={first_name}
                    disabled
                  />
                </div>
                <div className="inputGroup">
                  <label>Last name</label>
                  <input
                    name="last_name"
                    type="text"
                    value={last_name}
                    disabled
                  />
                </div>
              </div>

              <div className="inputGroup">
                <label>Phone (optional)</label>
                <input
                  onChange={this.handleChange}
                  name="phone"
                  type="text"
                  value={phone}
                />
              </div>

              <Spacer />

              <div className="inputGroup">
                <label>Password</label>
                <input
                  onChange={this.handleChange}
                  name="password"
                  type="password"
                  value={password}
                />
              </div>
              <Spacer />

              <div className="inputGroup">
                <label>Confirm password</label>
                <input
                  onChange={this.handleChange}
                  name="confirm_password"
                  type="password"
                  value={confirm_password}
                />
              </div>

              <p className="tandc">
                By proceeding, you are agreeing to the Terms & Conditions
              </p>

              <div className="buttons">
                <Button cancle>Decline invitation</Button>
                <Button disabled={!isFormDirty}>
                  {loading ? (
                    <Spinner size={12} color="#ffffff" />
                  ) : (
                    "Create account"
                  )}
                </Button>
              </div>
            </Form>
          </Container>
        )}
        <Footer>
          <BushaLogo />
        </Footer>
      </Fragment>
    );
  }
}

export default connect(
  null,
  { getInviteInfo, acceptInvite }
)(AcceptInvite);
