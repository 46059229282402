import axios from "axios";
import types from "../types";
import { coinBarterApi } from "../../config";
import { toast } from "react-toastify";

const completeTransfer = transferID => async dispatch => {
  const type = types("complete_transfer");

  try {
    dispatch({ type: type.pending });

    const { data } = await axios.post(
      `${coinBarterApi}/fence/transfers/${transferID}/complete`
    );

    dispatch({
      type: type.fulfilled,
      payload: data
    });

    toast.success("Transfer Successful");
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export default completeTransfer;
