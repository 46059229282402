import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Case from "case";
import format from "date-fns/format";

import {
  Table,
  TableHead,
  TableHeadRow,
  TableHeadData,
  TableBody,
  TableRow,
  TableData,
  TableDataBox,
  TableHeadCheckboxBox,
  CheckboxLeft
} from "./common";
import Checkbox from "../../../common/Checkbox";
import ActionDropdown from "../../../common/Dropdowns/ActionDropdown";
import { moneyFormatter } from "../../../../helpers/util";
import { ReactComponent as BackSVG } from "../images/back.svg";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false
    };
  }

  _onSelectAllToggled = () =>
    this.setState(state => ({ selectAll: !state.selectAll }));

  _getTransactionDescription(transaction) {
    const {
      // price,
      product
      // quote, quote_type, type
    } = transaction;

    return product;
  }

  render() {
    const { selectAll } = this.state;
    const {
      swaps: { transactions }
    } = this.props;

    return (
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadData colSpan={selectAll ? "7" : "1"} hasSelect>
              <TableHeadCheckboxBox>
                <CheckboxLeft selectedAll={selectAll}>
                  <Checkbox
                    checked={selectAll}
                    onChange={this._onSelectAllToggled}
                  />
                  {!selectAll ? (
                    <FontAwesomeIcon
                      icon="caret-down"
                      onClick={this._onSelectAllToggled}
                    />
                  ) : (
                    <span>1 payout selected</span>
                  )}
                </CheckboxLeft>
                {selectAll && <ActionDropdown />}
              </TableHeadCheckboxBox>
            </TableHeadData>
            {!selectAll && (
              <Fragment>
                <TableHeadData>
                  <span>Reference</span>
                </TableHeadData>
                <TableHeadData>
                  <span>Description</span>
                </TableHeadData>
                <TableHeadData>
                  <span>Date</span>
                </TableHeadData>
                {/* <TableHeadData>
                  <span>Customer</span>
                </TableHeadData> */}
                <TableHeadData>
                  <span>Status</span>
                </TableHeadData>
                <TableHeadData className="align-right">
                  <span>Price</span>
                </TableHeadData>
              </Fragment>
            )}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {(transactions || []).map(transaction => (
            <TableRow key={transaction.id}>
              <TableData hasSelect>
                <Checkbox />
              </TableData>
              <TableData>
                <Link to={`/transactions/swaps/${transaction.id}`}>
                  <TableDataBox>
                    <span>{transaction.reference}</span>
                  </TableDataBox>
                </Link>
              </TableData>
              <TableData>
                <Link to={`/transactions/swaps/${transaction.id}`}>
                  <TableDataBox>
                    <span>{this._getTransactionDescription(transaction)}</span>
                  </TableDataBox>
                </Link>
              </TableData>
              <TableData>
                <Link to={`/transactions/${transaction.id}`}>
                  <TableDataBox>
                    <span>
                      {format(
                        new Date(transaction.created_at),
                        "MMM D, h:mm a"
                      )}
                    </span>
                  </TableDataBox>
                </Link>
              </TableData>

              <TableData>
                <Link to={`/transactions/swaps/${transaction.id}`}>
                  <TableDataBox>
                    <span className={`status-indicator ${transaction.status}`}>
                      {Case.capital(transaction.status)}
                    </span>
                  </TableDataBox>
                </Link>
              </TableData>
              <TableData>
                <Link to={`/transactions/swaps/${transaction.id}`}>
                  <TableDataBox className="align-right">
                    {transaction.quote_type === "fiat" ? (
                      <span>{moneyFormatter.format(transaction.price)}</span>
                    ) : (
                      <span>{`${transaction.price} ${transaction.quote}`}</span>
                    )}
                  </TableDataBox>
                </Link>
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}
