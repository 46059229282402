import types from "../actions/types";

const initialState = {
  fetching: false,
  fetched: false,
  error: "",
  payload: {}
};

const permissions = (state = initialState, { type, payload }) => {
  const cases = {
    [types("get_permissions").fulfilled]: () => ({
      ...state,
      fetching: false,
      fetched: true,
      payload
    }),
    [types("get_permissions").pending]: () => ({
      ...state,
      fetched: false,
      fetching: true
    }),
    [types("get_permissions").rejected]: () => ({
      ...state,
      fetched: false,
      fetching: false
    }),
    DEFAULT: () => state
  };

  return cases[type] ? cases[type]() : cases["DEFAULT"]();
};

export default permissions;
