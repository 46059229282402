import types, {
  GET_TRADE_ANALYTICS,
  GET_ASSET_ANALYTICS
} from "../actions/types";

const initialState = {
  fetching: false,
  fetched: false,
  swapsFetching: false,
  swapsFetched: false,
  payoutsAnalytics: null,
  depositsAnalytics: null,
  swapBuyAnalytics: null,
  swapSellAnalytics: null,
  products: null,
  period: "hour",
  tradeAnalytics: null,
  tradeVolumeAnalytics: null,
  tradeMetricsAnalytics: null,
  assetsAnalytics: null,
  error: null
};

const analytics = (state = initialState, { type, payload }) => {
  switch (type) {
    case types("get_analytics").fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        payoutsAnalytics: payload.payoutsAnalytics,
        depositsAnalytics: payload.depositsAnalytics,
        period: payload.period
      };

    case types("get_swap_analytics").fulfilled:
      return {
        ...state,
        swapsFetching: false,
        swapsFetched: true,
        period: payload.period,
        swapBuyAnalytics: payload.buyAnalytics,
        swapSellAnalytics: payload.sellAnalytics,
        products: payload.products
      };

    case types("get_swap_analytics").pending:
      return {
        ...state,
        swapsFetching: true,
        swapsFetched: false
      };
    case types("get_analytics").pending:
      return {
        ...state,
        fetched: false,
        fetching: true
      };

    //
    case GET_TRADE_ANALYTICS.fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        tradeAnalytics: payload.tradeAnalytics,
        tradeVolumeAnalytics: payload.tradeVolumeAnalytics,
        tradeMetricsAnalytics: payload.tradeMetricsAnalytics
      };

    case GET_TRADE_ANALYTICS.pending:
      return {
        ...state,
        fetching: true
      };
    case GET_TRADE_ANALYTICS.rejected:
      return {
        ...state,
        error: payload
      };

    //
    case GET_ASSET_ANALYTICS.fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        assetsAnalytics: payload
      };

    case GET_ASSET_ANALYTICS.pending:
      return {
        ...state,
        fetching: true
      };
    case GET_ASSET_ANALYTICS.rejected:
      return {
        ...state,
        error: payload
      };

    default:
      return state;
  }
};

export default analytics;
