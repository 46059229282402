import React from "react";
import { Spinner } from "react-activity";
import Case from "case";
import styled, { css } from "styled-components";

export default ({ detail, loading, handleSetStatus }) => (
  <Container>
    <Section />
    <Section>
      <InfoItem>
        <div>
          <img
            src={`https://res.cloudinary.com/dwoc5fknz/image/upload/v1543504221/crypto_assets/${detail.asset ||
              detail.currency}.svg`}
            alt=""
          />
          <span>
            {`${Case.sentence(detail.side)}ing ${detail.asset} @ ${
              detail.rate
            } x ${detail.reference_exchange} price`}
          </span>
        </div>
        <span />
      </InfoItem>
    </Section>
    <Section borderTop>
      <InfoItem>
        <div>
          {detail.status === "active" ? (
            <ActionBtn onClick={handleSetStatus}>
              {loading ? (
                <Spinner size={10} color="#ffffff" />
              ) : (
                "Deactivate"
              )}
            </ActionBtn>
          ) : (
            <ActionBtn onClick={handleSetStatus}>
              {loading ? (
                <Spinner size={10} color="#ffffff" />
              ) : (
                "Activate"
              )}
            </ActionBtn>
          )}
        </div>
      </InfoItem>
    </Section>
  </Container>
);

const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border-radius: 3px;
  min-height: 100px;
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;

export const Section = styled.div`
  width: 100%;
  padding: 10px;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 10px;

  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 500;
  }
  & > div {
    display: flex;
    align-items: center;
  }
  & > div:first-child {
    img {
      margin-right: 10px;
      height: 30px;
      width: 30px;
    }
    flex: 1;
  }
  & > div:last-child {
    flex: 1.5;
    justify-content: space-between;
  }

  & > div:only-child {
    flex: 1;
    justify-content: flex-end;
  }
  .text-end {
    justify-content: flex-end !important;
  }
  .right-space {
    margin-right: 15px;
  }
`;

const ActionBtn = styled.a`
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  border: 1px solid #3f4eae;
  box-shadow: inset 0 1px 0 0 #6f7bcb;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 0.7rem;
  border-radius: 3px;
  min-width: 134px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.disabled,
  &.disabled:hover {
    cursor: not-allowed;
    background: #f6f8f9;
    box-shadow: none;
    color: initial;
    border: 1px solid #c3cdd5;
  }

  &:hover {
    background: linear-gradient(to bottom, #5c6ac4, #4959bd);
    border-color: #3f4eae;
    color: white;
  }
`;
