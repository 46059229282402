import React from "react";
import styled, { css } from "styled-components";
import { Spinner } from "react-activity";

import { toNaira, moneyFormatter } from "../../../../../helpers/util";

export const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 27px;
  border-radius: 3px;
  min-height: 100px;
`;

export const Section = styled.div`
  width: 100%;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
  padding: 20px;
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;
export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #212b36;
    font-size: 0.6rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;

export const SectionBody = styled.div`
  padding: 15px 0px;
`;

export const Info = styled.div`
  span.action {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span.action:hover {
    text-decoration: underline;
  }
  span.card-number {
    margin-left: 5px;
  }
  p {
    font-size: 0.7rem;
    color: #212b36;
    padding: 5px 0px;
  }
`;

const Email = styled.span`
  font-size: 14px;
  line-height: 1;
`;

const CompleteTransferBtn = styled.button`
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  border: 1px solid #3f4eae;
  box-shadow: inset 0 1px 0 0 #6f7bcb;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  height: 39px;
  font-size: 0.7rem;
  border-radius: 3px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
    background: #f6f8f9;
    box-shadow: none;
    color: initial;
    border: 1px solid #c3cdd5;
  }

  &:hover {
    background: linear-gradient(to bottom, #5c6ac4, #4959bd);
    border-color: #3f4eae;
    color: white;
  }
`;

class SummaryCard extends React.Component {
  state = {
    loading: false
  };

  render() {
    const { transfer, completeTransfer } = this.props;
    return (
      <Container>
        <Section>
          <MainHeader>
            <h3>Summary</h3>
          </MainHeader>

          <SectionBody>
            <Info>
              <p>
                <span style={{ lineHeight: "20px" }}>
                  Fulfilling trade for {transfer.recipient.account_name}
                </span>
              </p>
              <p>
                <span>{transfer.recipient.account_number}</span>
              </p>
            </Info>
          </SectionBody>
        </Section>

        <Section borderTop>
          <Email>{moneyFormatter.format(toNaira(transfer.amount))}</Email>
        </Section>
        {transfer.status !== "success" && (
          <Section borderTop>
            <Email>
              <CompleteTransferBtn
                onClick={() => {
                  this.setState({
                    loading: true
                  });
                  completeTransfer(transfer.id).then(() => {
                    this.setState({
                      loading: false
                    });
                  });
                }}
              >
                {this.state.loading ? (
                  <Spinner color="#fff" />
                ) : (
                  "Complete Transfer"
                )}
              </CompleteTransferBtn>
            </Email>
          </Section>
        )}
      </Container>
    );
  }
}

export default SummaryCard;
