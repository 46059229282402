import React from "react";
import { Spinner } from "react-activity";
import Case from "case";
import styled, { css } from "styled-components";

export default ({ detail, loading, handleSetStatus, statusToSet }) => {
  const description =
    detail.ad.side === "sell"
      ? `${Case.capital(detail.buyer.username)} is buying ${
          detail.buyer_amount
        } ${detail.ad.asset} from ${Case.sentence(detail.seller.username)}`
      : `${Case.capital(detail.seller.username)} is selling ${
          detail.seller_amount
        }${detail.ad.asset} to ${Case.sentence(detail.buyer.username)}`;
  return (
    <Container>
      <Section />
      <Section>
        <InfoItem>
          <div>
            <img
              src={`https://res.cloudinary.com/dwoc5fknz/image/upload/v1543504221/crypto_assets/${detail
                .ad.asset || detail.ad.currency}.svg`}
              alt=""
            />
            <span>{description}</span>
          </div>
          <span />
        </InfoItem>
      </Section>
      <Section>
        <ActionBtnGroup>
          <ActionBtn
            className={
              canResolveTrade(detail.status) || loading ? "" : "disabled"
            }
            onClick={handleSetStatus("completed")}
          >
            {loading && statusToSet === "completed" ? (
              <Spinner size={10} color="#ffffff" />
            ) : (
              "Resolve Trade"
            )}
          </ActionBtn>
          <ActionBtn
            className={
              canCancelTrade(detail.status) || loading ? "" : "disabled"
            }
            onClick={handleSetStatus("canceled")}
          >
            {loading && statusToSet === "canceled" ? (
              <Spinner size={10} color="#ffffff" />
            ) : (
              "Cancel Trade"
            )}
          </ActionBtn>
          {/* {detail.in_dispute && (
            <ActionBtn onClick={handleSetStatus}>
              {loading ? <Spinner size={10} color="#ffffff" /> : "View Dispute"}
            </ActionBtn>
          )} */}
        </ActionBtnGroup>
      </Section>
    </Container>
  );
};

function canResolveTrade(status) {
  return status === "processing" || status === "disputed";
}

function canCancelTrade(status) {
  return (
    status === "processing" || status === "disputed" || status === "pending"
  );
}

const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border-radius: 3px;
  min-height: 100px;
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;

export const Section = styled.div`
  width: 100%;
  padding: 10px;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 10px;

  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 500;
  }
  & > div {
    display: flex;
    align-items: center;
  }
  & > div:first-child {
    img {
      margin-right: 10px;
      height: 30px;
      width: 30px;
    }
    flex: 1;
  }
  & > div:last-child {
    flex: 1.5;
    justify-content: space-between;
  }

  & > div:only-child {
    flex: 1;
    justify-content: flex-end;
  }
  .text-end {
    justify-content: flex-end !important;
  }
  .right-space {
    margin-right: 15px;
  }
`;

const ActionBtnGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  & > a {
    margin-right: 5px;
  }
`;

const ActionBtn = styled.a`
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  border: 1px solid #3f4eae;
  box-shadow: inset 0 1px 0 0 #6f7bcb;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 0.7rem;
  border-radius: 3px;
  min-width: 134px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.disabled,
  &.disabled:hover {
    cursor: not-allowed;
    pointer-events: none;
    background: #f6f8f9;
    box-shadow: none;
    color: initial;
    border: 1px solid #c3cdd5;
  }

  &:hover {
    background: linear-gradient(to bottom, #5c6ac4, #4959bd);
    border-color: #3f4eae;
    color: white;
  }
`;
