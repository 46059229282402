import types from "../../../actions/types";

export const GET_ADS = types("GET_ADS");
export const SET_AD_STATUS = types("SET_AD_STATUS");
export const GET_ADS_DETAIL = types("GET_ADS_DETAIL");
export const GET_ADS_PRODUCTS = types("GET_ADS_PRODUCTS");
export const GET_TRADES = types("GET_TRADES");
export const GET_TRADES_DETAIL = types("GET_TRADES_DETAIL");
export const SET_TRADE_STATUS = types("SET_TRADE_STATUS");
export const GET_MARKETPLACE_ASSET_ADS_SUMMARY = types(
  "GET_MARKETPLACE_ASSET_ADS_SUMMARY"
);
export const GET_MARKETPLACE_ASSET_TRADES_SUMMARY = types(
  "GET_MARKETPLACE_ASSET_TRADES_SUMMARY"
);
