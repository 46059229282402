import React, { Fragment, Component } from "react";
import { extendMoment } from "moment-range";
import Moment from "moment";
import { connect } from "react-redux";
import { differenceInDays } from "date-fns";

import {
  Container,
  MainHeader,
  CalendarBtn,
  CalendarBtnCont,
  Graphs
} from "./elements";
import dateUtil from "../Dashboard/dateUtil";
import Popover from "../Dashboard/Popover";
import { ReactComponent as CalendarIcon } from "../Dashboard/assets/calendar.svg";
import Graph from "../Dashboard/Graph";
import { getSwapAnalytics } from "../../../../actions/analytics";
import GraphLoader from "../GraphLoader";

const moment = extendMoment(Moment);

const currentDate = dateUtil();

class Swap extends Component {
  initialDate = moment.range(
    currentDate.twoDaysAgo().value,
    currentDate.today().value
  );

  state = {
    isModalActive: false,
    shoPopover: false,
    dateRange: this.initialDate,
    appliedDate: "",
    customRange: "today",
    isRangeCustom: false
  };

  togglePopover = () => {
    this.setState(({ showPopover }) => ({
      showPopover: !showPopover,
      dateRange: this.initialDate
    }));
  };

  handleDateRange = dateRange =>
    this.setState({ dateRange, isRangeCustom: false });

  handleCustomRange = ({ target: { value, name } }) => {
    this.setState({
      [name]: value,
      isRangeCustom: true
    });

    this.setRange(value);
  };

  applyDate = ({ end, start }) => {
    const endDate = end.format("MMM D, YYYY");
    const startDate = start.format("MMM D, YYYY");
    const appliedDate = `${startDate} — ${endDate}`;

    this.setState(({ customRange, isRangeCustom }) => {
      const toApply = isRangeCustom ? ranges[customRange].phrase : appliedDate;
      return {
        appliedDate: toApply
      };
    });

    const { getSwapAnalytics } = this.props;
    const period = this.calculatePeriod(startDate, endDate);

    getSwapAnalytics(period, startDate, endDate);
  };

  calculatePeriod = (start, end) => {
    const dayDifference = differenceInDays(end, start);

    if (dayDifference > 365) {
      return "year";
    }

    if (dayDifference > 30 && dayDifference <= 365) {
      return "month";
    }

    if (dayDifference > 7 && dayDifference <= 30) {
      return "week";
    }

    if (dayDifference > 1 && dayDifference <= 7) {
      return "day";
    }

    return "hour";
  };

  setRange = customRange => {
    const { start, end } = ranges[customRange];

    this.setState({
      dateRange: moment.range(start, end)
    });
  };

  componentDidMount() {
    document.title = "Buy-Sell Analytics";
    const { getSwapAnalytics } = this.props;
    const {
      dateRange: { end, start }
    } = this.state;

    const startDate = start.format("YYYY-MM-DD");
    const endDate = end.format("YYYY-MM-DD");
    const period = this.calculatePeriod(startDate, endDate);

    getSwapAnalytics(period, start.format(), end.format());
  }

  render() {
    const { showPopover, dateRange, appliedDate, customRange } = this.state;
    const {
      swapBuyAnalytics,
      swapSellAnalytics,
      products,
      swapsFetching,
      swapsFetched,
      period
    } = this.props.analytics;

    return (
      <Container>
        <div>
          <MainHeader>
            <h2>Buy-Sell Analytics</h2>
          </MainHeader>

          <CalendarBtnCont>
            <CalendarBtn onClick={this.togglePopover}>
              <CalendarIcon />
              <span>{appliedDate ? appliedDate : "Last 2 days"}</span>
            </CalendarBtn>

            {showPopover && (
              <Popover
                dateRange={dateRange}
                appliedDate={appliedDate}
                customRange={customRange}
                applyDate={this.applyDate}
                togglePopover={this.togglePopover}
                handleDateRange={this.handleDateRange}
                handleCustomRange={this.handleCustomRange}
              />
            )}
          </CalendarBtnCont>

          <Graphs>
            {swapsFetching &&
              [1, 2, 4, 3].map(loader => <GraphLoader key={loader} />)}

            {swapsFetched &&
              products.map(product => (
                <Fragment key={product.id}>
                  <Graph
                    period={period}
                    graphData={swapBuyAnalytics[product.id]}
                    unit={product.id}
                    domain="buys"
                    color="#9C6ADE"
                  />

                  <Graph
                    period={period}
                    graphData={swapSellAnalytics[product.id]}
                    unit={product.id}
                    domain="sells"
                    color="#48C0BF"
                  />
                </Fragment>
              ))}
          </Graphs>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ analytics }) => ({
  analytics
});

export default connect(
  mapStateToProps,
  { getSwapAnalytics }
)(Swap);

const today = currentDate.today().toObject();
const yesterday = currentDate.yesterday().toObject();
const twoDaysAgo = currentDate.twoDaysAgo().toObject();
const oneWeekAgo = currentDate.oneWeekAgo().toObject();
const thirtyDaysAgo = currentDate.thirtyDaysAgo().toObject();
const threeMonthsAgo = currentDate.threeMonthsAgo().toObject();

const ranges = {
  today: {
    end: today,
    start: today,
    phrase: "Today"
  },
  yesterday: {
    end: yesterday,
    start: yesterday,
    phrase: "Yesterday"
  },
  twoDaysAgo: {
    end: today,
    start: twoDaysAgo,
    phrase: "Last 2 days"
  },
  oneWeekAgo: {
    start: oneWeekAgo,
    end: today,
    phrase: "Last 7 days"
  },
  thirtyDaysAgo: {
    start: thirtyDaysAgo,
    end: today,
    phrase: "Last 30 days"
  },
  threeMonthsAgo: {
    start: threeMonthsAgo,
    end: today,
    phrase: "Last 90 days"
  }
};
