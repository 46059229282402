import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "react-activity/lib/Spinner/Spinner";
import "react-activity/lib/Spinner/Spinner.css";
import isEmpty from "lodash/isEmpty";
import { ToastContainer } from "react-toastify";

import {
  Container,
  Body,
  Header,
  AssetTitleBox,
  FormFooter as FormActionBox
} from "./components/common";
import { ReactComponent as BackSVG } from "./svgs/back.svg";
import AssetLoader from "./components/AssetLoader";
import BasicInfoForm from "./components/BasicInfoForm";
import ActivityForm from "./components/ActivityForm";

import FeesForm from "./components/FeesForm";
import {
  getProvider,
  updateProvider,
  createProvider
} from "../../../actions/providers";
import LimitForm from "./components/LimitForm";
import { ActionBtn } from "../Transactions/NewTransaction/elements";

class AssetInfo extends React.Component {
  requiredFields = [];

  constructor(props) {
    super(props);
    if (props.match.params.providerType === "deposits") {
      this.requiredFields = [
        "name",
        "code",
        "currency",
        "channel",
        "description",
        "full_description",
        "base_fee_amount",
        "extra_fee_amount",
        "extra_fee_minimum_amount",
        "maximum_fee_amount",
        "maximum_amount",
        "minimum_amount",
        "status"
      ];
    } else {
      this.requiredFields = [
        "name",
        "code",
        "currency",
        "channel",
        "description",
        "base_fee_amount",
        "maximum_amount",
        "minimum_amount",
        "status"
      ];
    }
    this.state = {
      form: {
        name: "",
        code: "",
        currency: "",
        channel:
          props.match.params.providerType === "deposits" ? "card" : "bank",
        description: "",
        full_description: "",
        base_fee_amount: "0",
        extra_fee_amount: "0",
        extra_fee_minimum_amount: "0",
        maximum_fee_amount: "0",
        maximum_amount: "0",
        minimum_amount: "0",
        status: "online"
      }
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    if (this.props.mode === "edit")
      this.props
        .getProvider(params.providerType, params.providerId)
        .then(({ value: { data } }) => {
          // console.log({ data });
          this.setState(state => ({ form: { ...state.form, ...data } }));
        });
  }

  handleInputChange = event => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.type === "radio"
        ? target.value == "true"
        : target.value;
    const name = target.name;

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  formIsValid = () => {
    let isValid = true;
    const { form } = this.state;
    for (const key in form) {
      if (form.hasOwnProperty(key)) {
        if (this.requiredFields.indexOf(key) !== -1) {
          const formInput = form[key];
          // console.log({ [key]: formInput });
          if (isEmpty(formInput.toString().trim())) {
            isValid = false;
            break;
          }
        }
      }
    }
    if (
      Number(form.deposit_fee) > 0 &&
      isEmpty(form.deposit_fee_description.trim())
    ) {
      return false;
    }
    if (
      Number(form.payout_fee) > 0 &&
      isEmpty(form.payout_fee_description.trim())
    ) {
      return false;
    }
    return isValid;
  };

  onBtnClicked = () => {
    const {
      mode,
      providers,
      createProvider,
      updateProvider,
      history,
      match: { params }
    } = this.props;
    if (providers.creating || providers.updating) return;
    if (mode === "edit") {
      updateProvider(
        params.providerType,
        params.providerId,
        this.state.form,
        () => history.push(`/settings/providers/${params.providerType}`)
      );
    } else {
      createProvider(params.providerType, this.state.form, data => {
        console.log({ data });
        history.push(`/settings/providers/${params.providerType}`);
        // history.push(
        //   `/settings/providers/${params.providerType}/${this.state.form.code}`
        // );
      });
    }
  };

  render() {
    const {
      providers,
      match: { params }
    } = this.props;
    const { form } = this.state;

    return providers.fetching ? (
      <AssetLoader />
    ) : (
      <Container>
        <ToastContainer
          closeButton={false}
          autoClose={1000}
          hideProgressBar={true}
        />
        <Header>
          <nav>
            <Link
              className="back-button"
              to={`/settings/providers/${params.providerType}`}
            >
              <BackSVG className="svg" />
              <span>Settings</span>
            </Link>
          </nav>
        </Header>
        <AssetTitleBox>
          <h1>{form.name}</h1>
        </AssetTitleBox>
        <Body>
          <BasicInfoForm
            form={form}
            handleInputChange={this.handleInputChange}
            providerType={params.providerType}
          />
          <FeesForm
            form={form}
            handleInputChange={this.handleInputChange}
            providerType={params.providerType}
          />
          <LimitForm
            form={form}
            handleInputChange={this.handleInputChange}
            providerType={params.providerType}
          />
          <ActivityForm
            form={form}
            handleInputChange={this.handleInputChange}
          />
          <FormActionBox>
            <ActionBtn
              disabled={!this.formIsValid()}
              onClick={this.onBtnClicked}
            >
              {providers.creating || providers.updating ? (
                <Spinner size={15} color="#ffffff" speed={2} />
              ) : this.props.mode === "create" ? (
                "Create Currency"
              ) : (
                "Update Currency"
              )}
            </ActionBtn>
          </FormActionBox>
        </Body>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return { providers: state.providers };
}

export default connect(
  mapStateToProps,
  { getProvider, updateProvider, createProvider }
)(AssetInfo);
