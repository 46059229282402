import React from "react";

import RadioButton from "../../../common/RadioButton";
import { FormSection, FormSectionInfo } from "./common";
import { Container, Section, FormGroup } from "./formElements";
import Select from "../../../common/Select";

const ActivityForm = ({ form, handleInputChange }) => {
  return (
    <FormSection>
      <FormSectionInfo>
        <h3>Activity</h3>
      </FormSectionInfo>
      <Container>
        <Section>
          <section>
            <FormGroup>
              <Select
                name="status"
                label="Status"
                options={["Online", "Offline"]}
                value={form.status}
                onChange={handleInputChange}
              />
            </FormGroup>
          </section>
        </Section>
        {/* <Section borderTop>
          <section>
            <FormGroup>
              <label htmlFor="deposit_status">Deposit Status</label>
              <RadioButton
                name="deposit_status"
                value="true"
                label="True"
                checked={form.deposit_status.toString() === "true"}
                onChange={handleInputChange}
              />
              <RadioButton
                name="deposit_status"
                value="false"
                label="False"
                checked={form.deposit_status.toString() === "false"}
                onChange={handleInputChange}
              />
            </FormGroup>
          </section>
        </Section> */}
      </Container>
    </FormSection>
  );
};
export default ActivityForm;
