import React, { Fragment } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import format from "date-fns/format";
import styled from "styled-components";

import PayoutInfo from "./components/PayoutInfo";
import {
  Level,
  SubHead,
  Content,
  CustomerInfo,
  CustomerActivity
} from "./elements";
import { Spinner } from "react-activity";
import Modal from "../../common/Modal";
import {
  Container,
  Body,
  Header,
  NextPrev,
  ActiontBtnBox,
  PayoutActionBtnsBox,
  ReferenceAndDateBox
} from "./components/common";
import EditCustomerAddress from "./components/EditCustomerAddress";
import EditMemo from "./components/EditMemo";
import MoreInfo from "./components/MoreInfo";
import { ToastContainer, toast } from "react-toastify";
import AccountCard from "./components/AccountCard";
import Depositinfo from "./components/DepositInfo";
import ActionDropdown from "./components/ActionDropdown";
import { ReactComponent as BackSVG } from "./svgs/back.svg";
import { ReactComponent as ArrowSVG } from "./svgs/arrow.svg";
import { ReactComponent as PrintSVG } from "./svgs/print.svg";
import CustomerCard, { BtnCont } from "./components/CustomerCard";
import {
  fetchPayoutInfo,
  finalizePayout,
  fulfillPayout
} from "../../../actions/payouts";
import memoUpdate from "../../../actions/memoUpdate";
import updateAddress from "../../../actions/addressUpdate";
import PayoutDetailLoader from "../../common/SVGLoaders/InfoLoader";

const initialState = {
  whichModal: "",
  loading: false,
  modalLoading: false,
  loadingFulfill: false,
  loadingFinalize: false,
  isAddressModalOpen: false,
  isMemoModalOpen: false,
  address: "",
  memo: ""
};
class PayoutDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      ...initialState
    };
  }
  onModalErr = () => {
    this.setState({ modalLoading: false });
  };
  ontoggleAddressModal = () => {
    this.setState({
      isAddressModalOpen: !this.state.isAddressModalOpen,
      whichModal: "address"
    });
  };

  ontoggleisMemoModal = () => {
    this.setState({
      isMemoModalOpen: !this.state.isMemoModalOpen,
      whichModal: "memo"
    });
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  finilizeLoader = () =>
    this.setState(({ loadingFinalize }) => ({
      loadingFinalize: !loadingFinalize
    }));
  fulfillLoader = () =>
    this.setState(({ loadingFulfill }) => ({
      loadingFulfill: !loadingFulfill
    }));

  handleFinalize = () => {
    const {
      match: {
        params: { type, payoutId }
      },
      finalizePayout
    } = this.props;
    const cb = this.finilizeLoader;
    finalizePayout(payoutId, type, cb);
  };

  handleFulfill = () => {
    const {
      match: {
        params: { type, payoutId }
      },
      fulfillPayout
    } = this.props;
    const cb = this.fulfillLoader;
    fulfillPayout(payoutId, type, cb);
  };

  componentDidMount() {
    this.onFetchPayout();
  }

  onFetchPayout = () => {
    const {
      match: {
        params: { type, payoutId }
      },
      fetchPayoutInfo
    } = this.props;
    fetchPayoutInfo(type, payoutId);
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.payout, this.props.payout)) {
      if (this.props.payout.payout && this.props.payout.payout.address) {
        this.setState({
          address: this.props.payout.payout.address,
          memo: this.props.payout.payout.message
        });
      }
    }
  }

  onUpdate = () => {
    const { address } = this.state;
    const { id } = this.props.payout.payout;
    const cb = () => {
      this.setState({ ...initialState });
      this.onFetchPayout();
    };
    const error = () => this.onModalErr();
    this.setState({ modalLoading: true });
    this.props.updateAddress({ id, address, cb: cb, onError: error });
  };

  onMemoUpdate = () => {
    const { memo } = this.state;
    const { id } = this.props.payout.payout;
    const cb = () => {
      this.setState({ ...initialState });
      this.onFetchPayout();
    };
    const error = () => this.onModalErr();
    this.setState({ modalLoading: true });
    this.props.memoUpdate({ id, memo, cb: cb, onError: error });
  };
  render() {
    const {
      payout: { payout, fetching },
      match: {
        params: { type }
      }
    } = this.props;

    const modalContent = {
      address: {
        title: "Change Address",
        text: this.state.address,
        action: this.onUpdate
      },
      memo: {
        title: "Change Memo",
        text: this.state.memo,
        action: this.onMemoUpdate
      }
    };

    return fetching || !payout ? (
      <PayoutDetailLoader />
    ) : (
      <Fragment>
        <ToastContainer
          closeButton={false}
          autoClose={2000}
          hideProgressBar={true}
        />
        <Container>
          <Header>
            <nav>
              <a
                className="back-button"
                href="#goback"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                <BackSVG className="svg" />
                <span>Payouts</span>
              </a>

              <div>
                <NextPrev>
                  <ArrowSVG className="arrow-left" />
                </NextPrev>
                <NextPrev>
                  <ArrowSVG className="arrow-right" />
                </NextPrev>
              </div>
            </nav>
          </Header>
          <ReferenceAndDateBox>
            <h1>#{payout.reference}</h1>
            <span>
              {format(payout.created_at, "MMMM D, YYYY [at] hh:mm a")}
            </span>
          </ReferenceAndDateBox>
          <PayoutActionBtnsBox>
            <ActiontBtnBox>
              <PrintSVG />
              <span>Print</span>
            </ActiontBtnBox>
            <ActionDropdown />
          </PayoutActionBtnsBox>
          <Body>
            <div>
              <AccountCard
                account={payout}
                loadingFulfilled={this.state.loadingFulfill}
                loadingFinalize={this.state.loadingFinalize}
                handleFulfill={this.handleFulfill}
                handleFinalize={this.handleFinalize}
              />
              {/* <PayoutInfo payout={payout} type={type} /> */}
              <Depositinfo payout={payout} />
              {/* <MoreInfo>
              <BasicInfo payout={payout} type={type} />
            </MoreInfo>
            <MoreInfo>
              <UserInfo payout={payout} type={type} />
            </MoreInfo>
            <MoreInfo>
              <TransactionInfo
                payout={payout}
                handleClick={this.handleFinalize}
                type={type}
              />
            </MoreInfo> */}
            </div>
            <div>
              {payout.user && (
                <CustomerCard
                  bank={payout.bank || null}
                  payout={payout}
                  customer={payout.user}
                  ontoggleisMemoModal={this.ontoggleisMemoModal}
                  ontoggleAddressModal={this.ontoggleAddressModal}
                />
              )}
            </div>
          </Body>
        </Container>
        {this.state.isAddressModalOpen && (
          <Modal
            meta={{
              width: "620px",
              buttonType: "blue"
            }}
            data={{
              title: modalContent[this.state.whichModal].title,
              loading: this.state.loading,
              buttonTxt: this.state.modalLoading ? (
                <Spinner color="white" />
              ) : (
                modalContent[this.state.whichModal].title
              ),
              body: (
                <EditCustomerAddress
                  address={modalContent[this.state.whichModal].text}
                  handleChange={this.handleChange}
                />
              )
            }}
            actions={{
              toggleModal: this.ontoggleAddressModal,
              handleClick: modalContent[this.state.whichModal].action
            }}
          />
        )}
        {this.state.isMemoModalOpen && (
          <Modal
            meta={{
              width: "620px",
              buttonType: "blue"
            }}
            data={{
              title: modalContent[this.state.whichModal].title,
              loading: this.state.loading,
              buttonTxt: this.state.modalLoading ? (
                <Spinner color="white" />
              ) : (
                modalContent[this.state.whichModal].title
              ),
              body: (
                <EditMemo
                  memo={modalContent[this.state.whichModal].text}
                  handleChange={this.handleChange}
                />
              )
            }}
            actions={{
              toggleModal: this.ontoggleisMemoModal,
              handleClick: modalContent[this.state.whichModal].action
            }}
          />
        )}
      </Fragment>
    );
  }
}

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 0px;
  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
  }
  span.bold {
    font-weight: 500;
  }
  hr {
    width: 100%;
    color: #e6e6e6;
    background-color: #e6e6e6;
    height: 1px;
    padding: 0;
    border: 0;
  }
`;

const BasicInfo = ({ payout, type }) => (
  <>
    <InfoItem>
      <span>Status</span>
      <span>{payout.status}</span>
    </InfoItem>
    <InfoItem>
      <span>Created</span>
      <span>{format(payout.created_at, "MMM DD, YYYY")}</span>
    </InfoItem>
    <InfoItem>
      <span>Reference</span>
      <span>{payout.reference}</span>
    </InfoItem>
    {payout.ps_transfer_code && (
      <InfoItem>
        <span>Transfer Code</span>
        <span>{payout.ps_transfer_code}</span>
      </InfoItem>
    )}
  </>
);

const UserInfo = ({
  payout: {
    address,
    user: { profile, email }
  }
}) => (
  <>
    <InfoItem>
      <span>Name</span>
      <span>
        {profile.first_name} {profile.last_name}
      </span>
    </InfoItem>
    <InfoItem>
      <span>Email</span>
      <span>{email}</span>
    </InfoItem>
    {address && (
      <InfoItem>
        <span>Address</span>
        <span>{address}</span>
      </InfoItem>
    )}
  </>
);

const TransactionInfo = ({ payout, handleClick }) => (
  <>
    <InfoItem>
      <span>Amount</span>
      <span>{payout.amount}</span>
    </InfoItem>
    <InfoItem>
      <span>Fee</span>
      <span>{payout.fees || payout.fee}</span>
    </InfoItem>
    <InfoItem>
      <span>Total</span>
      <span>43798374897</span>
    </InfoItem>
    {payout.actual_fee && (
      <InfoItem>
        <span>Actual Fee</span>
        <span>{payout.actual_fee}</span>
      </InfoItem>
    )}
    <InfoItem>
      <span>Profit</span>
      <span>0.00434</span>
    </InfoItem>

    <ButtonContainer>
      <button disabled={payout.status === "success"} onClick={handleClick}>
        Process
      </button>
    </ButtonContainer>
  </>
);

const ButtonContainer = styled(BtnCont)`
  padding-top: 10px;
  padding-right: 20px;

  button:disabled {
    border: 1px solid #919dab;
    background: #f4f5f8;
    box-shadow: none;
    color: #919dab;
    cursor: not-allowed;
  }
`;

const ChangeAddressForm = styled.form`
  font-size: 14px;
  padding: 20px;

  textarea {
    display: block;
    width: 100%;
    padding: 10px;

    :focus {
      border: 2px solid #5c6ac4 !important;
    }
  }

  .formGroup {
    display: flex;
    padding: 0 20px;
    margin-bottom: 20px;

    .inputGroup {
      padding: 0;

      :not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .inputGroup {
    flex: 1;

    label {
      display: block;
      margin-bottom: 6px;
    }

    textarea {
      border-radius: 3px;
      height: 70px;
      box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
      border: 1px solid #c4cdd5;
      outline: none;
      font-size: inherit;
    }
  }
`;

function mapStateToProps({ payout }) {
  return { payout };
}

export default connect(
  mapStateToProps,
  { fetchPayoutInfo, finalizePayout, updateAddress, memoUpdate, fulfillPayout }
)(PayoutDetail);
