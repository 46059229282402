import React from "react";
import styled, { css } from "styled-components";

import icon from "../../assets/processin.svg";
import { ImageBox } from "./DepositInfo";
import statuses from "./statuses";
import markAsFulfilled from "../../../../../actions/coinbarter/markAsFulfilled";
// import { moneyFormatter } from "../../../../helpers/util";

export default ({ trade, history }) => (
  <Container>
    <ExtendedSection>
      <ImageBox>
        {statuses[trade.status]}
        <span>{trade.status}</span>
      </ImageBox>

      <span className="confirmations">
        Confirmations: {trade.confirmations} / {trade.required_confirmations}
      </span>
    </ExtendedSection>
    <Section>
      <InfoItem>
        <div>
          {trade.asset.code === "BTC" && (
            <img
              src="https://res.cloudinary.com/dwoc5fknz/image/upload/v1546452487/bob/BTC.svg"
              alt={trade.asset.code}
            />
          )}
          {trade.asset.code === "ETH" && (
            <img
              src="https://res.cloudinary.com/dwoc5fknz/image/upload/v1546452451/bob/ETH.svg"
              alt={trade.asset.code}
            />
          )}

          <span>
            {trade.asset.name} {trade.asset.code}
          </span>
        </div>
        <div>
          <span />
          <span>
            <span className="bold">
              {trade.amount} {trade.asset.code}{" "}
            </span>{" "}
            @ {trade.price} / {trade.asset.code}
          </span>
        </div>
      </InfoItem>
    </Section>
    <Section borderTop>
      <InfoItem>
        <div>
          <ActionBtn
            onClick={() => {
              markAsFulfilled(trade.id);
            }}
            disabled={trade.status === "completed"}
          >
            Mark as fulfilled
          </ActionBtn>
          <ActionBtn
            disabled={
              trade.status === "expired" || trade.status === "cancelled" || trade.status === "pending"
            }
            onClick={() =>
              history.push(`/coinbarter/transfers/${trade.id}/trade`)
            }
          >
            {trade.status === "completed" ? "View Transfer" : "Make Transfer"}
          </ActionBtn>
        </div>
      </InfoItem>
    </Section>
  </Container>
);

const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 27px;
  border-radius: 3px;
  min-height: 100px;
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;

export const Section = styled.div`
  width: 100%;
  padding: 10px;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
`;

export const ExtendedSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;

  .confirmations {
    font-size: 16px;
    font-weight: 500;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 10px;

  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 500;
  }

  .bold {
    font-weight: 700;
  }

  & > div {
    display: flex;
    align-items: center;
  }
  & > div:first-child {
    img {
      margin-right: 10px;
      height: 30px;
      width: 30px;
    }
    flex: 1;
  }
  & > div:last-child {
    flex: 1.5;
    justify-content: space-between;
  }

  & > div:only-child {
    flex: 1;
    justify-content: flex-end;
  }
`;

export const ActionBtn = styled.button`
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  border: 1px solid #3f4eae;
  box-shadow: inset 0 1px 0 0 #6f7bcb;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 0.7rem;
  border-radius: 3px;
  text-decoration: none;

  &:last-child {
    margin-left: 16px;
  }

  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
    background: #f6f8f9;
    box-shadow: none;
    color: initial;
    border: 1px solid #c3cdd5;
    pointer-events: none;
  }

  &:hover {
    background: linear-gradient(to bottom, #5c6ac4, #4959bd);
    border-color: #3f4eae;
    color: white;
  }
`;
