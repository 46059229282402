import React from "react";

import { Card, Body, MetricItem, Content } from "./elements";
import { currencyFormatter, round } from "../../../../../helpers/util";

function getQuoteCurrency(product) {
  return product.split("-")[1];
}

export default function ProductMetrics(props) {
    // console.log(props.data);
  const {
    total,
    count,
    buys,
    buy_count,
    sells,
    sell_count,
    fees,
    fee_count,
    abandoned
  } = props.data;
  return (
    <Card>
      <Body>
        <h2 className="title">{props.product}</h2>
        <Content>
            <MetricItem>
                <h3 className="convertion-rate">
                    {
                        round((Number(count)/ (Number(count) + Number(abandoned)) || 0)*100, 2)
                    }%
                </h3>
            </MetricItem>
            <MetricItem className="metric-title">
                <h3 className="metric-title">
                    Metric
                </h3>
                </MetricItem>
          <MetricItem>
            <div>
              <h3>Total</h3>
              <h3 className="sub-metric">{count} Trade(s)</h3>
            </div>
            <div>
              <h3>
                {currencyFormatter(total, getQuoteCurrency(props.product))}
              </h3>
            </div>
          </MetricItem>
          <MetricItem>
            <div>
              <h3>Buy</h3>
              <h3 className="sub-metric">{buy_count} Bought</h3>
            </div>
            <div>
              <h3>
                {currencyFormatter(buys, getQuoteCurrency(props.product))}
              </h3>
            </div>
          </MetricItem>
          <MetricItem>
            <div>
              <h3>Sell</h3>
              <h3 className="sub-metric">{sell_count} Sold</h3>
            </div>
            <div>
              <h3>
                {currencyFormatter(sells, getQuoteCurrency(props.product))}
              </h3>
            </div>
          </MetricItem>
          <MetricItem>
            <div>
              <h3>Fee</h3>
              <h3 className="sub-metric">{fee_count} Trade(s)</h3>
            </div>
            <div>
              <h3>
                {currencyFormatter(fees, getQuoteCurrency(props.product))}
              </h3>
            </div>
          </MetricItem>
        </Content>
      </Body>
    </Card>
  );
}
