import styled, { css } from "styled-components";

const BodyData = styled.td`
  font-weight: 400;
  font-size: 0.7rem;
  text-align: left;
  color: #212b36;
  height: 50px;
  box-sizing: border-box;
  padding: 8px 10px;
  border-bottom: 1px solid #ebeef0;
  line-height: 1.2rem;
  .newcont {
    display: flex;
    justify-content: flex-end;
  }
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.hasSelect &&
    css`
      width: 55px;
      max-width: 55px;
    `};

  ${({ weight }) =>
    weight &&
    css`
      font-weight: ${weight || "normal"};
    `};

  & > label {
    margin: 0;
  }

  & div.align-right {
    text-align: right;
  }

  & div.padding-right {
    padding-right: 20px;
  }
  & > a {
    text-decoration: none;
  }
  ${({ first }) =>
    first &&
    css`
      padding: 8px 20px;
    `}
`;
export default BodyData;
