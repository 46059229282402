import React from "react";
import styled from "styled-components";
import { Spinner } from "react-activity";
import Pulser from "../../common/CustomizableLoader";

const GraphLoader = () => (
  <Container>
    <Pulser width="200px" height="15px" />
    <br />
    <br />
    <Pulser width="200px" height="30px" />

    <Spacer />
    <Pulser width="250px" height="15px" />
    <br />
    <Graph>
      <Spinner size={40} color="#47C1BF" speed={2} />
    </Graph>
    <RightText>
      <Pulser width="200px" height="15px" />
    </RightText>
  </Container>
);

export default GraphLoader;

const Container = styled.div`
  width: calc(50% - 8px);
  height: 504px;
  padding: 20px;
  color: #212b36;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  background: #fff;
  border-radius: 3px;
  margin-top: 16px;
`;

const Graph = styled.div`
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spacer = styled.div`
  height: 30px;
`;

const RightText = styled.div`
  display: flex;
  justify-content: flex-end;
`;
