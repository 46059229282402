import React, { Fragment } from "react";
import {
  Header,
  Body,
  Card,
  HeaderRight,
  HeaderLeft,
  HeaderText,
  HeaderExportBtn,
  CardHeader,
  HeaderTextBox,
  HeaderExportBtnBox,
  CreateBtn,
  TabsBtnBox,
  TabBtnList,
  TabBtnItem,
  TabBtnLink,
  SearchAndFilterBox,
  FilterBox,
  SearchBox,
  SearchIconBox,
  SearchInput
} from "../Payouts/components/common";
import { isActive } from "../../../helpers/util";
import { debounce, isEqual } from "lodash";
import qs from "qs";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomersLoader from "./components/CustomersLoader";
import FilterDropdown from "./components/FilterDropdown";
import {
  CContainer,
  UploadBtnsBox,
  FilterAndSortBox,
  FilterSearchBox,
  SortBox,
  SortLabel,
  SortDropdownBox,
  CDropdownSelect,
  CCardBody,
  CTableBox
} from "./components/elements";
import { InnerSpinner } from "./components/elements";
import { Spinner } from "react-activity";
import CustomersTable from "./components/CustomersTable";
import Paginator from "../../common/Paginator";
import getVerifications from "../../../actions/verifications";

class App extends React.Component {
  state = {
    totalEntries: 1,
    perPage: 20,
    searchQuery: "",
    waitTime: 500
  };
  componentDidMount() {
    this.onGetVerification();
  }
  onGetVerification = () => {
    const {
      getVerifications,
      location: { search }
    } = this.props;

    const { page = 1, status = "", level = "" } = qs.parse(search, {
      ignoreQueryPrefix: true
    });

    const queryString =
      status && level
        ? `?status=${status}&level=${level}`
        : status
        ? `?status=${status} `
        : level
        ? `?level=${level} `
        : "";
    getVerifications(queryString);
  };

  debouncedSearch = debounce(query => {
    this.onGetVerification();
    // searchCustomers(
    //     page,
    //     status,
    //     totalEntries => {
    //         this.setState({
    //             totalEntries,
    //         });
    //     },
    //     query
    // );
  }, this.state.waitTime);

  handleChange = ({ target: { value, name } }) => {
    this.setState({
      [name]: value
    });

    const trimmedValue = value.trim();

    this.debouncedSearch(trimmedValue);
  };

  handleFilter = ({ target: { value } }) => {
    console.log({ value, ...this.props.history.location });
    const {
      location: { search, pathname },
      push
    } = this.props.history;
    let url;
    const { page = 1, status = "", level = "" } = qs.parse(search, {
      ignoreQueryPrefix: true
    });

    if (status && value) {
      url = `${pathname}?status=${status}&level=${value}`;
      push(url);
    } else if (value) {
      url = `${pathname}?level=${value}`;
      push(url);
    } else {
      url = `${pathname}`;
      push(url);
    }
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      this.onGetVerification();
    }
  }

  render() {
    const {
      history: {
        location: { search }
      },
      verifications
    } = this.props;

    const fetched = verifications.fetched_verification,
      searched = verifications.fetched_verification,
      searching = verifications.fetching_verification;

    const { level = "" } = qs.parse(search, {
      ignoreQueryPrefix: true
    });

    console.log({ fetched, searching, searched, verifications });
    return (
      <CContainer>
        <Fragment>
          <Header>
            <HeaderLeft>
              <HeaderTextBox>
                <HeaderText>KYC</HeaderText>
              </HeaderTextBox>
            </HeaderLeft>
          </Header>
          <Body>
            <Card>
              <CardHeader>
                <TabsBtnBox>
                  <TabBtnList>
                    <TabBtnItem>
                      <TabBtnLink
                        to="/customers/kyc"
                        className={isActive(undefined, search) && "active-tab"}
                      >
                        All
                      </TabBtnLink>
                    </TabBtnItem>
                    <TabBtnItem>
                      <TabBtnLink
                        to="/customers/kyc/?status=pending"
                        className={isActive("pending", search) && "active-tab"}
                      >
                        Pending
                      </TabBtnLink>
                    </TabBtnItem>
                    <TabBtnItem>
                      <TabBtnLink
                        to="/customers/kyc/?status=accepted"
                        className={isActive("accepted", search) && "active-tab"}
                      >
                        Accepted
                      </TabBtnLink>
                    </TabBtnItem>
                    <TabBtnItem>
                      <TabBtnLink
                        to="/customers/kyc/?status=rejected"
                        className={isActive("rejected", search) && "active-tab"}
                      >
                        Rejected
                      </TabBtnLink>
                    </TabBtnItem>
                  </TabBtnList>
                </TabsBtnBox>
              </CardHeader>
              <CCardBody>
                <FilterAndSortBox>
                  <FilterSearchBox>
                    <SearchAndFilterBox>
                      {/* <FilterBox flex="15%">
                        <FilterDropdown smallDropdownBox />
                      </FilterBox> */}
                      <SearchBox>
                        <SearchIconBox>
                          <FontAwesomeIcon icon="search" />
                        </SearchIconBox>
                        <SearchInput
                          value={this.state.searchQuery}
                          onChange={this.handleChange}
                          name="searchQuery"
                          placeholder="Search Customers"
                        />
                      </SearchBox>
                    </SearchAndFilterBox>
                  </FilterSearchBox>
                  <SortBox>
                    <SortLabel>
                      <span>Sort By</span>
                    </SortLabel>
                    <SortDropdownBox>
                      <CDropdownSelect
                        value={level}
                        onChange={this.handleFilter}
                      >
                        <option value="">All</option>
                        <option value="2">Level 2</option>
                        <option value="3">Level 3</option>
                      </CDropdownSelect>
                    </SortDropdownBox>
                  </SortBox>
                </FilterAndSortBox>
                <CTableBox>
                  {(searching || !searched) && (
                    <InnerSpinner>
                      <Spinner size={30} color="#47C1BF" speed={2} />
                    </InnerSpinner>
                  )}
                  {(fetched || searched) && (
                    <CustomersTable customers={verifications} />
                  )}
                </CTableBox>
              </CCardBody>
            </Card>
          </Body>
          {(fetched || searched) &&
            verifications.list.Paginate.total_entries_size > 20 && (
              <Paginator
                totalEntries={verifications.list.Paginate.total_entries_size}
                perPage={verifications.list.Paginate.per_page}
                {...this.props}
              />
            )}
        </Fragment>
      </CContainer>
    );
  }
}
const mapStateToprops = ({ verifications }) => ({ verifications });
const mapDispatchToProps = {
  getVerifications
};
export default connect(
  mapStateToprops,
  mapDispatchToProps
)(App);
