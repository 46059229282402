import React from "react";
import Chart from "chart.js";

import {
  Container,
  MainHeader,
  ActionBtnsBox,
  ActiontBtn,
  CustomerBehaviourAndPageViewBox
} from "./components/common";
import { ReactComponent as FullScreenSVG } from "./svgs/full-screen.svg";
import MapAndVisits from "./components/MapAndVisits";
import CustomerBehavior from "./components/CustomerBehaviour";
import PageViews from "./components/PageViews";

Chart.defaults.global.responsive = true;

export default class LiveView extends React.Component {
  /* View in fullscreen */
  openFullscreen() {
    // const elem = document.documentElement;
    const elem = this.containerElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  componentDidMount() {
    document.title = "Live View";
  }

  render() {
    return (
      <Container ref={ref => (this.containerElement = ref)}>
        <div>
          <MainHeader>
            <h2>Live View</h2>
          </MainHeader>
          <ActionBtnsBox>
            <ActiontBtn onClick={this.openFullscreen.bind(this)}>
              <FullScreenSVG />
              <span>Full screen</span>
            </ActiontBtn>
          </ActionBtnsBox>
          <MapAndVisits />
          <CustomerBehaviourAndPageViewBox>
            <CustomerBehavior />
            <PageViews />
          </CustomerBehaviourAndPageViewBox>
        </div>
      </Container>
    );
  }
}
