import React from "react";
import styled, { css } from "styled-components";

import { moneyFormatter, toNaira } from "../../../../../helpers/util";
import { ActionBtn } from "../../../../common/Dropdowns/ActionDropdown";
import statuses from "./statuses";

export default ({ trade }) => {
  const subTotal =
    toNaira(trade.total) + trade.service_charge + trade.transfer_fee;

  return (
    <Container>
      <Section>
        <ImageBox>
          {statuses[trade.status]}
          <span>{trade.status}</span>
        </ImageBox>
        <InfoItem>
          <span>Subtotal</span>
          <span>{moneyFormatter.format(subTotal)}</span>
        </InfoItem>
        <InfoItem>
          <span>Service charge</span>
          <span>{moneyFormatter.format(trade.service_charge)}</span>
        </InfoItem>
        <InfoItem>
          <span>Bank transfer fee</span>
          <span>{moneyFormatter.format(trade.transfer_fee)}</span>
        </InfoItem>
        <InfoItem>
          <span className="bold">Total</span>
          <span className="bold">
            {moneyFormatter.format(toNaira(trade.total))}
          </span>
        </InfoItem>

        <InfoItem borderTop>
          <span>Deposited by customer</span>
          <span>{moneyFormatter.format(toNaira(trade.total))}</span>
        </InfoItem>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border-radius: 3px;
  min-height: 100px;
  padding: 10px 0px 20px;
`;

const Section = styled.div`
  div.line {
    padding-top: 5px;
  }
`;

export const ImageBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  span {
    text-transform: capitalize;
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 500;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 0px;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
      margin: 10px 20px 0px;
      padding: 0;
      padding-top: 10px;
    `};
  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
  }
  span.bold {
    font-weight: 500;
  }
  hr {
    width: 100%;
    color: #e6e6e6;
    background-color: #e6e6e6;
    height: 1px;
    padding: 0;
    border: 0;
  }
`;
