import React from "react";

import { Card } from "../../components/common";
import {
  Container,
  ItemHeader,
  ItemSubHeader,
  ItemKeyValPair
} from "./elements";

function TradeSummaryItem({ assetKey, summary }) {
  return (
    <Card>
      <Container>
        <ItemHeader>
          <h3>{assetKey}</h3>
        </ItemHeader>
        <ItemSubHeader>
          <h4>Metrics</h4>
        </ItemSubHeader>
        <ItemKeyValPair>
          <div className="key-box">
            <h4>Awaiting Payments</h4>
            <h5>{summary.awaiting_payments.count} Trades</h5>
          </div>
          <div className="val-box">
            <h4>
              {summary.awaiting_payments.amount} {assetKey}
            </h4>
          </div>
        </ItemKeyValPair>
        <ItemKeyValPair>
          <div className="key-box">
            <h4>Awaiting Confirmations</h4>
            <h5>{summary.awaiting_confirmations.count} Trades</h5>
          </div>
          <div className="val-box">
            <h4>
              {summary.awaiting_confirmations.amount} {assetKey}
            </h4>
          </div>
        </ItemKeyValPair>
        <ItemKeyValPair>
          <div className="key-box">
            <h4>Completed</h4>
            <h5>{summary.completed.count} Trades</h5>
          </div>
          <div className="val-box">
            <h4>
              {summary.completed.amount} {assetKey}
            </h4>
          </div>
        </ItemKeyValPair>
        <ItemKeyValPair>
          <div className="key-box">
            <h4>Fees</h4>
            <h5>{summary.fees.count} Trades</h5>
          </div>
          <div className="val-box">
            <h4>
              {summary.fees.amount} {assetKey}
            </h4>
          </div>
        </ItemKeyValPair>
      </Container>
    </Card>
  );
}

export default TradeSummaryItem;
