import React from "react";
import styled from "styled-components";

import Case from "case";
import format from "date-fns/format";
import { Link } from "react-router-dom";

import {
  Table,
  TableHead,
  TableHeadRow,
  TableHeadData,
  TableBody,
  TableRow,
  TableData,
  TableDataBox
} from "./common";
import { ReactComponent as BackSVG } from "../images/back.svg";

const EmptyState = styled.div`
  width: 100%;
  padding: 40px;
  text-align: center;
`;

const header = ["Username", "Description", "Created At", "Bank Name"];
const Head = () => (
  <TableHead>
    <TableHeadRow>
      {header.map((val, key) => (
        <TableHeadData key={key}>
          <span>{val}</span>
        </TableHeadData>
      ))}
    </TableHeadRow>
  </TableHead>
);

const Body = ({ username, description, created_at, id, bank }) => (
  <TableRow>
    <TableData>
      <TableDataBox>
        {username ? (
          <span className="has-customer-link">
            <Link to={`/marketplace/ads/${id}`}>{username}</Link>
            <span className="customer-view-icon">
              <span className="tool-tip">View trader </span>
              <Link to={`/marketplace/ads/${id}`}>
                <BackSVG />
              </Link>
            </span>
          </span>
        ) : (
          <span style={{ color: "#A9A9A9" }}>No User</span>
        )}
      </TableDataBox>
    </TableData>
    <TableData>
      <Link to={`/marketplace/ads/${id}`}>
        <TableDataBox>
          <span>{description}</span>
        </TableDataBox>
      </Link>
    </TableData>
    <TableData>
      <Link to={`/marketplace/ads/${id}`}>
        <TableDataBox>
          <span>{format(new Date(created_at), "MMM D, h:mm a")}</span>
        </TableDataBox>
      </Link>
    </TableData>
    <TableData>
      <Link to={`/marketplace/ads/${id}`}>
        <TableDataBox>
          <span>{bank}</span>
        </TableDataBox>
      </Link>
    </TableData>
  </TableRow>
);

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false
    };
  }

  _onSelectAllToggled = () =>
    this.setState(state => ({ selectAll: !state.selectAll }));

  render() {
    const {
      ads: { data }
    } = this.props;
    return (
      <>
        <Table>
          <Head />
          <TableBody>
            {(data || []).map(ad => (
              <Body
                id={ad.id}
                key={ad.id}
                bank={ad.bank.name}
                created_at={ad.created_at}
                username={`@${ad.trader.username}`}
                description={`${Case.sentence(ad.side)}ing ${ad.minimum} - ${
                  ad.maximum
                } ${ad.asset} @ ${ad.rate} x ${ad.reference_exchange} price`}
              />
            ))}
          </TableBody>
        </Table>
        {data && data.length === 0 && <EmptyState>No Ads yet!</EmptyState>}
      </>
    );
  }
}
