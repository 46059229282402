import React, { Fragment } from "react";
import { connect } from "react-redux";
import { ReactComponent as ExportSVG } from "./images/export.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Container,
  Header,
  Body,
  Card,
  HeaderLeft,
  HeaderText,
  HeaderExportBtn,
  CardHeader,
  HeaderTextBox,
  HeaderExportBtnBox,
  TabsBtnBox,
  TabBtnList,
  TabBtnItem,
  TabBtnLink,
  CardBody,
  SearchAndFilterBox,
  FilterBox,
  SearchBox,
  SearchIconBox,
  SearchInput,
  TableBox
} from "./components/common";
import DepositsTable from "./components/DepositsTable";
import FilterDropdown from "../../common/Dropdowns/FilterDropdown";
import FullTableLoader from "../../common/SVGLoaders/FullTableLoader";
import { fetchBankDeposits } from "../../../actions/deposits";
import qs from "qs";
import { isEqual } from "lodash";
import Paginator from "../../common/Paginator";

class Bank extends React.Component {
  state = {
    totalEntries: 1,
    perPage: 20
  };

  componentDidMount() {
    const { page = 1, status = "all" } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    this.props.fetchBankDeposits(page, status, totalEntries => {
      this.setState({
        totalEntries
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { page = 1, status = "all" } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      this.props.fetchBankDeposits(page, status, totalEntries => {
        this.setState({
          totalEntries
        });
      });
    }
  }

  render() {
    const { deposits } = this.props;
    const { totalEntries, perPage } = this.state;

    return (
      <Container>
        {deposits.fetching ? (
          <FullTableLoader />
        ) : (
          <Fragment>
            <Header>
              <HeaderLeft>
                <HeaderTextBox>
                  <HeaderText>Cards</HeaderText>
                </HeaderTextBox>
                <HeaderExportBtnBox smallBox>
                  <HeaderExportBtn>
                    <ExportSVG />
                    <span>Export</span>
                  </HeaderExportBtn>
                </HeaderExportBtnBox>
              </HeaderLeft>
            </Header>
            <Body>
              <Card>
                <CardHeader>
                  <TabsBtnBox>
                    <TabBtnList>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/deposits/card"
                          activeClassName="active-tab"
                        >
                          All
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink to="#" activeClassName="active-tab">
                          Open
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink to="#" activeClassName="active-tab">
                          Unfulfilled and partially fulfilled
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink to="#" activeClassName="active-tab">
                          Unpaid
                        </TabBtnLink>
                      </TabBtnItem>
                    </TabBtnList>
                  </TabsBtnBox>
                </CardHeader>
                <CardBody>
                  <SearchAndFilterBox>
                    <FilterBox>
                      <FilterDropdown />
                    </FilterBox>
                    <SearchBox>
                      <SearchIconBox>
                        <FontAwesomeIcon icon="search" />
                      </SearchIconBox>
                      <SearchInput placeholder="Search Payouts" />
                    </SearchBox>
                  </SearchAndFilterBox>
                  <TableBox>
                    <DepositsTable type="card" deposits={deposits.bank} />
                  </TableBox>
                </CardBody>
              </Card>
            </Body>

            <Paginator
              totalEntries={totalEntries}
              perPage={perPage}
              {...this.props}
            />
          </Fragment>
        )}
      </Container>
    );
  }
}

function mapStateToProps({ deposits }) {
  return {
    deposits
  };
}

export default connect(
  mapStateToProps,
  { fetchBankDeposits }
)(Bank);
