import { GET_TRADES, GET_TRADES_DETAIL, SET_TRADE_STATUS } from "./types";

const initialState = {
  list: {},
  status: {},
  error: {},
  detail: {},
  fetched: false,
  fetching: false,
  fetched_trades: false,
  fetching_trades: false,
  fetched_detail: false,
  fetching_detail: false,
  fetching_status: false,
  fetched_status: false
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TRADES.fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetching_trades: false,
        fetched_trades: true,
        list: payload.data
      };
    case GET_TRADES_DETAIL.fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetching_detail: false,
        fetched_detail: true,
        detail: payload.data
      };
    case SET_TRADE_STATUS.fulfilled:
      return {
        ...state,
        fetching_status: false,
        fetched_status: true
      };

    case GET_TRADES.pending:
      return {
        ...state,
        fetching: true,
        fetched: false,
        fetching_trades: true,
        fetched_trades: false
      };

    case GET_TRADES_DETAIL.pending:
      return {
        ...state,
        fetching: true,
        fetched: false,
        fetching_detail: true,
        fetched_detail: false
      };
    case SET_TRADE_STATUS.pending:
      return {
        ...state,
        fetching_status: true,
        fetched_status: false
      };

    case SET_TRADE_STATUS.rejected:
      return {
        ...state,
        error: payload,
        fetching_status: false,
        fetched_status: false
      };
    case GET_TRADES.rejected:
    case GET_TRADES_DETAIL.rejected:
      return {
        ...state,
        fetching_detail: false,
        fetched_detail: false,
        fetching_trades: false,
        fetched_trades: false,
        error: payload,
        fetching: false,
        fetched: false,
        fetching_status: false,
        fetched_status: false
      };

    default:
      return state;
  }
};

export default reducer;
