import styled from "styled-components";

import BodyRow from "./BodyRow";
import HeadRow from "./HeadRow";
import Head from "./Head";
import Body from "./Body";
import HeadData from "./HeadData";
import BodyData from "./BodyData";
import BodyDataBox from "./BodyDataBox";

const Table = styled.table`
  width: 100%;
  color: #212b36;
  table-layout: auto;
`;
Table.Head = Head;
Table.Body = Body;
Table.HeadRow = HeadRow;
Table.BodyRow = BodyRow;
Table.HeadData = HeadData;
Table.BodyData = BodyData;
Table.BodyDataBox = BodyDataBox;

export default Table;
