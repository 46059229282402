import styled from "styled-components";

export const Nav = styled.nav`
  position: fixed;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 56px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3000;
  display: flex;

  .logo-area {
    display: inline-flex;
    background: #fafbfc;
    border-right: 1px solid #ebeef0;
    width: 246px;
    height: 100%;
    align-items: center;
    padding: 10px;
  }

  .container {
    width: calc(100% - 246px);
    display: inline-flex;
    height: 100%;
  }

  .button-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 998px;
    padding: 0 32px;
    height: 100%;
  }

  .unsaved {
    color: #637381;
    font-size: 16px;
    font-weight: 600;
  }

  .btn {
    :first-child {
      margin-right: 8px;
    }
  }

  .btn.save {
    background: linear-gradient(180deg, #6371c7, #5563c1);
    border-color: #3f4eae;
    box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05),
      0 0 0 0 transparent;
    color: #fff;
    fill: #fff;
    width: 63.09px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-top: 1px;
  }
`;
