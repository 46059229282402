import { UPDATE_LEVEL } from "./types";
import axios from "axios";

import { apiBaseURL } from "../config";
import { toast } from "react-toastify";

const action = payload => ({
  type: UPDATE_LEVEL.default,
  payload
});

const getVerifications = (id, level, cb) => dispatch => {
  const data = { level: `${level}` };

  const payload = axios.put(`${apiBaseURL}/v1/users/${id}/level`, data);

  dispatch(action(payload))
    .then(({ value }) => {
      toast.success(`Succesfully updated to level ${level}`);
      cb && cb();
    })
    .catch(err => {
      console.log({ err });
      err.response &&
        err.response.data &&
        err.response.data.message &&
        toast.error(err.response.data.message);
    });
};

export default getVerifications;
