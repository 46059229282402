import types from "../actions/types";

const initialState = {
  fetching: false,
  fetched: false,
  trades: [],
  meta: {}
};

const coinBarterTrades = (state = initialState, { type, payload }) => {
  switch (type) {
    case types("get_trades").fulfilled:
      return {
        fetching: false,
        fetched: true,
        trades: payload.trades,
        meta: payload.meta
      };

    case types("get_trades").pending:
      return {
        ...state,
        fetching: true,
        fetched: false
      };

    default:
      return state;
  }
};

export default coinBarterTrades;
