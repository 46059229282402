import format from "date-fns/format";
import qs from "qs";

export const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "NGN",
  minimumFractionDigits: 2
});

export const isValidNumber = text => /^-?\d*\.?\d*$/.test(text);

export const isValidEmail = email => {
  const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return re.test(String(email).toLowerCase());
};

export const xformAccountNo = acct_no =>
  "XXXXXX" + acct_no.substr(acct_no.length - 4);

export function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

export const toNaira = amount => amount / 100;

export const dayMonthYear = ISODate => format(ISODate, "DD MMM, YYYY");

export const shortenString = longString => longString.substring(0, 6);

export const currencyFormatter = (amount = 0, currency = "NGN") => {
  const nairaFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 2
  });

  const cryptoFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 8
  });

  if (currency === "NGN") {
    return nairaFormatter.format(amount);
  }

  return cryptoFormatter.format(amount);
};

export const priceFormatter = (amount = 0, currency = "NGN") => {
  const nairaFormatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency,
    maximumFractionDigits: 2,
    useGrouping: false
  });

  const cryptoFormatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency,
    maximumFractionDigits: 8,
    useGrouping: false
  });

  if (currency === "NGN") {
    return nairaFormatter.format(amount);
  }

  return cryptoFormatter.format(amount);
};

export const isActive = (txt, search, key) => {
  const queryString = qs.parse(search, {
    ignoreQueryPrefix: true
  });
  if (key && queryString) {
    return txt === queryString[key];
  }
  if (queryString.status) {
    return txt === queryString.status;
  }

  if (!txt) {
    return true;
  }

  return false;
};

export const getTradeQueryString = ({ settled, page }) => {
  if (settled && page) {
    return `?settled=${settled}&page=${page}`;
  } else if (settled) {
    return `?settled=${settled}`;
  } else if (page) {
    return `?page=${page}`;
  } else {
    return "";
  }
};

const constructString = (param, values, key) => {
  const prefix = values[key - 1] ? "&" : "?";
  const name = param;
  const value = values[key];
  if (value) {
    return `${prefix}${name}=${value}`;
  } else {
    return "";
  }
};

export const getQueryString = arg => {
  Object.keys(arg).forEach(key => arg[key] === undefined && delete arg[key]);

  const keys = Object.keys(arg);
  const values = Object.values(arg);

  const queryStringArray = keys.map((param, key) => {
    return constructString(param, values, key);
  });
  const queryString = queryStringArray.join("");

  return queryString;
};

export const formatAMPM = value => {
  const date = new Date(value);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
