import styled, { css } from "styled-components";

export const Card = styled.div`
  background-color: white;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  width: 100%;
  border-radius: 3px;
  /* min-height: 200px; */
`;

export const CardHeader = styled.div`
  background-color: transparent;
  border-bottom: 1px solid #dfe3e8;
  ${props =>
    props.noBorder &&
    css`
      border: none;
    `};
  border-radius: 0;
`;

export const CardBody = styled.div`
  min-height: 200px;
  margin: 20px 0px;
  padding: 0px 20px;
`;

export const SearchAndFilterBox = styled.div`
  display: flex;
  align-items: stretch;
  height: 36px;
  /* margin: 20px 0px;
  padding: 0px 20px; */
`;
export const FilterBox = styled.div`
  flex: ${props => (props.flex ? props.flex : "6%")};
  display: flex;
  align-items: stretch;
`;
export const SearchBox = styled.div`
  flex: 94%;
  display: flex;
  padding: 0px 10px;
  border: 1px solid #c4cdd5;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
`;

export const FilterBtn = styled.button.attrs({
  type: "button"
})`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: linear-gradient(to bottom, white, #f9fafb);
  outline: none;
  cursor: pointer;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border: 1px solid #c4cdd5;
  color: #212b36;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition-property: background, border, box-shadow, -webkit-box-shadow;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-duration: 200ms;
  -webkit-tap-highlight-color: transparent;
  font-size: 0.7rem;

  &:hover {
    background: linear-gradient(to bottom, #f9fafb, #f4f6f8);
  }
`;
