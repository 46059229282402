import axios from "axios";

import types from "./types";
import { apiBaseURL } from "../config";
import { toast } from "react-toastify";

const updateProfileFulfilled = payload => ({
  type: types("update_staff_profile").fulfilled,
  payload
});

const updateProfile = (staffId, payload, callback) => async dispatch => {
  try {
    await axios.put(`${apiBaseURL}/v1/staff/${staffId}`, payload);

    dispatch(updateProfileFulfilled(payload));

    callback();
    toast.success("Profile updated successfully!");
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export default updateProfile;
