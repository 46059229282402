import styled from "styled-components";

export const Container = styled.main`
  width: 50%;
  margin: 0 auto;
  padding: 0px 32px;
  margin-top: 56px;
  position: relative;
  min-height: 100%;
  margin-bottom: 40px;

  @media (max-width: 2000px) {
    width: 60%;
  }

  @media (max-width: 1800px) {
    width: 70%;
  }
`;

export const Body = styled.div`
  display: flex;

  & > div:first-child {
    flex: 2;
    padding-right: 10px;
  }

  & > div:last-child {
    flex: 1;
    padding-left: 10px;
  }
`;

export const Header = styled.header`
  margin-top: 28px;
  margin-bottom: 8px;
  width: 100%;

  nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .back-button {
    font-weight: 400;
    line-height: 14px;
    font-size: 0.7rem;
    color: #637381;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    & > svg {
      width: 20px;
      height: 20px;
      fill: #637381;
      margin: 0 0 0 -6px;
    }
  }
`;

export const NextPrev = styled.a`
  height: 15px;
  color: #637381;
  margin-left: 15px;

  svg.arrow-left {
    transform: rotate(-90deg);
  }

  svg.arrow-right {
    transform: rotate(90deg);
  }

  svg {
    height: 16px;
    width: 16px;
    path {
      fill: #637381;
    }
  }
`;

export const EmptyBox = styled.div`
  background: transparent;
  padding: ${props => (props.padding ? props.padding : "20px 20px 10px")};
  & > div:first-child {
    padding-top: 0px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  svg {
    color: transparent;
    fill: #c3cfd8;
    width: 20px;
    height: 20px;
  }
  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
  }
`;

export const ReferenceAndDateBox = styled.div`
  display: flex;
  align-items: center;

  h1 {
    font-size: 0.8rem;
    font-weight: 500;
    margin-right: 10px;
    line-height: 23px;
  }
  span {
    color: #212b36;
    font-size: 0.6rem;
    font-weight: 400;
  }
`;

export const PayoutActionBtnsBox = styled.header`
  margin-top: 5px;
  display: flex;
  background-color: transparent;
  margin-left: -10px;
  & > button:first-child {
    margin-right: 15px;
  }
`;
export const ActiontBtnBox = styled.button`
  cursor: pointer;
  background: transparent;
  display: flex;
  border-color: transparent;
  border-radius: 3px;
  color: #637381;
  padding: 8px;
  &:hover {
    color: #212b36;
    text-decoration: none;
  }
  &:active {
    background-color: rgba(33, 43, 54, 0.1);
    text-decoration: none;
  }
  span {
    font-size: 0.7rem;
  }
  svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    fill: #637381;
  }
`;

export const Level = styled.span`
  padding: 4px 10px;
  color: #576675;
  background-color: #dfe3e8;
  border-radius: 2rem;
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: initial;
  letter-spacing: initial;
  display: inline-flex;
  align-items: center;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  background-color: ${({ active }) =>
    active ? "rgb(187, 229, 179)" : "#dfe3e8"};
  border: 2px solid white;
  font-size: 1.3rem;
  white-space: nowrap;
  vertical-align: middle;
  margin: 0;
  margin-left: 15px;
  .level {
    margin: 0;
    font-size: 14px;
    color: #4c5c6c;
  }
  .dot {
    height: 0.5rem;
    width: 0.5rem;
    margin-right: 6px;
    border: 0.1rem solid
      ${({ active }) => (active ? "rgb(16, 128, 67)" : "#919eab")};
    border-radius: 50%;
    color: #919eab;
    background-color: ${({ active }) =>
      active ? "rgb(16, 128, 67)" : "#919eab"};
  }
`;

export const CustomerActivity = styled.span`
  padding: 4px 10px;

  border-radius: 2rem;
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: initial;
  letter-spacing: initial;
  display: inline-flex;
  align-items: center;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
  background-color: ${({ active }) =>
    active ? "rgb(187, 229, 179)" : "#ffea8a"};
  border: 2px solid white;
  font-size: 1.3rem;
  white-space: nowrap;
  vertical-align: middle;
  margin: 0;
  margin-left: 4px;
  .level {
    margin: 0;
    font-size: 14px;
    color: ${({ active }) => (active ? "#212b36" : "#4c5c6c")};
  }
  .dot {
    height: 0.5rem;
    width: 0.5rem;
    margin-right: 6px;
    border: 0.1rem solid
      ${({ active }) => (active ? "rgb(16, 128, 67)" : "#8a6116")};
    border-radius: 50%;
    color: #919eab;
  }
`;
