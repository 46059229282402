import axios from "axios";
import { toast } from "react-toastify";

import types from "./types";
import { apiBaseURL } from "../config";

const createStaffPending = () => ({
  type: types("create_staff").pending
});

const createStaff = (payload, callback, loading) => async dispatch => {
  try {
    loading && loading();
    dispatch(createStaffPending());

    await axios.post(`${apiBaseURL}/v1/staff`, payload);
    loading && loading();
    toast.success("Staff account created successfully!");

    setTimeout(() => {
      callback("/settings/accounts");
    }, 500);
  } catch (err) {
    loading && loading();
    toast.error(err.response.data.message);
  }
};

export default createStaff;
