import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Transfers from './Transfers';
import Trades from './Trades';
import TradeDetails from './TradeDetails';
import TransferDetails from './TransferDetails';

const Coinbarter = ({ match }) => (
	<Switch>
		<Route exact path={`${match.path}/transfers`} component={Transfers} />
		<Route exact path={`${match.path}/trades`} component={Trades} />
		<Route exact path={`${match.path}/trades/:tradeID`} component={TradeDetails} />
		<Route path={`${match.path}/transfers/:transferID`} component={TransferDetails} />
	</Switch>
);

export default Coinbarter;
