import { connect } from "react-redux";
import React, { Component } from "react";
import styled from "styled-components";

import {
  getMarketplaceADsAssetSummary,
  getMarketplaceTradeAssetsSummary
} from "./redux/action";
import GridLoader from "../../../common/SVGLoaders/GridLoader";
import SummaryItem from "./components/SummaryItem";
import TradeSummaryItem from "./components/TradeSummaryItem";

const Container = styled.div`
  margin: 80px 32px;
  height: 100%;
  width: 100%;
  min-height: 100vh;
`;
const AssetsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  & > div {
    width: 32%;
    margin-right: 1.3%;
    margin-top: 1.3%;
  }

  /* & > div {
  } */
`;

const SectionHeader = styled.div`
  padding: 20px 0px 0px;
  h4 {
    font-size: 20px;
    font-weight: bold;
  }
`;

class AssetsOverview extends Component {
  componentDidMount() {
    this.props.getMarketplaceADsAssetSummary();
    this.props.getMarketplaceTradeAssetsSummary();
  }

  render() {
    const {
      fetchingAssetsSummary: fetching,
      fetchingtradeAssetsSummary,
      assetsSummary,
      tradeAssetsSummary
    } = this.props.marketplaceSummary;
    return (
      <Container>
        {fetching || fetchingtradeAssetsSummary ? (
          <GridLoader />
        ) : (
          <React.Fragment>
            <SectionHeader>
              <h4>ADs</h4>
            </SectionHeader>
            <AssetsBox>
              {Object.keys(assetsSummary).map(key => (
                <SummaryItem
                  key={key}
                  assetKey={key}
                  summary={assetsSummary[key]}
                />
              ))}
            </AssetsBox>
            <SectionHeader>
              <h4>Trades</h4>
            </SectionHeader>
            <AssetsBox>
              {Object.keys(tradeAssetsSummary).map(key => (
                <TradeSummaryItem
                  key={key}
                  assetKey={key}
                  summary={tradeAssetsSummary[key]}
                />
              ))}
            </AssetsBox>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

const mapStateToProps = ({ marketplaceSummary }) => ({
  marketplaceSummary
});

export default connect(
  mapStateToProps,
  { getMarketplaceADsAssetSummary, getMarketplaceTradeAssetsSummary }
)(AssetsOverview);
