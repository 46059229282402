import React, { Fragment } from "react";
import { connect } from "react-redux";
import format from "date-fns/format";

import { Spinner } from "react-activity";
import Modal from "../../../common/Modal";
import {
  Container,
  Body,
  Header,
  NextPrev,
  ActiontBtnBox,
  PayoutActionBtnsBox,
  ReferenceAndDateBox
} from "./components/common";
import EditCustomerAddress from "./components/EditCustomerAddress";
import EditMemo from "./components/EditMemo";
import { ToastContainer } from "react-toastify";
import AccountCard from "./components/AccountCard";
import Depositinfo from "./components/DepositInfo";
import ActionDropdown from "./components/ActionDropdown";
import { ReactComponent as BackSVG } from "./svgs/back.svg";
import { ReactComponent as ArrowSVG } from "./svgs/arrow.svg";
import { ReactComponent as PrintSVG } from "./svgs/print.svg";
import CustomerCard from "./components/CustomerCard";
import { getTradesDetail, setStatus } from "../Trades.action";
import Step from "./step";
import Chat from "../../Chat";
import PayoutDetailLoader from "../../../common/SVGLoaders/InfoLoader";

const initialState = {
  statusToSet: null,
  whichModal: "",
  loading: false,
  modalLoading: false,
  loadingFulfill: false,
  loadingFinalize: false,
  isAddressModalOpen: false,
  isMemoModalOpen: false,
  address: "",
  memo: ""
};
class AdsDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      ...initialState
    };
  }
  onModalErr = () => {
    this.setState({ modalLoading: false });
  };
  ontoggleAddressModal = () => {
    this.setState({
      isAddressModalOpen: !this.state.isAddressModalOpen,
      whichModal: "address"
    });
  };

  ontoggleisMemoModal = () => {
    this.setState({
      isMemoModalOpen: !this.state.isMemoModalOpen,
      whichModal: "memo"
    });
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  finilizeLoader = () =>
    this.setState(({ loadingFinalize }) => ({
      loadingFinalize: !loadingFinalize
    }));
  fulfillLoader = () =>
    this.setState(({ loadingFulfill }) => ({
      loadingFulfill: !loadingFulfill
    }));

  handleFinalize = () => {
    const {
      match: {
        params: { type, payoutId }
      },
      finalizePayout
    } = this.props;
    const cb = this.finilizeLoader;
    finalizePayout(payoutId, type, cb);
  };

  handleFulfill = () => {
    const {
      match: {
        params: { type, payoutId }
      },
      fulfillPayout
    } = this.props;
    const cb = this.fulfillLoader;
    fulfillPayout(payoutId, type, cb);
  };

  componentDidMount() {
    this.onGetDetail();
  }
  onGetDetail = () => {
    const { tradeId } = this.props.match.params;

    this.props.getTradesDetail(tradeId);
  };

  onFetchPayout = () => {
    const {
      match: {
        params: { type, payoutId }
      },
      fetchPayoutInfo
    } = this.props;
    fetchPayoutInfo(type, payoutId);
  };

  handleSetStatus = status => e => {
    const { id } = this.props.trades.detail;
    console.log({ id, status });
    this.setState({ statusToSet: status }, () =>
      this.props.setStatus(status, id)
    );
  };

  // componentDidUpdate(prevProps) {
  //   if (!isEqual(prevProps.payout, this.props.payout)) {
  //     if (this.props.payout.payout && this.props.payout.payout.address) {
  //       this.setState({
  //         address: this.props.payout.payout.address,
  //         memo: this.props.payout.payout.message
  //       });
  //     }
  //   }
  // }

  onUpdate = () => {
    const { address } = this.state;
    const { id } = this.props.payout.payout;
    const cb = () => {
      this.setState({ ...initialState });
      this.onFetchPayout();
    };
    const error = () => this.onModalErr();
    this.setState({ modalLoading: true });
    this.props.updateAddress({ id, address, cb: cb, onError: error });
  };

  onMemoUpdate = () => {
    const { memo } = this.state;
    const { id } = this.props.payout.payout;
    const cb = () => {
      this.setState({ ...initialState });
      this.onFetchPayout();
    };
    const error = () => this.onModalErr();
    this.setState({ modalLoading: true });
    this.props.memoUpdate({ id, memo, cb: cb, onError: error });
  };
  render() {
    const {
      trades: { detail, fetching_detail, fetched_detail, fetching_status },
      match: {
        params: { type }
      }
    } = this.props;

    const modalContent = {
      address: {
        title: "Change Address",
        text: this.state.address,
        action: this.onUpdate
      },
      memo: {
        title: "Change Memo",
        text: this.state.memo,
        action: this.onMemoUpdate
      }
    };

    return (
      <Fragment>
        <ToastContainer
          closeButton={false}
          autoClose={2000}
          hideProgressBar={true}
        />
        {fetching_detail || !detail ? (
          <PayoutDetailLoader />
        ) : (
          fetched_detail && (
            <Fragment>
              <link
                rel="stylesheet"
                href="https://unpkg.com/@progress/kendo-theme-material@latest/dist/all.css"
              />
              <Container>
                <Header>
                  <nav>
                    <a
                      className="back-button"
                      href="#goback"
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                    >
                      <BackSVG className="svg" />
                      <span>Trades</span>
                    </a>

                    <div>
                      <NextPrev>
                        <ArrowSVG className="arrow-left" />
                      </NextPrev>
                      <NextPrev>
                        <ArrowSVG className="arrow-right" />
                      </NextPrev>
                    </div>
                  </nav>
                </Header>
                <ReferenceAndDateBox>
                  <h1>#{detail.id.substring(0, 6)}...</h1>
                  <span>
                    {format(detail.created_at, "MMMM D, YYYY [at] hh:mm a")}
                  </span>
                </ReferenceAndDateBox>
                <PayoutActionBtnsBox>
                  <ActiontBtnBox>
                    <PrintSVG />
                    <span>Print</span>
                  </ActiontBtnBox>
                  <ActionDropdown />
                </PayoutActionBtnsBox>
                <Body>
                  <div>
                    <AccountCard
                      detail={detail}
                      handleSetStatus={this.handleSetStatus}
                      loading={fetching_status}
                      statusToSet={this.state.statusToSet}
                    />
                    <Depositinfo detail={detail} />
                    <Chat channel={detail.dispute.channel} />
                    <Step events={detail.events} />
                  </div>
                  <div>
                    {detail.bank && (
                      <CustomerCard
                        ad={detail.ad}
                        buyer={detail.buyer}
                        seller={detail.seller}
                        bank={detail.bank || null}
                      />
                    )}
                  </div>
                </Body>
              </Container>
              {this.state.isAddressModalOpen && (
                <Modal
                  meta={{
                    width: "620px",
                    buttonType: "blue"
                  }}
                  data={{
                    title: modalContent[this.state.whichModal].title,
                    loading: this.state.loading,
                    buttonTxt: this.state.modalLoading ? (
                      <Spinner color="white" />
                    ) : (
                      modalContent[this.state.whichModal].title
                    ),
                    body: (
                      <EditCustomerAddress
                        address={modalContent[this.state.whichModal].text}
                        handleChange={this.handleChange}
                      />
                    )
                  }}
                  actions={{
                    toggleModal: this.ontoggleAddressModal,
                    handleClick: modalContent[this.state.whichModal].action
                  }}
                />
              )}
              {this.state.isMemoModalOpen && (
                <Modal
                  meta={{
                    width: "620px",
                    buttonType: "blue"
                  }}
                  data={{
                    title: modalContent[this.state.whichModal].title,
                    loading: this.state.loading,
                    buttonTxt: this.state.modalLoading ? (
                      <Spinner color="white" />
                    ) : (
                      modalContent[this.state.whichModal].title
                    ),
                    body: (
                      <EditMemo
                        memo={modalContent[this.state.whichModal].text}
                        handleChange={this.handleChange}
                      />
                    )
                  }}
                  actions={{
                    toggleModal: this.ontoggleisMemoModal,
                    handleClick: modalContent[this.state.whichModal].action
                  }}
                />
              )}
            </Fragment>
          )
        )}
      </Fragment>
    );
  }
}

function mapStateToProps({ trades }) {
  return { trades };
}
AdsDetail.defaultProps = {
  trades: {
    detail: {}
  }
};
export default connect(
  mapStateToProps,
  { getTradesDetail, setStatus }
)(AdsDetail);
