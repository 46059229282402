import axios from "axios";
import { toast } from "react-toastify";

import { NEW_TRANSACTION } from "./types";
import { apiBaseURL } from "../config";

const newTransactionAction = data => ({
  type: NEW_TRANSACTION.default,
  payload: axios.post(`${apiBaseURL}/v1/transactions`, data)
});

export const makeNewTransaction = (data, cb) => async dispatch =>
  dispatch(newTransactionAction(data))
    .then(res => {
      toast.success(res.value.data.message, { onClose: () => (cb ? cb(res) : null) });
    })
    .catch(err => {
      toast.error(err.response.data.message);
    });
