import React from "react";
import { Spinner } from "react-activity";

import { Nav } from "./elements";

const SaveBar = ({ handleDiscard, handleSave, loading = false }) => (
  <Nav>
    <div className="logo-area">
      <svg width="95px" height="22px" viewBox="0 0 95 22">
        <g id="-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group-69" fill="#637381" fillRule="nonzero">
            <path
              d="M31.1095877,8.37102726 C31.4742524,7.88180233 31.9734881,7.50195106 32.5502311,7.2748912 C33.2220852,6.98903356 33.9491509,6.84565793 34.6827625,6.85436102 C36.2606919,6.83609274 37.7754996,7.45452875 38.8648936,8.56175953 C40.0338367,9.7015572 40.6175185,11.1944777 40.6159388,13.0405209 C40.6143592,14.8865642 40.0227792,16.3787186 38.8411988,17.5169843 C37.7396541,18.6391131 36.2067566,19.2667323 34.6093087,19.2496606 C33.9007478,19.2570582 33.1987296,19.1176007 32.5502311,18.8406203 C31.9689922,18.6057862 31.4633188,18.2240883 31.0858929,17.7352923 L31.0858929,18.6728678 C31.0891703,18.8078988 31.0353025,18.9383439 30.936823,19.0338516 C30.8383436,19.1293594 30.7038398,19.1816018 30.5646074,19.1784232 L28.0813931,19.1784232 C27.9345526,19.185138 27.7913695,19.1327528 27.68569,19.0336506 C27.5867105,18.9385313 27.5326843,18.8079575 27.5364128,18.6728678 L27.5364128,2.56862989 C27.5326843,2.43354022 27.5867105,2.30296641 27.68569,2.20784716 C27.7913695,2.10874494 27.9345526,2.05635973 28.0813931,2.06307447 L30.5883022,2.06307447 C30.7231448,2.06433711 30.8522364,2.11622764 30.9484631,2.20784716 C31.053562,2.29976772 31.1123496,2.43140226 31.1095877,2.56862989 L31.1095877,8.37102726 Z M31.9412749,10.9585518 C30.8008857,12.1448234 30.8008857,13.984476 31.9412749,15.1707476 C32.4793861,15.7438128 33.2463259,16.0637795 34.0453726,16.0485756 C34.8534753,16.0678618 35.630823,15.747877 36.177904,15.1707476 C37.3182933,13.984476 37.3182933,12.1448234 36.177904,10.9585518 C35.6310683,10.3808245 34.8537855,10.0600289 34.0453726,10.0784258 C33.2460163,10.0641092 32.4791405,10.3848868 31.9412749,10.9585518 Z"
              id="Shape"
            />
            <path
              d="M45.4472577,8.0452338 L45.4472577,14.377929 C45.4472577,15.1841322 45.6471868,15.820325 46.047045,16.2865074 C46.445377,16.7480741 47.0382964,16.9788574 47.8212249,16.9788574 C48.6041533,16.9788574 49.1833372,16.7480741 49.6068511,16.2865074 C50.0479844,15.7718175 50.2758538,15.1058012 50.2432666,14.4263935 L50.2432666,8.0452338 C50.2436616,7.91534685 50.3005676,7.79217522 50.3989366,7.70829011 C50.4916341,7.61586469 50.6165293,7.56367902 50.7469048,7.5628966 L53.1689465,7.5628966 C53.312876,7.5571363 53.452255,7.61434975 53.5512537,7.71982928 C53.6453653,7.82103287 53.6970393,7.95498654 53.6954773,8.09369831 L53.6954773,19.3559259 C53.6990796,19.4915949 53.6468825,19.6227287 53.5512537,19.7182558 C53.4490036,19.817547 53.3107726,19.870117 53.1689465,19.8636493 L50.7446155,19.8636493 C50.6121941,19.8672836 50.4834745,19.8193454 50.385201,19.729795 C50.2894314,19.6391388 50.2369041,19.5113784 50.2409774,19.3790043 L50.2409774,18.3658654 C49.3771616,19.4136218 48.2103998,19.9375 46.7406921,19.9375 C45.2709843,19.9375 44.1156689,19.4820875 43.2747458,18.5712625 C42.4185609,17.6619761 41.9912315,16.457287 41.9927577,14.9571952 L41.9927577,8.0452338 C41.9946678,7.91312643 42.0561845,7.78909502 42.159874,7.70829011 C42.2578578,7.61690345 42.3858211,7.5651386 42.5192885,7.5628966 L44.9184376,7.5628966 C45.0560333,7.56132201 45.1889091,7.61341511 45.2892984,7.70829011 C45.386588,7.79323109 45.4439217,7.91552995 45.4472577,8.0452338 Z"
              id="Shape"
            />
            <path
              d="M61.2647283,6.87636708 C62.2516697,6.85890999 63.234126,7.00876212 64.167372,7.31910164 C64.8738501,7.54470132 65.5285022,7.8998929 66.095336,8.36514808 C66.4947169,8.67024496 66.5113578,9.02122106 66.1452586,9.42266432 L65.2252561,10.4320074 C64.9257203,10.7669258 64.5287167,10.7921593 64.0366223,10.5031202 C63.057188,9.87916266 62.1356006,9.5671839 61.2718601,9.5671839 C60.8778713,9.55007748 60.4865438,9.6373413 60.1402807,9.81951966 C59.8889835,9.93026886 59.7216242,10.165892 59.7052407,10.4320074 C59.7052407,10.8907997 60.1696003,11.2111896 61.0983195,11.3931772 L63.0167745,11.7533292 C65.5224145,12.2121215 66.7752345,13.3896884 66.7752345,15.2860299 C66.785154,16.4183522 66.2171043,17.4829003 65.2561605,18.1328361 C64.2434445,18.8791382 62.924061,19.2515246 61.29801,19.2499953 C59.5055503,19.2499953 57.9698354,18.8172012 56.6908654,17.9516131 C55.6607162,17.2634247 55.4863832,16.6631714 56.1678666,16.1508533 L57.2637869,15.3342031 C57.6631679,15.0451639 58.0696806,15.0688682 58.4833252,15.4053159 C59.4072899,16.1959679 60.3843468,16.591294 61.4144961,16.591294 C61.8461068,16.6156223 62.2754939,16.5158294 62.648298,16.3045488 C62.9060595,16.1623625 63.0702789,15.9025843 63.083338,15.6163603 C63.0838269,15.345409 62.9242032,15.0978925 62.6720707,14.978639 C62.2520463,14.7812201 61.8030318,14.6473676 61.3408008,14.5817837 L59.3486506,14.1734586 C58.4393244,14.0344633 57.5984309,13.6227307 56.9452331,12.9966563 C56.3429562,12.3777537 56.0242183,11.5509569 56.0608895,10.7026948 C56.0554565,9.62538537 56.5859524,8.61095859 57.4872501,7.97517463 C58.428648,7.24416557 59.6878074,6.87789639 61.2647283,6.87636708 Z"
              id="Shape"
            />
            <path
              d="M77.019889,18.7651362 L77.019889,12.9168602 C77.0599082,12.1688895 76.8115914,11.4339815 76.3258493,10.8628091 C75.8224839,10.3298788 75.1083382,10.0467642 74.3755976,10.089655 C73.6313591,10.0567967 72.9084196,10.3422571 72.3883304,10.8743487 C71.8750481,11.4281299 71.6044212,12.1633019 71.636454,12.9168602 L71.636454,18.7651362 C71.6409077,18.8999881 71.582884,19.0293775 71.4791383,19.1159404 C71.3743322,19.2039314 71.2413287,19.2514359 71.1043569,19.2497999 L68.661337,19.2497999 C68.5252012,19.2536642 68.3934818,19.2014119 68.2971803,19.1053411 C68.2008788,19.0092703 68.1485009,18.8778665 68.1523745,18.7420569 L68.1523745,2.57044306 C68.1485009,2.43463346 68.2008788,2.30322965 68.2971803,2.2071589 C68.3934818,2.11108814 68.5252012,2.05883583 68.661337,2.06270008 L71.1089838,2.06270008 C71.2459557,2.06106413 71.3789592,2.10856857 71.4837653,2.19655959 C71.5875109,2.28312252 71.6455347,2.41251192 71.641081,2.54736383 L71.641081,8.44410616 C72.5124864,7.3978479 73.7887484,6.87471877 75.4698669,6.87471877 C77.0029236,6.87471877 78.2306028,7.3578439 79.1529045,8.32409418 C80.0838689,9.30857814 80.5833795,10.6224653 80.540984,11.9752278 L80.540984,18.7651362 C80.5454377,18.8999881 80.487414,19.0293775 80.3836684,19.1159404 C80.2788622,19.2039314 80.1458588,19.2514359 80.0088869,19.2497999 L77.5542996,19.2497999 C77.4165695,19.2517851 77.2827204,19.204272 77.1772047,19.1159404 C77.0734591,19.0293775 77.0154353,18.8999881 77.019889,18.7651362 Z"
              id="Shape"
            />
            <path
              d="M83.7187306,17.5135457 C82.5197701,16.3595743 81.9202899,14.8708749 81.9202899,13.0474473 C81.9202899,11.2240197 82.5197701,9.73914133 83.7187306,8.59281217 C84.8370912,7.47899705 86.3789615,6.85821072 87.9838074,6.87561108 C88.708778,6.86409219 89.426616,7.01556178 90.0808035,7.31809414 C90.6419443,7.56738458 91.1190844,7.96469019 91.4574755,8.4644233 L91.4574755,7.47628756 C91.4569439,7.33647218 91.515091,7.2024367 91.6186006,7.10487692 C91.7118174,7.00777601 91.8418187,6.95150327 91.9787627,6.94897615 L94.4785716,6.94897615 C94.6200694,6.94630379 94.7558001,7.00318546 94.850581,7.10487692 C94.9479906,7.20541504 95.0014754,7.3384879 94.9998587,7.47628756 L94.9998587,18.6644602 C95.0031362,18.7991785 94.9492681,18.9293215 94.8507884,19.0246081 C94.7523086,19.1198948 94.6178044,19.1720162 94.4785716,19.168845 L91.9716542,19.168845 C91.8368112,19.1675853 91.7077192,19.1158149 91.6114922,19.0244075 C91.5063929,18.9326998 91.4476051,18.8013701 91.450367,18.6644602 L91.450367,17.6579832 C91.1128583,18.1584645 90.6354691,18.5559776 90.073695,18.8043123 C89.4082693,19.1110166 88.6780779,19.2633099 87.9411566,19.2490881 C86.3472781,19.2552199 84.8207315,18.627764 83.7187306,17.5135457 Z M86.3867731,10.9702988 C85.2623525,12.1598678 85.2623525,13.9831726 86.3867731,15.1727416 C86.9364863,15.7319311 87.6991887,16.0484805 88.4968013,16.0484805 C89.294414,16.0484805 90.0571164,15.7319311 90.6068296,15.1727416 C91.7312502,13.9831726 91.7312502,12.1598678 90.6068296,10.9702988 C90.0578095,10.4098927 89.2948485,10.0924861 88.4968013,10.0924861 C87.6987541,10.0924861 86.9357932,10.4098927 86.3867731,10.9702988 Z"
              id="Shape"
            />
            <path
              d="M19.1713098,11.7298708 L18.15254,12.375 L16.0633577,8.59336984 C14.9071608,6.49378197 12.6985396,5.19011871 10.3005009,5.19177944 L2.75362319,5.19177944 L10.1432254,0.455358048 C11.2804634,-0.272587686 12.7708251,-0.114677177 13.7300462,0.835397803 L19.6994739,6.7541651 C20.3930087,7.43824329 20.7370825,8.40072025 20.6342883,9.3691196 C20.5314941,10.337519 19.9930209,11.2064322 19.1713098,11.7298708 Z"
              id="Shape"
            />
            <path
              d="M16.9398141,21.9999996 L7.97531784,21.9999996 C7.07688294,22.0005543 6.25981035,21.4792087 5.88123048,20.6638353 L0.172885914,8.37966814 L0.0875097588,8.19455125 C-0.0266310908,7.95316425 -0.0292317382,7.67382405 0.0803950792,7.43035074 C0.0873274489,7.41329135 0.0952460357,7.39665016 0.104110678,7.38051157 C0.263816661,7.07148301 0.581761131,6.87673681 0.929413507,6.87500006 L10.7073548,6.87500006 C12.5651888,6.87471971 14.2768465,7.88338228 15.1777451,9.50935585 L17.3358646,13.4252901 L19.5864749,17.507355 C20.1041214,18.4450967 20.0881513,19.5867534 19.5444774,20.509627 C19.0008035,21.4325007 18.0103432,21.999236 16.9398141,21.9999996 Z"
              id="Shape"
            />
          </g>
        </g>
      </svg>
    </div>
    <div className="container">
      <div className="button-area">
        <span className="unsaved">Unsaved changes</span>
        <span
          style={{
            display: "flex"
          }}
        >
          <button onClick={handleDiscard} className="btn">
            Discard
          </button>
          <button onClick={handleSave} className="btn save">
            {loading ? <Spinner color="#fff" /> : "Save"}
          </button>
        </span>
      </div>
    </div>
  </Nav>
);

export default SaveBar;
