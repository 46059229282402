import styled from "styled-components";

export const EmptyBox = styled.div`
  background: transparent;
  padding: ${props => (props.padding ? props.padding : "20px 20px 10px")};
  .text-center {
    text-align: center;
    line-height: 17px;
  }
  .empty-state {
    color: #637381;
  }
  & > div:first-child {
    padding-top: 0px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  svg {
    color: transparent;
    fill: #c3cfd8;
    width: 20px;
    height: 20px;
  }
  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
  }
`;

export const AccountActionBtnsBox = styled.div`
  margin-top: 5px;
  display: flex;
  background-color: transparent;
  margin-left: -10px;
  & > button:not(:last-child) {
    margin-right: 20px;
  }
`;
export const ActiontBtnBox = styled.button`
  cursor: pointer;
  background: transparent;
  display: flex;
  border-color: transparent;
  border-radius: 3px;
  color: #637381;
  padding: 8px;
  &:hover {
    color: #212b36;
    text-decoration: none;
  }
  &:active {
    background-color: rgba(33, 43, 54, 0.1);
    text-decoration: none;
  }
  span {
    font-size: 0.7rem;
  }
  svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    fill: #637381;
  }
`;

export const ClearButton = styled.button`
  height: 38px;
  background-image: ${props =>
    props.action
      ? "linear-gradient(to bottom, #6371c7, #5563c1)"
      : "linear-gradient(to bottom, white, #f9fafb)"};
  color: ${props => (props.action ? "white" : "#212b36")};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #c4cdd5;
  font-size: 14px;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 3px;
  padding: 0 16px;
  cursor: pointer;

  :hover {
    background: linear-gradient(to bottom, #f9fafb, #f4f6f8);
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
    color: #212b36;
  }

  :active {
    background: linear-gradient(to bottom, #f4f6f8, #f4f6f8);
    border-color: #c4cdd5;
    box-shadow: inset 0 1px 1px 0 rgba(99, 115, 129, 0.1),
      inset 0 1px 4px 0 rgba(99, 115, 129, 0.2);
  }
`;

export const AccountListHiddenBtn = styled(ClearButton)`
  display: none;
  padding: 5px 10px;
  height: auto;
  position: absolute;
  right: 0;
  z-index: 2;
  width: max-content;
`;
