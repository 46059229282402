import React, { Component, Fragment } from "react";
import SVG from "react-inlinesvg";
import Case from "case";
import { connect } from "react-redux";
// import { isEqual } from "lodash";
import CustomerCard from "./components/CustomerCard";
import distanceInWords from "date-fns/distance_in_words";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-activity";
import countries from "../../common/countriesv2.json";

import {
  Content,
  Header,
  SubHead,
  Section,
  InfoItem,
  NextPrev,
  Container,
  ActionBtn,
  CustomerInfo,
  GalleryBox
} from "./elements";
import { Status } from "../Verification/components/elements";
import { ReactComponent as BackSVG } from "./svgs/back.svg";
// import { ReactComponent as ResetSVG } from "./svgs/refresh.svg";
// import { ReactComponent as CancelSVG } from "./svgs/cancel.svg";
import arrow from "../arrow.svg";
// import { AccountActionBtnsBox, ActiontBtnBox } from "./components/common";
import CustomerDetailLoader from "../../common/SVGLoaders/InfoLoader";
import Gallery from "react-grid-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import Modal from "../../common/Modal";
import EditCustomerForm from "./EditCustomerForm";
import getVerificationDetail, {
  updateVerificationDetail
} from "../../../actions/getVerificationDetails";

class VerificationDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      editModalOpen: false,
      loading: false,
      fetching: false,
      fetched: true,
      whichModal: "",
      selectedCountry: "",
      statesAndProvinces: [],
      modalLoading: false,
      loading: false,
      note: "",
      customer: {
        id: "",
        email: "",
        phone: {},
        profile: {
          avatar: "",
          city: "",
          country: "",
          country_code: "",
          date_of_birth: "",
          gender: "",
          first_name: "",
          last_name: "",
          postal_code: "",
          street_address1: "",
          street_address2: ""
        }
      }
    };
  }

  toggleEditModal = () => {
    this.setState(({ editModalOpen }) => ({
      editModalOpen: !editModalOpen
    }));
  };

  // toggleCustomerStatus = () => {
  //   this.setState({
  //     modalLoading: true
  //   });

  //   const {
  //     toggleCustomerActiveState,
  //     match,
  //     customer: {
  //       customer: { active }
  //     }
  //   } = this.props;

  //   toggleCustomerActiveState(match.params.customerId, active).then(() => {
  //     this.toggleModal();

  //     this.setState({
  //       modalLoading: false
  //     });
  //   });
  // };

  // resetPassword = () => {
  //   this.setState({
  //     modalLoading: true
  //   });

  //   this.props.resetPassword(this.props.match.params.customerId).then(() => {
  //     this.setState({ modalLoading: false });
  //     this.toggleModal();
  //     toast.success("Password reset successful!");
  //   });
  // };

  toggleModal = () => {
    this.setState(({ isModalOpen }) => ({
      isModalOpen: !isModalOpen
    }));
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  // handleChange = ({ target: { name, value } }) => {
  //   const profileProperties = [
  //     "first_name",
  //     "last_name",
  //     "country",
  //     "state",
  //     "gender",
  //     "city",
  //     "postal_code",
  //     "street_address_1",
  //     "street_address_2",
  //     "date_of_birth"
  //   ];

  //   if (profileProperties.includes(name)) {
  //     this.setState({
  //       customer: {
  //         ...this.state.customer,
  //         profile: {
  //           ...this.state.customer.profile,
  //           [name]: value
  //         }
  //       }
  //     });
  //   } else {
  //     this.setState({
  //       customer: {
  //         ...this.state.customer,
  //         [name]: value
  //       }
  //     });
  //   }
  // };

  // handleCountryStateChange = ({ target: { name, value } }) => {
  //   if (name === "country_code") {
  //     this.setState({
  //       statesAndProvinces: this.getStatesAndProvinces(value),
  //       customer: {
  //         ...this.state.customer,
  //         profile: {
  //           ...this.state.customer.profile,
  //           [name]: value
  //         }
  //       }
  //     });
  //   } else {
  //     this.setState({
  //       customer: {
  //         ...this.state.customer,
  //         profile: {
  //           ...this.state.customer.profile,
  //           [name]: value.split("~")[0]
  //         }
  //       }
  //     });
  //   }
  // };

  // getStatesAndProvinces = country_code => {
  //   const country = countries.find(country => country.iso2 === country_code);
  //   return country.states;
  // };

  // preloadStates = country_code => {
  //   this.setState({
  //     statesAndProvinces: this.getStatesAndProvinces(country_code)
  //   });
  // };

  // chunkDate = () => {
  //   const [year, month, day] = this.state.customer.profile.date_of_birth.split(
  //     "-"
  //   );

  //   this.setState({
  //     customer: {
  //       ...this.state.customer,
  //       year,
  //       month,
  //       day
  //     }
  //   });
  // };

  // handleProfileUpdate = () => {
  //   const customer = { ...this.state.customer };
  //   customer.selectedCountry = this.state.selectedCountry;

  //   this.setState({
  //     modalLoading: true
  //   });

  //   this.props.updateCustomer(customer).then(() => {
  //     this.setState({
  //       editModalOpen: false,
  //       modalLoading: false
  //     });
  //   });
  // };

  componentDidMount() {
    document.title = "Customer's details";

    const { match } = this.props;
    const customerId = match.params.id;
    this.props.getVerificationDetail(customerId);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (!isEqual(prevProps, this.props) || !isEqual(prevState, this.state)) {
  //     if (!isEqual(prevProps, this.props)) {
  //       this.setState({
  //         ...this.state,
  //         customer: {
  //           ...this.props.customer.customer
  //         }
  //       });
  //     }
  //   }
  // }
  // resend = () =>
  //   this.props.resendVerification({
  //     id: this.props.customer.customer.id,
  //     cb: this.toggleModal
  //   });
  onLoading = () =>
    this.setState(prevState => ({ loading: !prevState.loading }));

  onReject = () => {
    const { _id: id } = this.props.verifications.detail;
    const { note } = this.state;

    const data = {
      accepted: false,
      note
    };
    const payload = {
      id,
      data,
      loading: this.onLoading,
      cb: this.toggleEditModal
    };

    this.props.updateVerificationDetail(payload);
  };

  onAccept = () => {
    const { _id: id } = this.props.verifications.detail;

    const data = {
      accepted: true
    };

    const payload = {
      id,
      data,
      loading: this.onLoading
    };
    this.props.updateVerificationDetail(payload);
  };
  render() {
    const {
      verifications: {
        fetched_detail,
        fetched_detail: fetched,
        fetching_detail,
        detail: customer
      }
    } = this.props;
    const { loading } = this.state;
    // fetching_detail:false,
    // fetched_detail:false,
    // fetching_verification:false,
    // fetched_verification:false,

    const active = fetched && customer.active;
    const modalContent = {
      disableAct: {
        title: active ? "Disable account" : "Enable account",
        text: active
          ? "Are you sure you want to disable the account for this customer? Disabled customers will not be able to login to their account."
          : "Are you sure you want to enable the account for the customer?",
        action: this.toggleCustomerStatus
      },
      resetPwd: {
        title: "Reset password",
        text:
          "Are you sure you want to reset the password for this customer? The customer will be sent an email with instructions to reset their password.",
        action: this.resetPassword
      },
      reverify: {
        title: "Re-send verification",
        text:
          "Are you sure you want to re-send verification email to this customer? The customer will be sent an email with instructions to verify their account.",
        action: this.resend
      }
    };
    console.log({ customer });
    const IMAGES =
      customer && customer.images
        ? customer.images.map(src => ({
            // src,
            original: src,
            thumbnail: src,
            originalClass: "kyc-img-original",
            thumbnailClass: "kyc-img-thumbnail"
            // thumbnailWidth: 220,
            // thumbnailHeight: 174
          }))
        : [];

    return (
      <Fragment>
        {fetching_detail || !fetched_detail || !customer ? (
          <CustomerDetailLoader />
        ) : (
          <Content>
            <ToastContainer
              closeButton={false}
              autoClose={2000}
              hideProgressBar={true}
            />
            <Header>
              <nav>
                <a
                  className="back-button"
                  href="#goback"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  <BackSVG className="svg" />
                  <span>Customers</span>
                </a>

                <div>
                  <NextPrev>
                    <SVG src={arrow} className="arrow-left" />
                  </NextPrev>
                  <NextPrev>
                    <SVG src={arrow} className="arrow-right" />
                  </NextPrev>
                </div>
              </nav>
            </Header>
            <SubHead>
              <h1>{Case.capital(`${customer.full_name}`)}</h1>
            </SubHead>
            {/* <AccountActionBtnsBox>
              <ActiontBtnBox
                onClick={() => {
                  this.setState({
                    whichModal: "disableAct"
                  });
                  this.toggleModal();
                }}
              >
                <CancelSVG />
                <span>
                  {customer.active ? "Disable account" : "Enable account"}
                </span>
              </ActiontBtnBox>

              <ActiontBtnBox
                onClick={() => {
                  this.setState({
                    whichModal: "resetPwd"
                  });
                  this.toggleModal();
                }}
              >
                <ResetSVG />
                <span>Reset password</span>
              </ActiontBtnBox>

              {!customer.verified && (
                <ActiontBtnBox
                  onClick={() => {
                    this.setState({
                      whichModal: "reverify"
                    });
                    this.toggleModal();
                  }}
                >
                  <ResetSVG />
                  <span>Re-send verification</span>
                </ActiontBtnBox>
              )}
            </AccountActionBtnsBox> */}

            <Container>
              <div>
                <CustomerInfo>
                  <div className="top">
                    <div className="bio">
                      <h4 className="title space">
                        <span>{Case.capital(`${customer.full_name}`)}</span>
                        <Status>
                          <span
                            className={`status-indicator ${customer.status}`}
                          >
                            {Case.capital(`${customer.status}`)}
                          </span>
                        </Status>
                      </h4>
                      <p className="address">
                        {customer.country &&
                          Case.capital(`${customer.country}, `)}
                      </p>
                      <p className="address">
                        Submitted{" "}
                        {distanceInWords(
                          new Date(customer.created_at),
                          new Date()
                        )}{" "}
                        ago
                      </p>
                    </div>
                  </div>
                  <Section borderTop>
                    <InfoItem>
                      <div>
                        <ActionBtn
                          disabled={customer.status !== "PENDING"}
                          onClick={this.onAccept}
                        >
                          {loading ? (
                            <Spinner size={15} color="#ffffff" />
                          ) : (
                            "Mark as Accepted"
                          )}
                        </ActionBtn>
                      </div>
                    </InfoItem>
                  </Section>
                </CustomerInfo>
                <CustomerInfo noshadow>
                  <GalleryBox>
                    <ImageGallery items={IMAGES} showPlayButton={false} />
                  </GalleryBox>
                </CustomerInfo>
              </div>
              <section className="side-boxes">
                <CustomerCard
                  toggleEditModal={this.toggleEditModal}
                  customer={customer}
                  note={this.state.note}
                />
              </section>
            </Container>
          </Content>
        )}

        {this.state.isModalOpen && (
          <Modal
            meta={{
              width: "620px",
              buttonType: "blue"
            }}
            data={{
              title: modalContent[this.state.whichModal].title,
              loading: this.state.loading,
              buttonTxt:
                this.state.modalLoading || this.props.resend.fetching ? (
                  <Spinner color="white" />
                ) : (
                  modalContent[this.state.whichModal].title
                ),
              body: modalContent[this.state.whichModal].text
            }}
            actions={{
              toggleModal: this.toggleModal,
              handleClick: modalContent[this.state.whichModal].action
            }}
          />
        )}

        {this.state.editModalOpen && (
          <Modal
            meta={{
              width: "620px",
              buttonType: "blue"
            }}
            data={{
              title: "Note",
              loading: this.state.loading,
              buttonTxt: this.state.loading ? (
                <Spinner color="white" />
              ) : (
                "Reject"
              ),
              body: (
                <EditCustomerForm
                  note={this.state.note || customer.note}
                  handleChange={this.handleChange}
                />
              )
            }}
            actions={{
              toggleModal: this.toggleEditModal,
              handleClick: this.onReject
            }}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ verifications }) => ({
  verifications
});
const mapDispatchToProps = {
  getVerificationDetail,
  updateVerificationDetail
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationDetails);
