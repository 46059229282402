import format from "date-fns/format";
import types, {
  GET_LOGIN_ACTIVITIES,
  UPDATE_CUSTOMER_ACCOUNT
} from "../actions/types";

const INITIAL_STATE = {
  fetching: false,
  fetchingOtherInfo: false,
  fetched: false,
  customer: null,
  accounts: [],
  cards: [],
  banks: [],
  recent_transactions: { Data: [] },
  error: null,
  fetchingLoginInfo: false,
  fetchedLoginInfo: false,
  updatingAccountBalance: false,
  updatedAccountBalance: false,
  loginActivities: {}
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_LOGIN_ACTIVITIES.pending:
      return {
        ...state,
        fetchingLoginInfo: true
      };
    case GET_LOGIN_ACTIVITIES.fulfilled:
      return {
        ...state,
        fetchingLoginInfo: false,
        fetchedLoginInfo: true,
        loginActivities: action.payload
      };
    case GET_LOGIN_ACTIVITIES.rejected:
      return {
        ...state,
        fetchingLoginInfo: false,
        fetchedLoginInfo: false,
        error: action.payload
      };
    case types("fetch_customer").pending:
      return {
        ...state,
        fetching: true
      };
    case types("fetch_customer").fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        customer: {
          ...state.customer,
          ...action.payload,
          profile: {
            ...action.payload.profile,
            date_of_birth: format(
              action.payload.profile.date_of_birth,
              "YYYY-MM-DD"
            )
          }
        }
      };
    case types("update_customer").fulfilled:
      return {
        ...state,
        customer: {
          ...action.payload,
          profile: {
            ...action.payload.profile
          }
        }
      };
    case types("toggle_customer_active_state").fulfilled:
      return {
        ...state,
        customer: {
          ...state.customer,
          active: action.payload.active
        }
      };
    case types("fetch_customer").rejected:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload
      };

    case types("fetch_customer_cards").pending:
      return {
        ...state,
        fetchingOtherInfo: true
      };
    case types("fetch_customer_cards").fulfilled:
      return {
        ...state,
        fetchingOtherInfo: false,
        customer: {
          ...state.customer,
          cards: action.payload
        }
      };
    case types("fetch_customer_cards").rejected:
      return {
        ...state,
        fetchingOtherInfo: false,
        error: action.payload
      };
    case types("fetch_customer_banks").pending:
      return {
        ...state,
        fetchingOtherInfo: true
      };
    case types("fetch_customer_banks").fulfilled:
      return {
        ...state,
        fetchingOtherInfo: false,
        customer: {
          ...state.customer,
          banks: action.payload
        }
      };
    case types("fetch_customer_banks").rejected:
      return {
        ...state,
        fetchingOtherInfo: false,
        error: action.payload
      };
    case types("fetch_customer_accounts").pending:
      return {
        ...state,
        fetchingOtherInfo: true
      };
    case types("fetch_customer_accounts").fulfilled:
      return {
        ...state,
        fetchingOtherInfo: false,
        customer: {
          ...state.customer,
          accounts: action.payload
        }
      };
    case types("fetch_customer_accounts").rejected:
      return {
        ...state,
        fetchingOtherInfo: false,
        error: action.payload
      };
    case types("fetch_customer_recent_transactions").pending:
      return {
        ...state,
        fetchingOtherInfo: true
      };
    case types("fetch_customer_recent_transactions").fulfilled:
      return {
        ...state,
        fetchingOtherInfo: false,
        customer: {
          ...state.customer,
          recent_transactions: action.payload
        }
      };
    case types("fetch_customer_recent_transactions").rejected:
      return {
        ...state,
        fetchingOtherInfo: false,
        error: action.payload
      };
    case UPDATE_CUSTOMER_ACCOUNT.pending:
      return {
        ...state,
        updatingAccountBalance: true,
        updatedAccountBalance: false
      };
    case UPDATE_CUSTOMER_ACCOUNT.fulfilled:
      return {
        ...state,
        updatingAccountBalance: false,
        updatedAccountBalance: true
      };
    case UPDATE_CUSTOMER_ACCOUNT.rejected:
      return {
        ...state,
        updatingAccountBalance: false,
        updatedAccountBalance: false
      };
    default:
      return state;
  }
}
