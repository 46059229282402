import styled from "styled-components";

export const DatePickerCont = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  position: relative;

  > span {
    margin-left: 8px;
    display: inline-block;
    font-size: 14px;
    color: #637381;
  }
  font-size: 14px;
`;

export const PickerButton = styled.button`
  height: 38px;
  background-image: ${props =>
    props.action
      ? "linear-gradient(to bottom, #6371c7, #5563c1)"
      : "linear-gradient(rgb(249, 250, 251), rgb(244, 246, 248))"};
  color: ${props => (props.action ? "white" : "#212b36")};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #c4cdd5;
  font-size: 14px;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 3px;
  padding: 0 16px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    fill: #637381;
  }

  > span {
    margin-left: 4px;
  }
`;

export const DropwDownContainer = styled.div`
  position: absolute;
  top: 40px;
  z-index: 100;
`;

export const PickerFooter = styled.div`
  border-top: 1px solid #dfe3e8;
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const DateRangeSelectBox = styled.div`
  padding: 10px 20px;
  div.label-box {
    span {
    }
  }
`;

export const DateRangeInputBox = styled.div`
  padding: 10px 20px;
  display: flex;
  div {
    flex: 1;
  }
  & > div:first-child {
    margin-right: 10px;
  }
  & > div:last-child {
    margin-left: 10px;
  }
`;
