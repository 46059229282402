import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as ExportSVG } from './assets/export.svg';
import { connect } from 'react-redux';
import Paginator from '../../common/Paginator';
import qs from 'qs';
import { isEqual } from 'lodash';
import { isActive } from '../../../helpers/util';

import {
	Container,
	Header,
	HeaderLeft,
	HeaderTextBox,
	HeaderText,
	HeaderExportBtn,
	HeaderExportBtnBox,
	Body,
	Card,
	CardHeader,
	TabsBtnBox,
	TabBtnList,
	TabBtnItem,
	TabBtnLink,
	CardBody,
	SearchAndFilterBox,
	FilterBox,
	SearchBox,
	SearchIconBox,
	SearchInput,
	TableBox,
} from './components/common';
import FilterDropdown from '../../common/Dropdowns/FilterDropdown';
import TransfersTable from './components/TransfersTable';
import { getTransfers } from '../../../actions/coinbarter/transfer';
import FullTableLoader from '../../common/SVGLoaders/FullTableLoader';

class Transfers extends Component {
	state = {
		totalEntries: 1,
		perPage: 20,
	};

	componentDidMount() {
		document.title = 'Coinbarter Transfers';

		const { page, status } = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		});

		this.props.getTransfers(page, status, totalEntries =>
			this.setState({
				totalEntries,
			})
		);
	}

	componentDidUpdate(prevProps) {
		const { page, status } = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		});

		if (!isEqual(prevProps.location.search, this.props.location.search)) {
			this.props.getTransfers(page, status, totalEntries =>
				this.setState({
					totalEntries,
				})
			);
		}
	}

	render() {
		const {
			coinBarterTransfers,
			history: {
				location: { search },
			},
		} = this.props;
		const { totalEntries, perPage } = this.state;

		return (
			<Container>
				{coinBarterTransfers.fetching ? (
					<FullTableLoader />
				) : (
					<Fragment>
						<Header>
							<HeaderLeft>
								<HeaderTextBox>
									<HeaderText>All Transfers</HeaderText>
								</HeaderTextBox>
								{/* <HeaderExportBtnBox smallBox>
                  <HeaderExportBtn>
                    <ExportSVG />
                    <span>Export</span>
                  </HeaderExportBtn>
                </HeaderExportBtnBox> */}
							</HeaderLeft>
						</Header>

						<Body>
							<Card>
								<CardHeader>
									<TabsBtnBox>
										<TabBtnList>
											<TabBtnItem>
												<TabBtnLink
													to="/coinbarter/transfers"
													className={isActive(undefined, search) && 'active-tab'}
												>
													All
												</TabBtnLink>
											</TabBtnItem>
											<TabBtnItem>
												<TabBtnLink
													to="/coinbarter/transfers?status=created"
													className={isActive('created', search) && 'active-tab'}
												>
													Created
												</TabBtnLink>
											</TabBtnItem>
											<TabBtnItem>
												<TabBtnLink
													to="/coinbarter/transfers?status=pending"
													className={isActive('pending', search) && 'active-tab'}
												>
													Pending
												</TabBtnLink>
											</TabBtnItem>
											<TabBtnItem>
												<TabBtnLink
													to="/coinbarter/transfers?status=processing"
													className={isActive('processing', search) && 'active-tab'}
												>
													Processing
												</TabBtnLink>
											</TabBtnItem>
											<TabBtnItem>
												<TabBtnLink
													to="/coinbarter/transfers?status=success"
													className={isActive('success', search) && 'active-tab'}
												>
													Success
												</TabBtnLink>
											</TabBtnItem>
										</TabBtnList>
									</TabsBtnBox>
								</CardHeader>
								<CardBody>
									<SearchAndFilterBox>
										{/* <FilterBox>
											<FilterDropdown />
										</FilterBox> */}
										<SearchBox>
											<SearchIconBox>
												<FontAwesomeIcon icon="search" />
											</SearchIconBox>
											<SearchInput placeholder="Search Transfers" />
										</SearchBox>
									</SearchAndFilterBox>
									<TableBox>
										<TransfersTable coinBarterTransfers={coinBarterTransfers} />
									</TableBox>
								</CardBody>
							</Card>
						</Body>

						<Paginator totalEntries={totalEntries} perPage={perPage} {...this.props} />
					</Fragment>
				)}
			</Container>
		);
	}
}

const mapStateToProps = ({ coinBarterTransfers }) => ({ coinBarterTransfers });

export default connect(
	mapStateToProps,
	{ getTransfers }
)(Transfers);
