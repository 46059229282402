import axios from "axios";
import { toast } from 'react-toastify';

import types from "./types";
import { apiBaseURL } from "../config";

const getStaffProfileFulfilled = payload => ({
  type: types("get_staff_profile").fulfilled,
  payload
});

const getStaffProfileRejected = payload => ({
  type: types("get_staff_profile").rejected,
  payload
});

const getStaffProfilePending = () => ({
  type: types("get_staff_profile").pending
});

const getStaffProfile = (staffId, callback) => async dispatch => {
  try {
    dispatch(getStaffProfilePending());

    const [staffInfo, globalPermissions] = await Promise.all([
      axios.get(`${apiBaseURL}/v1/staff/${staffId}`),
      axios.get(`${apiBaseURL}/v1/auth/permissions`)
    ]);

    staffInfo.data.globalPermissions = globalPermissions.data.permissions;

    dispatch(getStaffProfileFulfilled(staffInfo.data));
  } catch (err) {
    dispatch(getStaffProfileRejected());
    toast.error(err.response.data.message);

  }
};

export default getStaffProfile;
