import React, { Component } from "react";
import styled from "styled-components";

class EditCustomerForm extends Component {
  render() {
    const { handleChange, memo } = this.props;

    return (
      <Form>
        <div className="inputGroup">
          <label>Memo</label>
          <input onChange={handleChange} type="text" name="memo" value={memo} />
        </div>
      </Form>
    );
  }
}

export default EditCustomerForm;

const Form = styled.form`
  font-size: 14px;
  max-height: 50vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  .topForm {
    padding: 0 20px 20px;
    border-bottom: 1px solid #dfe3e8;
    margin-bottom: 16px;
    padding-bottom: 0;
  }

  .btn {
    border-radius: 3px;
    padding: 9px 15px;
    background-image: linear-gradient(to bottom, white, #f9fafb);
    border: 1px solid #c4cdd5;
    font-size: 14px;
    font-weight: 400;
    color: #212b36;

    :disabled {
      cursor: not-allowed;
      background: #f4f5f8;
      color: #919dab;
    }
  }

  & > .inputGroup:not(:last-child) {
    margin-bottom: 20px;
  }

  input,
  select {
    display: block;
    width: 100%;
    padding: 0 10px;
    background: #fff;

    :focus {
      border: 2px solid #5c6ac4 !important;
    }
  }

  .formGroup {
    display: flex;
    margin-bottom: 20px;

    .inputGroup {
      padding: 0;

      :not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .passwordFormGroup {
    margin-bottom: 0;
    margin-top: 20px;
  }

  .inputGroup {
    flex: 1;

    label {
      display: block;
      margin-bottom: 6px;
    }

    input,
    select {
      border-radius: 3px;
      height: 36px;
      box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
      border: 1px solid #c4cdd5;
      outline: none;
      font-size: inherit;
    }
  }

  .bigBio {
    padding: 20px;
    padding-top: 0;
    font-weight: 600;
    color: #212b36;
    font-weight: 400;
    border-bottom: 1px solid #dfe3e8;

    .bio-details {
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      font-size: 12px;
      margin-bottom: 16px;
      font-weight: 600;
    }

    .textareaTitle {
      margin-bottom: 4px;
    }

    .textarea {
      width: 100%;
      height: 106px;
      border-radius: 3px;
      box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
      border-color: #c4cdd5;
      outline: none;
      padding: 4px 8px;
      font-size: inherit;

      :focus {
        border: 2px solid #5c6ac4 !important;
      }
    }

    .underTextarea {
      color: #637381;
      margin-bottom: 16px;
    }
  }

  .notifications {
    padding: 20px;

    :not(:last-child) {
      border-bottom: 1px solid #dfe3e8;
    }

    .notif-title {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    .notif-desc {
      color: #637381;
      margin-left: 23px;
      margin-top: -5px;
    }
  }
`;
