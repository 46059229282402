const STORAGE = localStorage;
export default {
  get(key) {
    const item = STORAGE.getItem(key);
    return item ? JSON.parse(item) : null;
  },
  add(key, val) {
    STORAGE.setItem(key, JSON.stringify(val));
  },
  remove(key) {
    STORAGE.removeItem(key);
  },
  clear() {
    STORAGE.clear();
  }
};
