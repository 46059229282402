import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  & > div {
    min-height: 100%;
    margin-top: 60px;
    width: 100%;
    padding: 30px 32px 30px 26px;
  }
`;

export const MainHeader = styled.div`
  h2 {
    font-size: 1.4rem;
    font-weight: 600;
  }
`;

export const CalendarBtn = styled.button`
  height: 36px;
  background-image: linear-gradient(rgb(249, 250, 251), rgb(244, 246, 248));
  color: #212b36;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #c4cdd5;
  font-size: 14px;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 3px;
  padding: 0 16px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    fill: #637381;
  }

  > span {
    margin-left: 4px;
  }
`;

export const CalendarBtnCont = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  position: relative;

  > span {
    margin-left: 8px;
    display: inline-block;
    font-size: 14px;
    color: #637381;
  }
`;

export const GraphCard = styled.div`
  color: #212b36;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  background: #fff;
  padding: 20px;
  border-radius: 3px;
  position: relative;

  .title {
    font-weight: 600;
    font-size: 16px;
    border-bottom: 2px dotted #c4cdd5;
    display: inline;
  }

  .totals {
    display: flex;
    margin-bottom: 30px;
  }

  .amount {
    font-size: 20px;
    font-weight: 700;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    span:last-child {
      font-weight: normal;
    }
  }
`;

export const GraphCont = styled.div`
  margin-top: 16px;
  min-width: calc(50% - 8px);
`;

export const GraphTitle = styled.p`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

export const GraphFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Bullet = styled.div`
  display: inline-flex;
  color: #637381;
  font-size: 14px;
  align-items: center;

  &:last-child {
    margin-left: 8px;
  }

  .dot {
    height: 12px;
    width: 12px;
    display: inline-block;
    margin-right: 4px;
    border-radius: 3px;
    background: ${({ color }) => color || "#9C6ADE"};
  }
`;

export const Graphs = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
