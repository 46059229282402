import React, { Fragment } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import qs from "qs";
import { isEqual } from "lodash";

import { ReactComponent as ExportSVG } from "./images/export.svg";
import Paginator from "../../common/Paginator";
import { isActive } from "../../../helpers/util";

import {
  Container,
  Header,
  Body,
  Card,
  HeaderLeft,
  HeaderText,
  HeaderExportBtn,
  CardHeader,
  HeaderTextBox,
  HeaderExportBtnBox,
  TabsBtnBox,
  TabBtnList,
  TabBtnItem,
  TabBtnLink,
  CardBody,
  SearchAndFilterBox,
  FilterBox,
  SearchBox,
  SearchIconBox,
  SearchInput,
  TableBox
} from "./components/common";
import FilterDropdown from "../../common/Dropdowns/FilterDropdown";
import FullTableLoader from "../../common/SVGLoaders/FullTableLoader";
import { fetchAllSwaps } from "../../../actions/transactions";
import SwapsTable from "./components/SwapsTable";

class Swaps extends React.Component {
  state = {
    totalEntries: 1,
    perPage: 20
  };

  componentDidMount() {
    document.title = "Swaps";
    const { page = 1, status = "all" } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    this.props.fetchAllSwaps(page, status, totalEntries => {
      this.setState({
        totalEntries
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { page = 1, status = "all" } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      this.props.fetchAllSwaps(page, status, totalEntries => {
        this.setState({
          totalEntries
        });
      });
    }
  }

  render() {
    const {
      transactions,
      history: {
        location: { search }
      }
    } = this.props;
    const { totalEntries, perPage } = this.state;

    return (
      <Container>
        {transactions.fetching ? (
          <FullTableLoader />
        ) : (
          <Fragment>
            <Header>
              <HeaderLeft>
                <HeaderTextBox>
                  <HeaderText>All Swaps</HeaderText>
                </HeaderTextBox>
                <HeaderExportBtnBox smallBox>
                  <HeaderExportBtn>
                    <ExportSVG />
                    <span>Export</span>
                  </HeaderExportBtn>
                </HeaderExportBtnBox>
              </HeaderLeft>
            </Header>
            <Body>
              <Card>
                <CardHeader>
                  <TabsBtnBox>
                    <TabBtnList>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/transactions"
                          className={
                            isActive(undefined, search) && "active-tab"
                          }
                        >
                          All
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/transactions?status=success"
                          className={
                            isActive("success", search) && "active-tab"
                          }
                        >
                          Success
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/transactions?status=pending"
                          className={
                            isActive("pending", search) && "active-tab"
                          }
                        >
                          Pending
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/transactions?status=failed"
                          className={isActive("failed", search) && "active-tab"}
                        >
                          Failed
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/transactions?status=abandoned"
                          className={
                            isActive("abandoned", search) && "active-tab"
                          }
                        >
                          Abandoned
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/transactions?status=reserved"
                          className={
                            isActive("reserved", search) && "active-tab"
                          }
                        >
                          Reserved
                        </TabBtnLink>
                      </TabBtnItem>
                    </TabBtnList>
                  </TabsBtnBox>
                </CardHeader>
                <CardBody>
                  <SearchAndFilterBox>
                    <FilterBox>
                      <FilterDropdown />
                    </FilterBox>
                    <SearchBox>
                      <SearchIconBox>
                        <FontAwesomeIcon icon="search" />
                      </SearchIconBox>
                      <SearchInput placeholder="Search Payouts" />
                    </SearchBox>
                  </SearchAndFilterBox>
                  <TableBox>
                    <SwapsTable swaps={transactions.swaps} />
                  </TableBox>
                </CardBody>
              </Card>
            </Body>

            <Paginator
              totalEntries={totalEntries}
              perPage={perPage}
              {...this.props}
            />
          </Fragment>
        )}
      </Container>
    );
  }
}

function mapStateToProps({ transactions }) {
  return { transactions };
}

export default connect(
  mapStateToProps,
  { fetchAllSwaps }
)(Swaps);
