const types = action => ({
  default: `${action.toUpperCase()}`,
  fulfilled: `${action.toUpperCase()}_FULFILLED`,
  rejected: `${action.toUpperCase()}_REJECTED`,
  pending: `${action.toUpperCase()}_PENDING`
});

export default types;

export const UPDATE_MEMO = types("UPDATE_MEMO");
export const UPDATE_LEVEL = types("UPDATE_LEVEL");
export const CLEAR_UPDATE = types("CLEAR_UPDATE");
export const VERIFICATIONS = types("VERIFICATIONS");
export const UPDATE_ADDRESS = types("UPDATE_ADDRESS");
export const FINALIZE_DEPOSIT = types("FINALIZE_DEPOSIT");
export const GET_LOGIN_ACTIVITIES = types("GET_LOGIN_ACTIVITIES");
export const VERIFICATIONS_DETAIL = types("VERIFICATIONS_DETAIL");
export const UPDATE_SINGLE_CUSTOMER = types("UPDATE_SINGLE_CUSTOMER");
export const UPDATE_VERIFICATION_DETAIL = types("UPDATE_VERIFICATION_DETAIL");
export const GET_PRODUCTS = types("GET_PRODUCTS");
export const NEW_TRANSACTION = types("NEW_TRANSACTION");
export const GET_TRADE_ANALYTICS = types("GET_TRADE_ANALYTICS");
export const GET_ASSETS = types("GET_ASSETS");
export const GET_ASSET_ANALYTICS = types("GET_ASSET_ANALYTICS");
export const GET_CURRENCIES = types("GET_CURRENCIES");
export const GET_CURRENCY = types("GET_CURRENCY");
export const UPDATE_CURRENCY = types("UPDATE_CURRENCY");
export const CREATE_CURRENCY = types("CREATE_CURRENCY");
export const GET_PROVIDERS = types("GET_PROVIDERS");
export const GET_PROVIDER = types("GET_PROVIDER");
export const UPDATE_PROVIDER = types("UPDATE_PROVIDER");
export const CREATE_PROVIDER = types("CREATE_PROVIDER");
export const PROCESS_DEPOSIT = types("PROCESS_DEPOSIT");
export const GET_COUNTRIES = types("GET_COUNTRIES");
export const UPDATE_CUSTOMER_ACCOUNT = types("UPDATE_CUSTOMER_ACCOUNT");
