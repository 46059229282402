import types from "../actions/types";

export default function(
  state = {
    fetching: false,
    fetched: false,
    fiat: {
      transactions: [],
      meta: {}
    },
    crypto: {
      transactions: [],
      meta: {}
    },
    error: null
  },
  action
) {
  switch (action.type) {
    case types("fetch_fiat_payouts").pending:
      return {
        ...state,
        fetching: true
      };
    case types("fetch_fiat_payouts").fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        fiat: action.payload
      };
    case types("fetch_fiat_payouts").rejected:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload
      };
    case types("fetch_crypto_payouts").pending:
      return {
        ...state,
        fetching: true
      };
    case types("fetch_crypto_payouts").fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        crypto: action.payload
      };
    case types("fetch_crypto_payouts").rejected:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload
      };
    default:
      return state;
  }
}
