import { combineReducers } from "redux";

import auth from "./auth";
import user from "./user";
import staff from "./staff";
import resend from "./resend";
import payout from "./payout";
import payouts from "./payouts";
import deposit from "./deposit";
import customer from "./customer";
import deposits from "./deposits";
import liveData from "./liveData";
import customers from "./customers";
import analytics from "./analytics";
import updateLevel from "./updateLevel";
import permissions from "./permissions";
import transaction from "./transaction";
import transactions from "./transactions";
import staffProfile from "./staffProfile";
import verifications from "./verifications";
import updateCustomer from "./updateCustomer";
import coinBarterStats from "./coinBarterStats";
import coinBarterTrades from "./coinBarterTrades";
import coinBarterOneTrade from "./coinBarterOneTrade";
import coinBarterTransfers from "./coinBarterTransfers";
import coinBarterOneTransfer from "./coinBarterOneTransfer";
import products from "./products";
import assets from "./assets";
import currencies from "./currencies";
import providers from "./providers";
import ads from "../components/Dashboard/MarketPlace/Ads.reducer";
import trades from "../components/Dashboard/MarketPlace/Trades.reducer";
import marketplaceSummary from "../components/Dashboard/MarketPlace/Summary/redux/reducer";
import countries from "./countries";

const reducers = combineReducers({
  ads,
  auth,
  user,
  staff,
  resend,
  payout,
  trades,
  deposit,
  payouts,
  deposits,
  customer,
  liveData,
  customers,
  analytics,
  updateLevel,
  permissions,
  transaction,
  staffProfile,
  transactions,
  verifications,
  updateCustomer,
  coinBarterStats,
  coinBarterTrades,
  coinBarterOneTrade,
  coinBarterTransfers,
  coinBarterOneTransfer,
  products,
  assets,
  currencies,
  providers,
  countries,
  marketplaceSummary
});

export default reducers;
