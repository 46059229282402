import React, { Fragment, Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Case from 'case';
import Checkbox from '../../../common/Checkbox';
import ActionDropdown from '../../../common/Dropdowns/ActionDropdown';
import { moneyFormatter, toNaira, dayMonthYear } from '../../../../helpers/util';

import {
	Table,
	TableHead,
	TableHeadData,
	TableHeadCheckboxBox,
	CheckboxLeft,
	TableHeadRow,
	TableBody,
	TableRow,
	TableData,
	TableDataBox,
	Status,
} from './common';

class TransfersTable extends Component {
	state = {
		selectAll: false,
	};

	render() {
		const { selectAll } = this.state;
		const {
			coinBarterTransfers: { transfers, meta },
		} = this.props;

		return (
			<Table>
				<TableHead>
					<TableHeadRow>
						{/* <TableHeadData colSpan={selectAll ? 7 : 1} hasSelect>
							<TableHeadCheckboxBox>
								<CheckboxLeft selectedAll={selectAll}>
									<Checkbox checked={selectAll} onChange={this._onSelectAllToggled} />
									{!selectAll ? (
										<FontAwesomeIcon icon="caret-down" onClick={this._onSelectAllToggled} />
									) : (
										<span>1 payout selected</span>
									)}
								</CheckboxLeft>
								{selectAll && <ActionDropdown />}
							</TableHeadCheckboxBox>
						</TableHeadData> */}

						{/* {!selectAll && ( */}
						{/* <Fragment> */}
						<TableHeadData>
							<span>Transfer Details</span>
						</TableHeadData>
						<TableHeadData>
							<span>Recipient Account</span>
						</TableHeadData>
						<TableHeadData>
							<span>Status</span>
						</TableHeadData>
						<TableHeadData>
							<span>Date</span>
						</TableHeadData>
						{/* </Fragment> */}
						{/* )} */}
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{transfers.map(transfer => (
						<TableRow key={transfer.id}>
							{/* <TableData hasSelect>
								<Checkbox />
							</TableData> */}
							<TableData>
								<Link to={`/coinbarter/transfers/${transfer.id}`}>
									<TableDataBox>
										<span>
											{moneyFormatter.format(toNaira(transfer.amount))} to{' '}
											{transfer.customer.email}
										</span>
									</TableDataBox>
								</Link>
							</TableData>
							<TableData>
								<Link to={`/coinbarter/transfers/${transfer.id}`}>
									<TableDataBox>
										<span>
											{transfer.recipient.account_number} {transfer.recipient.account_name}
										</span>
									</TableDataBox>
								</Link>
							</TableData>
							<TableData>
								<Link to={`/coinbarter/transfers/${transfer.id}`}>
									<TableDataBox>
										<Status status={transfer.status}>{Case.capital(transfer.status)}</Status>
									</TableDataBox>
								</Link>
							</TableData>
							<TableData>
								<Link to={`/coinbarter/transfers/${transfer.id}`}>
									<TableDataBox>
										<span>{dayMonthYear(transfer.created_at)}</span>
									</TableDataBox>
								</Link>
							</TableData>
						</TableRow>
					))}
				</TableBody>
			</Table>
		);
	}
}
export default TransfersTable;
