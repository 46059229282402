import styled from "styled-components";

export const Label = styled.label`
  margin: 0;
  padding: 0;
  display: flex;
  ${"" /* margin-bottom: 12px; */}
  cursor: pointer;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-items: center;
  .label {
    margin-left: 8px;
    line-height: 20px;
  }
  .input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    outline-color: #5c6ac4;
    :checked ~ .checkmark {
      border: 1px solid #5c6ac4;

      svg {
        display: inline;
      }
    }
  }

  .checkmark {
    height: 18px;
    width: 18px;
    background: linear-gradient(to bottom, white, #f9fafb);
    border: 1px solid #c4cdd5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    svg {
      height: 10px;
      width: 10px;
      fill: #5c6ac4;
      display: none;
    }
  }
`;
