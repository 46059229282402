import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Case from "case";

import Card from "../../../common/Card";
import {
  Section,
  //   MainHeader,
  SectionBody,
  Info,
  SubHeader,
  EmptyBox
} from "./common";

const Container = styled.div``;

export default function ChannelCard({ transaction }) {
  return (
    <Container>
      <Card>
        <Section>
          <SubHeader>
            <h3>Channel</h3>
          </SubHeader>
          <SectionBody>
            <Info>
              <p>{Case.capital(transaction.channel)}</p>
            </Info>
          </SectionBody>
        </Section>
        {transaction.quote_type === "fiat" &&
          transaction.channel !== "balance" && (
            <React.Fragment>
              {transaction.type === "buy" && (
                <Section borderTop>
                  <SubHeader>
                    <h3>CARD</h3>
                    {!!transaction.charge.reference && (
                      <Link
                        to={`/deposits/${transaction.quote_type}/${
                          transaction.charge.id
                        }`}
                      >
                        <span>View</span>
                      </Link>
                    )}
                  </SubHeader>
                  <SectionBody>
                    {!!transaction.charge.reference &&
                    transaction.charge.card_type ? (
                      (() => {
                        const {
                          last4,
                          bank,
                          card_type,
                          country
                        } = transaction.charge;
                        return (
                          <Info>
                            <p>{card_type}</p>
                            <p>•••• •••• •••• {last4}</p>
                            <p>
                              {bank}({country})
                            </p>
                          </Info>
                        );
                      })()
                    ) : (
                      <EmptyBox padding="40px 20px 0px">
                        <div>
                          <span className="text-center empty-state ">
                            No Card
                          </span>
                        </div>
                      </EmptyBox>
                    )}
                  </SectionBody>
                </Section>
              )}
              {transaction.type === "sell" && (
                <Section borderTop>
                  <SubHeader>
                    <h3>BANK</h3>
                  </SubHeader>
                  <SectionBody>
                    {!!transaction.payout.reference ? (
                      (() => {
                        const {
                          account_name,
                          name,
                          account_number
                        } = transaction.payout.bank;
                        return (
                          <Info>
                            <p>{name}</p>
                            <p>{account_name}</p>
                            <p>{account_number}</p>
                          </Info>
                        );
                      })()
                    ) : (
                      <EmptyBox padding="40px 20px 0px">
                        <div>
                          <span className="text-center empty-state ">
                            No Bank
                          </span>
                        </div>
                      </EmptyBox>
                    )}
                  </SectionBody>
                </Section>
              )}
            </React.Fragment>
          )}
      </Card>
    </Container>
  );
}
