import React from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { ReactComponent as DashboardIcon } from "./svg/dashboard.svg";
import { ReactComponent as CustomerIcon } from "./svg/user.svg";
import { ReactComponent as SettingsIcon } from "./svg/settings.svg";
import { ReactComponent as PayoutsIcon } from "./svg/payouts.svg";
import { ReactComponent as DepositsIcon } from "./svg/deposits.svg";
import { ReactComponent as AnalyticsIcon } from "./svg/analytics.svg";
import { ReactComponent as TransactionsIcon } from "./svg/transactions.svg";
import { ReactComponent as CoinbarterIcon } from "./svg/coinbarter.svg";
import { ReactComponent as MarketPlace } from "./svg/marketplace.svg";

const NavigationItems = ({ location, permissions }) => [
  {
    hasSubLinks: false,
    isActive: (match, location) => location.pathname.startsWith("/dashboard"),
    defaultPath: "/dashboard",
    defaultTitle: "Home",
    icon: <DashboardIcon />,
    blur: permissions.includes("Home") ? false : true
  },
  {
    hasSubLinks: true,
    isActive: (match, location) => location.pathname.startsWith("/analytics"),
    defaultPath: "/analytics/dashboard",
    defaultTitle: "Analytics",
    blur: permissions.includes("Analytics") ? false : true,
    icon: <AnalyticsIcon />,
    list: [
      {
        title: "Dashboard",
        path: "/analytics/dashboard",
        isActive: (match, location) => location.pathname.includes("/dashboard")
      }
      // {
      //   title: "Buy/Sell",
      //   path: "/analytics/swaps",
      //   isActive: location.pathname.includes("/swaps")
      // },
      // {
      //   title: "Live View",
      //   path: "/analytics/live-view",
      //   isActive: location.pathname.includes("/live-view")
      // }
    ]
  },
  {
    hasSubLinks: true,
    isActive: (match, location) => location.pathname.startsWith("/customers"),
    defaultPath: permissions.includes("Customers")
      ? "/customers"
      : "/no-access",
    blur: permissions.includes("Customers") ? false : true,
    defaultTitle: "Customers",
    icon: <CustomerIcon />,
    list: [
      {
        title: "All Customers",
        path: permissions.includes("Customers") ? "/customers" : "/no-access"
        // isActive:
        //   location.pathname.includes("/customers") &&
        //   !location.pathname.endsWith("kyc")
      },
      {
        title: "Verifications",
        path: permissions.includes("Customers")
          ? "/customers/kyc"
          : "/no-access"
        // isActive: location.pathname.includes("/customers/kyc")
      }
    ]
  },
  {
    hasSubLinks: true,
    isActive: (match, location) =>
      location.pathname.startsWith("/transactions"),
    defaultPath: permissions.includes("Transactions")
      ? "/transactions?status=success"
      : "/no-access",
    defaultTitle: "Transactions",
    icon: <TransactionsIcon />,
    exact: false,
    blur: permissions.includes("Transactions") ? false : true,
    list: [
      {
        path: permissions.includes("Transactions")
          ? "/transactions?status=success"
          : "/no-access",
        title: "Buy/Sell",
        exact: false,
        isActive: (match, location) =>
          location.pathname.includes("/transactions") &&
          !location.pathname.endsWith("swaps")
      },
      { path: "/transactions/swaps", title: "Swaps" }
    ]
  },
  {
    hasSubLinks: true,
    icon: <PayoutsIcon />,
    isActive: (match, location) => location.pathname.startsWith("/payouts"),
    defaultPath: permissions.includes("Payouts")
      ? "/payouts/fiat"
      : "/no-access",
    defaultTitle: "Payouts",
    blur: permissions.includes("Payouts") ? false : true,
    list: [
      { title: "Fiat", path: "/payouts/fiat" },
      { title: "Crypto", path: "/payouts/crypto" }
    ]
  },
  {
    hasSubLinks: true,
    isActive: (match, location) => location.pathname.includes("/deposits"),
    defaultPath: permissions.includes("Deposits")
      ? "/deposits/fiat?channel=card"
      : "/no-access",
    defaultTitle: "Deposits",
    icon: <DepositsIcon />,
    blur: permissions.includes("Deposits") ? false : true,
    list: [
      {
        title: "Fiat",
        path: "/deposits/fiat?channel=card",
        isActive: (match, location) =>
          location.pathname.includes("/deposits/fiat")
      },
      {
        title: "Crypto",
        path: "/deposits/crypto?channel=card",
        isActive: (match, location) =>
          location.pathname.includes("/deposits/crypto")
      }
    ]
  },
  {
    hasSubLinks: true,
    isActive: (match, location) => location.pathname.includes("/marketplace"),
    defaultPath: permissions.includes("Ads")
      ? "/marketplace/ads?side=buy&country=NG&asset=BTC"
      : "/no-access",
    defaultTitle: "Market Place",
    icon: <MarketPlace />,
    blur: permissions.includes("Ads") ? false : true,
    list: [
      { path: "/marketplace/ads", title: "Ads" },
      { path: "/marketplace/trades", title: "Trades" },
      { path: "/marketplace/summary", title: "Summary" }
    ]
  },
  {
    hasSubLinks: true,
    isActive: (match, location) => location.pathname.startsWith("/coinbarter"),
    defaultPath: "/coinbarter/trades",
    defaultTitle: "Coinbarter",
    icon: <CoinbarterIcon />,
    blur: permissions.includes("Coinbarter") ? false : true,
    list: [
      { path: "/coinbarter/trades", title: "Trades" },
      { path: "/coinbarter/transfers", title: "Transfers" }
    ]
  },
  {
    hasSubLinks: false,
    icon: <SettingsIcon />,
    isActive: (match, location) => location.pathname.startsWith("/settings"),
    defaultPath: "/settings",
    blur: permissions.includes("Settings") ? false : true,
    defaultTitle: "Settings",
    bottom: true
  }
];

const NavigationLinks = ({
  hasSubLinks,
  isActive,
  activeClassName = "sidenav-active",
  defaultPath,
  defaultTitle,
  list = [],
  icon,
  blur = false,
  bottom = false,
  exact = true,
  location
}) => (
  <SidebarLinkBox
    hasSubLinks={hasSubLinks}
    isActive={isActive(null, location)}
    bottom={bottom}
    blur={blur}
    // exact={exact}
  >
    <SidebarLink
      to={defaultPath}
      activeClassName={activeClassName}
      className={isActive(null, location) ? activeClassName : ""}
    >
      <div>{icon}</div>
      <span>{defaultTitle}</span>
    </SidebarLink>
    {list.length > 0 && (
      <SidebarLinkChildBox>
        <SidebarLinkChildLinks>
          {list.map(({ title, path, isActive, exact = true }, key) => (
            <SidebarLinkBox key={key}>
              <SidebarLink
                to={path}
                sublink="true"
                activeClassName={activeClassName}
                exact={exact}
                isActive={isActive || null}
                // className={_isActive ? activeClassName : ""}
              >
                <span>{title}</span>
              </SidebarLink>
            </SidebarLinkBox>
          ))}
        </SidebarLinkChildLinks>
      </SidebarLinkChildBox>
    )}
  </SidebarLinkBox>
);

const SideBar = ({ location, permissions }) => (
  <SideNav>
    <ul className="nav-links">
      {NavigationItems({ location, permissions }).map((val, key) => (
        <NavigationLinks location={location} {...val} key={key} />
      ))}
    </ul>
  </SideNav>
);

export default SideBar;

// const App = ({ location, permissions }) => {
//   return (
//     <SideNav>
//       <ul className="nav-links">
//         {/* ////////////////////  DASHBOARD*/}
//         <SidebarLinkBox>
//           <SidebarLink activeClassName="sidenav-active" to="/dashboard">
//             <div>
//               <DashboardIcon />
//             </div>
//             <span>Home</span>
//           </SidebarLink>
//         </SidebarLinkBox>
//         {/* //////////////////// ANALYTICS */}
//         <SidebarLinkBox
//           hasSubLinks
//           isActive={location.pathname.startsWith("/analytics")}
//         >
//           <SidebarLink
//             activeClassName="sidenav-active"
//             to="/analytics/transfers"
//           >
//             <div>
//               <AnalyticsIcon />
//             </div>
//             <span>Analytics</span>
//           </SidebarLink>
//           <SidebarLinkChildBox>
//             <SidebarLinkChildLinks>
//               <SidebarLinkBox>
//                 <SidebarLink
//                   activeClassName="sidenav-active"
//                   to="/analytics/transfers"
//                   sublink="true"
//                 >
//                   <span>Transfers</span>
//                 </SidebarLink>
//               </SidebarLinkBox>

//               <SidebarLinkBox>
//                 <SidebarLink
//                   activeClassName="sidenav-active"
//                   to="/analytics/swaps"
//                   sublink="true"
//                 >
//                   <span>Buy/Sell</span>
//                 </SidebarLink>
//               </SidebarLinkBox>

//               <SidebarLinkBox>
//                 <SidebarLink
//                   activeClassName="sidenav-active"
//                   to="/analytics/live-view"
//                   sublink="true"
//                 >
//                   <span>Live View</span>
//                 </SidebarLink>
//               </SidebarLinkBox>
//             </SidebarLinkChildLinks>
//           </SidebarLinkChildBox>
//         </SidebarLinkBox>
//         {/* ////////////////////  CUSTOMERS*/}

//         <SidebarLinkBox
//           hasSubLinks
//           isActive={location.pathname.startsWith("/customers")}
//         >
//           <SidebarLink
//             activeClassName="sidenav-active"
//             to={permissions.includes("Users") ? "/customers" : "/no-access"}
//           >
//             <div>
//               <CustomerIcon />
//             </div>
//             <span>Customers</span>
//           </SidebarLink>
//           <SidebarLinkChildBox>
//             <SidebarLinkChildLinks>
//               <SidebarLinkBox>
//                 <SidebarLink
//                   activeClassName="sidenav-active"
//                   exact
//                   to={
//                     permissions.includes("Users") ? "/customers" : "/no-access"
//                   }
//                   sublink="true"
//                 >
//                   <span>All Customers</span>
//                 </SidebarLink>
//               </SidebarLinkBox>

//               <SidebarLinkBox>
//                 <SidebarLink
//                   activeClassName="sidenav-active"
//                   to={
//                     permissions.includes("Users")
//                       ? "/customers/kyc"
//                       : "/no-access"
//                   }
//                   sublink="true"
//                 >
//                   <span>Verifications</span>
//                 </SidebarLink>
//               </SidebarLinkBox>
//             </SidebarLinkChildLinks>
//           </SidebarLinkChildBox>
//         </SidebarLinkBox>
//         {/* ////////////////////  TRANSACTIONS*/}
//         <SidebarLinkBox
//           hasSubLinks
//           isActive={location.pathname.startsWith("/transactions")}
//         >
//           <SidebarLink
//             activeClassName="sidenav-active"
//             to={
//               permissions.includes("Transactions")
//                 ? "/transactions"
//                 : "/no-access"
//             }
//           >
//             <div>
//               <TransactionsIcon />
//             </div>
//             <span>Transactions</span>
//           </SidebarLink>
//           <SidebarLinkChildBox>
//             <SidebarLinkChildLinks>
//               <SidebarLinkBox>
//                 <SidebarLink
//                   activeClassName="sidenav-active"
//                   to={
//                     permissions.includes("Transactions")
//                       ? "/transactions"
//                       : "/no-access"
//                   }
//                   sublink="true"
//                 >
//                   <span>Buy/Sell</span>
//                 </SidebarLink>
//               </SidebarLinkBox>

//               <SidebarLinkBox>
//                 <SidebarLink
//                   activeClassName="sidenav-active"
//                   to="/transactions/swaps"
//                   sublink="true"
//                 >
//                   <span>Swaps</span>
//                 </SidebarLink>
//               </SidebarLinkBox>
//             </SidebarLinkChildLinks>
//           </SidebarLinkChildBox>
//         </SidebarLinkBox>
//         {/* ////////////////////  PAYOUTS*/}
//         <SidebarLinkBox
//           hasSubLinks
//           isActive={location.pathname.startsWith("/payouts")}
//         >
//           <SidebarLink
//             activeClassName="sidenav-active"
//             to={
//               permissions.includes("Payouts") ? "/payouts/fiat" : "/no-access"
//             }
//           >
//             <div>
//               <PayoutsIcon />
//             </div>
//             <span>Payouts</span>
//           </SidebarLink>
//           <SidebarLinkChildBox>
//             <SidebarLinkChildLinks>
//               <SidebarLinkBox>
//                 <SidebarLink
//                   activeClassName="sidenav-active"
//                   to="/payouts/fiat"
//                   sublink="true"
//                 >
//                   <span>Fiat</span>
//                 </SidebarLink>
//               </SidebarLinkBox>
//               <SidebarLinkBox>
//                 <SidebarLink
//                   activeClassName="sidenav-active"
//                   to="/payouts/crypto"
//                   sublink="true"
//                 >
//                   <span>Crypto</span>
//                 </SidebarLink>
//               </SidebarLinkBox>
//             </SidebarLinkChildLinks>
//           </SidebarLinkChildBox>
//         </SidebarLinkBox>
//         {/* //////////////////// DEPOSITS */}
//         <SidebarLinkBox
//           hasSubLinks
//           isActive={location.pathname.startsWith("/deposits")}
//         >
//           <SidebarLink
//             activeClassName="sidenav-active"
//             to={
//               permissions.includes("Deposits") ? "/deposits/fiat" : "/no-access"
//             }
//           >
//             <div>
//               <PayoutsIcon />
//             </div>
//             <span>Deposits</span>
//           </SidebarLink>
//           <SidebarLinkChildBox>
//             <SidebarLinkChildLinks>
//               <SidebarLinkBox>
//                 <SidebarLink
//                   activeClassName="sidenav-active"
//                   to="/deposits/fiat"
//                   sublink="true"
//                 >
//                   <span>Fiat</span>
//                 </SidebarLink>
//               </SidebarLinkBox>
//               {/* <SidebarLinkBox>
//                 <SidebarLink
//                   activeClassName="sidenav-active"
//                   to="/deposits/banks"
//                   sublink="true"
//                 >
//                   <span>Bank Transfers</span>
//                 </SidebarLink>
//               </SidebarLinkBox> */}
//               <SidebarLinkBox>
//                 <SidebarLink
//                   activeClassName="sidenav-active"
//                   to="/deposits/crypto"
//                   sublink="true"
//                 >
//                   <span>Crypto</span>
//                 </SidebarLink>
//               </SidebarLinkBox>
//             </SidebarLinkChildLinks>
//           </SidebarLinkChildBox>
//         </SidebarLinkBox>

//         <SidebarLinkBox
//           hasSubLinks
//           isActive={location.pathname.startsWith("/coinbarter")}
//         >
//           <SidebarLink activeClassName="sidenav-active" to="/coinbarter/trades">
//             <div>
//               <CoinbarterIcon />
//             </div>
//             <span>Coinbarter</span>
//           </SidebarLink>
//           <SidebarLinkChildBox>
//             <SidebarLinkChildLinks>
//               <SidebarLinkBox>
//                 <SidebarLink
//                   activeClassName="sidenav-active"
//                   to="/coinbarter/trades"
//                   sublink="true"
//                 >
//                   <span>Trades</span>
//                 </SidebarLink>
//               </SidebarLinkBox>
//               <SidebarLinkBox>
//                 <SidebarLink
//                   activeClassName="sidenav-active"
//                   to="/coinbarter/transfers"
//                   sublink="true"
//                 >
//                   <span>Transfers</span>
//                 </SidebarLink>
//               </SidebarLinkBox>
//             </SidebarLinkChildLinks>
//           </SidebarLinkChildBox>
//         </SidebarLinkBox>

//         <SidebarLinkBox bottom>
//           <SidebarLink activeClassName="sidenav-active" to="/settings">
//             <div>
//               <SettingsIcon />
//             </div>
//             <span>Settings</span>
//           </SidebarLink>
//         </SidebarLinkBox>
//       </ul>
//     </SideNav>
//   );
// };

export const SideNav = styled.nav`
  position: fixed;
  z-index: 1000;
  top: 56px;
  left: 0;
  /* width: 246px; */
  /* min-width: 246px; */
  width: 12.5%;
  height: calc(100vh - 56px);
  background: #f4f6f8;
  border-right: 1px solid #dfe3e8;

  .nav-links {
    list-style: none;
    padding: 0 10px;
    margin: 0;
    margin-top: 10px;
    font-size: 14px;

    .active {
      color: #57a7ed;
    }
  }
  @media (max-width: 1800px) {
    width: 12.5%;
  }
`;

export const SidebarLink = styled(NavLink)`
  display: flex;
  align-items: center;
  & > div:first-child {
    margin-right: 10px;
    & > svg {
      width: 20px;
      height: 20px;
    }
  }
  & > span {
    display: flex;
    align-items: center;
  }
  ${props =>
    props.sublink &&
    css`
      & > span {
        color: #637381;
        font-weight: 400;
      }
    `};
`;

export const SidebarLinkChildBox = styled.div`
  margin-left: 30px;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;

export const SidebarLinkChildLinks = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0;
  font-size: 14px;
`;

export const SidebarLinkBox = styled.li`
  padding: 0;

  ${props =>
    props.bottom &&
    css`
      position: absolute;
      bottom: 20px;
      margin-bottom: 16px;
    `};

  a {
    color: #212b36;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    text-align: center;
    line-height: 17px;
  }

  ${SidebarLink} {
    padding: 10px 0px 0px 0px;
  }

  ${SidebarLink}.sidenav-active {
    & > span {
      font-weight: 500;
      color: #5b6ac4;
    }
    & use,
    & .outline {
      fill: #5c6ac4;
    }
    #marketplace {
      path {
        fill: #5b6ac4 !important;
      }
    }
  }

  ${SidebarLinkChildBox} {
    ${props =>
      props.hasSubLinks && props.isActive
        ? css`
            max-height: 100px;
            opacity: 1;
            transform: scale(1);
          `
        : css`
            max-height: 0;
            opacity: 0;
            transform: scale(0);
          `};
  }
  ${({ blur }) =>
    blur &&
    css`
      color: #919eab;
      pointer-events: none;
      opacity: 0.6;
    `}
`;
