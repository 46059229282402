import React, { Component } from "react";
import qs from "qs";

import { Container } from "./elements";
import Cell from "./Cell";
import ReactPaginate from "react-paginate";
import "./style.css";

class Paginator extends Component {
  pageNumbers = () => {
    const { totalEntries, perPage } = this.props;
    const numbers = [];

    for (let i = 1; i <= Math.ceil(totalEntries / perPage); i += 1) {
      numbers.push(i);
    }
    return numbers;
  };

  paginate = pageNumbers => {
    const { page, status } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    const { pathname } = this.props.location;

    return pageNumbers.map(number => {
      const queryString = status
        ? `?page=${number}&status=${status}`
        : `?page=${number}`;

      if (number === 1 && page === undefined) {
        return (
          <Cell
            number={number}
            className="active"
            key={number}
            to={`${pathname}${queryString}`}
          />
        );
      }

      return (
        <Cell
          number={number}
          className={Number(page) === number && "active"}
          key={number}
          to={`${pathname}${queryString}`}
        />
      );
    });
  };
  handlePageClick = ({ selected }) => {
    const { status, level } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    const { pathname } = this.props.location;
    const queryString =
      level && status
        ? `?page=${selected + 1}&status=${status}&level=${level}`
        : status
        ? `?page=${selected + 1}&status=${status}`
        : level
        ? `?page=${selected + 1}&level=${status}`
        : `?page=${selected + 1}`;

    const url = `${pathname}${queryString}`;
    this.props.history.push(url);
  };
  getPage = () => {
    const { page } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    if (page) {
      return Number(page) - 1;
    } else {
      return 0;
    }
  };
  render() {
    return (
      this.props.totalEntries > 20 && (
        <Container>
          <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.pageNumbers().length}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={this.handlePageClick}
            forcePage={this.getPage()}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active-number"}
          />
        </Container>
      )
    );

    // return <Container>{this.paginate(this.pageNumbers())}</Container>;
  }
}

export default Paginator;
