import React from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { Nav, Profile } from "./elements";
import logo from "./logo.svg";
import { ReactComponent as LogoutSVG } from "./logout.svg";
import { ReactComponent as ProfileSVG } from "./profile.svg";
import logout from "../../actions/logout";

const Dropdown = styled.div`
  width: 170px;
  position: absolute;
  top: 61px;
  right: 25px;
  border-radius: 6px;
  background: #fff;
  box-shadow: rgba(6, 44, 82, 0.1) 0px 0px 0px 1px,
    rgba(33, 43, 54, 0.08) 0px 2px 16px;
  font-size: 14px;
  font-weight: 400px;
  padding: 10px 0px;
  color: rgb(33, 43, 54);

  svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;

    g {
      fill: rgb(33, 43, 54);
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      cursor: pointer;

      padding: 10px 16px;

      &:hover {
        background-image: linear-gradient(
          rgba(223, 227, 232, 0.3),
          rgba(223, 227, 232, 0.3)
        );
      }
    }

    a,
    span {
      text-decoration: none;
      color: inherit;

      :hover {
        text-decoration: underline;
      }
    }
  }

  img {
    width: 20px;
    width: 20px;
    vertical-align: middle;
  }

  transition: opacity 0.4s ease-in-out;

  ${props =>
    props.show
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `};
`;

class TopNav extends React.Component {
  state = {
    profileDropdownActive: false
  };

  toggleProfileDropdown = () => {
    this.setState(prevState => ({
      profileDropdownActive: !prevState.profileDropdownActive
    }));
  };

  render() {
    const {
      user: { payload, fetched }
    } = this.props;

    return (
      <Nav>
        <div className="brand">
          <img className="logo" height="22" src={logo} alt="Busha" />
        </div>
        <div className="search-cont">
          <form className="search">
            <input type="search" />
          </form>
        </div>

        <Profile onClick={this.toggleProfileDropdown}>
          <div className="profile-img">
            {fetched && <img src={payload.avatar} alt="profile" />}
          </div>

          <div className="profile-summary">
            {fetched && (
              <h4>
                {payload.first_name} {payload.last_name}
              </h4>
            )}
            <p>Busha</p>
          </div>
        </Profile>
        <Dropdown
          onMouseLeave={this.toggleProfileDropdown}
          show={this.state.profileDropdownActive}
        >
          <ul>
            <li>
              <ProfileSVG />
              <Link to="/settings/profile">Your profile</Link>
            </li>

            <li onClick={this.props.logout}>
              <LogoutSVG />
              <span>Log out</span>
            </li>
          </ul>
        </Dropdown>
      </Nav>
    );
  }
}

export default connect(
  null,
  { logout }
)(TopNav);
