import { UPDATE_SINGLE_CUSTOMER, CLEAR_UPDATE } from "../actions/types";

const initialState = {
  fetching: false,
  fetched: false,
  error: {},
  resp: {}
};

const customer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_SINGLE_CUSTOMER.fulfilled:
      return {
        fetching: false,
        fetched: true,
        resp: payload.data
      };

    case UPDATE_SINGLE_CUSTOMER.pending:
      return {
        ...state,
        fetching: true,
        fetched: false
      };

    case UPDATE_SINGLE_CUSTOMER.rejected:
      return {
        ...state,
        error: payload,
        fetching: false,
        fetched: false
      };
    case CLEAR_UPDATE.default:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};

export default customer;
