import React from "react";
import styled, { css } from "styled-components";

import { LoaderBox, Rect } from "./index";
import { Card, CardBody } from "./elements";

const Box = styled.div`
  width: 59%;
  margin: 65px auto;

  @media (max-width: 1800px) {
    width: 68.5%;
  }
`;

const Container = styled.div`
  margin-top: 20px;
`;

const Body = styled.div`
  display: flex;
`;
const Left = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  padding-right: 10px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 10px;
`;

const TopLoaderBox = styled.div`
  height: 100px;
`;

const LCard = styled(Card)`
  margin-top: 15px;
`;

const LCardBody = styled(CardBody)`
  min-height: auto;
  height: ${props => (props.height ? props.height : "100px")};
  width: 100%;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #dfe3e8;
    `};
  ${props =>
    props.paddingTop &&
    css`
      padding-top: 20px;
    `};
`;

export default () => (
  <Box>
    <Container>
      <TopLoaderBox>
        <LoaderBox width={1000} height={100}>
          <Rect height="35" width="15%" />
          <Rect y={65} height="7" width="10%" />
        </LoaderBox>
      </TopLoaderBox>
      <Body>
        <Left>
          <LCard>
            <LCardBody height="38px">
              <LoaderBox width={1500} height={200}>
                <Rect height="15" width="100%" />
                <Rect y={45} height="15" width="100%" />
                <Rect y={90} height="15" width="80%" />
              </LoaderBox>
            </LCardBody>
          </LCard>
          <LCard>
            <LCardBody height="38px">
              <LoaderBox width={1500} height={200}>
                <Rect height="15" width="100%" />
                <Rect y={45} height="15" width="100%" />
                <Rect y={90} height="15" width="80%" />
              </LoaderBox>
            </LCardBody>
          </LCard>
          <LCard>
            <LCardBody height="38px">
              <LoaderBox width={1500} height={200}>
                <Rect height="15" width="100%" />
                <Rect y={45} height="15" width="100%" />
                <Rect y={90} height="15" width="80%" />
              </LoaderBox>
            </LCardBody>
          </LCard>
        </Left>
        <Right>
          <LCard>
            <LCardBody height="20px">
              <LoaderBox width={700} height={200}>
                <Rect height="15" width="100%" />
                <Rect y={45} height="15" width="80%" />
              </LoaderBox>
            </LCardBody>
            <LCardBody borderTop paddingTop height="35px">
              <LoaderBox width={700} height={200}>
                <Rect height="15" width="100%" />
                <Rect y={45} height="15" width="80%" />
              </LoaderBox>
            </LCardBody>
          </LCard>
          <LCard>
            <LCardBody height="20px">
              <LoaderBox width={700} height={200}>
                <Rect height="15" width="100%" />
                <Rect y={45} height="15" width="80%" />
              </LoaderBox>
            </LCardBody>
            <LCardBody borderTop paddingTop height="35px">
              <LoaderBox width={700} height={200}>
                <Rect height="15" width="100%" />
                <Rect y={45} height="15" width="80%" />
              </LoaderBox>
            </LCardBody>
          </LCard>
        </Right>
      </Body>
    </Container>
  </Box>
);
