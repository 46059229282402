import axios from "axios";
import types from "../types";
import { coinBarterApi } from "../../config";
import { toast } from "react-toastify";

const getTradesFulfilled = payload => ({
  type: types("get_trades").fulfilled,
  payload
});

const getTradesPending = () => ({
  type: types("get_trades").pending
});

const getOneTradeFulfilled = payload => ({
  type: types("get_one_trade").fulfilled,
  payload
});

export const getTrades = (
  page = 1,
  status = "all",
  callback
) => async dispatch => {
  const queryString =
    status === "all" ? `?page=${page}` : `?status=${status}&page=${page}`;

  try {
    dispatch(getTradesPending());
    const { data } = await axios.get(
      `${coinBarterApi}/fence/trades${queryString}`
    );

    dispatch(getTradesFulfilled(data));

    callback(data.meta.total_entries_size);
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const getOneTrade = tradeID => async dispatch => {
  try {
    const { data } = await axios.get(
      `${coinBarterApi}/fence/trades/${tradeID}`
    );

    dispatch(getOneTradeFulfilled(data));
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const adjustTrade = (tradeID, amount) => async dispatch => {
  try {
    await axios.post(`${coinBarterApi}/fence/trades/${tradeID}/adjust`, {
      amount
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
