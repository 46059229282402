import styled from "styled-components";

import DefaultCard from "../../../../common/Card";

export const Card = styled(DefaultCard)`
  margin: 10px;
  margin-left: 0px;
`;

export const Body = styled.div`
  padding: 20px;
  .title {
    font-weight: 600;
    font-size: 16px;
    border-bottom: 2px dotted #c4cdd5;
    display: inline;
  }
`;

export const Content = styled.div`
  padding: 16px 0px 20px;
`;

export const MetricItem = styled.div`
  display: flex;

  justify-content: space-between;

  h3 {
    font-size: 15px;
    color: #212b36;
  }

  .sub-metric {
    color: #637381;
  }

  &.metric-title {
    margin-top: 24px;
  }

  h3.metric-title {
    text-transform: uppercase;
    font-size: 12px;
    margin-left: 10px;
    font-weight: bold;
    margin: 0;
    /* margin-top: 10px; */
  }

  h3.convertion-rate {
    color: #212b36;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 10px;
  }
`;
