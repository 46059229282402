import styled from "styled-components";

const Pulser = styled.span`
  display: inline-block;
  margin: 0;
  padding: 0;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  border-radius: ${({ borderRadius }) => borderRadius};
  animation: pulse 1s infinite ease-in-out;

  @keyframes pulse {
    0% {
      background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
      background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
      background-color: rgba(165, 165, 165, 0.1);
    }
  }
`;

export default Pulser;
