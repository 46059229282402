import React from "react";

import { Card } from "../../components/common";
import {
  Container,
  ItemHeader,
  ItemSubHeader,
  ItemKeyValPair
} from "./elements";

function SummaryItem({ assetKey, summary }) {
  return (
    <Card>
      <Container>
        <ItemHeader>
          <h3>{assetKey}</h3>
        </ItemHeader>
        <ItemSubHeader>
          <h4>Metrics</h4>
        </ItemSubHeader>
        <ItemKeyValPair>
          <div className="key-box">
            <h4>Buy</h4>
            <h5>{summary.no_of_buys} Ads</h5>
          </div>
          <div className="val-box">
            <h4>
              {summary.buys.amount} {assetKey}
            </h4>
          </div>
        </ItemKeyValPair>
        <ItemKeyValPair>
          <div className="key-box">
            <h4>Sell</h4>
            <h5>{summary.no_of_sells} Ads</h5>
          </div>
          <div className="val-box">
            <h4>
              {summary.sells.amount} {assetKey}
            </h4>
          </div>
        </ItemKeyValPair>
        <ItemKeyValPair>
          <div className="key-box">
            <h4>Escrowed</h4>
          </div>
          <div className="val-box">
            <h4>
              {summary.ecrowed.amount} {assetKey}
            </h4>
          </div>
        </ItemKeyValPair>
      </Container>
    </Card>
  );
}

export default SummaryItem;
