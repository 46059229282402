import styled from "styled-components";

const BodyDataBox = styled.div`
  position: relative;
  .customer-view-icon svg {
    fill: #ffffff;
    background: transparent;
  }
  .customer-view-icon {
    position: relative;
    .tool-tip {
      text-align: center;
      width: 100px;
      padding: 2px 4px;
      display: block;
      position: absolute;
      top: -40px;
      left: -44px;
      background-color: rgba(0, 0, 0, 0.9);
      color: #ffffff;
      z-index: 1;
      border-radius: 4px;
      visibility: hidden;
      font-size: 12px;
    }
    .tool-tip::before {
      content: "";
      background-color: rgba(0, 0, 0, 0.9);
      padding: 4px;
      position: absolute;
      top: calc(100% - 4px);
      transform: rotate(45deg);
      left: calc(50% - 4px);
    }
    &:hover {
      .tool-tip {
        visibility: visible;
      }
    }
  }
  & > span {
    background-color: transparent;
    font-size: 0.7rem;
    font-weight: 400;
    color: #212b36;
  }
  & > span.has-customer-link {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      font-size: 0.7rem;
      font-weight: 400;
      color: #212b36;
    }
  }
  & > span.status-indicator {
    padding: 3px 10px;
    border: 2px solid white;
    border-radius: 15px;
  }

  & > span.success {
    color: #576675;
    background-color: #dfe3e8;
  }
  & > span.pending {
    background-color: #ffc58b;
    color: #212b36;
  }

  & > span.created {
    background-color: #ffea8a;
    color: #212b36;
  }
  & > span.cancelled {
    color: #d83e3e;
  }

  & > span.failed {
    background-color: #ee6d52;
  }

  & > span.strikeout {
    text-decoration: line-through;
  }
`;

export default BodyDataBox;
