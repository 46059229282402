import React, { Fragment } from "react";

import { Permissions, AllPermissions } from "./elements";
import Checkbox from "../../common/Checkbox";

const AdminAccess = ({
  permissions,
  fullAccess,
  first_name,
  last_name,
  generalPermissions = [],
  settingsPermissions = [],
  handlePermissionsChange
}) => (
  <Permissions>
    <h3 className="title">Admin access</h3>

    <AllPermissions>
      <h3 className="subtitle">Customize permissions</h3>

      <p className="description">
        Enable or restrict {first_name}'s access to various parts of this site.
      </p>

      <Checkbox
        checked={fullAccess}
        onChange={handlePermissionsChange}
        name="fullAccess"
        label={`${first_name} ${last_name} has full permissions`}
      />

      {!fullAccess && (
        <Fragment>
          <h4 style={{ paddingTop: "5px", fontWeight: 600, marginTop: "16px" }}>
            Edit Permissions
          </h4>
          <div className="all-checkbox-container">
            <div className="checkboxes">
              <p className="general">General</p>
              <ul className="checkbox-parent">
                {generalPermissions.map(permission => (
                  <li key={permission}>
                    <Checkbox
                      label={permission}
                      checked={permissions[permission]}
                      name={permission}
                      onChange={handlePermissionsChange}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div className="checkboxes">
              <p className="general">Settings</p>
              <ul className="checkbox-parent">
                {settingsPermissions.map(permission => (
                  <li key={permission}>
                    <Checkbox
                      label={permission}
                      checked={permissions[permission]}
                      name={permission}
                      onChange={handlePermissionsChange}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Fragment>
      )}
    </AllPermissions>
  </Permissions>
);

export default AdminAccess;
