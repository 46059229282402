import types from "../actions/types";

const initialState = {
  transfer: {}
};

const coinBarterOneTransfer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types("get_one_transfer").fulfilled:
    case types("complete_transfer").fulfilled:
      return {
        ...state,
        transfer: payload
      };

    default:
      return state;
  }
};

export default coinBarterOneTransfer;
