import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';

import { moneyFormatter } from '../../../../helpers/util';

export default ({ payout, type, children }) => {
	console.log(payout);
	return (
		<Container>
			<Section>{children}</Section>
		</Container>
	);
};

const Container = styled.div`
	background: #fff;
	box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 20px;
	border-radius: 3px;
	min-height: 100px;
	padding: 10px 0px 20px;
`;

const Section = styled.div`
	div.line {
		padding-top: 5px;
	}
`;
