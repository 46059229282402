import axios from "axios";
import { toast } from "react-toastify";

import {
  GET_PROVIDERS,
  GET_PROVIDER,
  CREATE_PROVIDER,
  UPDATE_PROVIDER
} from "./types";
import { apiBaseURL } from "../config";

const getProvidersAction = type => ({
  type: GET_PROVIDERS.default,
  payload: axios.get(`${apiBaseURL}/v1/providers/${type}`)
});

const getProviderAction = (providerType, providerId) => ({
  type: GET_PROVIDER.default,
  payload: axios.get(`${apiBaseURL}/v1/providers/${providerType}/${providerId}`)
});

const updateProviderAction = (providerType, providerId, data) => ({
  type: UPDATE_PROVIDER.default,
  payload: axios.put(
    `${apiBaseURL}/v1/providers/${providerType}/${providerId}`,
    data
  )
});

const createProviderAction = (providerType, data) => ({
  type: CREATE_PROVIDER.default,
  payload: axios.post(`${apiBaseURL}/v1/providers/${providerType}`, data) //Promise.resolve({ data: { message: "success" } })
});

export const getProviders = type => async dispatch =>
  dispatch(getProvidersAction(type));

export const getProvider = (providerType, providerId) => dispatch =>
  dispatch(getProviderAction(providerType, providerId));

export const updateProvider = (
  providerType,
  providerId,
  data,
  cb
) => dispatch =>
  dispatch(updateProviderAction(providerType, providerId, data))
    .then(res => {
      toast.success(res.value.data.message, {
        onClose: () => (cb ? cb(res) : null)
      });
    })
    .catch(err => {
      toast.error(err.response.data.message);
    });

export const createProvider = (providerType, data, cb) => dispatch =>
  dispatch(createProviderAction(providerType, data))
    .then(res => {
      // console.log({ res });
      return toast.success(res.value.data.message, {
        onClose: () => (cb ? cb(res.value.data) : null)
      });
    })
    .catch(err => {
      console.log({ err });
      toast.error(err.response.data.message);
    });
