import React from "react";

import RadioButton from "../../../common/RadioButton";
import { FormSection, FormSectionInfo } from "./common";
import { Container, Section, FormGroup } from "./formElements";

const TagsForm = ({ form, handleInputChange }) => {
  return (
    <FormSection>
      <FormSectionInfo>
        <h3>Tags</h3>
      </FormSectionInfo>
      <Container>
        <Section>
          <section>
            <FormGroup>
              <label htmlFor="tag_name">Tag Name</label>
              <input
                name="tag_name"
                value={form.tag_name}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="empty_tag_message">Empty Tag Message</label>
              <textarea
                name="empty_tag_message"
                rows="2"
                id="empty_tag_message"
                value={form.empty_tag_message}
                onChange={handleInputChange}
              />
            </FormGroup>
          </section>
        </Section>
        <Section borderTop>
          <section>
            <FormGroup>
              <label htmlFor="use_tag">Use Tag</label>
              <RadioButton
                name="use_tag"
                value="true"
                label="True"
                checked={form.use_tag.toString() === "true"}
                onChange={handleInputChange}
              />
              <RadioButton
                name="use_tag"
                value="false"
                label="False"
                checked={form.use_tag.toString() === "false"}
                onChange={handleInputChange}
              />
            </FormGroup>
          </section>
        </Section>
      </Container>
    </FormSection>
  );
};
export default TagsForm;
