import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { createStore, applyMiddleware, compose } from "redux";

import reducers from "./reducers";
import asyncErrorCatch from "./middleware/asyncErrorCatch";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(asyncErrorCatch, thunk, promise))
);

export default store;
