import { VERIFICATIONS_DETAIL, UPDATE_VERIFICATION_DETAIL } from "./types";
import axios from "axios";

import { apiBaseURL } from "../config";
import { toast } from "react-toastify";

const action = payload => ({
  type: VERIFICATIONS_DETAIL.default,
  payload
});

const updateAction = payload => ({
  type: UPDATE_VERIFICATION_DETAIL.default,
  payload
});

const getVerificationDetail = id => dispatch => {
  const payload = axios.get(`${apiBaseURL}/v1/verification/${id}`);
  dispatch(action(payload)).catch(
    err => console.log({ err })
    // toast.error(err.response.data.message)
  );
};

export const updateVerificationDetail = ({
  id,
  data,
  loading,
  cb
}) => dispatch => {
  const payload = axios.post(
    `${apiBaseURL}/v1/verification/${id}/status`,
    data
  );
  loading && loading();
  dispatch(updateAction(payload))
    .then(() => {
      cb && cb();
      loading && loading();
      const payload = axios.get(`${apiBaseURL}/v1/verification/${id}`);
      dispatch(action(payload));
    })
    .catch(err => {
      console.log({ err });
      loading && loading();
      err.response &&
        err.response.data &&
        err.response.data.message &&
        toast.error(err.response.data.message);
    });
};
export default getVerificationDetail;
