import {
  GET_CURRENCIES,
  GET_CURRENCY,
  UPDATE_CURRENCY,
  CREATE_CURRENCY
} from "../actions/types";

const initialState = {
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  creating: false,
  created: false,
  error: "",
  payload: {},
  meta: {},
  currencies: [],
  selectedCurrency: null
};

const currencies = (state = initialState, { type, payload }) => {
  const cases = {
    [GET_CURRENCIES.fulfilled]: () => ({
      ...state,
      fetching: false,
      fetched: true,
      payload: payload,
      meta: payload.data.meta,
      currencies: payload.data.currencies
    }),
    [GET_CURRENCIES.pending]: () => ({
      ...state,
      fetched: false,
      fetching: true
    }),
    [GET_CURRENCIES.rejected]: () => ({
      ...state,
      fetched: false,
      fetching: false,
      error: payload
    }),
    // get
    [GET_CURRENCY.fulfilled]: () => ({
      ...state,
      fetching: false,
      fetched: true,
      selectedCurrency: payload.data
    }),
    [GET_CURRENCY.pending]: () => ({
      ...state,
      fetched: false,
      fetching: true
    }),
    [GET_CURRENCY.rejected]: () => ({
      ...state,
      fetched: false,
      fetching: false,
      error: payload
    }),
    // create
    [CREATE_CURRENCY.fulfilled]: () => ({
      ...state,
      creating: false,
      created: true,
      payload: payload.data
    }),
    [CREATE_CURRENCY.pending]: () => ({
      ...state,
      created: false,
      creating: true
    }),
    [CREATE_CURRENCY.rejected]: () => ({
      ...state,
      created: false,
      creating: false,
      error: payload
    }),
    // update
    [UPDATE_CURRENCY.fulfilled]: () => ({
      ...state,
      updating: false,
      updated: true,
      selectedCurrency: payload.data
    }),
    [UPDATE_CURRENCY.pending]: () => ({
      ...state,
      updated: false,
      updating: true
    }),
    [UPDATE_CURRENCY.rejected]: () => ({
      ...state,
      updated: false,
      updating: false,
      error: payload
    }),
    DEFAULT: () => state
  };

  return cases[type] ? cases[type]() : cases["DEFAULT"]();
};

export default currencies;
