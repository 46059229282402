import React from "react";

import CustomizableLoader from "../../common/CustomizableLoader";
import { AccountCard } from "./elements";

const Loader = () => (
  <div>
    <AccountCard>
      <CustomizableLoader width="20%" height="10px" borderRadius="3px" />
      <br />
      <br />
      <div className="details">
        <div className="avatar">
          <CustomizableLoader width="100%" height="100%" borderRadius="100%" />
        </div>

        <div className="account-info">
          <div>
            <CustomizableLoader width="50px" height="10px" borderRadius="3px" />
            <span />
          </div>
        </div>
      </div>
    </AccountCard>

    {[1, 2, 3].map(value => (
      <AccountCard key={value}>
        <CustomizableLoader width="20%" height="10px" borderRadius="3px" />

        <div className="details">
          <div className="account-info">
            <div>
              <span>
                <br />

                <CustomizableLoader
                  width="400px"
                  height="10px"
                  borderRadius="3px"
                />
                <br />
                <CustomizableLoader
                  width="450px"
                  height="10px"
                  borderRadius="3px"
                />
              </span>
            </div>
          </div>
        </div>
      </AccountCard>
    ))}
  </div>
);

export default Loader;
