import React, { Fragment, Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Case from 'case';
import Checkbox from '../../../common/Checkbox';
import ActionDropdown from '../../../common/Dropdowns/ActionDropdown';
import { shortenString, dayMonthYear, moneyFormatter } from '../../../../helpers/util';

import {
	Table,
	TableHead,
	TableHeadData,
	TableHeadCheckboxBox,
	CheckboxLeft,
	TableHeadRow,
	TableBody,
	TableRow,
	TableData,
	TableDataBox,
	Status,
} from './common';

class TradesTable extends Component {
	state = {
		selectAll: false,
	};

	render() {
		const { selectAll } = this.state;
		const {
			coinBarterTrades: { trades, meta },
		} = this.props;

		return (
			<Table>
				<TableHead>
					<TableHeadRow>
						{/* <TableHeadData colSpan={selectAll ? 7 : 1} hasSelect>
              <TableHeadCheckboxBox>
                <CheckboxLeft selectedAll={selectAll}>
                  <Checkbox
                    checked={selectAll}
                    onChange={this._onSelectAllToggled}
                  />
                  {!selectAll ? (
                    <FontAwesomeIcon
                      icon="caret-down"
                      onClick={this._onSelectAllToggled}
                    />
                  ) : (
                    <span>1 payout selected</span>
                  )}
                </CheckboxLeft>
                {selectAll && <ActionDropdown />}
              </TableHeadCheckboxBox>
            </TableHeadData> */}

						{/* {!selectAll && ( */}
						<Fragment>
							<TableHeadData>
								<span>Trade</span>
							</TableHeadData>
							<TableHeadData>
								<span>Date</span>
							</TableHeadData>
							<TableHeadData>
								<span>Email</span>
							</TableHeadData>
							<TableHeadData>
								<span>Status</span>
							</TableHeadData>
							<TableHeadData>
								<span>Fulfilment Status</span>
							</TableHeadData>
							<TableHeadData>
								<span>Amount Received</span>
							</TableHeadData>
							<TableHeadData>
								<span>Total</span>
							</TableHeadData>
						</Fragment>
						{/* )} */}
					</TableHeadRow>
				</TableHead>
				<TableBody>
					{trades.map(trade => (
						<TableRow key={trade.id}>
							{/* <TableData hasSelect>
								<Checkbox />
							</TableData> */}
							<TableData>
								<Link to={`/coinbarter/trades/${trade.id}`}>
									<TableDataBox>
										<span>#{Case.upper(shortenString(trade.id))}</span>
									</TableDataBox>
								</Link>
							</TableData>
							<TableData>
								<Link to={`/coinbarter/trades/${trade.id}`}>
									<TableDataBox>
										<span>{dayMonthYear(trade.created_at)}</span>
									</TableDataBox>
								</Link>
							</TableData>
							<TableData>
								<Link to={`/coinbarter/trades/${trade.id}`}>
									<TableDataBox>
										<span>{trade.customer.email}</span>
									</TableDataBox>
								</Link>
							</TableData>
							<TableData>
								<Link to={`/coinbarter/trades/${trade.id}`}>
									<TableDataBox>
										<Status status={trade.status}>{Case.capital(trade.status)}</Status>
									</TableDataBox>
								</Link>
							</TableData>
							<TableData>
								<Link to={`/coinbarter/trades/${trade.id}`}>
									<TableDataBox>
										<Status status={trade.fulfilment_status}>
											{Case.capital(trade.fulfilment_status)}
										</Status>
									</TableDataBox>
								</Link>
							</TableData>

							<TableData>
								<Link to={`/coinbarter/trades/${trade.id}`}>
									<TableDataBox>
										<span>
											{trade.amount_received} {trade.asset.code}
										</span>
									</TableDataBox>
								</Link>
							</TableData>

							<TableData>
								<Link to={`/coinbarter/trades/${trade.id}`}>
									<TableDataBox>
										<span>
											<strong>{moneyFormatter.format(trade.total / 100)}</strong>
										</span>
									</TableDataBox>
								</Link>
							</TableData>
						</TableRow>
					))}
				</TableBody>
			</Table>
		);
	}
}

export default TradesTable;
