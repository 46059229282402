import React from "react";
import propTypes from "prop-types";
import { Chat } from "@progress/kendo-react-conversational-ui";

import { db } from "./config";
import Container from "./Blocks/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CollapsableContainer from "./Blocks/CollapsableContainer";

function MessageTemplate(props) {
  console.log({ props }, "MessageTemplate");
  if (props.item.type === "image")
    return (
      <div className="k-bubble">
        <img
          src={props.item.text}
          draggable={false}
          style={{ height: "100px" }}
          alt="attachment"
        />
      </div>
    );
  return (
    <div className="k-bubble">
      <div>{props.item.text}</div>
    </div>
  );
}

class App extends React.Component {
  demoChannel = "iF9e4umgaNtD6TI7q01T";
  constructor(props) {
    super(props);
    this.user = {
      id: 1
    };
    this.state = {
      messages: [],
      show: false
    };
  }
  toggleShow = () => {
    if (!this.props.channel) return;
    this.setState(prevState => ({ show: !prevState.show }));
  };
  addNewMessage = event => {
    this.addMessage(event.message);
  };

  updateLocalMessages = message => {
    const messages = [...this.state.messages, ...message];
    messages.sort((a, b) => a.timestamp - b.timestamp);
    this.setState({ messages });
  };

  getUser = () => {
    this.user.id = this.props.user_id;
    this.user.name = this.props.name;
  };

  getMessages = () => {
    db.doc(this.props.channel || this.demoChannel)
      .collection("thread")
      .onSnapshot(snapshot => {
        let messages = [];
        snapshot.docChanges().forEach(change => {
          const data = change.doc.data();
          if (data) {
            const message = this.handleMessageUpdates(data);
            messages.push(message);

            if (this.user.id !== message.author.id) {
              this.playAudio();
            }
          }
        });

        this.updateLocalMessages(messages);
      });
  };

  addMessage = ({ author, text, timestamp }) => {
    const data = {
      content: text,
      created: timestamp,
      senderID: author.id,
      senderName: author.name
    };

    db.doc(this.props.channel || this.demoChannel)
      .collection("thread")
      .add(data)
      .catch(function(error) {
        console.error("Error adding document: ", error);
      });
  };

  handleMessageUpdates = ({ content, created, senderID, senderName, type }) => {
    const author = {
      id: senderID,
      name: senderName,
      avatarUrl:
        this.user.id === senderID
          ? "https://www.whatsappprofiledpimages.com/wp-content/uploads/2018/07/funny-profile-pic5.jpg"
          : "https://res.cloudinary.com/dwoc5fknz/image/upload/v1536169098/Avatar.svg"
    };

    const text = content;
    const timestamp = new Date(created.seconds * 1000);
    // const attachments =
    //   type === "image"
    //     ? [
    //         {
    //           content,
    //           contentType: "image/"
    //         }
    //       ]
    //     : [];
    const message = { author, text, timestamp, type };
    // console.log(message);
    return message;
  };

  componentDidMount() {
    this.getUser();
    if (this.props.channel) {
      this.getMessages();
    }
  }
  playAudio = () => {
    if (this.sound) {
      try {
        this.sound.play();
      } catch (err) {
        console.log({ err });
      }
    }
  };
  render() {
    const { show } = this.state;
    const btnTxt = show ? "Hide " : "Show";
    return (
      <CollapsableContainer expand={show}>
        <div className="btn-cont">
          <button onClick={this.toggleShow}>
            {btnTxt} Chat{" "}
            <FontAwesomeIcon icon="comment-dots" color="#000639" />
          </button>
        </div>
        {show && (
          <Container>
            <link
              rel="stylesheet"
              href="https://unpkg.com/@progress/kendo-theme-material@latest/dist/all.css"
            />
            <audio
              ref={sound => {
                this.sound = sound;
              }}
            >
              <source
                src="https://res.cloudinary.com/dwoc5fknz/video/upload/v1558542764/notification_sounds/stairs.mp3"
                type="audio/mpeg"
              />
            </audio>

            <div>
              {this.props.channel && (
                <Chat
                  user={this.user}
                  messages={this.state.messages}
                  onMessageSend={this.addNewMessage}
                  width={"100%"}
                  messageTemplate={MessageTemplate}
                  attachmentTemplate={AttachmentTemplate}
                />
              )}
            </div>
          </Container>
        )}
      </CollapsableContainer>
    );
  }
}

function AttachmentTemplate(props) {
  let attachment = props.item;
  if (attachment.contentType.match("^image/")) {
    return (
      <div style={{ padding: "10px 0px 10px 10px" }}>
        <img
          src={attachment.content}
          draggable={false}
          style={{ height: "100px" }}
          alt="attachment"
        />
      </div>
    );
  } else if (attachment.contentType === "text/plain") {
    return attachment.content;
  } else {
    return null;
  }
}

export default App;

App.propTypes = {
  user_id: propTypes.string.isRequired,
  name: propTypes.string.isRequired
};
App.defaultProps = {
  user_id: "eve",
  name: "admin"
};
