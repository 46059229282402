import React from "react";

import { ReactComponent as SucessSVG } from "../../assets/success.svg";
import { ReactComponent as PendingSVG } from "../../assets/pending.svg";
import { ReactComponent as ProcessingSVG } from "../../assets/processin.svg";

const statuses = {
  completed: <SucessSVG />,
  pending: <PendingSVG />,
  processing: <ProcessingSVG />
};

export default statuses;
