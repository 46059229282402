import React, { Fragment } from "react";
import { connect } from "react-redux";
import { ReactComponent as ExportSVG } from "./images/export.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Container,
  Header,
  Body,
  Card,
  HeaderLeft,
  HeaderText,
  HeaderExportBtn,
  CardHeader,
  HeaderTextBox,
  HeaderExportBtnBox,
  TabsBtnBox,
  TabBtnList,
  TabBtnItem,
  TabBtnLink,
  CardBody,
  SearchAndFilterBox,
  FilterBox,
  SearchBox,
  SearchIconBox,
  SearchInput,
  TableBox,
  CContainer,
  UploadBtnsBox,
  FilterAndSortBox,
  FilterSearchBox,
  SortBox,
  SortLabel,
  SortDropdownBox,
  CDropdownSelect
} from "./components/common";
import DepositsTable from "./components/DepositsTable";
import FilterDropdown from "../../common/Dropdowns/FilterDropdown";
import FullTableLoader from "../../common/SVGLoaders/FullTableLoader";
import { fetchCardDeposits } from "../../../actions/deposits";
import qs from "qs";
import { isEqual } from "lodash";
import Paginator from "../../common/Paginator";
import { isActive, getQueryString } from "../../../helpers/util";

class Fiat extends React.Component {
  state = {
    totalEntries: 1,
    perPage: 20
  };

  componentDidMount() {
    document.title = "Card Deposits";
    this.getAllDeposits();
  }

  getAllDeposits = () => {
    const { page = 1, status = "all", channel } = qs.parse(
      this.props.location.search,
      {
        ignoreQueryPrefix: true
      }
    );

    this.props.fetchCardDeposits(
      page,
      status,
      totalEntries => {
        this.setState({
          totalEntries
        });
      },
      channel
    );
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      this.getAllDeposits();
    }
  }

  handleFilter = ({ target: { name, value } }) => {
    const { channel, status, page } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    const queryObject = {
      channel,
      page,
      status,
      [name]: value
    };

    const query = getQueryString(queryObject);

    this.props.history.push(`/deposits/fiat${query}`);
  };

  linkResponse = status => {
    const target = { name: "status", value: status };
    const resp = { target };

    this.handleFilter(resp);
  };

  render() {
    const {
      deposits,
      history: {
        location: { search }
      }
    } = this.props;
    const { totalEntries, perPage } = this.state;
    const { channel } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    const sort = channel || "";
    return (
      <Container>
        {deposits.fetching ? (
          <FullTableLoader />
        ) : (
          <Fragment>
            <Header>
              <HeaderLeft>
                <HeaderTextBox>
                  <HeaderText>Fiat</HeaderText>
                </HeaderTextBox>
              </HeaderLeft>
            </Header>
            <Body>
              <Card>
                <CardHeader>
                  <TabsBtnBox>
                    <TabBtnList>
                      <TabBtnItem>
                        <TabBtnLink
                          onClick={() => {
                            this.linkResponse();
                          }}
                          active={isActive(undefined, search)}
                        >
                          All
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink
                          onClick={() => {
                            this.linkResponse("success");
                          }}
                          active={isActive("success", search) && "active-tab"}
                        >
                          Success
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink
                          onClick={() => this.linkResponse("abandoned")}
                          active={isActive("abandoned", search) && "active-tab"}
                        >
                          Abandoned
                        </TabBtnLink>
                      </TabBtnItem>
                    </TabBtnList>
                  </TabsBtnBox>
                </CardHeader>
                <CardBody>
                  {/* <SearchAndFilterBox> */}
                  {/* <FilterBox>
											<FilterDropdown />
										</FilterBox> */}
                  {/* <SearchBox>
                      <SearchIconBox>
                        <FontAwesomeIcon icon="search" />
                      </SearchIconBox>
                      <SearchInput placeholder="Search Payouts" />
                    </SearchBox>
                  </SearchAndFilterBox> */}
                  <FilterAndSortBox>
                    <FilterSearchBox>
                      <SearchAndFilterBox>
                        <SearchBox>
                          <SearchIconBox>
                            <FontAwesomeIcon icon="search" />
                          </SearchIconBox>
                          <SearchInput placeholder="Search Payouts" />
                        </SearchBox>
                      </SearchAndFilterBox>
                    </FilterSearchBox>
                    <SortBox>
                      <SortLabel>
                        <span>Sort By</span>
                      </SortLabel>
                      <SortDropdownBox>
                        <CDropdownSelect
                          name="channel"
                          value={sort}
                          onChange={this.handleFilter}
                        >
                          <option value="card">Card</option>
                          <option value="bank_transfer">Bank Transfer</option>
                        </CDropdownSelect>
                      </SortDropdownBox>
                    </SortBox>
                  </FilterAndSortBox>
                  <TableBox>
                    <DepositsTable type="fiat" deposits={deposits.card} />
                  </TableBox>
                </CardBody>
              </Card>
            </Body>

            {totalEntries > 20 && (
              <Paginator
                totalEntries={totalEntries}
                perPage={perPage}
                {...this.props}
              />
            )}
          </Fragment>
        )}
      </Container>
    );
  }
}

function mapStateToProps({ deposits }) {
  return {
    deposits
  };
}

export default connect(
  mapStateToProps,
  { fetchCardDeposits }
)(Fiat);
