import React from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import format from "date-fns/format";
import Case from "case";

import {
  Container,
  Body,
  Header,
  NextPrev,
  ActiontBtnBox,
  ActionBtnsBox,
  ReferenceAndDateBox,
  PebbleStatus
} from "./components/infoElements";
import { ReactComponent as BackSVG } from "./svgs/back.svg";
import { ReactComponent as ArrowSVG } from "./svgs/arrow.svg";
import { ReactComponent as PrintSVG } from "./svgs/print.svg";
import ActionDropdown from "./components/ActionDropdown";
import {
  fetchTransactionInfo,
  finalizeTransaction
} from "../../../actions/transactions";
import TransactionInfoLoader from "../../common/SVGLoaders/InfoLoader";
import CustomerCard from "./components/CustomerCard";
import ProductBreakdown from "./components/ProductBreakdown";
import TransactionInfoCard from "./components/TransactionInfoCard";
import ChannelCard from "./components/ChannelCard";

class TransactionDetail extends React.Component {
  state = {
    loading: false
  };
  componentDidMount() {
    const {
      match: {
        params: { transactionId }
      },
      fetchTransactionInfo
    } = this.props;
    fetchTransactionInfo(transactionId);
  }
  onToggleActivity = () => {
    this.setState(prevState => ({ loading: !prevState.loading }));
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props);
  }

  onFinalize = () => {
    const {
      match: {
        params: { transactionId }
      }
    } = this.props;
    this.onToggleActivity();
    this.props.finalizeTransaction(
      transactionId,
      this.props.fetchTransactionInfo,
      this.onToggleActivity
    );
  };
  render() {
    const {
      transaction: { transaction, fetching }
    } = this.props;
    const { loading } = this.state;
    return fetching || !transaction ? (
      <TransactionInfoLoader />
    ) : (
      <Container>
        <Header>
          <nav>
            <a
              className="back-button"
              href="#goback"
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              <BackSVG className="svg" />
              <span>Transactions</span>
            </a>

            <div>
              <NextPrev>
                <ArrowSVG className="arrow-left" />
              </NextPrev>
              <NextPrev>
                <ArrowSVG className="arrow-right" />
              </NextPrev>
            </div>
          </nav>
        </Header>
        <ReferenceAndDateBox>
          <h1>#{transaction.reference}</h1>
          <span>
            {format(transaction.created_at, "MMMM D, YYYY [at] hh:mm a")}
          </span>
          <PebbleStatus status={transaction.status}>
            <span className="dot" />
            <span>{Case.capital(transaction.status)}</span>
          </PebbleStatus>
        </ReferenceAndDateBox>
        <ActionBtnsBox>
          <ActiontBtnBox>
            <PrintSVG />
            <span>Print</span>
          </ActiontBtnBox>
          <ActionDropdown />
        </ActionBtnsBox>
        <Body>
          <div>
            <ProductBreakdown
              loading={loading}
              transaction={transaction}
              onFinalize={this.onFinalize}
            />
            <TransactionInfoCard transaction={transaction} />
          </div>
          <div>
            <CustomerCard customer={transaction.user} />
            <ChannelCard transaction={transaction} />
          </div>
        </Body>
      </Container>
    );
  }
}

function mapStateToProps({ transaction }) {
  return { transaction };
}

export default connect(
  mapStateToProps,
  { fetchTransactionInfo, finalizeTransaction }
)(TransactionDetail);
