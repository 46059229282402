import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";
import format from "date-fns/format";
import { ToastContainer } from "react-toastify";
import Case from "case";

import DepositInfo from "./components/DepositInfo";
import {
  Container,
  Body,
  Header,
  NextPrev,
  ActiontBtnBox,
  PayoutActionBtnsBox,
  ReferenceAndDateBox
} from "./components/common";
import { ReactComponent as BackSVG } from "../assets/back.svg";
import { ReactComponent as ArrowSVG } from "../assets/arrow.svg";
import { ReactComponent as PrintSVG } from "../assets/print.svg";
// import ActionDropdown from "./components/ActionDropdown";
// import CustomerCard from "./components/CustomerCard";
import TradeDetailLoader from "../../../common/SVGLoaders/InfoLoader";
import AccountCard from "./components/AccountCard";
import Modal from "../../../common/Modal";
import { getOneTrade, adjustTrade } from "../../../../actions/coinbarter/trade";
import CustomerCard from "./components/CustomerCard";
import PageFooter from "./components/PageFooter";
import { Status } from "../components/common";
import AdjustCard from "./components/AdjustCard";
import EditOrder from "./components/EditOrder";
import { Spinner } from "react-activity";

class TradeDetails extends Component {
  state = {
    isLoading: true,
    loading: false,
    modalActive: false,
    debit_account: false,
    refunding: false,
    amountToAdjust: 0,
    deposit: {
      amount: 0
    }
  };

  toggleModal = () => {
    this.setState(prevState => {
      return {
        modalActive: !prevState.modalActive
      };
    });
  };

  handleChange = ({ target: { value, name } }) => {
    this.setState({
      deposit: {
        [name]: value
      }
    });
  };

  handleCheck = ({ target: { checked } }) => {
    this.setState({
      debit_account: checked
    });
  };

  handleAdjust = amount => {
    this.setState({
      amountToAdjust: amount
    });
  };

  componentDidMount() {
    const {
      match: {
        params: { type, tradeID }
      },
      getOneTrade
    } = this.props;

    getOneTrade(tradeID).then(() => this.setState({ isLoading: false }));
  }

  render() {
    const { isLoading } = this.state;

    return isLoading ? (
      <TradeDetailLoader />
    ) : (
        <Container>
          <ToastContainer
            closeButton={false}
            autoClose={2000}
            hideProgressBar={true}
          />
          <Header>
            <nav>
              <Link className="back-button" to="/coinbarter/trades">
                <BackSVG className="svg" />
                <span>All trades</span>
              </Link>
              <div>
                <NextPrev>
                  <ArrowSVG className="arrow-left" />
                </NextPrev>
                <NextPrev>
                  <ArrowSVG className="arrow-right" />
                </NextPrev>
              </div>
            </nav>
          </Header>
          <ReferenceAndDateBox>
            <h1>#234232</h1>
            <span>
              {format(this.props.coinBarterOneTrade.trade.created_at, 'MMMM D, YYYY [at] HH:mma')}</span>
            &nbsp;
          <Status status={this.props.coinBarterOneTrade.trade.status}>
              {Case.capital(this.props.coinBarterOneTrade.trade.status)}
            </Status>
          </ReferenceAndDateBox>
          <PayoutActionBtnsBox>
            <ActiontBtnBox>
              <PrintSVG />
              <span>Print</span>
            </ActiontBtnBox>
            {/* <ActionDropdown /> */}
          </PayoutActionBtnsBox>
          <Body>
            <div>
              <AccountCard
                history={this.props.history}
                trade={this.props.coinBarterOneTrade.trade}
              />
              <AdjustCard
                adjustTrade={this.props.adjustTrade}
                history={this.props.history}
                trade={this.props.coinBarterOneTrade.trade}
              />
              <DepositInfo trade={this.props.coinBarterOneTrade.trade} />
            </div>
            <div>
              <CustomerCard trade={this.props.coinBarterOneTrade.trade} />
            </div>
          </Body>
          <PageFooter toggleModal={this.toggleModal} />

          {this.state.modalActive && (
            <Modal
              meta={{
                width: "620px",
                buttonType: "blue"
              }}
              data={{
                title: "Edit order",
                loading: this.state.loading,
                buttonTxt: this.state.loading ? (
                  <Spinner color="white" />
                ) : (
                    "Confirm"
                  ),
                body: (
                  <EditOrder
                    handleAdjust={this.handleAdjust}
                    trade={this.props.coinBarterOneTrade.trade}
                  />
                )
              }}
              actions={{
                toggleModal: this.toggleModal,
                handleClick: () => {
                  this.setState({
                    loading: true
                  });
                  this.props
                    .adjustTrade(
                      this.props.coinBarterOneTrade.trade.id,
                      this.state.amountToAdjust
                    )
                    .then(() => {
                      window.location.reload();
                    });
                }
              }}
            />
          )}
        </Container>
      );
  }
}

function mapStateToProps({ coinBarterOneTrade }) {
  return { coinBarterOneTrade };
}

export default connect(
  mapStateToProps,
  { getOneTrade, adjustTrade }
)(TradeDetails);
