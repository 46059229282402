import { subDays } from "date-fns";
import moment from "moment";

const dateUtil = (date = new Date()) => {
  const originalDate = date;
  const deductDays = days => {
    date = subDays(originalDate, days);
  };

  return {
    today() {
      date = originalDate;

      return this;
    },
    yesterday() {
      deductDays(1);

      return this;
    },
    twoDaysAgo() {
      deductDays(2);

      return this;
    },
    oneWeekAgo() {
      deductDays(7);

      return this;
    },
    thirtyDaysAgo() {
      deductDays(30);

      return this;
    },
    threeMonthsAgo() {
      deductDays(90);

      return this;
    },
    toObject() {
      return moment(date);
    },
    get value() {
      return date;
    }
  };
};

export default dateUtil;
