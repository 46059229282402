import styled, { keyframes } from "styled-components";

const k_loading_animation = keyframes`
  from{
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
`;

const Container = styled.section`
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-height: 80px;
  box-sizing: border-box;
  padding: 30px 0;
  max-height: 660px;
  padding-top: 5px;
  .k-i-loading.k-example-loading {
    font-size: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(63, 81, 181);
  }

  .k-i-loading.k-example-loading::before,
  .k-i-loading.k-example-loading::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    content: "";
    box-sizing: inherit;
    border-radius: 50%;
    border-width: 0.05em;
    border-style: solid;
    border-color: currentColor;
    border-top-color: transparent;
    border-bottom-color: transparent;
    background-color: transparent;
  }
  .k-icon.k-i-loading.k-example-loading::before,
  .k-icon.k-i-loading::after {
    content: "";
  }

  .k-i-loading.k-example-loading::before {
    margin-top: -0.5em;
    margin-left: -0.5em;
    width: 1em;
    height: 1em;
    animation: ${k_loading_animation} 0.7s linear infinite;
  }

  .k-i-loading.k-example-loading::after {
    margin-top: -0.25em;
    margin-left: -0.25em;
    width: 0.5em;
    height: 0.5em;
    animation: ${k_loading_animation} reverse 1.4s linear infinite;
  }

  .example-wrapper {
    min-height: 280px;
    align-content: flex-start;
  }
  .example-wrapper p,
  .example-col p {
    margin: 20px 0 10px;
  }
  .example-wrapper p:first-child,
  .example-col p:first-child {
    margin-top: 0;
  }
  .example-col {
    display: inline-block;
    vertical-align: top;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .example-config {
    margin: 0 0 20px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.08);
  }
  .event-log {
    margin: 0;
    padding: 0;
    max-height: 100px;
    overflow-y: auto;
    list-style-type: none;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background-color: white;
  }
  .event-log li {
    margin: 0;
    padding: 0.3em;
    line-height: 1.2em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  .event-log li:last-child {
    margin-bottom: -1px;
  }
  .k-chat {
    max-width: 100%;
  }
`;

export default Container;
