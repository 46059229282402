import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import format from "date-fns/format";

import { EmptyBox } from "./common";
import { ReactComponent as EmptyBoxSVG } from "../svgs/empty-box.svg";
import { ReactComponent as ArrowSVG } from "../svgs/arrow.svg";

const Container = styled.div`
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  margin-top: 20px;
  /* min-height: 400px; */
  padding: 10px 0px;
`;

const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 5px;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;

const Section = styled.div`
  width: 100%;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
  padding: ${props => (props.padding ? props.padding : "20px")};
`;

const RefAndDateBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const RefBox = styled.div`
  a {
    color: #007ace;
    text-decoration: none;
    cursor: pointer;
    font-size: 0.7rem;
  }
`;
const DateBox = styled.div`
  h5 {
    color: #637381;
    font-size: 0.7rem;
  }
`;

const DescriptionBox = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  p {
    color: #212b36;
    font-size: 0.7rem;
  }
  span {
    color: #637381;
    font-size: 0.7rem;
  }
`;

const StatusBox = styled.div`
  padding-top: 10px;
  span {
    background-color: #ffea8a;
    color: #212b36;
    padding: 2px 10px;
    border: 2px solid white;
    font-size: 0.7rem;
    border-radius: 25px;
  }
`;

const PaginatorBox = styled.div`
  div {
    width: 20%;
    display: flex;
  }
`;
const PaginatorBtn = styled.button.attrs({
  type: "button"
})`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: linear-gradient(to bottom, white, #f9fafb);
  outline: none;
  cursor: pointer;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border: 1px solid #c4cdd5;
  color: #212b36;
  transition-property: background, border, box-shadow, -webkit-box-shadow;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-duration: 200ms;
  -webkit-tap-highlight-color: transparent;
  font-size: 0.7rem;
  padding: 10px 0px;
  ${props =>
    props.left
      ? css`
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          svg {
            transform: rotate(270deg);
          }
        `
      : css`
          /* border-top-left-radius: 0;
          border-bottom-left-radius: 0; */
          border-left: none
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          svg {
            transform: rotate(90deg);
          }
        `};

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      background: #f4f6f8;
      border-color: #c4cdd5;
      color: #919eab;
      outline: 0;
      box-shadow: none;
    `};

  &:hover {
    background: linear-gradient(to bottom, #f9fafb, #f4f6f8);
  }

  &:active {
    background: linear-gradient(to bottom, #f4f6f8, #f4f6f8);
    border-color: #c4cdd5;
    box-shadow: inset 0 1px 1px 0 rgba(99, 115, 129, 0.1),
      inset 0 1px 4px 0 rgba(99, 115, 129, 0.2);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export default class extends React.Component {
  state = {
    activeIndex: 0
  };
  _changeActiveIndex = action =>
    this.setState(state => ({
      activeIndex:
        action === "increase" ? state.activeIndex + 1 : state.activeIndex - 1
    }));
  render() {
    const { activeIndex } = this.state;
    const { transactions } = this.props;
    let currentTransaction = null;
    let subDescriptionStringArray = null;
    let action = null;
    let restOfSubDescriptionString = null;
    if (transactions.length > 0) {
      currentTransaction = transactions[activeIndex];
      subDescriptionStringArray = currentTransaction.sub_description
        ? currentTransaction.sub_description.split(" ")
        : [];
      action = subDescriptionStringArray[0] || "";
      restOfSubDescriptionString = subDescriptionStringArray
        ? subDescriptionStringArray.slice(1).join(" ")
        : "";
    }

    return (
      <Container>
        <MainHeader>
          <h3>Recent Transactions</h3>
          <span>
            <Link to={`/customers/${this.props.customer.id}/transactions`}>
              View Transactions
            </Link>
          </span>
        </MainHeader>
        {transactions.length > 0 ? (
          <Fragment>
            <Section padding="20px 20px 25px">
              <RefAndDateBox>
                <RefBox>
                  <Link to="#">Reference: {currentTransaction.reference}</Link>
                </RefBox>
                <DateBox>
                  <h5>
                    {format(
                      new Date(currentTransaction.timestamp),
                      "MMM D, h:mm a"
                    )}
                  </h5>
                </DateBox>
              </RefAndDateBox>
              <DescriptionBox>
                <p>
                  {currentTransaction.description} <span>{action}</span>{" "}
                  {restOfSubDescriptionString}
                </p>
                <p>
                  {currentTransaction.meta.debit &&
                    `- ${currentTransaction.meta.debit}`}
                  {currentTransaction.meta.credit &&
                    `+ ${currentTransaction.meta.credit}`}
                </p>
              </DescriptionBox>
              <StatusBox>
                <span>{currentTransaction.status}</span>
              </StatusBox>
            </Section>
            {transactions.length > 1 && (
              <Section borderTop>
                <PaginatorBox>
                  <div>
                    <PaginatorBtn
                      left
                      disabled={activeIndex === 0}
                      onClick={this._changeActiveIndex.bind(this, "decrease")}
                    >
                      <ArrowSVG />
                    </PaginatorBtn>
                    <PaginatorBtn
                      disabled={transactions.length - 1 === activeIndex}
                      onClick={this._changeActiveIndex.bind(this, "increase")}
                    >
                      <ArrowSVG />
                    </PaginatorBtn>
                  </div>
                </PaginatorBox>
              </Section>
            )}
          </Fragment>
        ) : (
          <EmptyBox padding="40px 20px">
            <div>
              <EmptyBoxSVG />
            </div>
            <div>
              <span className="">This customer has no transaction</span>
            </div>
          </EmptyBox>
        )}
      </Container>
    );
  }
}
