import React from "react";

import Select from "../../../common/Select";
import { FormSection, FormSectionInfo } from "./common";
import { Container, Section, FormGroup } from "./formElements";

const BasicInfoForm = ({ form, handleInputChange }) => {
  return (
    <FormSection>
      <FormSectionInfo>
        <h3>Basic</h3>
      </FormSectionInfo>
      <Container>
        <Section>
          <FormGroup>
            <label htmlFor="name">Name</label>
            <input
              name="name"
              id="name"
              value={form.name}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="code">Code</label>
            <input
              name="code"
              id="code"
              value={form.code}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="symbol">Symbol</label>
            <input
              name="symbol"
              id="symbol"
              value={form.symbol}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Select
              name="type"
              label="Type"
              options={["Fiat", "Digital"]}
              value={form.type}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="website">Website</label>
            <input
              name="website"
              id="website"
              value={form.website}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="description">Description</label>
            <textarea
              name="description"
              rows="5"
              id="description"
              value={form.description}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Section>
      </Container>
    </FormSection>
  );
};

export default BasicInfoForm;
