import React from "react";
import { Switch, Route } from "react-router-dom";

import Providers from "./Providers";
import ProviderInfo from "./ProviderInfo";

export default ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/:providerType`} component={Providers} />
      <Route
        exact
        path={`${match.path}/:providerType/new`}
        render={routerProps => <ProviderInfo {...routerProps} mode="create" />}
      />
      <Route
        exact
        path={`${match.path}/:providerType/:providerId`}
        render={routerProps => <ProviderInfo {...routerProps} mode="edit" />}
      />
    </Switch>
  );
};
