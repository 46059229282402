import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as ExportSVG } from './assets/export.svg';
import { connect } from 'react-redux';
import FullTableLoader from '../../common/SVGLoaders/FullTableLoader';
import Paginator from '../../common/Paginator';
import { isEqual } from 'lodash';
import qs from 'qs';
import { isActive } from '../../../helpers/util';

import {
	Container,
	Header,
	HeaderLeft,
	HeaderTextBox,
	HeaderText,
	HeaderExportBtn,
	HeaderExportBtnBox,
	Body,
	Card,
	CardHeader,
	TabsBtnBox,
	TabBtnList,
	TabBtnItem,
	TabBtnLink,
	CardBody,
	SearchAndFilterBox,
	FilterBox,
	SearchBox,
	SearchIconBox,
	SearchInput,
	TableBox,
} from './components/common';
import FilterDropdown from '../../common/Dropdowns/FilterDropdown';
import TradesTable from './components/TradesTable';
import { getTrades } from '../../../actions/coinbarter/trade';

class Transfers extends Component {
	state = {
		totalEntries: 1,
		perPage: 20,
	};

	componentDidMount() {
		document.title = 'Coinbarter Trades';
		const { getTrades } = this.props;
		const { page, status } = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		});

		getTrades(page, status, totalEntries => {
			this.setState({
				totalEntries,
			});
		});
	}

	componentDidUpdate(prevProps, _) {
		const { getTrades } = this.props;
		const { page, status } = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		});

		if (!isEqual(prevProps.location.search, this.props.location.search)) {
			getTrades(page, status, totalEntries => {
				this.setState({
					totalEntries,
				});
			});
		}
	}

	render() {
		const {
			coinBarterTrades,
			history: {
				location: { search },
			},
		} = this.props;
		const { totalEntries, perPage } = this.state;

		return (
			<Container>
				{coinBarterTrades.fetching ? (
					<FullTableLoader />
				) : (
					<Fragment>
						<Header>
							<HeaderLeft>
								<HeaderTextBox>
									<HeaderText>All Trades</HeaderText>
								</HeaderTextBox>
								{/* <HeaderExportBtnBox smallBox>
                  <HeaderExportBtn>
                    <ExportSVG />
                    <span>Export</span>
                  </HeaderExportBtn>
                </HeaderExportBtnBox> */}
							</HeaderLeft>
						</Header>

						<Body>
							<Card>
								<CardHeader>
									<TabsBtnBox>
										<TabBtnList>
											<TabBtnItem>
												<TabBtnLink
													to="/coinbarter/trades"
													className={isActive(undefined, search) && 'active-tab'}
												>
													All
												</TabBtnLink>
											</TabBtnItem>
											<TabBtnItem>
												<TabBtnLink
													to="/coinbarter/trades?status=created"
													className={isActive('created', search) && 'active-tab'}
												>
													Created
												</TabBtnLink>
											</TabBtnItem>
											<TabBtnItem>
												<TabBtnLink
													to="/coinbarter/trades?status=pending"
													className={isActive('pending', search) && 'active-tab'}
												>
													Pending
												</TabBtnLink>
											</TabBtnItem>
											<TabBtnItem>
												<TabBtnLink
													to="/coinbarter/trades?status=processing"
													className={isActive('processing', search) && 'active-tab'}
												>
													Processing
												</TabBtnLink>
											</TabBtnItem>
											<TabBtnItem>
												<TabBtnLink
													to="/coinbarter/trades?status=completed"
													className={isActive('completed', search) && 'active-tab'}
												>
													Completed
												</TabBtnLink>
											</TabBtnItem>
											<TabBtnItem>
												<TabBtnLink
													to="/coinbarter/trades?status=expired"
													className={isActive('expired', search) && 'active-tab'}
												>
													Expired
												</TabBtnLink>
											</TabBtnItem>
											<TabBtnItem>
												<TabBtnLink
													to="/coinbarter/trades?status=cancelled"
													className={isActive('cancelled', search) && 'active-tab'}
												>
													Cancelled
												</TabBtnLink>
											</TabBtnItem>
										</TabBtnList>
									</TabsBtnBox>
								</CardHeader>
								<CardBody>
									<SearchAndFilterBox>
										{/* <FilterBox>
											<FilterDropdown />
										</FilterBox> */}
										<SearchBox>
											<SearchIconBox>
												<FontAwesomeIcon icon="search" />
											</SearchIconBox>
											<SearchInput placeholder="Search Trades" />
										</SearchBox>
									</SearchAndFilterBox>
									<TableBox>
										<TradesTable coinBarterTrades={coinBarterTrades} />
									</TableBox>
								</CardBody>
							</Card>
						</Body>
						<Paginator totalEntries={totalEntries} perPage={perPage} {...this.props} />
					</Fragment>
				)}
			</Container>
		);
	}
}

const mapStateToProps = ({ coinBarterTrades }) => ({ coinBarterTrades });

export default connect(
	mapStateToProps,
	{ getTrades }
)(Transfers);
