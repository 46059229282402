import React, { Component, Fragment } from "react";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import AccountDetails from "./AccountDetails";
import LoginLogs from "./LoginLogs";
import TwoStepAuth from "./TwoStepAuth";
import SaveBar from "../../common/SaveBar";
import { ToastContainer } from "react-toastify";
import RemoveAccount from "./RemoveAccount";
import getStaffProfile from "../../../actions/getStaffProfile";
import { isEqual } from "lodash";
import updateStaff from "../../../actions/updateStaff";
import deleteStaff from "../../../actions/deleteStaff";
import deactivateStaff from "../../../actions/deactivateStaff";
import Modal from "../../common/Modal";
import activateStaff from "../../../actions/activateStaff";
import getPermissions from "../../../actions/getPermissions";
import CustomizableLoader from "../../common/CustomizableLoader";

import { Content, SubHead, Header, Footer } from "./elements";
import back from "../back.svg";
import AdminAccess from "./AdminAccess";
import Loader from "./Loader";

const AllPermissions = [
  "StaffAccounts",
  "Users",
  "Profiles",
  "Payouts",
  "Deposits",
  "Assets",
  "Accounts",
  "Cards",
  "Transactions",
  "KYC_Verifications"
];
const initialPermissions = {
  Users: false,
  Cards: false,
  Assets: false,
  Payouts: false,
  Deposits: false,
  Profiles: false,
  Accounts: false,
  Transactions: false,
  StaffAccounts: false,
  KYC_Verifications: false
};
class StaffProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormDirty: false,
      first_name: "",
      last_name: "",
      bio: "",
      phone: "",
      email: "",
      avatar: "",
      deactivating: false,
      deleting: false,
      deactivationModal: false,
      deletionModal: false,
      activating: true,
      fullAccess: false,
      loading: false,
      permissions: initialPermissions
    };
  }

  handleFormChange = ({ target: { value, name } }) => {
    this.setState({
      isFormDirty: true,
      [name]: value
    });
  };

  handleDiscard = () => {
    this.setState({
      isFormDirty: false
    });
  };

  handleSubmit = () => {
    this.setState({
      loading: true
    });

    const {
      first_name,
      last_name,
      bio,
      phone,
      email,
      permissions
    } = this.state;
    const fullAccess = isEqual(AllPermissions, permissions);
    const payload = {
      first_name,
      last_name,
      bio,
      phone,
      email,
      full_access: fullAccess,
      permissions: Object.keys(permissions).filter(
        permission => permissions[permission] === true
      )
    };

    const { updateStaff } = this.props;

    updateStaff(this.props.match.params.id, payload, () => {
      this.setState({
        isFormDirty: false,
        loading: false
      });
    });
  };

  handlePermissionsChange = ({ target: { checked, name } }) => {
    this.setState({
      isFormDirty: true
    });

    if (name === "fullAccess") {
      this.setState({
        [name]: checked
      });
    } else {
      this.setState({
        permissions: {
          ...this.state.permissions,
          [name]: checked
        }
      });
    }
  };

  tokenizePermissions = permissions => {
    const tokenizedPermissions = {};

    permissions.forEach(perm => {
      tokenizedPermissions[perm] = true;
    });

    return tokenizedPermissions;
  };

  handleDelete = () => {
    this.setState({
      deleting: true
    });

    const {
      match: {
        params: { id }
      },
      deleteStaff
    } = this.props;

    deleteStaff(id, () => {
      this.setState({
        deleting: false
      });

      this.props.history.push("/settings/accounts");
    });
  };

  handleDeactivate = () => {
    this.setState({
      deactivating: true
    });

    const {
      match: {
        params: { id }
      },
      deactivateStaff
    } = this.props;

    deactivateStaff(id, () => {
      this.setState({
        deactivating: false
      });
    });
  };

  toggleModal = type => {
    this.setState(currentState => ({
      [type]: !currentState[type]
    }));
  };

  handleActivate = () => {
    this.setState({
      activating: true
    });

    const {
      match: {
        params: { id }
      },
      activateStaff
    } = this.props;

    activateStaff(id, () => {
      this.setState({
        activating: false
      });
    });
  };

  componentDidMount() {
    const {
      match: {
        params: { id }
      },
      getStaffProfile
    } = this.props;
    getStaffProfile(id);
    this.props.getPermissions();
  }
  negativeTokenization = (token = []) => token.map(perm => ({ [perm]: false }));

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps, this.props) || !isEqual(prevState, this.state)) {
      if (
        (!isEqual(prevProps.data, this.props.data) ||
          !isEqual(prevProps.permissions, this.props.permissions)) &&
        this.props.permissions.payload &&
        this.props.data
      ) {
        if (Object.keys(this.props.permissions.payload || {}).length) {
          console.log({
            user: this.props.data,
            permission: [
              ...this.props.permissions.payload.general_permissions,
              ...this.props.permissions.payload.settings_permissions
            ]
          });
          this.setState({
            first_name: this.props.data.first_name || "",
            last_name: this.props.data.last_name || "",
            bio: this.props.data.bio || "",
            phone: this.props.data.phone || "",
            email: this.props.data.email || "",
            avatar: this.props.data.avatar || "",
            fullAccess: this.props.data.full_access,
            permissions: this.props.data.full_access
              ? this.negativeTokenization([
                  ...this.props.permissions.payload.general_permissions,
                  ...this.props.permissions.payload.settings_permissions
                ])
              : {
                  ...this.negativeTokenization([
                    ...this.props.permissions.payload.general_permissions,
                    ...this.props.permissions.payload.settings_permissions
                  ]),
                  ...this.tokenizePermissions(this.props.data.permissions || [])
                }
          });
        }
      }
    }
  }

  render() {
    const { fetched, fetching, data, permissions } = this.props;

    const { isFormDirty, deactivationModal, deletionModal } = this.state;
    console.log({
      data,
      permissions: this.props.permissions,
      all: this.props.all
    });
    return (
      <Content>
        <ToastContainer
          closeButton={false}
          autoClose={2000}
          hideProgressBar={true}
        />
        {isFormDirty && (
          <SaveBar
            loading={this.state.loading}
            handleSave={this.handleSubmit}
            handleDiscard={this.handleDiscard}
          />
        )}
        <Header>
          <nav
            style={{
              height: "45px"
            }}
          >
            <a
              className="back-button"
              href="#goback"
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              <SVG src={back} className="svg" />
              <span>Account</span>
            </a>
          </nav>
        </Header>

        <SubHead>
          {fetching || permissions.fetching ? (
            <Fragment>
              <CustomizableLoader
                borderRadius="3px"
                width="30%"
                height="20px"
              />
              <br />
              <br />
            </Fragment>
          ) : (
            <h1>
              {data.first_name} {data.last_name}
            </h1>
          )}
        </SubHead>

        {(fetching || permissions.fetching) && <Loader />}

        {fetched && permissions.fetched && (
          <AccountDetails
            first_name={this.state.first_name}
            last_name={this.state.last_name}
            email={this.state.email}
            phone={this.state.phone}
            bio={this.state.bio}
            avatar={this.state.avatar}
            handleFormChange={this.handleFormChange}
          />
        )}

        {fetched && permissions.fetched && (
          <AdminAccess
            permissions={this.state.permissions}
            generalPermissions={
              this.props.permissions.payload.general_permissions
            }
            settingsPermissions={
              this.props.permissions.payload.settings_permissions
            }
            fullAccess={this.state.fullAccess}
            first_name={this.state.first_name}
            last_name={this.state.last_name}
            handlePermissionsChange={this.handlePermissionsChange}
          />
        )}

        {fetched && permissions.fetched && data.login_activities.length > 1 && (
          <LoginLogs login_activities={data.login_activities} />
        )}

        {/* {fetched && <TwoStepAuth />} */}

        {fetched && permissions.fetched && (
          <RemoveAccount
            handleDelete={this.handleDelete}
            handleDeactivate={this.handleDeactivate}
            handleActivate={this.handleActivate}
            toggleModal={this.toggleModal}
            active={data.active}
          />
        )}

        {fetched && permissions.fetched && (
          <Footer>
            <button
              disabled={!isFormDirty}
              onClick={this.handleSubmit}
              className="btn"
            >
              Save
            </button>
          </Footer>
        )}

        {deactivationModal && (
          <Modal
            meta={{
              width: "620px"
            }}
            data={{
              title: `Deactivate ${data.first_name} ${
                data.last_name
              }'s staff account`,
              loading: this.state.deactivating,
              buttonTxt: "Deactivate",
              body: `Are you sure you want to deactivate the staff account for ${
                data.first_name
              } ${data.last_name}?`
            }}
            actions={{
              toggleModal: () => {
                this.toggleModal("deactivationModal");
              },
              handleClick: () => {
                this.handleDeactivate();
                this.toggleModal("deactivationModal");
              }
            }}
          />
        )}

        {deletionModal && (
          <Modal
            meta={{
              width: "620px"
            }}
            data={{
              title: `Delete ${data.first_name} ${
                data.last_name
              }'s staff account`,
              loading: this.state.deleting,
              buttonTxt: "Delete",
              body: `Are you sure you want to delete the staff account for ${
                data.first_name
              } ${data.last_name}? This action cannot be reversed.`
            }}
            actions={{
              toggleModal: () => {
                this.toggleModal("deletionModal");
              },
              handleClick: this.handleDelete
            }}
          />
        )}
      </Content>
    );
  }
}

const mapStateToProps = state => ({
  data: state.staffProfile.payload,
  permissions: state.permissions,
  fetched: state.staffProfile.fetched,
  fetching: state.staffProfile.fetching
});

export default connect(
  mapStateToProps,
  {
    getStaffProfile,
    updateStaff,
    deleteStaff,
    deactivateStaff,
    activateStaff,
    getPermissions
  }
)(StaffProfile);
