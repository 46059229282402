import React from "react";
import styled, { css } from "styled-components";
import Chart from "./CustomerBehaviourChart";

const Container = styled.div`
  flex: 1;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  min-height: 100px;
  margin-right: 10px;
`;
export const Section = styled.div`
  width: 100%;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
  padding: 10px;
`;

export const MainHeader = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    font-size: 0.6rem;
    border-radius: 3px;
    padding: 4px 4px;
    background-color: #919eab;
    color: #fff;
    /* font-size: 1.2rem; */
    white-space: nowrap;
  }
`;
class CustomerBehavior extends React.Component {
  render() {
    return (
      <Container>
        <Section>
          <MainHeader>
            <h3>Customer Behavior</h3>
            <span>10 min</span>
          </MainHeader>
          <Chart data={[10, 15, 11]} />
        </Section>
      </Container>
    );
  }
}

export default CustomerBehavior;
