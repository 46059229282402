import styled from "styled-components";

import caretDown from "./caret-down.svg";
import search from "./search.svg";

export const Container = styled.div`
  /* margin-left: 246px; */
  margin-left: 12.5%;
  /* width: 100%; */
  display: flex;
  justify-content: flex-start;
  background: #f4f6f8;
`;

export const Nav = styled.nav`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 56px;
  width: 100%;
  background: #000639;
  padding: 0 32px;
  padding-right: 0;
  padding-left: 0;
  display: flex;
  align-items: center;

  .back-button {
    text-decoration: none !important;
  }

  .brand {
    width: 12.5%;
    /* width: 246px; */
    /* max-width: 246px; */
    background: #000639;
    height: 56px;
    display: flex;
    align-items: center;
    padding-left: 16px;

    img {
      height: 20px;
    }

    .brand-text {
      color: #57a7ed;
      color: #57a7ed;
      font-size: 20px;
      font-weight: 800;
      line-height: 24px;
      display: inline-block;
      margin-left: 10px;
    }
  }

  .search-cont {
    width: calc(100% - 264px - 199px);
    display: flex;
    align-items: center;
    background: #1b2160;
    height: 100%;
  }

  .search {
    display: flex;
    justify-content: center;
    width: 100%;

    input {
      background: #43487c;
      border: none;
      outline: none;
      height: 34px;
      width: 95%;
      color: #fff;
      border-radius: 3px;
      align-items: center;
      padding: 0 20px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  background: #f4f6f8;
  height: calc(100vh - 56px);
  margin-top: 56px;
  padding-bottom: 50px;
`;

export const Table = styled.div`
  width: 934px;
  margin: 0 auto;
  margin-top: 34px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);

  .table-header {
    height: 55px;
    padding: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dfe3e8;

    li {
      display: inline-block;

      .all {
        border-bottom: ${({ parameter }) =>
          !parameter ? "3px solid #5c6ac4" : "3px solid transparent"};
        padding-top: 3px;
      }

      .created {
        border-bottom: ${({ parameter }) =>
          parameter === "created"
            ? "3px solid #5c6ac4"
            : "3px solid transparent"};
        padding-top: 3px;
      }

      .pending {
        border-bottom: ${({ parameter }) =>
          parameter === "pending"
            ? "3px solid #5c6ac4"
            : "3px solid transparent"};
        padding-top: 3px;
      }

      .processing {
        border-bottom: ${({ parameter }) =>
          parameter === "processing"
            ? "3px solid #5c6ac4"
            : "3px solid transparent"};
        padding-top: 3px;
      }

      .completed {
        border-bottom: ${({ parameter }) =>
          parameter === "completed"
            ? "3px solid #5c6ac4"
            : "3px solid transparent"};
        padding-top: 3px;
      }

      .expired {
        border-bottom: ${({ parameter }) =>
          parameter === "expired"
            ? "3px solid #5c6ac4"
            : "3px solid transparent"};
        padding-top: 3px;
      }

      .cancelled {
        border-bottom: ${({ parameter }) =>
          parameter === "cancelled"
            ? "3px solid #5c6ac4"
            : "3px solid transparent"};
        padding-top: 3px;
      }

      a {
        display: flex;
        height: 55px;
        align-items: center;
        padding: 0 20px;
        text-decoration: none;
        color: #212b36;

        &:hover {
          border-bottom: 3px solid #5c6ac4;
          padding-top: 3px;
        }
      }
    }
  }
`;

export const Header = styled.header`
  width: 934px;
  margin: 52px auto;

  .title {
    font-size: 28px;
    font-weight: 600;
  }
`;

export const Filters = styled.div`
  height: 116px;
  padding: 16px;
  padding-top: 0;

  .filter-box {
    display: flex;
    justify-content: flex-start;
    height: 36px;
    width: 100%;

    .filter-dropdown {
      height: 100%;
      width: 85px;
      outline: none;
      cursor: pointer;
      font-size: 14px;
      border-radius: 3px;
      border: 1px solid #dfe3e8;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-image: url(${caretDown});
      background-repeat: no-repeat;
      background-size: 11px 11px;
      text-align: left;
      background-position: 75% 11px;
      padding-left: 15px;
    }

    .search-box {
      height: 100%;
      border-radius: 3px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      width: 100%;
      border: 1px solid #dfe3e8;

      input {
        border: 0;
        height: 100%;
        width: 100%;
        padding-left: 30px;
        padding-right: 10px;
        font-size: 14px;
        outline: none;
        background-image: url(${search});
        background-repeat: no-repeat;
        background-position: 10px 50%;
        background-size: 12px;
      }
    }
  }
`;

export const SelectAll = styled.div`
  margin-top: 25px;
  margin-left: 4px;
  display: flex;
  align-items: center;

  input {
    margin-right: 13px;
  }

  .showing-text {
    vertical-align: middle;
  }
`;

export const Options = styled.div`
  padding: 23px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  .left {
    display: flex;
    align-items: center;
  }

  .right {
    width: 257px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .amount {
      color: #212b36;
    }

    .date {
      color: #637381;
    }
  }

  .img {
    margin-right: 10px;
    margin-top: 2px;
  }

  input {
    margin-right: 13px;
  }

  &:not(:first-child) {
    border-top: 1px solid #dfe3e8;
  }
`;

export const Selected = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 25px;
  height: 36px;
  border: 1px solid #dfe3e8;
  padding-left: 5px;
  border-radius: 3px;
  font-size: 14px;
  color: #5c6ac4;
  font-weight: bold;

  input {
    margin-right: 13px;
  }

  button {
    padding: 0 10px;
    height: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    border-left: 1px solid #dfe3e8;
    margin-left: 17px;
    cursor: pointer;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

export const TradesTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin: 0;

  thead {
    text-align: left;
    color: #212b36;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;

    th:first-child {
      padding-left: 16px;
    }

    tr {
      height: 56px;
    }
  }

  tbody {
    text-align: left;

    tr {
      border-top: 1px solid #edf0f2;
      height: 56px;
      color: #212b36;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
    }

    td:first-child {
      padding-left: 16px;
    }

    td:last-child {
      padding-right: 16px;
    }
  }
`;

export const TradesFilter = styled.div`
  padding: 0 16px;

  .filter-box {
    display: flex;
    justify-content: flex-start;
    height: 36px;
    width: 100%;

    .filter-dropdown {
      height: 100%;
      width: 85px;
      outline: none;
      cursor: pointer;
      font-size: 14px;
      border-radius: 3px;
      border: 1px solid #dfe3e8;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-image: url(${caretDown});
      background-repeat: no-repeat;
      background-size: 11px 11px;
      text-align: left;
      background-position: 75% 11px;
      padding-left: 15px;
    }

    .search-box {
      height: 100%;
      border-radius: 3px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      width: 100%;
      border: 1px solid #dfe3e8;

      input {
        border: 0;
        height: 100%;
        width: 100%;
        padding-left: 30px;
        padding-right: 10px;
        font-size: 14px;
        outline: none;
        background-image: url(${search});
        background-repeat: no-repeat;
        background-position: 10px 50%;
        background-size: 12px;
      }
    }
  }
`;

export const Status = styled.span`
  display: inline-flex;
  align-items: center;
  height: 24px;
  border-radius: 24px;
  padding: 0 10px;
  border: 2px solid #fff;
  font-size: 0.7rem;
  background: ${({ status }) => {
    if (status === "created") {
      return "#DFE3E8";
    }

    if (status === "completed") {
      return "#BBE5B3";
    }

    if (status === "processing") {
      return "#FFC48B";
    }

    if (status === "pending") {
      return "#FFEA8A";
    }

    if (status === "cancelled") {
      return "#EE6D51";
    }
  }};
`;

export const Profile = styled.button`
  outline: none;
  width: 216.344px;
  height: 100%;
  background: transparent;
  border: none;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 0.42);
  color: #fff;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: hsla(0, 0%, 100%, 0.08);
  }

  .profile-img {
    height: 32px;
    width: 32px;
    border-radius: 100%;
    background: #b763ff;

    img {
      height: 32px;
      width: 32px;
      border-radius: 100%;
    }
  }

  .profile-summary {
    margin-left: 16px;
    ${"" /* margin-right: 16px; */} h4,
    p {
      margin: 0;
      padding: 0;
      text-align: left;
    }

    h4 {
      font-size: 14px;
      font-weight: 500;
    }

    p {
      font-size: 12px;
      font-weight: 400px;
      color: rgb(249, 250, 251);
    }
  }
`;

export const Dropdown = styled.div`
  width: 168px;
  position: absolute;
  top: 61px;
  right: 25px;
  background: red;
  border-radius: 6px;
  background: #fff;
  box-shadow: rgba(6, 44, 82, 0.1) 0px 0px 0px 1px,
    rgba(33, 43, 54, 0.08) 0px 2px 16px;
  font-size: 14px;
  font-weight: 400px;
  padding: 10px 16px;
  color: rgb(33, 43, 54);

  svg {
    height: 20px;
    width: 20px;

    g {
      fill: rgb(33, 43, 54);
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
    }

    a {
      text-decoration: none;
      color: inherit;

      :hover {
        text-decoration: underline;
      }
    }
  }

  img {
    width: 20px;
    width: 20px;
    vertical-align: middle;
  }
`;

export const Loader = styled.div`
  margin: 0;
  margin-top: ${({ marginTop }) => marginTop || 0};
  padding: 0;
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
