import React from "react";

import CanvasJSReact from "../../canvasjs/canvasjs.react";
import { ChartBox, ChartCard, ChartTitle } from "./elements";
import { currencyFormatter, round } from "../../../../../helpers/util";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const TradeChart = props => {
  const options = {
    theme: "light2",
    animationEnabled: true,
    height: 250,
    legend: {
      fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif`,
      fontWeight: 400
    },
    // title: null,
    // subtitles: [
    //   {
    //     text: "GBP & USD to INR"
    //   }
    // ],

    axisX: {
      //   valueFormatString: "hh TT",
      labelFormatter: function(e) {
        // console.log(e.value.toString());
        switch (props.data.period) {
          case "hour":
            if (e.value.toString().includes("00:00:00"))
              return CanvasJSReact.CanvasJS.formatDate(e.value, "MMM DD");
            return CanvasJSReact.CanvasJS.formatDate(e.value, "hh TT");
          case "day":
            return CanvasJSReact.CanvasJS.formatDate(e.value, "MMM DD");
          default:
            return CanvasJSReact.CanvasJS.formatDate(e.value, "MMM YY");
        }
      },
      gridThickness: 1,
      gridDashType: "dash",
      gridColor: "#E6E6E6",
      lineColor: "#CDD6E9",
      tickColor: "#CDD6E9",
      labelFontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif`,
      labelFontSize: 11
      //   labelAngle: -50
    },
    axisY: {
      //   includeZero: false,
      labelFormatter: function(e) {
        // console.log(e.value);
        if (e.value < 1) {
          return e.value;
        } else if (e.value < 1000) {
          return CanvasJSReact.CanvasJS.formatNumber(e.value, "0");
        } else if (e.value >= 1000000) {
          return CanvasJSReact.CanvasJS.formatNumber(e.value, "#,###,,.##M");
        }
        return CanvasJSReact.CanvasJS.formatNumber(e.value, "#,###,.##K");
      },
      // prefix: "NGN",
      // suffix: "K",
      gridThickness: 1,
      gridColor: "#E6E6E6",
      tickThickness: 0,
      lineThickness: 0,
      labelFontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif`,
      labelFontSize: 11
    },
    toolTip: {
      shared: true,
      backgroundColor: "black",
      borderColor: "black",
      borderThickness: 1,
      fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif`,
      cornerRadius: 4,
      fontSize: 12,
      contentFormatter: e => {
        const { entries } = e;
        // console.log({ e });
        return `<div class="chart-tooltip">
                  <div class="date">
                  ${CanvasJSReact.CanvasJS.formatDate(
                    entries[0].dataPoint.x,
                    "MMM DD, YYYY"
                  )}
                  </div>
                  ${entries
                    .map(
                      _e =>
                        `<div class="data-point">
                          <span class="point-color" style="background-color: ${
                            _e.dataSeries.color
                          }"></span>
                          <span>${_e.dataSeries.name}</span>
                          <span>${currencyFormatter(
                            _e.dataPoint.y,
                            props.asset.code
                          )}</span>
                      </div>`
                    )
                    .join("")}
                    <div class="total">
                      <span>Total</span>
                      <span>
                        ${currencyFormatter(
                          entries.reduce((_t, _c) => _t + _c.dataPoint.y, 0),
                          props.asset.code
                        )}
                      </span>
                    </div>
                  </div>`;
      }
    },
    data: [
      {
        type: "area",
        name: "Deposits",
        markerType: "none",
        legendMarkerType: "square",
        showInLegend: true,
        // xValueFormatString: "MMM YYYY",
        // xValueFormatString: "HH A",
        yValueFormatString: "NGN#,##0.##",
        dataPoints: props.data.deposits.map(_d => ({
          y: Number(_d.y),
          x: new Date(_d.x)
        }))
      },
      {
        type: "area",
        name: "Payouts",
        legendMarkerType: "square",
        markerType: "none",
        showInLegend: true,
        // xValueFormatString: "HH A",
        yValueFormatString: "NGN#,##0.##",
        dataPoints: props.data.payouts.map(_d => ({
          y: Number(_d.y),
          x: new Date(_d.x)
        }))
      }
    ]
  };
  return (
    <ChartCard>
      <ChartTitle className="title">
        <h3 className="title">{props.asset.name}</h3>
      </ChartTitle>
      <ChartBox>
        <CanvasJSChart options={options} />
      </ChartBox>
    </ChartCard>
  );
};

export default TradeChart;
