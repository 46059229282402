import axios from "axios";
import { toast } from "react-toastify";

import types from "./types";
import { apiBaseURL } from "../config";
import storage from "../helpers/storage";

const loginFulfilled = payload => ({
  type: types("login").fulfilled,
  payload
});

const loginRejected = payload => ({
  type: types("login").rejected,
  payload
});

const loginPending = () => ({
  type: types("login").pending
});

const login = (credentials, callback) => async dispatch => {
  try {
    dispatch(loginPending());

    const { data } = await axios.post(
      `${apiBaseURL}/v1/auth/login`,
      credentials
    );
    storage.add("payload", data);
    axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;

    dispatch(loginFulfilled(data));
    callback();
  } catch (err) {
    dispatch(loginRejected(err));
    if (err.message === "Network Error") {
      toast.error(err.message);
    } else {
      toast.error(err.response.data.message);
    }
  }
};

export default login;
