import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Transactions from "./Transactions";
import TransactionInfo from "./TransactionInfo";

import Swaps from "./Swaps";
import SwapInfo from "./SwapInfo";
import NewTransaction from "./NewTransaction";
class TransactionsRoutes extends Component {
  render() {
    const {
      user: { payload },
      match
    } = this.props;

    return (
      <Switch>
        {payload && !payload.permissions.includes("Transactions") && (
          <Redirect to="/dashboard" />
        )}
        <Route exact path={`${match.path}`} component={Transactions} />
        <Route exact path={`${match.path}/new`} component={NewTransaction} />
        <Route exact path={`${match.path}/swaps`} component={Swaps} />
        <Route
          exact
          path={`${match.path}/:transactionId`}
          component={TransactionInfo}
        />
        <Route
          exact
          path={`${match.path}/swaps/:swapId`}
          component={SwapInfo}
        />
      </Switch>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(TransactionsRoutes);
