import axios from 'axios';

import { apiBaseURL } from '../config';
import types from './types';

const fetchCustomersSuccessful = payload => ({
	type: types('fetch_customers').fulfilled,
	payload,
});

const fetchCustomersFailed = payload => ({
	type: types('fetch_customers').rejected,
	payload,
});

export const fetchCustomers = (page = 1, status = 'all', callback) => {
	const active = status === 'active' ? true : false;

	const url = status === 'all' ? `?page=${page}` : `?active=${active}&page=${page}`;

	return dispatch => {
		dispatch({ type: types('fetch_customers').pending });
		return axios
			.get(`${apiBaseURL}/v1/users${url}`)
			.then(({ data }) => {
				dispatch(fetchCustomersSuccessful(data));
				callback(data.meta.total_entries_size);
			})
			.catch(error => {
				dispatch(fetchCustomersFailed(error));
			});
	};
};

export const searchCustomers = (page = 1, status = 'all', callback, query = '') => {
	const active = status === 'active' ? true : false;

	const url = status === 'all' ? `?page=${page}&q=${query}` : `?active=${active}&page=${page}&q=${query}`;

	return dispatch => {
		dispatch({ type: types('search_customers').pending });

		return axios
			.get(`${apiBaseURL}/v1/users${url}`)
			.then(({ data }) => {
				dispatch(fetchCustomersSuccessful(data));
				dispatch({ type: types('search_customers').fulfilled, payload: data });
				callback(data.meta.total_entries_size);
			})
			.catch(error => {
				dispatch(fetchCustomersFailed(error));
			});
	};
};
