import styled, { css } from "styled-components";

const HeadData = styled.th`
  font-weight: 500;
  font-size: 0.7rem;
  text-align: left;
  color: #212b36;
  padding: 14px 10px;
  border-bottom: 1px solid #ebeef0;
  line-height: 1.5rem;
  & > span {
  }
  & > label {
    margin: 0;
  }
  ${props =>
    props.hasSelect &&
    css`
      width: 55px;
      /* max-width: 65px; */
      padding-left: 0px;
    `};
`;

export default HeadData;
