import axios from "axios";
import { getQueryString } from "../../../helpers/util";
import {
  GET_ADS,
  GET_ADS_DETAIL,
  SET_AD_STATUS,
  GET_ADS_PRODUCTS
} from "./types";

import { toast } from "react-toastify";
import { apiBaseURL } from "../../../config";

const action = payload => ({
  type: GET_ADS.default,
  payload
});

const detail_action = payload => ({
  type: GET_ADS_DETAIL.default,
  payload
});

const set_ad_detail = payload => ({
  type: SET_AD_STATUS.default,
  payload
});

export const getAds = queryString => dispatch => {
  const query = getQueryString(queryString);
  const payload = axios.get(`${apiBaseURL}/v1/ads${query}`);

  dispatch(action(payload)).catch(err => {
    err.response &&
      err.response.data &&
      err.response.data.message &&
      toast.error(err.response.data.message);
  });
};

export const getAdDetail = id => dispatch => {
  const payload = axios.get(`${apiBaseURL}/v1/ads/${id}`);

  dispatch(detail_action(payload)).catch(err => {
    err.response &&
      err.response.data &&
      err.response.data.message &&
      toast.error(err.response.data.message);
  });
};

export const setStatus = (initialstatus, id) => dispatch => {
  const status = initialstatus === "active" ? "inactive" : "active";
  const payload = axios.put(`${apiBaseURL}/v1/ads/${id}/status`, { status });
  dispatch(set_ad_detail(payload))
    .then(res => {
      toast.success(res.value.data.message);
      dispatch(getAdDetail(id));
    })
    .catch(err => {
      err.response &&
        err.response.data &&
        err.response.data.message &&
        toast.error(err.response.data.message);
    });
};

const getProductAction = () => ({
  type: GET_ADS_PRODUCTS.default,
  payload: axios.get(`${apiBaseURL}/v1/ads/products`)
});

export const getProducts = () => async dispatch => dispatch(getProductAction());
