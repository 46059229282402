import axios from "axios";
import types from "../types";
import { coinBarterApi } from "../../config";
import { toast } from "react-toastify";

const getStatsFulfilled = payload => ({
  type: types("get_stats").fulfilled,
  payload
});

const getStatsPending = () => ({
  type: types("get_stats").pending
});

const getStats = () => async dispatch => {
  try {
    dispatch(getStatsPending());

    const tradeStatsRequest = axios.get(`${coinBarterApi}/fence/trades/stats`);

    const transferStatsRequest = axios.get(
      `${coinBarterApi}/fence/transfers/stats`
    );

    const [tradeStats, transferStats] = await Promise.all([
      tradeStatsRequest,
      transferStatsRequest
    ]);

    dispatch(
      getStatsFulfilled({
        tradeStats: tradeStats.data,
        transferStats: transferStats.data
      })
    );
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export default getStats;
