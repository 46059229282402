import React from "react";

import { Permissions, LoginHistory } from "./elements";
import chopArrayHead from "../../../helpers/chopArrayHead";
import whatDayTime from "../../../helpers/whatDayTime";

const LoginLogs = ({ login_activities }) => (
  <Permissions>
    <div>
      <h3 className="title">Recent login history</h3>

      <div className="desc">
        Access from an ISP, Location, or IP address you don’t recognize may
        indicate that the account has been compromised and should be reviewed
        further.
      </div>
    </div>

    <LoginHistory>
      <table>
        <thead>
          <tr>
            <td>Date</td>
            <td>IP</td>
            <td>ISP</td>
            <td>Location</td>
          </tr>
        </thead>

        <tbody>
          {chopArrayHead(login_activities, [0, 9]).map(
            ({ created_at, ip, isp, location, agent }) => (
              <tr key={created_at}>
                <td>{whatDayTime(created_at)}</td>
                <td>{ip}</td>
                <td>{isp}</td>
                <td>{location}</td>
              </tr>
            )
          )}
        </tbody>
      </table>

      <button className="btn">Log out Michael Adeyeri</button>
    </LoginHistory>
  </Permissions>
);

export default LoginLogs;
