import { connect } from "react-redux";
import React, { Component } from "react";

import { ProductSection } from "./elements";
import { getTradeAnalytics } from "../../../../actions/analytics";
import GridLoader from "../../../common/SVGLoaders/GridLoader";
import { Charts } from "./Chart/elements";
import TradeChart from "./Chart/TradeChart";
import TradeVolumeChart from "./Chart/TradeVolumeChart";
import ProductMetrics from "./ProductMetrics";

class ProductsOverview extends Component {
  componentDidMount() {
    this.fetchAnalytics();
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props;
    if (startDate !== prevProps.startDate || endDate !== prevProps.endDate) {
      // console.log("make call");
      this.fetchAnalytics();
    }
  }

  fetchAnalytics() {
    const { startDate, endDate, dateFormat } = this.props;
    const { getTradeAnalytics } = this.props;
    getTradeAnalytics(
      startDate.format(dateFormat),
      endDate.format(dateFormat),
      true
    );
  }

  render() {
    const {
      fetching,
      tradeAnalytics,
      tradeVolumeAnalytics,
      tradeMetricsAnalytics
    } = this.props.analytics;
    const { products } = this.props.products;
    return (
      <React.Fragment>
        {fetching ? (
          <GridLoader />
        ) : (
          products.map((product, i) => (
            <ProductSection key={product.name}>
              <Charts>
                <ProductMetrics
                  data={tradeMetricsAnalytics[i]}
                  product={product.name}
                />
                <TradeVolumeChart
                  data={tradeVolumeAnalytics.data[i]}
                  metrics={tradeMetricsAnalytics[i]}
                  product={product.name}
                />
                <TradeChart
                  data={tradeAnalytics.data[i]}
                  metrics={tradeMetricsAnalytics[i]}
                  product={product.name}
                />
              </Charts>
            </ProductSection>
          ))
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ analytics, products }) => ({ analytics, products });

export default connect(
  mapStateToProps,
  { getTradeAnalytics }
)(ProductsOverview);
