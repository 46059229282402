import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Fiat from './Fiat';
import Crypto from './Crypto';

import PayoutDetail from '../PayoutDetail';

class Payouts extends Component {
	render() {
		const {
			user: { payload },
			match,
		} = this.props;

		return (
			<Switch>
				{payload && !payload.permissions.includes('Payouts') && <Redirect to="/dashboard" />}
				<Route exact path={`${match.path}/fiat`} component={Fiat} />
				<Route exact path={`${match.path}/crypto`} component={Crypto} />
				<Route exact path={`${match.path}/:type/:payoutId`} component={PayoutDetail} />
			</Switch>
		);
	}
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(Payouts);
