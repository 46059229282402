import React from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import Spinner from "react-activity/lib/Spinner";
import "react-activity/lib/Spinner/Spinner.css";
import { moneyFormatter } from "../../../../helpers/util";

import MapComponent from "./Map";
import { webSocketURL } from "../../../../config";
import { updateLiveData } from "../../../../actions/liveData";

class MapAndVisits extends React.Component {
  constructor(props) {
    super(props);
    this._webSocket = new WebSocket(`${webSocketURL}/streams/`);
  }
  componentDidMount() {
    this._webSocket.onopen = this._onOpen;
    this._webSocket.onmessage = this._onMessage;
    this._webSocket.onerror = this._onError;
    this._webSocket.onclose = this._onClose;
  }

  _onOpen = e => {
  };

  _onMessage = e => {
    const { updateLiveData } = this.props;
    const data = JSON.parse(e.data);

    updateLiveData(data);
  };

  _onError = e => {

  };

  _onClose = e => {

  };

  render() {
    const { liveData } = this.props;

    return (
      <Box>
        <Header>
          <InfoBox centerItems>
            <div>
              <InfoTitle>
                <h5>Visitors right now</h5>
              </InfoTitle>
              <InfoBody bold>
                <h3>0</h3>
                <div />
              </InfoBody>
            </div>
          </InfoBox>
          <InfoBox>
            <div>
              <InfoTitle>
                <h5>Today's totals</h5>
              </InfoTitle>
              <InfoBody>
                <h3>0</h3>
                <div />
              </InfoBody>
              <InfoSubTitle>
                <span>Sessions</span>
              </InfoSubTitle>
            </div>
          </InfoBox>
          <InfoBox>
            <div>
              <InfoBody>
                {liveData.live_stats.count === undefined ? (
                  <Spinner color="#fff" />
                ) : (
                    <h3>{liveData.live_stats.count}</h3>
                  )}
                <div />
              </InfoBody>
              <InfoSubTitle>
                <span>Transactions</span>
              </InfoSubTitle>
            </div>
          </InfoBox>
          <InfoBox>
            <div>
              <InfoBody>
                {liveData.live_stats.Total === undefined ? (
                  <Spinner color="#fff" />
                ) : (
                    <h3>{moneyFormatter.format(liveData.live_stats.Total)}</h3>
                  )}
                <div />
              </InfoBody>
              <InfoSubTitle>
                <span>Sales</span>
              </InfoSubTitle>
            </div>
          </InfoBox>
        </Header>
        <Body>
          <MapComponent />
        </Body>
      </Box>
    );
  }
}

function mapStateToProps({ liveData }) {
  return { liveData };
}

export default connect(
  mapStateToProps,
  { updateLiveData }
)(MapAndVisits);

const Box = styled.div`
  position: relative;
  box-sizing: border-box;
  margin-top: 15px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  min-height: 100px;
  width: 100%;
`;

const Header = styled.div`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  position: relative;
  background-color: #1c2260;
  display: flex;
  justify-content: space-between;
  color: #fff;
  & > div:not(:last-child) {
    margin-right: 45px;
  }
  & > div:first-child {
    border-right: 1px solid rgba(196, 205, 213, 0.2);
  }
  & > div:not(:first-child):not(:last-child) > div::after {
    content: "";
    position: absolute;
    top: 1rem;
    right: 0;
    bottom: 1rem;
    width: 1px;
    background-color: rgba(196, 205, 213, 0.2);
  }
`;

const InfoBox = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  & > div {
    flex: 1;
    padding: 18px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    ${props =>
    props.centerItems &&
    css`
        align-items: center;
        justify-content: unset;
      `};
  }
`;
const InfoTitle = styled.div`
  h5 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.6rem;
  }
`;
const InfoBody = styled.div`
  margin-top: 4px;
  h3 {
    font-weight: 300;
    font-size: 1.8rem;
  }
  ${props =>
    props.bold &&
    css`
      margin-top: 15px;
      h3 {
        font-weight: 600;
        font-size: 1.8rem;
      }
    `};
`;
const InfoSubTitle = styled.div`
  margin-top: 1px;
  span {
    font-weight: 400;
    font-size: 0.7rem;
  }
`;

const Body = styled.div`
  height: 350px;
`;
