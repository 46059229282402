import styled, { css } from "styled-components";

export const Container = styled.div`
  font-size: 14px;
  font-weight: 400;
  width: 580px;
  margin: 0 auto;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border-radius: 3px;
`;

export const Header = styled.h1`
  padding: 20px;
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
`;

export const Subtitle = styled.p`
  padding: 20px;
  margin: 0;
  border-top: 1px solid #ebeef0;
  line-height: 20px;
`;

export const Form = styled.form`
  border-top: 1px solid #ebeef0;
  padding: 20px 0;
  padding-top: 40px;
  font-size: 14px;

  input {
    display: block;
    width: 100%;
    padding: 0 10px;

    :focus {
      border: 2px solid #5c6ac4 !important;
    }
  }

  .formGroup {
    display: flex;
    padding: 0 20px;
    margin-bottom: 20px;

    .inputGroup {
      padding: 0;

      :not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .inputGroup {
    flex: 1;
    padding: 0 20px;

    label {
      display: block;
      margin-bottom: 6px;
    }

    input {
      border-radius: 3px;
      height: 36px;
      box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
      border: 1px solid #c4cdd5;
      outline: none;
      font-size: inherit;
    }
  }

  .tandc {
    margin: 20px;
    margin-bottom: 0;
    padding: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebeef0;
  }

  .buttons {
    padding-top: 20px;
    padding-right: 20px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export const Spacer = styled.div`
  height: 20px;
`;

export const Button = styled.button`
  font-size: 14px;
  color: #fff;
  padding: 8px 15px;
  border-radius: 3px;
  outline: none;
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  color: #fff;
  min-width: 138.5px;
  height: 35px;
  display: flex-inline;
  justify-content: center;
  align-items: center;
  :disabled {
    background: #bac0e6;
    border: 1px solid #a7aedf;
    cursor: not-allowed;
  }

  :not(:last-child) {
    margin-right: 10px;
  }

  ${({ cancle }) => {
    return (
      cancle &&
      css`
        background: #fff;
        color: #000;
      `
    );
  }}
`;

export const Footer = styled.footer`
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
`;
