import axios from "axios";
import { toast } from "react-toastify";

import { apiBaseURL } from "../config";

const acceptInvite = (payload, token, callback) => async () => {
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    await axios.post(`${apiBaseURL}/v1/invite/accept`, payload);

    callback();
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export default acceptInvite;
