import React from "react";
import styled, { css } from "styled-components";

import { ImageBox } from "./DepositInfo";
import Checkbox from "../../../../common/Checkbox";
import { moneyFormatter, toNaira } from "../../../../../helpers/util";
import markAsFulfilled from "../../../../../actions/coinbarter/markAsFulfilled";

export default ({ transfer }) => (
  <Container>
    <ExtendedSection>
      <ImageBox>
        <span>Bank Transfer</span>
      </ImageBox>
    </ExtendedSection>
    <Section>
      <InfoItem>
        <span className="transfer-approve">Transfer to approve</span>
      </InfoItem>
      <div>
        <ItemTable>
          <thead>
            <tr>
              <th>Items</th>
              <th className="last">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <img
                  src="https://bob.coinbarter.co/static/media/bitcoin.d7fe55f2.svg"
                  alt="bitcoin"
                  style={{ verticalAlign: "middle" }}
                />
                &nbsp;&nbsp;
                <span className="bold blue">Bitcoin</span>
              </td>
              <td className="last">
                <span className="bold">
                  {moneyFormatter.format(toNaira(transfer.amount))}
                </span>
              </td>
            </tr>
          </tbody>
        </ItemTable>
      </div>
    </Section>

    <TransferBox>
      <p className="title">Transfer to approve</p>
      <p className="subtitle">Total amount</p>
      <p className="amount">
        {moneyFormatter.format(toNaira(transfer.amount))}
      </p>
    </TransferBox>
    <Section borderTop>
      <InfoItem>
        <ActionBtn
          onClick={() => markAsFulfilled(transfer.id, "completed", "transfers")}
          disabled={transfer.status === "success"}
        >
          Mark as fulfilled
        </ActionBtn>
      </InfoItem>
    </Section>
  </Container>
);

const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 27px;
  border-radius: 3px;
  min-height: 100px;
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;

export const Section = styled.div`
  width: 100%;
  padding: 10px;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
`;

export const ExtendedSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;

  .confirmations {
    font-size: 16px;
    font-weight: 500;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 10px;
  height: 40px;

  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 500;
  }

  .transfer-approve {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }

  .bold {
    font-weight: 700;
  }

  & > div {
    display: flex;
    align-items: center;
  }
  & > div:first-child {
    img {
      margin-right: 10px;
      height: 30px;
      width: 30px;
    }
    flex: 1;
  }
  & > div:last-child {
    flex: 1.5;
    justify-content: space-between;
  }

  & > div:only-child {
    flex: 1;
    ${"" /* justify-content: flex-end; */}
  }
`;

const ActionBtn = styled.button`
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  border: 1px solid #3f4eae;
  box-shadow: inset 0 1px 0 0 #6f7bcb;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 0.7rem;
  border-radius: 3px;

  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
    background: #f6f8f9;
    box-shadow: none;
    color: initial;
    border: 1px solid #c3cdd5;
  }

  &:hover {
    background: linear-gradient(to bottom, #5c6ac4, #4959bd);
    border-color: #3f4eae;
    color: white;
  }
`;

const ItemTable = styled.table`
  width: calc(100% - 20px);
  font-size: 14px;
  font-weight: 400;
  margin: 0 10px;
  margin-top: 26px;
  margin-bottom: -10px;
  line-height: 20px;

  thead {
    border-bottom: 1px solid #ebeef0;
  }

  td {
    height: 50px;
  }

  th {
    text-align: left;
  }

  .last {
    text-align: right;
  }

  .bold {
    font-weight: 600;
  }

  .blue {
    color: rgb(26, 104, 195);
  }
`;

const TransferBox = styled.div`
  padding: 24px;
  border-top: 1px solid #ebeef0;

  .title {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  .subtitle {
    font-size: 14px;
  }

  .amount {
    font-size: 28px;
    font-weight: 500;
    margin-top: 5px;
  }
`;
