import React from "react";
// import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FilterBtn } from "../../Payouts/components/common";
import {
  Container,
  DropdownBox,
  DropdownLabel,
  DropdownSelectBox,
  DropdownSelect
} from "../../../common/Dropdowns/FilterDropdown";
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDropdown: false
    };
  }

  _toggleDropdown = () =>
    this.setState(state => ({ openDropdown: !state.openDropdown }));

  render() {
    const { openDropdown } = this.state;
    const { smallDropdownBox } = this.props;

    return (
      <Container>
        <FilterBtn onClick={this._toggleDropdown}>
          <span>Filter</span>
          <FontAwesomeIcon icon="caret-down" />
        </FilterBtn>
        <DropdownBox show={openDropdown} smallDropdownBox={smallDropdownBox}>
          <DropdownLabel>
            <span>Show all customers where:</span>
          </DropdownLabel>
          <DropdownSelectBox onClick={this._openSelect}>
            <DropdownSelect smallDropdownBox={smallDropdownBox}>
              <option>Select a filter....</option>
              <option>Status</option>
              <option>Fulfillment</option>
            </DropdownSelect>
          </DropdownSelectBox>
        </DropdownBox>
      </Container>
    );
  }
}
