import React, { Component } from "react";
import SVG from "react-inlinesvg";
import getUserProfile from "../../../actions/getUserProfile";
import { connect } from "react-redux";
import AccountDetails from "./AccountDetails";
import LoginLogs from "./LoginLogs";
import TwoStepAuth from "./TwoStepAuth";
import SaveBar from "../../common/SaveBar";
import updateProfile from "../../../actions/updateProfile";
import { ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";

import { Content, SubHead, Header, Footer } from "./elements";
import back from "../back.svg";

class UserProfile extends Component {
  state = {
    isFormDirty: false,
    data: this.props.data,
    ...this.props.data
  };

  handleFormChange = ({ target: { value, name } }) => {
    this.setState({
      isFormDirty: true,
      [name]: value
    });
  };

  handleDiscard = () => {
    this.setState({
      isFormDirty: false
    });
  };

  handleSubmit = () => {
    const { first_name, last_name, bio, phone, email } = this.state;
    const payload = {
      first_name,
      last_name,
      bio,
      phone,
      email
    };

    const { updateProfile } = this.props;

    updateProfile(payload, () => {
      this.setState({
        isFormDirty: false
      });
    });
  };

  // componentDidMount() {
  //   this.setState({
  //     data: this.props.data,
  //     ...this.props.data
  //   });
  // }

  render() {
    const { fetched, data } = this.props;
    const userState = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      bio: this.state.bio,
      phone: this.state.phone
    };

    const { isFormDirty } = this.state;
    console.log({ fetched, data, cond: !fetched || !data });
    return (
      <Content>
        {/* {(!fetched || !data) && <Redirect to="/dashboard" />} */}
        <ToastContainer
          closeButton={false}
          autoClose={2000}
          hideProgressBar={true}
        />
        {isFormDirty && (
          <SaveBar
            handleSave={this.handleSubmit}
            handleDiscard={this.handleDiscard}
          />
        )}
        {data && fetched && (
          <React.Fragment>
            <Header>
              <nav
                style={{
                  height: "45px"
                }}
              >
                <a
                  className="back-button"
                  href="#goback"
                  onClick={() => {
                    this.props.history.push("/settings/accounts");
                  }}
                >
                  <SVG src={back} className="svg" />
                  <span>Account</span>
                </a>
              </nav>
            </Header>

            <SubHead>
              <h1>
                {data.first_name} {data.last_name}
              </h1>
            </SubHead>

            {fetched && (
              <AccountDetails
                data={data}
                userState={userState}
                handleFormChange={this.handleFormChange}
              />
            )}

            {fetched && <LoginLogs login_activities={data.login_activities} />}

            <TwoStepAuth />

            {/* <Permissions
          style={{
            paddingTop: "0"
          }}
        >
          <h3 className="title">Remove staff account</h3>

          <div>
            <TwoStep>
              <h3 className="title">Deactivate staff account</h3>

              <div className="two-step-desc">
                Deactivated staff accounts will no longer have access to your
                store. You can re-activate staff accounts at any time.
              </div>

              <button className="btn">Deactivate staff account</button>
            </TwoStep>

            <TwoStep>
              <h3 className="title">Delete staff account</h3>

              <div className="two-step-desc">
                Deleted staff accounts will be permanently removed from your
                store. This action cannot be reversed.
              </div>

              <button className="btn danger">Delete staff account</button>
            </TwoStep>
          </div>
        </Permissions> */}

            <Footer>
              <button
                disabled={!isFormDirty}
                onClick={this.handleSubmit}
                className="btn"
              >
                Save
              </button>
            </Footer>
          </React.Fragment>
        )}
      </Content>
    );
  }
}

const mapStateToProps = state => ({
  data: state.user.payload,
  fetched: state.user.fetched,
  fetching: state.user.fetching
});

export default connect(
  mapStateToProps,
  {
    getUserProfile,
    updateProfile
  }
)(UserProfile);
