import axios from "axios";

import types from "./types";
import { apiBaseURL } from "../config";

const getUserProfileFulfilled = payload => ({
  type: types("get_user_profile").fulfilled,
  payload
});

const getUserProfileRejected = payload => ({
  type: types("get_user_profile").rejected,
  payload
});

const getUserProfilePending = () => ({
  type: types("get_user_profile").pending
});

const getUserProfile = callback => async dispatch => {
  // try {
  dispatch(getUserProfilePending());

  const { data } = await axios.get(`${apiBaseURL}/v1/me`);

  dispatch(getUserProfileFulfilled(data));

  callback && callback();
  // } catch (err) {
  //   dispatch(getUserProfileRejected());
  // }
};

export default getUserProfile;
