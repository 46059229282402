import React from "react";
import styled from "styled-components";
import Spinner from "react-activity/lib/Spinner";
import "react-activity/lib/Spinner/Spinner.css";

import { LoaderBox, Rect } from "../../../common/SVGLoaders";
import {
  Card,
  CardHeader,
  CardBody,
  SearchAndFilterBox,
  FilterBox,
  SearchBox,
  FilterBtn
} from "../../Payouts/components/common";

const Container = styled.div`
  margin-top: 25px;
`;
const TopLoaderBox = styled.div`
  height: 70px;
`;

const CardHeaderLoaderBox = styled.div`
  display: flex;
`;

const HeaderLoaderBox = styled.div`
  max-width: 10%;
  max-height: 20px;
  padding: 20px;
`;

const SpinnerBox = styled.div`
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default () => (
  <Container>
    <TopLoaderBox>
      <LoaderBox width={1000} height={100}>
        <Rect height="35" width="15%" />
      </LoaderBox>
    </TopLoaderBox>
    <Card>
      <CardHeader>
        <CardHeaderLoaderBox>
          <HeaderLoaderBox>
            <LoaderBox>
              <Rect height="6" width="20%" />
            </LoaderBox>
          </HeaderLoaderBox>
          <HeaderLoaderBox>
            <LoaderBox>
              <Rect height="6" width="35%" />
            </LoaderBox>
          </HeaderLoaderBox>
        </CardHeaderLoaderBox>
      </CardHeader>
      <CardBody>
        <SearchAndFilterBox>
          <FilterBox flex="15%" style={{ background: "#F4F6F8" }}>
            <FilterBtn disabled />
          </FilterBox>
          <SearchBox style={{ backgroundColor: "#F9FAFB" }} />
        </SearchAndFilterBox>
        <SpinnerBox>
          <Spinner size={30} color="#47C1BF" speed={2} />
        </SpinnerBox>
      </CardBody>
    </Card>
  </Container>
);
