import React, { Component, Fragment } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";

import {
  Content,
  SubHead,
  Header,
  Permissions,
  AccountCard,
  DeactivatedAccounts
} from "./elements";
import back from "../back.svg";
import getStaff from "../../../actions/getStaff";
import getPermissions from "../../../actions/getPermissions";
import Loader from "./Loader";

class AccountSettings extends Component {
  state = {
    showDeactivated: false
  };

  componentDidMount() {
    document.title = "Account Settings";
    const { getStaff, getPermissions } = this.props;

    getStaff();
    getPermissions();
  }

  render() {
    const {
      accountOwners,
      staffAccounts,
      deactivatedAccounts,
      fetched,
      fetching
    } = this.props;

    return (
      <Content>
        <Header>
          <nav
            style={{
              height: "45px"
            }}
          >
            <a
              className="back-button"
              href="#goback"
              onClick={() => {
                this.props.history.push("/settings");
              }}
            >
              <SVG src={back} className="svg" />
              <span>Settings</span>
            </a>
          </nav>
        </Header>
        <SubHead>
          <h1>Account</h1>
        </SubHead>

        <Permissions>
          <h3 className="title">Accounts and permissions</h3>
          <Fragment>
            {fetching && <Loader />}

            {fetched && (
              <div>
                {accountOwners.map(
                  ({ first_name, last_name, id, avatar, login_activities }) => (
                    <AccountCard key={id}>
                      <p className="owner">Account Owner</p>
                      <div className="details">
                        <div className="avatar">
                          <img src={avatar} alt="avatar" />
                        </div>

                        <div className="account-info">
                          <div>
                            <a href="#dsjfslf">
                              {first_name} {last_name}
                            </a>
                            <br />
                            <span>
                              {format(
                                login_activities[0].created_at,
                                "[Last login was] dddd, MMMM D, YYYY, h:mm a"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </AccountCard>
                  )
                )}

                {(staffAccounts.length > 0 ||
                  deactivatedAccounts.length > 0) && (
                  <AccountCard>
                    <p className="owner">
                      <span>Staff accounts</span>
                      <span className="links">
                        <a href="#dslkfj">Log out all staff accounts</a>
                        <Link to="/settings/accounts/new">
                          Add staff account
                        </Link>
                      </span>
                    </p>

                    {staffAccounts.map(
                      ({ first_name, last_name, id, avatar, permissions }) => (
                        <div key={id} className="details account active">
                          <div className="avatar">
                            <img
                              src={avatar}
                              alt={`${first_name} ${last_name}`}
                            />
                          </div>

                          <div className="account-info">
                            <div>
                              <Link to={`/settings/profile/${id}`}>
                                {first_name} {last_name}
                              </Link>
                              <br />
                              <span>Invitation Sent</span>
                            </div>
                            <span>
                              {permissions.length < 4
                                ? "Limited access"
                                : "Full access"}
                            </span>
                          </div>
                        </div>
                      )
                    )}

                    {deactivatedAccounts.length > 0 && (
                      <DeactivatedAccounts>
                        {staffAccounts.length === 0 && (
                          <div className="breather" />
                        )}

                        {this.state.showDeactivated && (
                          <div className="accountCont">
                            <span className="deact-title">
                              DEACTIVATED STAFF ACCOUNTS
                            </span>
                            {deactivatedAccounts.map(
                              ({
                                first_name,
                                last_name,
                                id,
                                avatar,
                                permissions
                              }) => (
                                <div
                                  key={id}
                                  className="details account nonactive"
                                >
                                  <div className="avatar">
                                    <img
                                      src={avatar}
                                      alt={`${first_name} ${last_name}`}
                                    />
                                  </div>

                                  <div className="account-info">
                                    <div>
                                      <Link to={`/settings/profile/${id}`}>
                                        {first_name} {last_name}
                                      </Link>
                                      <br />
                                      <span>Invitation Sent</span>
                                    </div>
                                    <span>
                                      {permissions.length < 4
                                        ? "Limited access"
                                        : "Full access"}
                                    </span>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}

                        <footer className="footer">
                          <Link
                            onClick={() => {
                              this.setState(state => ({
                                showDeactivated: !state.showDeactivated
                              }));
                            }}
                            to="#"
                          >
                            Show {deactivatedAccounts.length} deactivated
                            account
                          </Link>
                        </footer>
                      </DeactivatedAccounts>
                    )}
                  </AccountCard>
                )}

                {!staffAccounts.length && !deactivatedAccounts.length && (
                  <AccountCard>
                    <p className="owner">Staff accounts</p>
                    <div className="details">
                      <div className="account-info">
                        <div>
                          <span>
                            Customize what your staff members can edit and
                            access. You can add up to 2 staff accounts on this
                            plan. Compare plans.
                          </span>
                        </div>
                      </div>
                    </div>

                    <Link to="/settings/accounts/new" className="addStaff">
                      Add staff account
                    </Link>
                  </AccountCard>
                )}
              </div>
            )}
          </Fragment>
        </Permissions>
      </Content>
    );
  }
}

const mapStateToProps = state => ({
  accountOwners: state.staff.payload.accountOwners,
  staffAccounts: state.staff.payload.staffAccounts,
  deactivatedAccounts: state.staff.payload.deactivatedAccounts,
  fetching: state.staff.fetching,
  fetched: state.staff.fetched
});

export default connect(
  mapStateToProps,
  { getStaff, getPermissions }
)(AccountSettings);
