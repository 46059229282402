import axios from "axios";
import { toast } from "react-toastify";

import { apiBaseURL } from "../config";
import types from "./types";

const deactivateStaffFulfilled = payload => ({
  type: types("deactivate_staff").fulfilled,
  payload
});

const deactivateStaff = (staffId, callback) => async dispatch => {
  try {
    await axios.put(`${apiBaseURL}/v1/staff/${staffId}/deactivate`);

    dispatch(
      deactivateStaffFulfilled({
        active: false
      })
    );

    callback();

    toast.success("Staff account deactivated!");
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export default deactivateStaff;
