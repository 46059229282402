import styled from "styled-components";

export const Content = styled.main`
  width: 934px;
  margin: 0 auto;
  margin-top: 56px;
  position: relative;
  overflow-y: scroll;
  min-height: calc(100vh - 56px);

  .bold {
    font-weight: 700;
  }

  .blue {
    color: #1a68c3;
  }

  a {
    color: #007ace;
    text-decoration: none;
  }

  .little-gap {
    height: 5px;
  }
`;

export const Container = styled.section`
  display: flex;
  justify-content: space-between;

  .side-boxes {
    width: 291px;
  }
`;

export const Header = styled.header`
  margin-top: 24px;
  margin-bottom: 8px;
  width: 100%;

  nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .back-button {
    font-weight: 400;
    line-height: 14px;
    color: #637381;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;
    line-height: 0;

    span {
      font-size: 14px;
    }

    .svg {
      svg {
        height: 15px;
        width: 15px;
      }
    }
  }
`;

export const SubHead = styled.div`
  display: flex;
  justify-content: flex-start;
  line-height: 0;
  align-items: center;
  color: rgb(33, 43, 54);
  margin-top: 5px;
  border-bottom: 1px solid #dfe3e8;
  padding-bottom: 8px;

  h1 {
    font-size: 28px;
    font-weight: 600px;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  p {
    font-size: 14px;
  }
`;

export const SubLinks = styled.div`
  display: flex;
  color: #667380;
  font-size: 14px;
  justify-content: flex-start;
  margin-top: 11px;

  .printer-link,
  .more-actions {
    display: inline-flex;
    align-items: center;
    margin-right: 25px;
    svg {
      margin-top: 3px;
    }
  }

  .printer-link {
    svg {
      margin-right: 3px;
      width: 20px;
      height: 20px;
      fill: #667380;
    }
  }

  .more-actions {
    svg {
      margin-left: 3px;
      width: 14px;
      height: 14px;
      fill: #667380;
    }
  }
`;

export const Permissions = styled.div`
  padding: 32px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dfe3e8;

  .title {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
  }
`;

export const AccountCard = styled.div`
  margin-radius: 3px;
  padding: 20px;
  width: 622px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);

  :not(:last-child) {
    margin-bottom: 20px;
  }

  .addStaff {
    background: linear-gradient(to bottom, #6371c7, #5563c1);
    display: inline-block;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    border-color: #3f4eae;
    box-shadow: inset 0 1px 0 0 #6f7bcb;
    margin-top: 20px;
  }

  .owner {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .links {
      font-size: 14px;
      font-weight: 400;

      a:first-child {
        margin-right: 20px;
      }
    }
  }

  .account {
    padding-bottom: 16px;
    padding-top: 16px;
    border-bottom: 1px solid #dfe3e8;

    ${"" /* :not(:first-child) {
      background: red;
    } */}
  }

  .nonactive:last-child {
    border-bottom: none;
  }

  .active:last-child {
    border-bottom: none;
  }

  .details {
    display: flex;

    .avatar {
      border-radius: 50%;
      min-width: 32px;
      height: 32px;
      background: #b662ff;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }

    .account-info {
      font-size: 14px;
      font-weight: 400;
      color: #212b36;
      display: flex;
      justify-content: space-between;
      width: 100%;
      line-height: 20px;
    }
  }
`;

export const DeactivatedAccounts = styled.div`
  width: 100%;
  ${"" /* .account:last-child {
    border-bottom: none;
  } */}

  .accountCont {
    border-top: 1px solid #dfe3e8;

    .deact-title {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      display: block;
      padding-bottom: 20px;
    }

    background: #f8f9fa;
    margin: -20px;
    margin-top: 16px;
    margin-bottom: 0;
    padding: 20px;
    padding-bottom: 0;
  }

  .breather {
    height: 40px;
  }

  .footer {
    height: 61px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 -20px;
    margin-bottom: -20px;
    padding: 20px;
    font-size: 14px;
    border-top: 1px solid #dfe3e8;
  }
`;
