import React from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { Spinner } from "react-activity";

import Card from "../../../common/Card";
import { ReactComponent as SearchSVG } from "../../search.svg";

import {
  CardContainer,
  CardHeader,
  SearchBox,
  SearchResultBox,
  SearchCenterBox,
  CustomerResults,
  CustomerResultItem,
  CustomerDetail,
  SearchResultBoxCard
} from "./elements";
import Input from "./Input";
import { searchCustomers } from "../../../../actions/customers";
import { ReactComponent as CloseSVG } from "../images/closeIcon.svg";

class CustomerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      inputFocused: false,
      mouseInResultBox: false,
      customer: null
    };
  }

  debouncedSearch = debounce(query => {
    // console.log({ query });
    const { searchCustomers } = this.props;

    searchCustomers(1, "all", null, query);
  }, 300);

  handleQueryChange = e => {
    const target = e.target;
    const value = target.value;
    this.setState({ query: value.trim() });
    const trimmedValue = value.trim();

    this.debouncedSearch(trimmedValue);
  };

  onInputFocused = () => {
    const {
      customers: {
        customers: { users }
      },
      searchCustomers
    } = this.props;
    if (users.length === 0) {
      searchCustomers(1, "all", null, this.state.query);
    }
    this.toggleInputFocus();
  };

  toggleInputFocus = () =>
    this.setState(state => ({ inputFocused: !state.inputFocused }));

  toggleMouseInResultBox = () =>
    this.setState(state => ({ mouseInResultBox: !state.mouseInResultBox }));

  onCustomerSelected = customer => {
    console.log({ customer });
    this.setState({ customer }, () => {
      this.props.onCustomerChanged(customer);
      this.toggleMouseInResultBox();
    });
  };

  clearCustomer = () =>
    this.setState({ customer: null }, () => this.props.onCustomerChanged(null));

  render() {
    const {
      customers: { customers, searching }
    } = this.props;
    const { inputFocused, mouseInResultBox, customer } = this.state;
    return (
      <CardContainer>
        <Card>
          {!customer ? (
            <React.Fragment>
              <CardHeader>
                <h1>Select a customer</h1>
              </CardHeader>
              <SearchBox>
                <Input
                  //prefix="🕵🏽‍"
                  prefixIcon={<SearchSVG />}
                  value={this.state.query}
                  onChange={this.handleQueryChange}
                  onFocus={this.onInputFocused}
                  onBlur={this.toggleInputFocus}
                />
                {(searching || customers.users) &&
                  (inputFocused || mouseInResultBox) && (
                    <SearchResultBox
                      onMouseEnter={this.toggleMouseInResultBox}
                      onMouseLeave={this.toggleMouseInResultBox}
                    >
                      <SearchResultBoxCard>
                        {searching ? (
                          <SearchCenterBox>
                            <Spinner size={30} color="#47C1BF" speed={2} />
                          </SearchCenterBox>
                        ) : customers.users.length === 0 ? (
                          <SearchCenterBox>
                            <h3>No Customers</h3>
                          </SearchCenterBox>
                        ) : (
                          <CustomerResults>
                            {customers.users.map(u => (
                              <CustomerResultItem
                                key={u.id}
                                onClick={this.onCustomerSelected.bind(this, u)}
                              >
                                <div>
                                  <img src={u.profile.avatar} alt="avatar" />
                                </div>
                                <div>
                                  <span className="name">{`${
                                    u.profile.first_name
                                  } ${u.profile.last_name}`}</span>
                                  <span className="email">{u.email}</span>
                                </div>
                              </CustomerResultItem>
                            ))}
                          </CustomerResults>
                        )}
                      </SearchResultBoxCard>
                    </SearchResultBox>
                  )}
              </SearchBox>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <CardHeader>
                <h1>Customer</h1>
                <button className="action" onClick={this.clearCustomer}>
                  <CloseSVG />
                </button>
              </CardHeader>
              <CustomerDetail>
                <div>
                  <img src={customer.profile.avatar} alt="avatar" />
                </div>
                <div>
                  <h3>
                    {`${customer.profile.first_name} ${
                      customer.profile.last_name
                    }`}
                  </h3>
                </div>
                <div>
                  <h4>{customer.email}</h4>
                </div>
              </CustomerDetail>
            </React.Fragment>
          )}
        </Card>
      </CardContainer>
    );
  }
}

export default connect(
  ({ customers }) => ({ customers }),
  { searchCustomers }
)(CustomerForm);
