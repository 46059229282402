import types from "../actions/types";

const initialState = {
  fetching: false,
  fetched: false,
  error: "",
  payload: null
};

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case types("get_user_profile").fulfilled:
      return {
        fetching: false,
        fetched: true,
        payload
      };

    case types("update_profile").fulfilled:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...payload
        }
      };

    case types("get_user_profile").pending:
      return {
        ...state,
        fetching: true,
        fetched: false
      };

    case types("get_user_profile").rejected:
      return {
        ...state,
        payload,
        fetching: false,
        fetched: false
      };
    default:
      return state;
  }
};

export default user;
