import types from "../actions/types";

const initialState = {
  fetching: false,
  fetched: false,
  error: "",
  payload: {}
};

const staff = (state = initialState, { type, payload }) => {
  const cases = {
    [types("get_staff").fulfilled]: () => ({
      fetching: false,
      fetched: true,
      payload
    }),
    [types("get_staff").pending]: () => ({
      ...state,
      fetching: true,
      fetched: false
    }),
    [types("get_staff").rejected]: () => ({
      ...state,
      payload: {
        accountOwners: [],
        staffAccounts: []
      },
      fetching: false,
      fetched: true
    }),
    DEFAULT: () => state
  };

  return cases[type] ? cases[type]() : cases["DEFAULT"]();
};

export default staff;
