import React from "react";
import Steps, { Step } from "rc-steps";
import {
  DescCont,
  SubTitle,
  CircledTick,
  StepContainer,
  UncheckedCircle
} from "./elements";
import { formatAMPM } from "../../../../../helpers/util";

import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import "./style.css";

const FilledTick = () => <CircledTick />;
const UnCheckedCircle = () => <UncheckedCircle />;

class App extends React.Component {
  render() {
    const { events } = this.props;
    return (
      <StepContainer>
        <SubTitle>Events</SubTitle>
        <Steps direction="vertical">
          {events.map(({ created_at, event }, key) => {
            const Icon = true ? <FilledTick /> : <UnCheckedCircle />;
            const description = (
              <DescCont last={key === events.length - 1}>
                <span className="event">{event}</span>
                <span>{formatAMPM(created_at)}</span>
              </DescCont>
            );

            return (
              <Step
                key={key}
                icon={Icon}
                description={description}
                status="finish"
              />
            );
          })}
        </Steps>
      </StepContainer>
    );
  }
}

App.defaultProps = { events: [] };
export default App;
