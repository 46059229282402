import React from "react";
import Case from "case";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import pluralize from "pluralize";

import Table from "../../../../blocks/Table";

// import Checkbox from "../../../common/Checkbox";
import { HeaderCheckBoxBox, NameText, NameBox, Status } from "./elements";
// import ActionDropdown from "./ActionDropdown";

function KYCTable({ customers: { list } }) {
  return (
    <Table>
      <Table.Head>
        <Table.HeadRow>
          <Table.HeadData colSpan={5} hasSelect>
            <HeaderCheckBoxBox>
              <span>
                Showing {pluralize("customer", list.Documents.length, true)}
              </span>
            </HeaderCheckBoxBox>
          </Table.HeadData>
        </Table.HeadRow>
      </Table.Head>
      <Table.Body>
        {list &&
          list.Documents.map(customer => (
            <Table.BodyRow key={customer._id}>
              <Table.BodyData first>
                <Link to={`/customers/kyc/${customer._id}`}>
                  <NameBox>
                    <NameText>
                      #{Case.capital(`${customer.reference}`)}
                    </NameText>
                  </NameBox>
                </Link>
              </Table.BodyData>
              <Table.BodyData>
                <Link to={`/customers/kyc/${customer._id}`}>
                  <NameBox>
                    <NameText reference>
                      {Case.capital(`${customer.full_name}`)}
                    </NameText>
                  </NameBox>
                </Link>
              </Table.BodyData>
              <Table.BodyData>
                <Link to={`/customers/kyc/${customer._id}`}>
                  <NameBox>
                    <Status>
                      <span className={`status-indicator ${customer.status}`}>
                        {Case.capital(`${customer.status}`)}
                      </span>
                    </Status>
                  </NameBox>
                </Link>
              </Table.BodyData>
              <Table.BodyData>
                <Link to={`/customers/kyc/${customer._id}`}>
                  <Table.BodyDataBox className="align-right padding-right newcont">
                    <span className="align-right">
                      {format(customer.created_at, "MMM DD, YYYY")}
                    </span>
                  </Table.BodyDataBox>
                </Link>
              </Table.BodyData>
            </Table.BodyRow>
          ))}
      </Table.Body>
    </Table>
  );
}

export default KYCTable;
