import React from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import format from "date-fns/format";

import {
  Container,
  Body,
  Header,
  NextPrev,
  ActiontBtnBox,
  ActionBtnsBox,
  ReferenceAndDateBox
} from "./components/infoElements";
import { ReactComponent as BackSVG } from "./svgs/back.svg";
import { ReactComponent as ArrowSVG } from "./svgs/arrow.svg";
import { ReactComponent as PrintSVG } from "./svgs/print.svg";
import ActionDropdown from "./components/ActionDropdown";
import { fetchOneSwap } from "../../../actions/transactions";
import TransactionInfoLoader from "../../common/SVGLoaders/InfoLoader";
import CustomerCard from "./components/CustomerCard";
import ProductBreakdown from "./components/ProductBreakdown";
import TransactionInfoCard from "./components/TransactionInfoCard";
import SwapBreakdown from "./components/SwapBreakdown";
import SwapInfoCard from "./components/SwapInfoCard";

class TransactionDetail extends React.Component {
  componentDidMount() {
    const {
      match: {
        params: { swapId }
      },
      fetchOneSwap
    } = this.props;
    fetchOneSwap(swapId);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    const {
      transaction: { transaction, fetching }
    } = this.props;
    return fetching || !transaction ? (
      <TransactionInfoLoader />
    ) : (
      <Container>
        <Header>
          <nav>
            <a
              className="back-button"
              href="#goback"
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              <BackSVG className="svg" />
              <span>Swaps</span>
            </a>

            <div>
              <NextPrev>
                <ArrowSVG className="arrow-left" />
              </NextPrev>
              <NextPrev>
                <ArrowSVG className="arrow-right" />
              </NextPrev>
            </div>
          </nav>
        </Header>
        <ReferenceAndDateBox>
          <h1>#{transaction.reference}</h1>
          <span>
            {format(transaction.created_at, "MMMM D, YYYY [at] hh:mm a")}
          </span>
        </ReferenceAndDateBox>
        <ActionBtnsBox>
          <ActiontBtnBox>
            <PrintSVG />
            <span>Print</span>
          </ActiontBtnBox>
          <ActionDropdown />
        </ActionBtnsBox>
        <Body>
          <div>
            <SwapBreakdown transaction={transaction} />
            <SwapInfoCard transaction={transaction} />
          </div>
          <div>
            <CustomerCard customer={transaction.user} />
          </div>
        </Body>
      </Container>
    );
  }
}

function mapStateToProps({ transaction }) {
  return { transaction };
}

export default connect(
  mapStateToProps,
  { fetchOneSwap }
)(TransactionDetail);
