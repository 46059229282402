import axios from "axios";
import types from "../types";
import { coinBarterApi } from "../../config";
import { toast } from "react-toastify";

const getTransfersFulfilled = payload => ({
  type: types("get_transfers").fulfilled,
  payload
});

const getTransfersPending = () => ({
  type: types("get_transfers").pending
});

const getOneTransferFulfilled = payload => ({
  type: types("get_one_transfer").fulfilled,
  payload
});

export const getTransfers = (
  page = 1,
  status = "all",
  callback
) => async dispatch => {
  const queryString =
    status === "all" ? `?page=${page}` : `?status=${status}&page=${page}`;

  try {
    dispatch(getTransfersPending());
    const { data } = await axios.get(
      `${coinBarterApi}/fence/transfers${queryString}`
    );

    dispatch(getTransfersFulfilled(data));
    callback(data.meta.total_entries_size);
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const getOneTransfer = (transferID, viaTrade) => async dispatch => {
  try {
    let toDispatch;

    if (viaTrade === true) {
      const { data } = await axios.get(
        `${coinBarterApi}/fence/transfers/trade/${transferID}`
      );

      toDispatch = data;
    } else {
      const { data } = await axios.get(
        `${coinBarterApi}/fence/transfers/${transferID}`
      );

      toDispatch = data;
    }

    dispatch(getOneTransferFulfilled(toDispatch));
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
