import axios from "axios";
import { toast } from "react-toastify";

import { apiBaseURL } from "../config";
import types from "./types";

const activateStaffFulfilled = payload => ({
  type: types("activate_staff").fulfilled,
  payload
});

const activateStaff = (staffId, callback) => async dispatch => {
  try {
    await axios.put(`${apiBaseURL}/v1/staff/${staffId}/activate`);

    dispatch(
      activateStaffFulfilled({
        active: true
      })
    );

    callback();

    toast.success("Staff account activated!");
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export default activateStaff;
