import axios from "axios";

import { GET_PRODUCTS } from "./types";
import { apiBaseURL } from "../config";

const getProductAction = () => ({
  type: GET_PRODUCTS.default,
  payload: axios.get(`${apiBaseURL}/v1/products`)
});

export const getProducts = () => async dispatch => dispatch(getProductAction());
