import types from "../actions/types";
import storage from "../helpers/storage";
import axios from "axios";

const initialState = {
  token: initializeToken(),
  error: "",
  payload: storage.get("payload"),
  fetching: false,
  fetched: false,
  authed: false
};

const auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case types("login").fulfilled:
    case types("set_user").fulfilled:
      return {
        ...state,
        fetching: false,
        fetched: true,
        payload,
        token: payload.access_token,
        authed: true
      };

    case types("login").pending:
      return {
        ...state,
        fetched: false,
        fetching: true
      };

    case types("login").rejected:
      return {
        ...state,
        error: payload,
        fetching: false,
        fetched: false
      };

    case types("logout").fulfilled:
      return {
        ...state,
        token: null,
        payload: null,
        authed: false
      };

    default:
      return state;
  }
};

export default auth;

function initializeToken() {
  const token = storage.get("payload") && storage.get("payload").access_token;
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  return token;
}
