import {
  GET_MARKETPLACE_ASSET_ADS_SUMMARY,
  GET_MARKETPLACE_ASSET_TRADES_SUMMARY
} from "../../types";

const initialState = {
  assetsSummary: {},
  tradeAssetsSummary: {},
  fetchedAssetsSummary: false,
  fetchingAssetsSummary: false,
  fetchedtradeAssetsSummary: false,
  fetchingtradeAssetsSummary: false,
  error: null
};

function marketplaceSummary(state = initialState, action) {
  switch (action.type) {
    case GET_MARKETPLACE_ASSET_ADS_SUMMARY.pending:
      return {
        ...state,
        fetchedAssetsSummary: false,
        fetchingAssetsSummary: true
      };
    case GET_MARKETPLACE_ASSET_ADS_SUMMARY.fulfilled:
      return {
        ...state,
        fetchedAssetsSummary: true,
        fetchingAssetsSummary: false,
        assetsSummary: action.payload
      };
    case GET_MARKETPLACE_ASSET_ADS_SUMMARY.rejected:
      return {
        ...state,
        error: action.payload,
        fetchedAssetsSummary: false,
        fetchingAssetsSummary: false
      };

    case GET_MARKETPLACE_ASSET_TRADES_SUMMARY.pending:
      return {
        ...state,
        fetchedtradeAssetsSummary: false,
        fetchingtradeAssetsSummary: true
      };
    case GET_MARKETPLACE_ASSET_TRADES_SUMMARY.fulfilled:
      return {
        ...state,
        fetchedtradeAssetsSummary: true,
        fetchingtradeAssetsSummary: false,
        tradeAssetsSummary: action.payload
      };
    case GET_MARKETPLACE_ASSET_TRADES_SUMMARY.rejected:
      return {
        ...state,
        error: action.payload,
        fetchedtradeAssetsSummary: false,
        fetchingtradeAssetsSummary: false
      };
    default:
      return state;
  }
}

export default marketplaceSummary;
