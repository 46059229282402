import React from "react";

import { Permissions, LoginHistory } from "./elements";
import chopArrayHead from "../../../helpers/chopArrayHead";
import whatDayTime from "../../../helpers/whatDayTime";

const LoginLogs = ({ login_activities, customerType }) => (
  <Permissions customerType={customerType}>
    {customerType || (
      <div>
        <h3 className="title">Recent login history</h3>

        <div className="desc">
          Access from an ISP, Location, or IP address you don’t recognize may
          indicate that the account has been compromised and should be reviewed
          further.
        </div>
      </div>
    )}

    <LoginHistory>
      <table>
        <thead>
          <tr>
            <td>Date</td>
            <td>IP</td>
            {customerType || <td>ISP</td>}
            <td>Location</td>
          </tr>
        </thead>

        <tbody>
          {chopArrayHead(login_activities, [0, 9]).map(
            ({ created_at, ip, isp, location, agent, near }) => (
              <tr key={created_at}>
                <td>{whatDayTime(created_at)}</td>
                <td>{ip}</td>
                {customerType || <td>{isp}</td>}
                <td>{location || near}</td>
              </tr>
            )
          )}
        </tbody>
      </table>

      {customerType || <button className="btn">Log out Michael Adeyeri</button>}
    </LoginHistory>
  </Permissions>
);

export default LoginLogs;
