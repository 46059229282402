import React from "react";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";

const Container = styled.div`
  padding: 10px;
`;

export default ({ data }) => {
  const dataObject = {
    labels: ["Active carts", "Checking out", "Purchased"],
    datasets: [
      {
        data,
        fillColor: "rgba(32, 46, 120 ,0.5)",
        strokeColor: "rgba(32, 46, 120 ,0.8)",
        highlightFill: "rgba(32, 46, 120 ,0.75)",
        highlightStroke: "rgba(32, 46, 120 ,1)"
      }
    ]
  };
  return (
    <Container>
      <Bar data={dataObject} />
    </Container>
  );
};
