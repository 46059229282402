import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  box-sizing: border-box;
  * {
    margin: 0;
    padding: 0px;
    font-size: 20px;
    box-sizing: border-box;
  }
  h4 {
    font-size: 0.8em;
  }
  h3 {
    font-size: 1em;
  }
`;
export const ItemHeader = styled.div`
  h3 {
    font-weight: 600;
  }
`;
export const ItemSubHeader = styled.div`
  margin-top: 30px;
  h4 {
    font-weight: 500;
  }
`;
export const ItemKeyValPair = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .key-box {
    h4 {
      font-weight: 500;
    }
    h5 {
      margin-top: 10px;
      color: #637381;
      font-size: 0.7em;
    }
  }
  .val-box {
  }
`;
