import React from "react";
import styled, { css, keyframes } from "styled-components";
import Case from "case";

import { EmptyBox } from "./common";
import { ReactComponent as EmptyBoxSVG } from "../svgs/empty-box.svg";

const zoomIn = keyframes`
  from {
    transform: scale(0.8)
  }

  to {
    transform: scale(1)
  }
`;
export const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border-radius: 3px;
  min-height: 100px;
`;

export const Section = styled.div`
  width: 100%;
  position: relative;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
  padding: 20px;
  .elevate-modal {
    position: absolute;
    top: 25%;
    left: 20%;
    z-index: 1;
  }
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;
export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #212b36;
    font-size: 0.6rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;

export const SectionBody = styled.div`
  padding: 20px 0px;
`;

export const Info = styled.div`
  span.action {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span.action:hover {
    text-decoration: underline;
  }
  span.card-number {
    margin-left: 5px;
  }
  p {
    font-size: 0.7rem;
    color: #212b36;
    padding: 5px 0px;
  }
`;

const SpecialModal = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  width: 368px;
  max-height: 398px;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05),
    0 2px 7px 1px rgba(39, 44, 48, 0.16);
  border-radius: 3px;
  max-width: 368px;
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  animation: ${zoomIn} 0.06s linear;
  header {
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid rgb(223, 227, 232);
    .title {
      font-weight: 600;
      font-size: 16px;
    }
    button {
      font-size: 14px;
      color: #007ace;
      cursor: pointer;
      background: none;
      border: none;
    }
    button:hover {
      text-decoration: underline;
    }
  }
  section {
    max-height: 236px;
    overflow-y: scroll;
  }
`;
const EachChild = styled.div`
  padding: 16px;
  border-bottom: ${({ hide }) => (hide ? "0" : "1px")} solid rgb(223, 227, 232);
  div {
    padding: 0;
  }
`;
const Modal = ({ title, headerClick, headerLable, Child, list }) =>
  list &&
  list.length > 0 && (
    <SpecialModal>
      <header>
        <span className="title">{title}</span>{" "}
        <button onClick={headerClick}>{headerLable}</button>
      </header>
      <section>
        {list.map((val, key) => (
          <EachChild key={key} hide={key === list.length - 1}>
            <Child {...val} />
          </EachChild>
        ))}
      </section>
    </SpecialModal>
  );

const getCardType = card_type => {
  const cardTypeStringArray = card_type.split(" ");
  return (
    Case.capital(`${cardTypeStringArray[0]}`) + ` ${cardTypeStringArray[1]}`
  );
};
const Banks = ({ account_name, account_number, name }) => (
  <SectionBody>
    <Info>
      <p>{Case.capital(account_name)}</p>
      <p>{account_number}</p>
      <p>{name}</p>
    </Info>
  </SectionBody>
);

const Cards = ({ card_type, last4, bank, country_code }) => (
  <SectionBody>
    <Info>
      <p>{getCardType(card_type)}</p>
      <p>{`•••• •••• •••• ${last4}`}</p>
      <p>{`${bank} (${country_code})`}</p>
    </Info>
  </SectionBody>
);
export default class extends React.Component {
  state = {
    showmorecards: false,
    showbanks: false,
    showmorebanks: false
  };
  onToggleShowMoreBanks = () => {
    this.setState(({ showmorebanks }) => ({
      showmorebanks: !showmorebanks
    }));
  };

  onToggleShowMoreCards = () => {
    this.setState(({ showmorecards }) => ({
      showmorecards: !showmorecards
    }));
  };
  render() {
    const {
      customer,
      toggleEditModal,
      toggleEditPhoneModal,
      onToggleShowMoreCards,
      toggleEditContactModal
    } = this.props;
    const { showbanks, showmorebanks, showmorecards } = this.state;
    let cardTypeString = "";

    if (customer.cards.length > 0) {
      const cardTypeStringArray = customer.cards[0].card_type.split(" ");
      cardTypeString =
        Case.capital(`${cardTypeStringArray[0]}`) +
        ` ${cardTypeStringArray[1]}`;
    }
    console.log({ customer });
    return (
      <Container>
        <Section>
          <MainHeader>
            <h3>Contact</h3>
            <span onClick={toggleEditContactModal}>Edit</span>
          </MainHeader>
          <SectionBody>
            <Info>
              <p>
                <span className="action">{customer.email}</span>
              </p>
              {customer.phone.number && <p>{customer.phone.number}</p>}
            </Info>
          </SectionBody>
        </Section>

        <Section borderTop>
          <MainHeader>
            <h3>Phone</h3>
            <span onClick={toggleEditPhoneModal}>Edit</span>
          </MainHeader>
          <SectionBody>
            <Info>
              {customer.phone.number && <p>{customer.phone.number}</p>}
            </Info>
          </SectionBody>
        </Section>

        <Section borderTop>
          <MainHeader>
            <h3>Address</h3>
            <span onClick={toggleEditModal}>Edit</span>
          </MainHeader>
          <SectionBody>
            <Info>
              <p>
                {`${customer.profile.first_name} ${customer.profile.last_name}`}
              </p>
              <p>{`${customer.profile.city} `}</p>
              <p>{`${customer.profile.postal_code} ${customer.profile.city} ${
                customer.profile.state
              }`}</p>
              <p>{`${customer.profile.country}`}</p>
            </Info>
          </SectionBody>
        </Section>
        <Section borderTop>
          <SubHeader>
            <h3>Bank Verification Number</h3>
          </SubHeader>
          {customer.profile.bvn ? (
            <SectionBody>
              <Info>
                <p>{customer.profile.bvn}</p>
              </Info>
            </SectionBody>
          ) : (
            <EmptyBox padding="40px 20px 0px">
              <div>
                <span className="text-center empty-state ">No BVN</span>
              </div>
            </EmptyBox>
          )}
        </Section>
        <Section borderTop>
          <SubHeader>
            <h3>Default Card</h3>
            {customer.cards && (
              <span onClick={this.onToggleShowMoreCards}>View More</span>
            )}
          </SubHeader>
          {customer.cards.length > 0 ? (
            <Cards {...customer.cards[0]} />
          ) : (
            <EmptyBox padding="40px 20px 0px">
              <div>
                <span className="text-center empty-state ">No card</span>
              </div>
            </EmptyBox>
          )}
          {showmorecards && (
            <article className="elevate-modal">
              <Modal
                title="Change bank"
                headerClick={() => alert("YH")}
                headerLable="Add new bank"
                list={customer.cards}
                Child={Cards}
              />
            </article>
          )}
        </Section>
        <Section borderTop>
          <SubHeader>
            <h3>Default Bank</h3>
            {customer.banks && (
              <span onClick={this.onToggleShowMoreBanks}>View More</span>
            )}
          </SubHeader>
          {customer.banks.length > 0 ? (
            <Banks
              name={customer.banks[0].name}
              account_name={customer.banks[0].account_name}
              account_number={customer.banks[0].account_number}
            />
          ) : (
            <EmptyBox padding="40px 20px 0px">
              <div>
                <span className="text-center empty-state ">No bank</span>
              </div>
            </EmptyBox>
          )}
          {showmorebanks && (
            <article className="elevate-modal">
              <Modal
                title="Change bank"
                headerClick={() => alert("YH")}
                headerLable="Add new bank"
                list={customer.banks}
                Child={Banks}
              />
            </article>
          )}
        </Section>
      </Container>
    );
  }
}
