import React, { Component } from "react";
import styled, { css } from "styled-components";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography
} from "react-simple-maps";
import { Motion, spring } from "react-motion";

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const MComposableMap = styled(ComposableMap).attrs({
  projectionConfig: {
    scale: 300
    // rotation: [-11, 0, 0]
  }
})`
  width: 100%;
  height: 100%;
  background-color: #202e78;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;

const MapButtonBox = styled.div`
  position: absolute;
  background-color: transparent;
  width: 5%;
  top: 10px;
  right: 10px;
  div {
    background-color: transparent;
    /* width: 20%; */
    display: flex;
  }
`;
const MapButton = styled.button.attrs({
  type: "button"
})`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: linear-gradient(to bottom, white, #f9fafb);
  outline: none;
  cursor: pointer;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border: 1px solid #c4cdd5;
  color: #212b36;
  transition-property: background, border, box-shadow, -webkit-box-shadow;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-duration: 200ms;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.5rem;
  padding: 5px 0px;
  ${props =>
    props.left
      ? css`
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          svg {
            transform: rotate(270deg);
          }
        `
      : css`
          /* border-top-left-radius: 0;
          border-bottom-left-radius: 0; */
          border-left: none
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          svg {
            transform: rotate(90deg);
          }
        `};

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      background: #f4f6f8;
      border-color: #c4cdd5;
      color: #919eab;
      outline: 0;
      box-shadow: none;
    `};

  &:hover {
    background: linear-gradient(to bottom, #f9fafb, #f4f6f8);
  }

  &:active {
    background: linear-gradient(to bottom, #f4f6f8, #f4f6f8);
    border-color: #c4cdd5;
    box-shadow: inset 0 1px 1px 0 rgba(99, 115, 129, 0.1),
      inset 0 1px 4px 0 rgba(99, 115, 129, 0.2);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

class MapComponent extends Component {
  state = {
    zoom: 2,
    center: [0, 10]
  };

  _increaseZoom = () => this.setState(state => ({ zoom: state.zoom + 0.5 }));
  _decreaseZoom = () =>
    this.setState(state => ({
      zoom: state.zoom !== 0.5 ? state.zoom - 0.5 : 0.5
    }));

  render() {
    const { zoom, center } = this.state;
    return (
      <MapWrapper>
        <MapButtonBox>
          <div>
            <MapButton left onClick={this._increaseZoom}>
              +
            </MapButton>
            <MapButton right onClick={this._decreaseZoom}>
              -
            </MapButton>
          </div>
        </MapButtonBox>
        <Motion
          defaultStyle={{
            zoom: zoom,
            x: 0,
            y: 10
          }}
          style={{
            zoom: spring(zoom, { stiffness: 210, damping: 20 }),
            x: spring(center[0], { stiffness: 210, damping: 20 }),
            y: spring(center[1], { stiffness: 210, damping: 20 })
          }}
        >
          {({ zoom, x, y }) => (
            <MComposableMap>
              <ZoomableGroup center={[x, y]} zoom={zoom}>
                <Geographies geography="/static/world-50m.json">
                  {(geographies, projection) =>
                    geographies.map(
                      (geography, i) =>
                        geography.id !== "ATA" && (
                          <Geography
                            key={i}
                            geography={geography}
                            projection={projection}
                            style={{
                              default: {
                                fill: "#5c6ac4",
                                stroke: "#202e78",
                                strokeWidth: 0.5,
                                outline: "none"
                              },
                              hover: {
                                fill: "#5c6ac4",
                                stroke: "#202e78",
                                strokeWidth: 0.5,
                                outline: "none"
                              },
                              pressed: {
                                fill: "#5c6ac4",
                                stroke: "#202e78",
                                strokeWidth: 0.5,
                                outline: "none"
                              }
                            }}
                          />
                        )
                    )
                  }
                </Geographies>
              </ZoomableGroup>
            </MComposableMap>
          )}
        </Motion>
      </MapWrapper>
    );
  }
}

export default MapComponent;
