import styled, { css } from "styled-components";

export const Content = styled.main`
  width: 50%;
  margin: 0 auto;
  padding: 0px 32px;
  margin-top: 56px;
  position: relative;
  min-height: 100%;
  margin-bottom: 40px;

  .bold {
    font-weight: 700;
  }

  .blue {
    color: #1a68c3;
  }

  a {
    color: #007ace;
    text-decoration: none;
  }

  .little-gap {
    height: 5px;
  }

  @media (max-width: 2000px) {
    width: 60%;
  }

  @media (max-width: 1800px) {
    width: 70%;
  }
`;

export const Container = styled.section`
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 2;
    padding-right: 10px;
  }

  .side-boxes {
    /* width: 291px; */
    flex: 1;
    padding-left: 10px;
  }
`;

export const Header = styled.header`
  margin-top: 28px;
  margin-bottom: 8px;
  width: 100%;

  nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .back-button {
    font-weight: 400;
    line-height: 14px;
    font-size: 0.7rem;
    color: #637381;
    border: none;
    outline: none;
    display: inline-flex;
    align-items: center;

    & > svg {
      width: 20px;
      height: 20px;
      fill: #637381;
      margin: 0 0 0 -6px;
    }
  }
`;

export const NextPrev = styled.a`
  height: 15px;
  color: #637381;
  margin-left: 15px;

  .arrow-left {
    svg {
      transform: rotate(-90deg);
    }
  }

  .arrow-right {
    svg {
      transform: rotate(90deg);
    }
  }

  svg {
    height: 16px;
    width: 16px;
    path {
      fill: #637381;
    }
  }
`;

export const SubHead = styled.div`
  display: flex;
  justify-content: flex-start;
  /* line-height: 0; */
  align-items: center;
  color: rgb(33, 43, 54);
  margin-top: 13px;

  h1 {
    font-size: 28px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
  }
`;

export const SubLinks = styled.div`
  display: flex;
  color: #667380;
  font-size: 14px;
  justify-content: flex-start;
  margin-top: 11px;

  .printer-link,
  .more-actions {
    display: inline-flex;
    align-items: center;
    margin-right: 25px;
    svg {
      margin-top: 3px;
    }
  }

  .printer-link {
    svg {
      margin-right: 3px;
      width: 20px;
      height: 20px;
      fill: #667380;
    }
  }

  .more-actions {
    svg {
      margin-left: 3px;
      width: 14px;
      height: 14px;
      fill: #667380;
    }
  }
`;

export const Notes = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 3px;
  width: 100%;
  height: 100px;
  margin-top: 31px;
  padding: 23px 20px;

  .title {
    color: rgb(33, 43, 54);
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    display: flex;
    justify-content: space-between;

    a {
      font-size: 14px;
      color: #007ace;
      text-decoration: none;
      font-weight: 400;
    }
  }

  .text {
    color: #637381;
    font-size: 14px;
  }
`;

export const Customer = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border-radius: 3px;

  .customer-name {
    padding: 23px 20px;
    border-bottom: 1px solid #edf0f2;
  }

  .title {
    color: rgb(33, 43, 54);
    font-size: 16px;
    font-weight: 600;
    margin: 0;

    display: flex;
    justify-content: space-between;

    img {
      border-radius: 100%;
      width: 30px;
      height: 30px;
    }
  }

  .body {
    font-size: 14px;
    margin-top: 17px;

    p {
      margin: 0;
      padding: 0;
    }
  }

  .subtitle {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 23px;

    display: flex;
    justify-content: space-between;

    a {
      font-size: 14px;
      text-transform: none;
      color: #007ace;
      text-decoration: none;
      font-weight: 400;
    }
  }

  .contact-info {
    border-bottom: 1px solid #edf0f2;
    font-size: 12px;
    padding: 23px 20px;

    p {
      margin: 0;
      padding: 0;
      font-size: 14px;

      &.light {
        color: #637381;
        margin-top: 5px;
      }
    }
  }
`;

export const TimelineBody = styled.div`
  border-left: 3px solid #e3e6e9;
  margin-top: 20px;
  padding-left: 20px;
  position: relative;

  .bullet {
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    background: #c3cfd8;
  }

  p {
    padding: 0;
    margin: 0;
  }
`;

export const Footer = styled.footer`
  margin-top: 40px;
  width: 100%;
  border-top: 1px solid #dfe3e8;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;

  button.red {
    height: 36px;
    border: 1px solid #b02b13;
    border-radius: 3px;
    font-size: 14px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    background-image: linear-gradient(rgb(230, 57, 26), rgb(213, 52, 23));
    color: #fff;

    &:disabled {
      background: #f0f0f0;
      cursor: not-allowed;
      color: #000;
      border: 1px solid #c7cbce;
    }
  }

  button.save {
    height: 36px;
    border: 1px solid #a7aedf;
    border-radius: 3px;
    font-size: 14px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    background: #bac0e6;
    color: #fff;
  }
`;

export const CustomerInfo = styled.div`
  background: #fff;
  margin-top: 20px;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  .top {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    .avatar {
      width: 40px;
      height: 40px;
      display: inline-block;
      margin-right: 20px;
      padding-left: 20px;
      padding-top: 11px;

      img {
        height: 40px;
        width: 40px;
        border-radius: 100%;
      }
    }

    .bio {
      padding-top: 24px;
      margin-left: 20px;
      width: 100%;
      padding-right: 20px;
      .title {
        color: #212b36;
        padding: 0;
        margin: 0;
        margin-bottom: 15px;
        font-size: 0.8rem;
        font-weight: 600;
      }

      p:first-of-type {
        margin: 0;
        padding: 0;
        color: #637381;
        font-weight: 400;
        font-size: 14px;
        margin-top: 7px;
      }
      p:last-of-type {
        margin: 0;
        padding: 0;
        color: #637381;
        font-weight: 400;
        font-size: 14px;
        margin-top: 6px;
      }
    }
  }

  .customer-note {
    padding: 20px;
    padding-top: 0;
    border-bottom: 1px solid #ebeef0;

    label {
      display: block;
      color: #212b36;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 7px;
    }

    input {
      width: 100%;
      display: block;
      height: 34px;
      border: 1px solid #c4cdd5;
      box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
      border-radius: 3px;
      padding: 0 8px 1px;
      font-size: 14px;
      color: #212b36;
      outline: none;
    }
    input::placeholder {
      color: #939ea9;
    }
    input:focus {
      border: 1px solid #5c6ac4;
      box-shadow: 0 0 0 1px #5c6ac4;
    }
  }

  .footer {
    padding: 17px;
    display: flex;

    .footer-content {
      text-align: center;
      width: 200px;
      margin-top: 5px;
      margin-left: 10px;

      p {
        padding: 0;
        margin: 0;
        font-size: 14px;
        margin-bottom: 5px;
        color: #637381;
      }

      h3 {
        margin: 0;
        padding: 0;
        font-size: 0.8rem;
        line-height: 25px;
        font-weight: 600;
        margin-bottom: 7px;
        color: #212b36;
      }
    }
  }
  .button-cont {
    border-top: 1px solid rgba(0, 0, 0, 0.5);
  }
  .space {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ${({ noshadow }) =>
    noshadow &&
    css`
      min-height: 191px;
      padding: 4px;
      overflow-y: scroll;
      position: relative;
      box-shadow: none;
    `}
`;

export const RecentOrders = styled.div`
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  margin-top: 20px;

  .top {
    padding: 23px 20px 20px;

    .title {
      color: #212b36;
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 27px;
    }
  }

  ${props =>
    props.noOrder &&
    css`
      .top {
        padding-bottom: 0px;
      }
    `};

  .body {
    display: flex;
    justify-content: space-between;

    p {
      padding: 0;
      margin: 0;
      color: #212b36;
      font-weight: 400;
      font-size: 14px;
    }

    .order-info > p:first-of-type {
      padding: 0;
      margin: 0;
      color: #212b36;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .order-info > p:last-of-type {
      padding: 0;
      margin: 0;
      color: #212b36;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 7px;
    }

    .time,
    .from {
      color: #637381;
    }
  }

  .merch {
    display: flex;
    padding: 0 20px;
    margin-top: 5px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebeef0;
    align-items: center;

    p {
      padding: 0;
      margin: 0;
      color: #212b36;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 5px;
    }

    .img {
      margin-right: 16px;
    }
  }

  .foot {
    display: flex;
    padding: 20px 20px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    .previous,
    .next {
      width: 48px;
      height: 36px;
      background: #fff;
      border: 1px solid #c4cdd5;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: #212b36;
        height: 16px;
        width: 16px;
      }
    }

    .previous {
      border-right: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;

      svg {
        transform: rotate(-90deg);
      }
    }

    .next {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;

      svg {
        transform: rotate(90deg);
      }
    }
  }
`;

export const EmptyBox = styled.div`
  background: transparent;
  padding: 20px 20px 10px;
  & > div:first-child {
    padding-top: 0px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  svg {
    color: transparent;
    fill: #c3cfd8;
    width: 20px;
    height: 20px;
  }
  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
  }
`;

export const Timeline = styled.div`
  margin-bottom: 30px;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: 50px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d3dbe2;
    font-size: 14px;
    font-weight: 400;
    color: #212b36;

    h3 {
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .timeline {
    position: relative;
    margin: 0 20px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;

    ::before {
      position: absolute;
      top: 0;
      content: " ";
      height: 95%;
      width: 3px;
      background-color: #e3e6e9;
    }

    .comment {
      display: flex;
      position: relative;

      .initials {
        position: absolute;
        transform: translateX(-50%);

        width: 44px;
        height: 44px;
        display: inline-flex;
        background: #b662ff;
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .comment-input {
        border: 1px solid #d3dbe2;
        width: calc(100% - 30px);
        height: 44px;
        margin-left: 30px;
        border-radius: 3px;
        outline: none;
        font-size: 14px;
        font-weight: 400;
        padding: 10px;
      }
    }

    .note {
      color: #637381;
      font-size: 14px;
      font-weight: 400;
      padding: 0;
      margin: 0;
      text-align: right;
      margin-top: 10px;
    }

    .updates {
      padding-left: 30px;

      p {
        padding: 0;
        margin: 0;
      }

      .date {
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        color: #637381;
        margin-top: 18px;
        margin-bottom: 30px;
      }

      .single-update-container {
        display: flex;
        justify-content: space-between;
        position: relative;

        :not(:first-child) {
          margin-top: 30px;
        }

        .bullet {
          height: 19px;
          width: 19px;
          background: #bbc8d2;
          border-radius: 100%;
          position: absolute;
          left: 2px;
          transform: translateX(calc(-50% - 30px));
          border: 3px solid #ebeef0;
        }

        .single-update {
          color: #212b36;
        }

        .time {
          color: #637381;
        }
      }
    }
  }
`;

export const PreviousNext = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 90px;

  .cont {
    width: 95px;
    height: 36px;
    display: flex;

    svg {
      height: 16px;
      width: 16px;
    }

    button {
      height: 100%;
      width: 50%;
      background: #fff;
      border: 1px solid #d3dbe2;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;

      :first-child {
        border-right: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;

        svg {
          transform: rotate(-180deg);
        }
      }

      :last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
`;
export const Section = styled.div`
  width: 100%;
  padding: 10px;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
`;
export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 10px;

  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 500;
  }

  .bold {
    font-weight: 700;
  }

  & > div {
    display: flex;
    align-items: center;
  }
  & > div:first-child {
    img {
      margin-right: 10px;
      height: 30px;
      width: 30px;
    }
    flex: 1;
  }
  & > div:last-child {
    flex: 1.5;
    justify-content: space-between;
  }

  & > div:only-child {
    flex: 1;
    justify-content: flex-end;
  }
`;

export const ActionBtn = styled.button`
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  border: 1px solid #3f4eae;
  box-shadow: inset 0 1px 0 0 #6f7bcb;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 0.7rem;
  border-radius: 3px;
  text-decoration: none;
  min-width: 147px;
  height: 38px;
  &:last-child {
    margin-left: 16px;
  }

  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
    background: #f6f8f9;
    box-shadow: none;
    color: initial;
    border: 1px solid #c3cdd5;
    pointer-events: none;
  }

  &:hover {
    background: linear-gradient(to bottom, #5c6ac4, #4959bd);
    border-color: #3f4eae;
    color: white;
  }
`;

export const GalleryBox = styled.div`
  padding: 20px;
  .kyc-img-original {
    .image-gallery-image {
      background-color: #f5f5f5;
    }
    img {
      /* height: 300px;
      min-width: 100%; */
      height: 300px;
      width: 60%;
    }
  }

  .kyc-img-thumbnail {
    img {
      height: 60px;
      min-width: 100%;
    }
  }
`;
