import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  & > div {
    min-height: 100%;
    margin-top: 60px;
    width: 100%;
    padding: 30px 32px 30px 26px;
  }

  /* Chrome, Safari and Opera syntax */
  :-webkit-full-screen {
    background-color: #fff;
    & > div {
      width: 80%;
      margin: 0px auto;
      & > div:first-child {
        display: none;
      }
      & > header {
        display: none;
      }
    }
  }

  /* Firefox syntax */
  :-moz-full-screen {
    background-color: #fff;
    & > div {
      width: 80%;
      margin: 0px auto;
      & > div:first-child {
        display: none;
      }
      & > header {
        display: none;
      }
    }
  }

  /* IE/Edge syntax */
  :-ms-fullscreen {
    background-color: #fff;
    & > div {
      width: 80%;
      margin: 0px auto;
      & > div:first-child {
        display: none;
      }
      & > header {
        display: none;
      }
    }
  }

  /* Standard syntax */
  :fullscreen {
    background-color: #fff;
    & > div {
      width: 80%;
      margin: 0px auto;
      & > div:first-child {
        display: none;
      }
      & > header {
        display: none;
      }
    }
  }
`;

export const MainHeader = styled.div`
  h2 {
    font-size: 1.4rem;
    font-weight: 600;
  }
`;

export const ActionBtnsBox = styled.header`
  margin-top: 5px;
  display: flex;
  background-color: transparent;
  margin-left: -10px;
  & > button:first-child {
    margin-right: 15px;
  }
`;
export const ActiontBtn = styled.button`
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  border-color: transparent;
  border-radius: 3px;
  color: #637381;
  padding: 8px;
  &:hover {
    color: #212b36;
    text-decoration: none;
  }
  &:active {
    background-color: rgba(33, 43, 54, 0.1);
    text-decoration: none;
  }
  span {
    font-size: 0.7rem;
  }
  svg {
    margin-right: 4px;
    width: 20px;
    height: 20px;
    fill: #637381;
  }
`;

export const CustomerBehaviourAndPageViewBox = styled.div`
  margin-top: 20px;
  display: flex;
`;
