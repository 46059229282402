import axios from "axios";
import { getTradeQueryString } from "../../../helpers/util";
import { GET_TRADES, GET_TRADES_DETAIL, SET_TRADE_STATUS } from "./types";

import { toast } from "react-toastify";
import { apiBaseURL } from "../../../config";

const action = payload => ({
  type: GET_TRADES.default,
  payload
});

const detail_action = payload => ({
  type: GET_TRADES_DETAIL.default,
  payload
});

const set_trade_status = payload => ({
  type: SET_TRADE_STATUS.default,
  payload
});

export const getTrades = queryString => dispatch => {
  const query = getTradeQueryString(queryString);
  const payload = axios.get(`${apiBaseURL}/v1/trades${query}`);

  dispatch(action(payload)).catch(err => {
    err.response &&
      err.response.data &&
      err.response.data.message &&
      toast.error(err.response.data.message);
  });
};

export const getTradesDetail = id => dispatch => {
  const payload = axios.get(`${apiBaseURL}/v1/trades/${id}`);

  dispatch(detail_action(payload)).catch(err => {
    err.response &&
      err.response.data &&
      err.response.data.message &&
      toast.error(err.response.data.message);
  });
};

export const setStatus = (status, id) => dispatch => {
  const payload = axios.put(`${apiBaseURL}/v1/trades/${id}/status`, { status });
  dispatch(set_trade_status(payload))
    .then(res => {
      toast.success(res.value.data.message);
      dispatch(getTradesDetail(id));
    })
    .catch(err => {
      err.response &&
        err.response.data &&
        err.response.data.message &&
        toast.error(err.response.data.message);
    });
};
