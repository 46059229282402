import types from "../actions/types";

const initialState = {
  fetching: false,
  fetched: false,
  stats: {
    tradeStats: {
      total: 0,
      count: 0
    },
    transferStats: {
      total: 0,
      count: 0
    }
  }
};

const coinBarterStats = (state = initialState, { type, payload }) => {
  switch (type) {
    case types("get_stats").fulfilled:
      return {
        ...state,
        fetched: true,
        fetching: false,
        stats: payload
      };

    case types("get_stats").pending:
      return {
        ...state,
        fetching: true,
        fetched: false
      };
    default:
      return state;
  }
};

export default coinBarterStats;
