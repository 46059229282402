import React from "react";

import { FormSection, FormSectionInfo } from "./common";

import { Container, Section, FormGroup } from "./formElements";

const FeesForm = ({ form, handleInputChange, providerType }) => {
  return (
    <FormSection>
      <FormSectionInfo>
        <h3>Fees</h3>
      </FormSectionInfo>
      <Container>
        <Section>
          <FormGroup>
            <label htmlFor="base_fee_amount">Base Fee</label>
            <input
              name="base_fee_amount"
              value={form.base_fee_amount}
              onChange={handleInputChange}
            />
          </FormGroup>
          {providerType === "deposits" && (
            <FormGroup>
              <label htmlFor="extra_fee_amount">Extra Fee</label>
              <input
                name="extra_fee_amount"
                value={form.extra_fee_amount}
                onChange={handleInputChange}
              />
            </FormGroup>
          )}
        </Section>
      </Container>
    </FormSection>
  );
};

export default FeesForm;
