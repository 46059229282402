import React from "react";
import styled from "styled-components";

import { moneyFormatter } from "../../../../helpers/util";
import { ReactComponent as SucessSVG } from "../svgs/success.svg";
import { ReactComponent as PendingSVG } from "../svgs/pending.svg";

const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border-radius: 3px;
  min-height: 100px;
  padding: 10px 0px 20px;
`;

const Section = styled.div`
  div.line {
    padding-top: 5px;
  }
`;

export const ImageBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  span {
    text-transform: capitalize;
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 500;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 0px;
  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
  }
  span.bold {
    font-weight: 500;
  }
  hr {
    width: 100%;
    color: #e6e6e6;
    background-color: #e6e6e6;
    height: 0.4px;
    padding: 0;
    border: 0;
  }
`;

function getBaseCurrency(pair) {
  return pair.split("-")[0].trim();
}

function getOtherCurrency(pair) {
  return pair.split("-")[1].trim();
}

export default ({ transaction }) => {
  return (
    <Container>
      <Section>
        <ImageBox>
          {(transaction.status === "success" ||
            transaction.status === "completed") && <SucessSVG />}
          {(transaction.status === "created" ||
            transaction.status === "pending") && <PendingSVG />}
          <span>{transaction.status}</span>
        </ImageBox>
        <InfoItem>
          <span>Base</span>
          <span>
            {getBaseCurrency(transaction.product)} {transaction.base_amount}
          </span>
        </InfoItem>
        <InfoItem>
          <span>Price</span>
          {transaction.quote_type === "fiat" ? (
            <span>
              {moneyFormatter.format(transaction.price).toUpperCase()} /{" "}
              {getBaseCurrency(transaction.product)}
            </span>
          ) : (
            <span>
              {`${getOtherCurrency(transaction.product)} ${
                transaction.price
              }`.toUpperCase()}{" "}
              / {getBaseCurrency(transaction.product)}
            </span>
          )}
        </InfoItem>
        <InfoItem>
          <span>Service Charge</span>
          <span>
            {transaction.fee_currency} {transaction.fee}
          </span>
          {/* {transaction.quote_type === "digital" && (
            <span>
              {`${transaction.fee} ${transaction.quote}`.toUpperCase()}
            </span>
          )}
          {transaction.quote_type === "fiat" && !transaction.country && (
            <span>
              {moneyFormatter
                .format(transaction.fee || transaction.fees)
                .toUpperCase()}
            </span>
          )}
          {transaction.country === "NG" && (
            <span>
              {moneyFormatter
                .format(transaction.fee || transaction.fees)
                .toUpperCase()}
            </span>
          )} */}
        </InfoItem>
        {/* <InfoItem>
          <span className="bold">Total</span>
          {transaction.quote_type === "digital" && (
            <span className="bold">
              {`${transaction.quote_amount} ${transaction.quote}`.toUpperCase()}
            </span>
          )}
          {transaction.quote_type === "fiat" && !transaction.country && (
            <span className="bold">
              {moneyFormatter.format(transaction.quote_amount).toUpperCase()}
            </span>
          )}
          {transaction.country === "NG" && (
            <span>
              {moneyFormatter.format(transaction.quote_amount).toUpperCase()}
            </span>
          )}
        </InfoItem> */}

        {transaction.quote_type === "fiat" && (
          <InfoItem>
            <span>Bank Transfer Fee</span>
            <span>
              {getBaseCurrency(transaction.product)}{" "}
              {transaction.bank_transfer_fee}
            </span>
          </InfoItem>
        )}
        {transaction.quote_type === "fiat" && (
          <InfoItem>
            <span>Payment Method Fee</span>
            <span>{moneyFormatter.format(transaction.payment_method_fee)}</span>
          </InfoItem>
        )}
        {/* <InfoItem className="line"> */}
        <InfoItem>
          <hr />
        </InfoItem>
        <InfoItem>
          <span>
            {transaction.type === "sell"
              ? "Paid to Customer"
              : "Paid By Customer"}
          </span>
          {/* {transaction.quote_type === "digital" && (
            <span>
              {`${transaction.quote_amount} ${transaction.quote}`.toUpperCase()}
            </span>
          )}
          {transaction.quote_type === "fiat" && !transaction.country && (
            <span>
              {moneyFormatter.format(transaction.quote_amount).toUpperCase()}
            </span>
          )}
          {transaction.country === "NG" && (
            <span>
              {moneyFormatter.format(transaction.quote_amount).toUpperCase()}
            </span>
          )} */}
          <span>
            {transaction.quote_type === "fiat"
              ? moneyFormatter.format(transaction.quote_amount)
              : `${transaction.quote} ${transaction.quote_amount}`}
          </span>
        </InfoItem>
      </Section>
    </Container>
  );
};
