import { VERIFICATIONS, VERIFICATIONS_DETAIL } from "../actions/types";

const initialState = {
  fetching: false,
  fetched: false,
  fetching_detail: false,
  fetched_detail: false,
  fetching_verification: false,
  fetched_verification: false,
  error: {},
  list: [],
  detail: {}
};

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case VERIFICATIONS.fulfilled:
      return {
        fetching: false,
        fetched: true,
        fetching_verification: false,
        fetched_verification: true,
        list: payload.data
      };
    case VERIFICATIONS_DETAIL.fulfilled:
      return {
        fetching: false,
        fetched: true,
        fetching_detail: false,
        fetched_detail: true,
        detail: payload.data
      };
    case VERIFICATIONS.pending:
      return {
        ...state,
        fetching: true,
        fetched: false,
        fetching_verification: true,
        fetched_verification: false
      };

    case VERIFICATIONS_DETAIL.pending:
      return {
        ...state,
        fetching: true,
        fetched: false,
        fetching_detail: true,
        fetched_detail: false
      };

    case VERIFICATIONS.rejected:
    case VERIFICATIONS_DETAIL.rejected:
      return {
        ...state,
        fetching_detail: false,
        fetched_detail: false,
        fetching_verification: false,
        fetched_verification: false,
        error: payload,
        fetching: false,
        fetched: false
      };

    default:
      return state;
  }
};

export default user;
