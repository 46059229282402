import React from "react";
import styled from "styled-components";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { DayPickerRangeController } from "react-dates";
import ThemedStyleSheet from "react-with-styles/lib/ThemedStyleSheet";
import aphroditeInterface from "react-with-styles-interface-aphrodite";
import DefaultTheme from "react-dates/lib/theme/DefaultTheme";
import moment from "moment";

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    color: {
      ...DefaultTheme.reactDates.color,
      selected: {
        backgroundColor: "#5c6ac4",
        // backgroundColor_active: "#58D68D",
        backgroundColor_hover: "#4959bd",
        color: "#fff",
        color_active: "#fff",
        color_hover: "#186A3B"
      },
      selectedSpan: {
        backgroundColor: "#b3bcf5",
        // backgroundColor_active: "#58D68D",
        backgroundColor_hover: "#9ca8f2",
        color: "#000",
        color_active: "#000"
        // color_hover: "#186A3B"
      }
    },
    font: {
      ...DefaultTheme.reactDates.font,
      size: 12,
      captionSize: 12
    },
    spacing: {
      ...DefaultTheme.reactDates.spacing,
      captionPaddingTop: 30,
      captionPaddingBottom: 45
    }
  }
});

const Container = styled.div`
  width: 100%;
`;

export default class DateRangePicker extends React.Component {
  state = {
    focusedInput: "endDate"
  };

  onFocusChange = focusedInput => {
    if (focusedInput) {
      this.setState({ focusedInput });
    } else {
      this.setState({ focusedInput: "startDate" });
    }
  };

  // onDatesChange = ({ startDate, endDate }) => {
  //   const { onDatesChange, endDate: propEndDate } = this.props;
  //   const { focusedInput } = this.state;
  //   console.log({ focusedInput });
  //   if (focusedInput === "endDate" && propEndDate !== null) {
  //     this.setState({ focusedInput: "startDate" }, () =>
  //       onDatesChange({ endDate: startDate, startDate })
  //     );
  //   } else {
  //     onDatesChange({ endDate, startDate });
  //   }
  // };

  render() {
    const { startDate, endDate, onDatesChange } = this.props;
    return (
      <Container>
        <DayPickerRangeController
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDatesChange}
          focusedInput={this.state.focusedInput}
          onFocusChange={this.onFocusChange}
          hideKeyboardShortcutsPanel={true}
          numberOfMonths={2}
          noBorder={true}
          isOutsideRange={day => day > moment()}
        />
      </Container>
    );
  }
}
