import React from "react";

import Card from "../../../common/Card";

import { CardContainer, CardHeader, InputGroup } from "./elements";
import Input from "./Input";

export default class AmountForm extends React.Component {
  render() {
    const { base, quote } = this.props.form;
    const { handleInputChange, baseCurrency, quoteCurrency } = this.props;
    return (
      <CardContainer>
        <Card>
          <CardHeader>
            <h1>Amount</h1>
          </CardHeader>
          <InputGroup halfFlex>
            <div>
              <Input
                suffix={`${baseCurrency.toUpperCase()}`}
                label="Base"
                value={base}
                name="base"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <Input
                suffix={`${quoteCurrency.toUpperCase()}`}
                label="Quote"
                value={quote}
                name="quote"
                onChange={handleInputChange}
              />
            </div>
          </InputGroup>
        </Card>
      </CardContainer>
    );
  }
}
