import React from "react";
import styled, { css } from "styled-components";
import Case from "case";

import { EmptyBox } from "./common";
import { ReactComponent as EmptyBoxSVG } from "../svgs/empty-box.svg";

export const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border-radius: 3px;
  min-height: 100px;
`;

export const Section = styled.div`
  width: 100%;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
  padding: 20px;
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;
export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #212b36;
    font-size: 0.6rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;

export const SectionBody = styled.div`
  padding: 20px 0px;
`;

export const Info = styled.div`
  span.action {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span.action:hover {
    text-decoration: underline;
  }
  span.card-number {
    margin-left: 5px;
  }
  p {
    font-size: 0.7rem;
    color: #212b36;
    padding: 5px 0px;
  }
`;

export default class extends React.Component {
  render() {
    const { customer, toggleEditModal, note } = this.props;
    let cardTypeString = "";

    // if (customer.cards.length > 0) {
    //   const cardTypeStringArray = customer.cards[0].card_type.split(" ");
    //   cardTypeString =
    //     Case.capital(`${cardTypeStringArray[0]}`) +
    //     ` ${cardTypeStringArray[1]}`;
    // }
    return (
      <Container>
        <Section>
          <MainHeader>
            <h3>Note</h3>
            <span onClick={toggleEditModal}>Edit</span>
          </MainHeader>
          <SectionBody>
            <Info>{<p>{note || customer.note}</p>}</Info>
          </SectionBody>
        </Section>
        {/* <Section borderTop>
          <SubHeader>
            <h3>Bank Verification Number</h3>
          </SubHeader>
          {customer.profile.bvn ? (
            <SectionBody>
              <Info>
                <p>{customer.profile.bvn}</p>
              </Info>
            </SectionBody>
          ) : (
            <EmptyBox padding="40px 20px 0px">
              <div>
                <EmptyBoxSVG />
              </div>
              <div>
                <span>Customer has no BVN yet.</span>
              </div>
            </EmptyBox>
          )}
        </Section> */}
        {/* <Section borderTop>
          <SubHeader>
            <h3>Default Card</h3>
            {customer.cards && <span>View More</span>}
          </SubHeader>
          {customer.cards.length > 0 ? (
            <SectionBody>
              <Info>
                <p>{cardTypeString}</p>
                <p>{`•••• •••• •••• ${customer.cards[0].last4}`}</p>
                <p>{`${customer.cards[0].bank} (${
                  customer.cards[0].country_code
                })`}</p>
              </Info>
            </SectionBody>
          ) : (
            <EmptyBox padding="40px 20px 0px">
              <div>
                <EmptyBoxSVG />
              </div>
              <div>
                <span>This customer has no card</span>
              </div>
            </EmptyBox>
          )}
        </Section> */}
        {/* <Section borderTop>
          <SubHeader>
            <h3>Default Bank</h3>
            {customer.banks && <span>Change</span>}
          </SubHeader>
          {customer.banks.length > 0 ? (
            <SectionBody>
              <Info>
                <p>{Case.capital(customer.banks[0].account_name)}</p>
                <p>{customer.banks[0].account_number}</p>
                <p>{customer.banks[0].name}</p>
              </Info>
            </SectionBody>
          ) : (
            <EmptyBox padding="40px 20px 0px">
              <div>
                <EmptyBoxSVG />
              </div>
              <div>
                <span>This customer has no bank</span>
              </div>
            </EmptyBox>
          )}
        </Section> */}
      </Container>
    );
  }
}
