import types from "../actions/types";

export default (state = { live_stats: {} }, action) => {
  switch (action.type) {
    case types("live_data").fulfilled:
      return {
        ...state,
        [action.payload.event]: action.payload.data
      };
    default:
      return state;
  }
};
