import { connect } from "react-redux";
import React, { Component } from "react";

import { AssetsBox } from "./elements";
import { getAssetAnalytics } from "../../../../actions/analytics";
import GridLoader from "../../../common/SVGLoaders/GridLoader";
import { Charts } from "./Chart/elements";
import AssetChart from "./Chart/AssetsChart";

class AssetsOverview extends Component {
  componentDidMount() {
    this.fetchAnalytics();
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props;
    if (startDate !== prevProps.startDate || endDate !== prevProps.endDate) {
      // console.log("make call");
      this.fetchAnalytics();
    }
  }

  fetchAnalytics() {
    const { startDate, endDate, dateFormat } = this.props;
    const { getAssetAnalytics } = this.props;
    getAssetAnalytics(
      startDate.format(dateFormat),
      endDate.format(dateFormat),
      true
    );
  }

  render() {
    const { fetching, assetsAnalytics } = this.props.analytics;
    const { assets } = this.props.assets;
    return (
      <React.Fragment>
        {fetching ? (
          <GridLoader />
        ) : (
          <AssetsBox>
            {assets.map((asset, i) => (
              <Charts key={asset.code}>
                <AssetChart data={assetsAnalytics.data[i]} asset={asset} />
              </Charts>
            ))}
          </AssetsBox>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ analytics, assets }) => ({ analytics, assets });

export default connect(
  mapStateToProps,
  { getAssetAnalytics }
)(AssetsOverview);
