import React, { Component } from "react";
import { connect } from "react-redux";
import { Spinner } from "react-activity";

import { Permissions, AccountInfo, Form } from "./elements";
// import Checkbox from "../../common/Checkbox";
import changePassword from "../../../actions/changePassword";
import { toast } from "react-toastify";

class AccountDetails extends Component {
  state = {
    isFormDirty: false,
    changePassword: false,
    confirmPassword: "",
    password: "",
    fetching: false
  };

  handleClick = event => {
    event.preventDefault();

    this.setState(state => ({
      changePassword: !state.changePassword
    }));
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
      isFormDirty: true
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    this.setState({
      fetching: true
    });

    const { confirmPassword, password } = this.state;

    if (confirmPassword === password) {
      this.props.changePassword(
        {
          new_password: password
        },
        () => {
          this.setState({
            fetching: false,
            password: "",
            confirmPassword: "",
            changePassword: false
          });
        }
      );
    } else {
      toast.error("Passwords do not match");
      this.setState({
        fetching: false,
        password: "",
        confirmPassword: ""
      });
    }
  };

  render() {
    const { changePassword, password, confirmPassword, fetching } = this.state;

    const {
      handleFormChange,
      first_name,
      last_name,
      phone,
      email,
      bio,
      avatar
    } = this.props;

    return (
      <Permissions
        style={{
          paddingTop: "52px"
        }}
      >
        <h3 className="title">Account information</h3>
        <div>
          <AccountInfo>
            <h3 className="title">Staff profile</h3>

            <Form>
              <div className="topForm">
                <div className="avatarCont">
                  <div className="avatar">
                    <img src={avatar} alt={first_name} />
                  </div>

                  <div className="uploadWrapper">
                    <button className="btn">Upload photo</button>

                    <input type="file" accept="image/*" />
                  </div>

                  <button className="btn" disabled>
                    Delete photo
                  </button>
                </div>

                <div className="formGroup">
                  <div className="inputGroup">
                    <label>First name</label>
                    <input
                      name="first_name"
                      type="text"
                      value={first_name}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className="inputGroup">
                    <label>Last name</label>
                    <input
                      name="last_name"
                      onChange={handleFormChange}
                      type="text"
                      value={last_name}
                    />
                  </div>
                </div>

                <div className="formGroup">
                  <div className="inputGroup">
                    <label>Email</label>
                    <input
                      name="email"
                      type="email"
                      onChange={handleFormChange}
                      value={email}
                    />
                  </div>
                  <div className="inputGroup">
                    <label>Phone (optional)</label>
                    <input
                      onChange={handleFormChange}
                      name="phone"
                      type="text"
                      value={phone}
                    />
                  </div>
                </div>
              </div>
              <div className="bigBio">
                <p className="bio-details">Details</p>

                <div className="textareaTitle">Bio (optional)</div>
                <textarea
                  name="bio"
                  onChange={handleFormChange}
                  value={bio}
                  className="textarea"
                />
                <div className="underTextarea">
                  You can select for this to appear on blog posts that Michael
                  publishes, from within your theme editor.
                </div>
              </div>

              {/* <div className="notifications">
                <div className="notif-title">notifications</div>
                <Checkbox
                  checked
                  label="Allow important notifications to be sent by email"
                />
                <div className="notif-desc">
                  We periodically send out important news about Shopify to our
                  users via email. We keep the email volume to an absolute
                  minimum.
                </div>
              </div> */}

              <div className="notifications">
                <div className="notif-title">Password reset</div>

                <div>Change the password you use to login to shopbusha.</div>

                {changePassword && (
                  <div className="formGroup passwordFormGroup">
                    <div className="inputGroup">
                      <label>Password</label>
                      <input
                        name="password"
                        type="password"
                        value={password}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="inputGroup">
                      <label>Confirm password</label>
                      <input
                        name="confirmPassword"
                        type="password"
                        value={confirmPassword}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                )}
                <button
                  onClick={
                    !changePassword ? this.handleClick : this.handleSubmit
                  }
                  className="btn"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "16px",
                    width: "148px",
                    height: "37px",
                    padding: 0
                  }}
                >
                  {fetching ? <Spinner /> : "Change password"}
                </button>
              </div>
            </Form>
          </AccountInfo>
        </div>
      </Permissions>
    );
  }
}

export default connect(
  null,
  { changePassword }
)(AccountDetails);
