import axios from "axios";
import { toast } from "react-toastify";

import { apiBaseURL } from "../config";
import types, {
  GET_LOGIN_ACTIVITIES,
  UPDATE_SINGLE_CUSTOMER,
  CLEAR_UPDATE,
  UPDATE_CUSTOMER_ACCOUNT
} from "./types";

const fetchLoginActivities = payload => ({
  type: GET_LOGIN_ACTIVITIES.default,
  payload
});
const updateUser = payload => ({
  type: UPDATE_SINGLE_CUSTOMER.default,
  payload
});
const clearUpdate = () => ({
  type: CLEAR_UPDATE.default
});
const fetchCustomerSuccessful = payload => ({
  type: types("fetch_customer").fulfilled,
  payload
});

const fetchCustomerFailed = payload => ({
  type: types("fetch_customer").rejected,
  payload
});

const fetchCustomerCardsSuccessful = payload => ({
  type: types("fetch_customer_cards").fulfilled,
  payload
});

const fetchCustomerCardsFailed = payload => ({
  type: types("fetch_customer_cards").rejected,
  payload
});

const fetchCustomerBanksSuccessful = payload => ({
  type: types("fetch_customer_banks").fulfilled,
  payload
});

const fetchCustomerBanksFailed = payload => ({
  type: types("fetch_customer_banks").rejected,
  payload
});

const fetchCustomerAccountsSuccessful = payload => ({
  type: types("fetch_customer_accounts").fulfilled,
  payload
});

const fetchCustomerAccountsFailed = payload => ({
  type: types("fetch_customer_accounts").rejected,
  payload
});

const fetchCustomerMinTransactionsSuccessful = payload => ({
  type: types("fetch_customer_recent_transactions").fulfilled,
  payload
});

const fetchCustomerMinTransactionsFailed = payload => ({
  type: types("fetch_customer_recent_transactions").rejected,
  payload
});

const toggleCustomerActiveStateFulfilled = payload => ({
  type: types("toggle_customer_active_state").fulfilled,
  payload
});

const updateCustomerFulfilled = payload => ({
  type: types("update_customer").fulfilled,
  payload
});

export const loginActivities = customerId => dispatch => {
  const url = `${apiBaseURL}/v1/users/${customerId}/activities`;
  const payload = axios.get(url);
  dispatch(fetchLoginActivities(payload)).catch(err => {
    err.response &&
      err.response.data &&
      err.response.data.message &&
      toast.error(err.response.data.message);
  });
};

export const fetchCustomer = customerId => {
  return dispatch => {
    dispatch({ type: types("fetch_customer").pending });
    return axios
      .get(`${apiBaseURL}/v1/users/${customerId}`)
      .then(({ data }) => {
        dispatch(fetchCustomerSuccessful(data));
      })
      .catch(error => {
        dispatch(fetchCustomerFailed(error));
      });
  };
};

export const fetchCustomerCards = customerId => {
  return dispatch => {
    dispatch({ type: types("fetch_customer_cards").pending });
    return axios
      .get(`${apiBaseURL}/v1/users/${customerId}/cards`)
      .then(({ data }) => {
        dispatch(fetchCustomerCardsSuccessful(data));
      })
      .catch(error => {
        dispatch(fetchCustomerCardsFailed(error));
      });
  };
};

export const fetchCustomerBanks = customerId => {
  return dispatch => {
    dispatch({ type: types("fetch_customer_banks").pending });
    return axios
      .get(`${apiBaseURL}/v1/users/${customerId}/banks`)
      .then(({ data }) => {
        dispatch(fetchCustomerBanksSuccessful(data));
      })
      .catch(error => {
        dispatch(fetchCustomerBanksFailed(error));
      });
  };
};

export const fetchCustomerAccounts = customerId => {
  return dispatch => {
    dispatch({ type: types("fetch_customer_accounts").pending });
    return axios
      .get(`${apiBaseURL}/v1/users/${customerId}/accounts`)
      .then(({ data }) => {
        dispatch(fetchCustomerAccountsSuccessful(data));
      })
      .catch(error => {
        dispatch(fetchCustomerAccountsFailed(error));
      });
  };
};

export const fetchCustomerRecentTransactions = (customerId, page = 1) => {
  return dispatch => {
    dispatch({ type: types("fetch_customer_recent_transactions").pending });
    return axios
      .get(`${apiBaseURL}/v1/users/${customerId}/transactions?page=${page}`)
      .then(({ data }) => {
        dispatch(fetchCustomerMinTransactionsSuccessful(data));
        return data;
      })
      .catch(error => {
        dispatch(fetchCustomerMinTransactionsFailed(error));
      });
  };
};

export const toggleCustomerActiveState = (
  customerId,
  currentStatus
) => dispatch => {
  return axios
    .put(`${apiBaseURL}/v1/users/${customerId}/active`)
    .then(() => {
      dispatch(
        toggleCustomerActiveStateFulfilled({
          active: !currentStatus
        })
      );

      if (currentStatus === true) {
        toast.success("Customer account deactivated!");
      } else {
        toast.success("Customer account activated!");
      }
    })
    .catch(err => {
      toast.error(err.response.data.message);
    });
};

export const resetPassword = customerId => () => {
  return axios
    .post(`${apiBaseURL}/v1/users/${customerId}/password_reset`)
    .catch(err => {
      toast.error(err.response.data.message);
    });
};

export const updateCustomer = (customer, cb) => dispatch => {
  let {
    id,
    phone,
    email,
    year,
    month,
    day,
    profile: {
      country_code,
      first_name,
      last_name,
      gender,
      street_address_2,
      street_address_1,
      city,
      postal_code,
      state
    }
  } = customer;
  console.log(`${year}-${month}-${day}`);
  let date_of_birth = new Date("01-01-0001").toISOString();
  if (year && month && day) {
    date_of_birth = new Date(`${year}-${month}-${day}`).toISOString();
  }

  customer.profile.date_of_birth = date_of_birth;

  const payload = {
    phone,
    email,
    country_code: country_code,
    first_name,
    last_name,
    gender,
    date_of_birth: date_of_birth,
    street_address_1,
    street_address_2,
    city,
    state,
    postal_code
  };

  return axios
    .put(`${apiBaseURL}/v1/users/${id}/profile`, payload)
    .then(() => {
      dispatch(updateCustomerFulfilled(customer));
      cb && cb();
      toast.success("Profile updated successfully");
    })
    .catch(err => {
      toast.error(err.response.data.message);
    });
};

export const updateCustomerPhone = ({ phone, id }, cb) => dispatch => {
  const data = {
    phone
  };
  const url = `${apiBaseURL}/v1/users/${id}/phone`;
  const payload = axios.put(url, data);

  dispatch(updateUser(payload))
    .then(() => {
      cb && cb();
      dispatch(clearUpdate());
    })
    .catch(err => {
      err.response &&
        err.response.data &&
        err.response.data.message &&
        toast.error(err.response.data.message);
    });
};

export const updateCustomerAddress = (
  {
    profile: {
      state,
      city,
      postal_code,
      country_code,
      street_address_1,
      street_address_2
    },
    id
  },
  cb
) => dispatch => {
  const data = {
    state,
    city,
    postal_code,
    country_code,
    street_address_1,
    street_address_2
  };

  const url = `${apiBaseURL}/v1/users/${id}/address`;
  const payload = axios.put(url, data);

  dispatch(updateUser(payload))
    .then(() => {
      cb && cb();
      dispatch(clearUpdate());
    })
    .catch(err => {
      err.response &&
        err.response.data &&
        err.response.data.message &&
        toast.error(err.response.data.message);
    });
};

export const updateCustomerAccount = (
  { customerId, currency, amount, action },
  cb
) => {
  return dispatch => {
    dispatch({
      type: UPDATE_CUSTOMER_ACCOUNT.default,
      payload: axios.post(`${apiBaseURL}/v1/wallets/${customerId}/fund`, {
        currency,
        amount,
        action
      })
    })
      .then(_d => {
        toast.success("Balance updated successfully!");
        // dispatch(fetchCustomerAccounts(customerId));
        cb && cb(_d);
      })
      .catch(error => {
        // error.response &&
        //   error.response.data &&
        //   error.response.data.message &&
        //   toast.error(error.response.data.message);
      });
  };
};
