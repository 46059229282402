import React from "react";
import Case from "case";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";
import format from "date-fns/format";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { Spinner } from "react-activity";
import DepositInfo from "./components/DepositInfo";
import {
  Container,
  Body,
  Level,
  CustomerActivity,
  Header,
  NextPrev,
  ActiontBtnBox,
  PayoutActionBtnsBox,
  ReferenceAndDateBox
} from "./components/common";
import { ReactComponent as BackSVG } from "./svgs/back.svg";
import { ReactComponent as ArrowSVG } from "./svgs/arrow.svg";
import { ReactComponent as PrintSVG } from "./svgs/print.svg";
import ActionDropdown from "./components/ActionDropdown";
import CustomerCard, { BtnCont } from "./components/CustomerCard";
import {
  fetchDepositInfo,
  fetchDepositAccount,
  refundCustomer,
  finalizeDeposit,processDeposit
} from "../../../actions/deposits";
import PayoutDetailLoader from "../../common/SVGLoaders/InfoLoader";
import AccountCard from "./components/AccountCard";
import Modal from "../../common/Modal";
import RefundForm from "./components/RefundForm";
import ProcessForm from "./components/ProcessForm";
import MoreInfo from "./components/MoreInfo";


class DepositDetail extends React.Component {
  state = {
    isLoading: true,
    modalActive: false,
    debit_account: false,
    refunding: false,
    deposit: {
      amount: 0
    },
    finalizing: false,
    processModalActive: false,
    process_amount:0,
    process_loading:false
  };

  toggleModal = () => {
    this.setState(prevState => {
      return {
        modalActive: !prevState.modalActive
      };
    });
  };

toggleProcess=()=>{
  this.setState(prevState => {
    return {
      processModalActive: !prevState.processModalActive
    };
  });
}

handleActivity=()=> {
  this.setState(prevState => {
    return {
      process_loading: !prevState.process_loading
    };
  });
};

onProcessDeposit=()=>{
  const {
    deposit: { deposit}
    
  } = this.props;
  const {process_amount:amount}=this.state
  const {reference,currency}=deposit
  const payload={
    amount,
    currency,
    reference,
  }
  this.handleActivity()
 const success_cb=()=>{
  this.getDeposit()
  this.toggleProcess()
 
 }
  this.props.processDeposit(payload,this.handleActivity,success_cb)
}

  handleRefund = () => {
    if (
      this.state.deposit.amount <= this.props.deposit.deposit.amount &&
      this.state.deposit.amount >= 0
    )
      this.setState({
        refunding: true
      });

    const payload = {
      amount: this.state.deposit.amount,
      debit_account: false
    };

    const { payoutId } = this.props.match.params;

    this.props.refundCustomer(payoutId, payload).then(() => {
      this.setState({
        refunding: false,
        modalActive: false
      });
    });
  };

  handleChange = ({ target: { value, name } }) => {
    this.setState({
      deposit: {
        [name]: value
      }
    });
  };

  onHandleChange= ({ target: { value, name } }) => {
    this.setState({[name]: value});
  };

  handleCheck = ({ target: { checked } }) => {
    this.setState({
      debit_account: checked
    });
  };

  handleFinalize = () => {
    this.setState({
      finalizing: true
    });
    const {
      match: {
        params: { type, payoutId }
      }
    } = this.props;
    this.props.finalizeDeposit(payoutId, type).then(() => {
      this.setState(
        {
          finalizing: false
        },
        () => this.onFetchDepositDetail()
      );
    });
  };

  onFetchDepositDetail = () => {
    const {
      match: {
        params: { type, payoutId }
      },
      fetchDepositInfo,
      fetchDepositAccount
    } = this.props;
    fetchDepositInfo(type, payoutId)
      .then(d =>{ fetchDepositAccount(d.payload.account_id);})
      .then(() => this.setState({ isLoading: false }));
      
  };

  componentDidMount() {
   this.getDeposit()
  }

  getDeposit=()=>{
    const {
      match: {
        params: { type, payoutId }
      },
      fetchDepositInfo,
      fetchDepositAccount
    } = this.props;
    fetchDepositInfo(type, payoutId)
      .then(d => {this.setState({process_amount:d.payload.amount}); return fetchDepositAccount(d.payload.account_id);})
      .then(() => this.setState({ isLoading: false }));
  }
  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps, this.props) || !isEqual(prevState, this.state)) {
      if (!isEqual(prevProps, this.props)) {
        this.setState({
          deposit: this.props.deposit.deposit
        });
      }
    }
  }

  render() {
    const { isLoading } = this.state;
    const {
      deposit: { deposit, account },
      match: {
        params: { type }
      }
    } = this.props;
    return isLoading ? (
      <PayoutDetailLoader />
    ) : (
      <Container>
        <ToastContainer
          closeButton={false}
          autoClose={2000}
          hideProgressBar={true}
        />
        <Header>
          <nav>
            <Link className="back-button" to={`/deposits/${type}`}>
              <BackSVG className="svg" />
              <span>Deposits</span>
            </Link>
            <div>
              <NextPrev>
                <ArrowSVG className="arrow-left" />
              </NextPrev>
              <NextPrev>
                <ArrowSVG className="arrow-right" />
              </NextPrev>
            </div>
          </nav>
        </Header>
        <ReferenceAndDateBox>
          <h1>#{deposit.ref || deposit.reference}</h1>
          <span>{format(deposit.created_at, "MMMM D, YYYY [at] hh:mm a")}</span>
          <Level
            active={
              deposit.status === "success" || deposit.status === "completed"
            }
          >
            <span className="dot" />
            <span className="level">{Case.capital(deposit.status)}</span>
          </Level>
          <CustomerActivity active={deposit.fulfilment_status === "fulfilled"}>
            <span className="dot" />
            <span className="level">
              {deposit.fulfilment_status
                ? Case.capital(deposit.fulfilment_status)
                : "Unfulfilled"}
            </span>
          </CustomerActivity>
        </ReferenceAndDateBox>
        <PayoutActionBtnsBox>
          <ActiontBtnBox>
            <PrintSVG />
            <span>Print</span>
          </ActiontBtnBox>
          {/* <ActionDropdown /> */}
        </PayoutActionBtnsBox>
        <Body>
          <div>
            <AccountCard
              account={account}
              deposit={deposit}
              onProcess={this.toggleProcess}
              loading={this.state.finalizing}
              handleFinalize={this.handleFinalize}
            />
            <DepositInfo deposit={deposit} account={account} type={type} />

            {/* <MoreInfo>
              <BasicInfo deposit={deposit} type={type} />
            </MoreInfo>

            <MoreInfo>
              <UserInfo deposit={deposit} account={account} />
            </MoreInfo>

            <MoreInfo>
              <TransactionInfo
                deposit={deposit}
                handleFinalize={this.handleFinalize}
                finalizing={this.state.finalizing}
                status={deposit.status}
              />
            </MoreInfo> */}

            {/* {deposit.address && (
              <MoreInfo>
                <ChangeAddressForm>
                  <div className="inputGroup">
                    <textarea
                      className="input"
                      readOnly
                      value={deposit.address}
                    />
                  </div>

                  <BtnCont>
                    <button disabled type="submit">
                      Save
                    </button>
                  </BtnCont>
                </ChangeAddressForm>
              </MoreInfo>
            )} */}
          </div>

          <div>
            <CustomerCard
              bank={deposit.bank || null}
              deposit={deposit}
              customer={account.user}
            />
          </div>
        </Body>
        {this.state.modalActive && (
          <Modal
            actions={{
              toggleModal: this.toggleModal,
              handleClick: this.handleRefund
            }}
            data={{
              id: "",
              loading: this.state.refunding,
              title: "Refund customer",
              body: (
                <RefundForm
                  amount={this.state.deposit.amount}
                  fullAmount={deposit.amount}
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                />
              ),
              buttonTxt: "Refund customer"
            }}
            meta={{
              width: "620px",
              buttonType: "blue"
            }}
          />
        )}
        {this.state.processModalActive && (
          <Modal
            actions={{
              toggleModal: this.toggleProcess,
              handleClick: this.onProcessDeposit
            }}
            data={{
              id: "",
              loading: this.state.refunding,
              title: "Process Payment",
              body: (
                <ProcessForm
                  amount={this.state.process_amount}
                  handleChange={this.onHandleChange}
                />
              ),
              buttonTxt:this.state.process_loading?<Spinner color="#ffffff" size={14}/>: "Process"
            }}
            meta={{
              width: "620px",
              buttonType: "blue"
            }}
          />
        )}
      </Container>
    );
  }
}

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 0px;
  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
  }
  span.bold {
    font-weight: 500;
  }
  hr {
    width: 100%;
    color: #e6e6e6;
    background-color: #e6e6e6;
    height: 1px;
    padding: 0;
    border: 0;
  }
`;

const TransactionInfo = ({ deposit, handleFinalize, finalizing, status }) => (
  <>
    <InfoItem>
      <span>Amount</span>
      <span>{deposit.amount}</span>
    </InfoItem>
    <InfoItem>
      <span>Fee</span>
      <span>{deposit.fees || deposit.fee}</span>
    </InfoItem>
    <InfoItem>
      <span>Total</span>
      <span>43798374897</span>
    </InfoItem>
    {deposit.actual_fee && (
      <InfoItem>
        <span>Actual Fee</span>
        <span>{deposit.actual_fee}</span>
      </InfoItem>
    )}
    <InfoItem>
      <span>Profit</span>
      <span>0.00434</span>
    </InfoItem>

    <ButtonContainer>
      <button disabled={status === "success"} onClick={handleFinalize}>
        {finalizing ? <Spinner color="#fff" /> : "Finalize"}
      </button>
    </ButtonContainer>
  </>
);

const BasicInfo = ({ deposit, type }) => (
  <>
    <InfoItem>
      <span>Status</span>
      <span>{deposit.status}</span>
    </InfoItem>
    <InfoItem>
      <span>Created</span>
      <span>{format(deposit.created_at, "MMM DD, YYYY")}</span>
    </InfoItem>
    <InfoItem>
      <span>Reference</span>
      <span>{deposit.reference || deposit.ref}</span>
    </InfoItem>
    {deposit.ps_transfer_code && (
      <InfoItem>
        <span>Transfer Code</span>
        <span>{deposit.ps_transfer_code}</span>
      </InfoItem>
    )}
  </>
);

const UserInfo = ({
  deposit: { address },
  account: {
    user: { profile, email }
  }
}) => (
  <>
    <InfoItem>
      <span>Name</span>
      <span>
        {profile.first_name} {profile.last_name}
      </span>
    </InfoItem>
    <InfoItem>
      <span>Email</span>
      <span>{email}</span>
    </InfoItem>
    {address && (
      <InfoItem>
        <span>Address</span>
        <span>{address}</span>
      </InfoItem>
    )}
  </>
);

const ChangeAddressForm = styled.form`
  font-size: 14px;
  padding: 20px;

  textarea {
    display: block;
    width: 100%;
    padding: 10px;

    :focus {
      border: 2px solid #5c6ac4 !important;
    }
  }

  .formGroup {
    display: flex;
    padding: 0 20px;
    margin-bottom: 20px;

    .inputGroup {
      padding: 0;

      :not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .inputGroup {
    flex: 1;

    label {
      display: block;
      margin-bottom: 6px;
    }

    textarea {
      border-radius: 3px;
      height: 70px;
      box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
      border: 1px solid #c4cdd5;
      outline: none;
      font-size: inherit;
    }
  }
`;

const ButtonContainer = styled(BtnCont)`
  padding-top: 10px;
  padding-right: 20px;
  button {
    cursor: pointer;
    padding: 0;
    min-width: 68px;

    :disabled {
      border: 1px solid #919dab;
      background: #f4f5f8;
      box-shadow: none;
      color: #919dab;
      cursor: not-allowed;
    }
  }
`;

function mapStateToProps({ deposit }) {
  return { deposit };
}

export default connect(
  mapStateToProps,
  { fetchDepositInfo, refundCustomer,processDeposit, fetchDepositAccount, finalizeDeposit }
)(DepositDetail);
