import React from "react";

import CustomizableLoader from "../common/CustomizableLoader";
import { Container, Header, Subtitle, Form, Button } from "./elements";

const Loader = () => (
  <Container>
    <Header>
      <CustomizableLoader width="50%" height="30px" borderRadius="3px" />
    </Header>

    <Subtitle>
      <CustomizableLoader width="30%" height="10px" borderRadius="3px" />
      <br />
      <CustomizableLoader width="50%" height="10px" borderRadius="3px" />
      <br />
      <CustomizableLoader width="100%" height="10px" borderRadius="3px" />
    </Subtitle>

    <Form>
      <div style={{ padding: "0 20px" }}>
        <CustomizableLoader width="10%" height="10px" borderRadius="3px" />
        <br />
        <CustomizableLoader width="100%" height="30px" borderRadius="3px" />
        <br />
        <br />
        <CustomizableLoader width="15%" height="10px" borderRadius="3px" />
        <br />
        <CustomizableLoader width="100%" height="30px" borderRadius="3px" />
        <br />
        <br />
        <CustomizableLoader width="13%" height="10px" borderRadius="3px" />
        <br />
        <CustomizableLoader width="100%" height="30px" borderRadius="3px" />
        <br />
        <br />
        <CustomizableLoader width="20%" height="10px" borderRadius="3px" />
        <br />
        <CustomizableLoader width="100%" height="30px" borderRadius="3px" />
        <br />
        <br />
        <CustomizableLoader width="10%" height="10px" borderRadius="3px" />
        <br />
        <CustomizableLoader width="100%" height="30px" borderRadius="3px" />
      </div>
      <p className="tandc">
        <CustomizableLoader width="90%" height="10px" borderRadius="3px" />
      </p>

      <div className="buttons">
        <Button disabled>Create account</Button>
      </div>
    </Form>
  </Container>
);

export default Loader;
