import React from "react";
import SVG from "react-inlinesvg";

import { Container } from "./elements";
import closeIcon from "./closeIcon.svg";
import { Spinner } from "react-activity";

const Modal = ({
  actions: { toggleModal, handleClick },
  data: { id, title, body, loading, buttonTxt },
  meta
}) => (
  <Container {...meta}>
    <div className="overlay" />
    <div className="modalContainer">
      <header className="header">
        <h2>{title}</h2>{" "}
        <button onClick={toggleModal} className="close">
          <SVG src={closeIcon} />
        </button>
      </header>
      <div className="body">{body}</div>
      <footer className="footer">
        <button onClick={handleClick} className="cancel-order">
          {loading ? <Spinner color="#fff" /> : buttonTxt}
        </button>
      </footer>
    </div>
  </Container>
);

export default Modal;
