import React from "react";
import styled from "styled-components";

import { ReactComponent as SvgSrc } from "./images/select_sort.svg";

const Container = styled.div`
  width: 100%;
  position: relative;

  label {
    font-size: 15px;
  }
`;

const SelectBox = styled.div`
  display: block;
  border: 1px solid #c4cdd5;
  border-radius: 3px;
  box-sizing: border-box;
  position: relative;
  background: white;
  overflow: hidden;
  width: 100%;
  /* vertical-align: bottom; */

  transition: all 0.2s ease-out;
  background: linear-gradient(to bottom, white, #f9fafb);
  border-color: #c4cdd5;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);

  margin-top: 5px;
  svg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 25px;
    z-index: 1;
  }

  select {
    position: relative;
    margin: 0;
    font-size: 14px;
    display: inline-block;
    color: #222;
    height: 36px;
    line-height: 28px;
    padding: 0px 0px 0px 10px;
    border: 1px solid #ccc;
    border-radius: 1px;
    max-width: 100%;

    font-weight: 400;
    text-transform: initial;
    letter-spacing: initial;
    appearance: none;
    box-sizing: border-box;
    display: block;
    width: 100%;
    background: transparent;
    border: 0;
    max-width: none;
    transition-property: background, border, box-shadow, -webkit-box-shadow;
    transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
    transition-duration: 200ms;
    outline: none;

    z-index: 2;
  }
`;

export default function Select({
  options,
  label,
  name,
  containerClass,
  ...props
}) {
  return (
    <Container className={containerClass}>
      {label && <label htmlFor={label.toLowerCase()}>{label}</label>}
      <SelectBox>
        <select name={name} {...props} id={name.toLowerCase() || ""}>
          {options.map(option => {
            let optionVal, optionTxt;
            if (typeof option === "string") {
              optionVal = optionTxt = option.trim();
            } else {
              optionVal = option.value.trim();
              optionTxt = option.option;
            }
            return (
              <option key={optionVal} value={optionVal.toLowerCase()}>
                {optionTxt}
              </option>
            );
          })}
        </select>
        <SvgSrc />
      </SelectBox>
    </Container>
  );
}
