import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import Case from "case";
import { EmptyBox } from "./common";

// import { ReactComponent as TrashSVG } from "../svgs/trash.svg";
import { ReactComponent as EmptyBoxSVG } from "../svgs/empty-box.svg";

const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 27px;
  border-radius: 3px;
  min-height: 100px;
  padding-top: 16px;
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  /* padding: 10px 0px; */
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 500;
  }
  & > a {
    text-decoration: none;
    padding: 10px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  & > div:first-child {
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }
    a::hover {
    }
  }
  & > div:last-child {
    display: flex;
    align-items: center;
  }
`;

export const Section = styled.div`
  width: 100%;
  padding: 10px;

  ${InfoItem}:not(:last-child) {
    border-bottom: 1px solid #ebeef0;
  }
`;

const AssetInfoBox = styled.div`
  display: flex;
  align-items: center;
  span {
    color: #007ace;
  }
  span:hover {
    color: #084e8a;
    text-decoration: underline;
  }
  img {
    margin-right: 15px;
    height: 25px;
    width: 25px;
  }
`;
const AssetActionBox = styled.div`
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
    margin-right: 10px;
  }
  span {
    color: #007ace;
  }
  span:hover {
    color: #084e8a;
    text-decoration: underline;
  }
`;
const TrashBtn = styled.button`
  display: inline-block;
  height: auto;
  margin: 0;
  background: inherit;
  border: 0;
  border-radius: inherit;
  font-family: inherit;
  font-weight: 400;
  cursor: pointer;
  text-transform: none;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  background: linear-gradient(to bottom, white, #f9fafb);
  border: 1px solid #c4cdd5;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 3px;
  font-size: 1.5rem;
  line-height: 1.6rem;
  color: #212b36;
  transition-property: background, border, -webkit-box-shadow;
  transition-property: background, border, box-shadow;
  transition-property: background, border, box-shadow, -webkit-box-shadow;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-duration: 200ms;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const AssetStatusBox = styled.div`
  & > span {
    background-color: transparent;
    font-size: 0.7rem;
    font-weight: 400;
    color: #212b36;
  }
  & > span.has-customer-link {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      font-size: 0.7rem;
      font-weight: 400;
      color: #212b36;
    }
  }
  & > span.status-indicator {
    padding: 3px 10px;
    border: 2px solid white;
    border-radius: 15px;
  }

  & > span.online {
    color: #576675;
    background-color: #dfe3e8;
  }
  & > span.pending {
    /* background-color: #ffc58b; */
    background-color: #ffea8a;
    color: #212b36;
  }

  & > span.created {
    background-color: #ffea8a;
    color: #212b36;
  }
  & > span.offline {
    background-color: #ffc58b;
    color: #212b36;
  }

  & > span.strikeout {
    text-decoration: line-through;
  }
`;

function addDefaultSrc(ev) {
  ev.target.src =
    "https://res.cloudinary.com/dwoc5fknz/image/upload/v1556111475/bob/default-coin.jpg";
}

export default ({ providers, providerType }) => (
  <Container>
    {providers.length !== 0 ? (
      <>
        <Section>
          <MainHeader>
            <h3>Installed Providers</h3>
          </MainHeader>
        </Section>
        <Section>
          {providers.map(provider => (
            <InfoItem key={provider.code}>
              <Link to={`/settings/providers/${providerType}/${provider.id}`}>
                <div>
                  <AssetInfoBox>
                    <img
                      src={`https://res.cloudinary.com/dwoc5fknz/image/upload/v1543504221/crypto_assets/${
                        provider.code
                      }.svg`}
                      alt=""
                      onError={addDefaultSrc}
                    />
                    <span>{provider.name}</span>
                  </AssetInfoBox>
                </div>
                <div>
                  <AssetStatusBox>
                    <span className={`status-indicator ${provider.status}`}>
                      {Case.capital(provider.status)}
                    </span>
                  </AssetStatusBox>
                  {/* <AssetActionBox>
              <Link to={`/settings/assets/${currency.code}`}>
                <span>View Asset</span>
              </Link>
            </AssetActionBox> */}
                </div>
              </Link>
            </InfoItem>
          ))}
        </Section>
      </>
    ) : (
      <EmptyBox padding="40px 20px">
        <div>
          <EmptyBoxSVG />
        </div>
        <div>
          <span>There're no installed providers</span>
        </div>
      </EmptyBox>
    )}
  </Container>
);
