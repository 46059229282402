import axios from "axios";
import { toast } from "react-toastify";

import { apiBaseURL } from "../config";

const deleteStaff = (staffId, callback) => async () => {
  try {
    await axios.delete(`${apiBaseURL}/v1/staff/${staffId}`);

    callback();

    toast.success("Staff account deleted!");
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export default deleteStaff;
