import { VERIFICATIONS } from "./types";
import axios from "axios";

import { apiBaseURL } from "../config";
import { toast } from "react-toastify";

const action = payload => ({
  type: VERIFICATIONS.default,
  payload
});

const getVerifications = queryString => dispatch => {
  const payload = queryString
    ? axios.get(`${apiBaseURL}/v1/verification${queryString}`)
    : axios.get(`${apiBaseURL}/v1/verification`);
  dispatch(action(payload)).catch(err => {
    err.response &&
      err.response.data &&
      err.response.data.message &&
      toast.error(err.response.data.message);
  });
};

export default getVerifications;
