import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Case from "case";
import format from "date-fns/format";

import {
  Table,
  TableHead,
  TableHeadRow,
  TableHeadData,
  TableBody,
  TableRow,
  TableData,
  TableDataBox,
  TableHeadCheckboxBox,
  CheckboxLeft
} from "./common";
import Checkbox from "../../../common/Checkbox";
import ActionDropdown from "../../../common/Dropdowns/ActionDropdown";
import { moneyFormatter } from "../../../../helpers/util";
import { ReactComponent as BackSVG } from "../images/back.svg";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false
    };
  }

  _onSelectAllToggled = () =>
    this.setState(state => ({ selectAll: !state.selectAll }));

  _getTransactionDescription(transaction) {
    const {
      price,
      base_amount,
      product,
      quote,
      quote_type,
      type
    } = transaction;
    const cryptoMap = {
      BTC: "Bitcoin",
      ETH: "Ethereum",
      LTC: "Litecoin"
    };
    const productPair = product.split("-");
    const formatedMoney =
      quote_type === "fiat"
        ? moneyFormatter.format(price)
        : `${quote} ${price}`;
    let description = type === "sell" ? "Sell" : "Buy";
    description += ` ${productPair[0]} ${base_amount} @ ${formatedMoney} / ${
      productPair[0]
    }`;
    return description;
  }

  render() {
    const { selectAll } = this.state;
    const {
      transactions: { transactions }
    } = this.props;
    console.log({ transactions });
    return (
      <Table>
        <TableHead>
          <TableHeadRow>
            {/* <TableHeadData colSpan={selectAll ? "7" : "1"} hasSelect>
              <TableHeadCheckboxBox>
                <CheckboxLeft selectedAll={selectAll}>
                  <Checkbox
                    checked={selectAll}
                    onChange={this._onSelectAllToggled}
                  />
                  {!selectAll ? (
                    <FontAwesomeIcon
                      icon="caret-down"
                      onClick={this._onSelectAllToggled}
                    />
                  ) : (
                    <span>1 payout selected</span>
                  )}
                </CheckboxLeft>
                {selectAll && <ActionDropdown />}
              </TableHeadCheckboxBox>
            </TableHeadData> */}
            {!selectAll && (
              <Fragment>
                <TableHeadData>
                  <span>Reference</span>
                </TableHeadData>
                <TableHeadData>
                  <span>Name</span>
                </TableHeadData>
                <TableHeadData>
                  <span>Description</span>
                </TableHeadData>
                <TableHeadData>
                  <span>Date</span>
                </TableHeadData>
                <TableHeadData>
                  <span>Status</span>
                </TableHeadData>
                <TableHeadData className="align-right">
                  <span>Total</span>
                </TableHeadData>
              </Fragment>
            )}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {(transactions || []).map(transaction => (
            <TableRow key={transaction.id}>
              {/* <TableData hasSelect>
                <Checkbox />
              </TableData> */}
              <TableData>
                <Link to={`/transactions/${transaction.id}`}>
                  <TableDataBox>
                    <span>{transaction.reference}</span>
                  </TableDataBox>
                </Link>
              </TableData>
              <TableData>
                <TableDataBox>
                  {transaction.user ? (
                    <span className="has-customer-link">
                      <Link to={`/transactions/${transaction.id}`}>
                        {Case.capital(
                          `${transaction.user.profile.first_name} ${
                            transaction.user.profile.last_name
                          }`
                        )}
                      </Link>
                      <span className="customer-view-icon">
                        <span className="tool-tip">View customer </span>
                        <Link to={`/customers/${transaction.user.id}`}>
                          <BackSVG />
                        </Link>
                      </span>
                    </span>
                  ) : (
                    <span style={{ color: "#A9A9A9" }}>No User</span>
                  )}
                </TableDataBox>
              </TableData>
              {/* <TableData>
                <Link to={`/transactions/${transaction.id}`}>
                  <TableDataBox>
                    <span>{`${transaction.user.profile.first_name} ${
                      transaction.user.profile.last_name
                    }`}</span>
                  </TableDataBox>
                </Link>
              </TableData> */}
              <TableData>
                <Link to={`/transactions/${transaction.id}`}>
                  <TableDataBox>
                    <span>{this._getTransactionDescription(transaction)}</span>
                  </TableDataBox>
                </Link>
              </TableData>
              <TableData>
                <Link to={`/transactions/${transaction.id}`}>
                  <TableDataBox>
                    <span>
                      {format(
                        new Date(transaction.created_at),
                        "MMM D, h:mm a"
                      )}
                    </span>
                  </TableDataBox>
                </Link>
              </TableData>

              <TableData>
                <Link to={`/transactions/${transaction.id}`}>
                  <TableDataBox>
                    <span className={`status-indicator ${transaction.status}`}>
                      {Case.capital(transaction.status)}
                    </span>
                  </TableDataBox>
                </Link>
              </TableData>
              <TableData>
                <Link to={`/transactions/${transaction.id}`}>
                  <TableDataBox className="align-right">
                    {transaction.quote_type === "fiat" ? (
                      <span>
                        {moneyFormatter.format(transaction.quote_amount)}
                      </span>
                    ) : (
                      <span>{`${transaction.quote_amount} ${
                        transaction.quote
                      }`}</span>
                    )}
                  </TableDataBox>
                </Link>
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}
