import React from "react";
import { Spinner } from "react-activity";
import styled, { css } from "styled-components";

import { ReactComponent as SucessSVG } from "../svgs/success.svg";
import { ReactComponent as PendingSVG } from "../svgs/pending.svg";
import { moneyFormatter } from "../../../../helpers/util";

const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 27px;
  border-radius: 3px;
  min-height: 100px;
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;

export const Section = styled.div`
  width: 100%;
  padding: 10px;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
`;

export const ImageBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  span {
    text-transform: capitalize;
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 500;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 10px;

  span {
    color: #212b36;
    font-size: 0.7rem;
    /* font-weight: 500; */
  }
  & > div {
    display: flex;
    align-items: center;
  }
  & > div:first-child {
    img {
      margin-right: 10px;
      height: 30px;
      width: 30px;
    }
    /* flex: 1; */
  }
  /* & > div:last-child {
    flex: 1.5;
    justify-content: space-between;
  } */
  & > div.align-end {
    justify-content: flex-end;
    font-weight: 500;
  }

  & > div:only-child {
    flex: 1;
    justify-content: flex-end;
  }
`;

const ActionBtn = styled.a`
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  border: 1px solid #3f4eae;
  box-shadow: inset 0 1px 0 0 #6f7bcb;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 0.7rem;
  border-radius: 3px;
  &:hover {
    background: linear-gradient(to bottom, #5c6ac4, #4959bd);
    border-color: #3f4eae;
    color: white;
  }

  ${props =>
    props.disabled &&
    css`
      background: #bac0e6;
      border-color: #a7aedf;
      color: white;
      box-shadow: none;
      pointer-events: none;
      &:hover {
        background: #bac0e6;
        border-color: #a7aedf;
        color: white;
      }
    `};
`;

function getTransactionDescription(transaction) {
  const { price, product, quote, quote_type, type, base_amount } = transaction;
  const productPair = product.split("-");
  const formatedMoney =
    quote_type === "fiat" ? moneyFormatter.format(price) : `${price} ${quote}`;
  let description = type === "sell" ? "Sell" : "Buy";
  description += ` ${productPair[0]} ${base_amount} @ ${formatedMoney} / ${
    productPair[0]
  }`;
  return description;
}

export default ({ transaction, loading, onFinalize }) => {
  const productPair = transaction.product.split("-");
  return (
    <Container>
      <Section>
        <ImageBox>
          {(transaction.status === "success" ||
            transaction.status === "completed") && <SucessSVG />}
          {transaction.status === "pending" && <PendingSVG />}
          <span>{transaction.status}</span>
        </ImageBox>
      </Section>
      <Section>
        <InfoItem>
          {/* <div>
            <img
              src={`https://res.cloudinary.com/dwoc5fknz/image/upload/v1543504221/crypto_assets/${
                productPair[0]
              }.svg`}
              alt=""
            />
            <span>{productPair[0]}</span>
          </div> */}
          <div>
            <img
              src={`https://res.cloudinary.com/dwoc5fknz/image/upload/v1543504221/crypto_assets/${
                productPair[0]
              }.svg`}
              alt=""
            />
            <span>
              {/* {moneyFormatter.format(transaction.price)} X{" "}
              {transaction.quantity} */}

              {getTransactionDescription(transaction)}
            </span>
          </div>
          <div className="align-end">
            <span>
              {transaction.quote_type === "fiat"
                ? moneyFormatter.format(transaction.quote_amount)
                : `${transaction.quote} ${transaction.quote_amount}`}
            </span>
          </div>
        </InfoItem>
      </Section>
      <Section borderTop>
        <InfoItem>
          <div>
            <ActionBtn
              disabled={transaction.status === "success"}
              onClick={onFinalize}
            >
              {loading ? (
                <Spinner color="#ffffff" size={12} />
              ) : (
                "Mark as fulfilled"
              )}
            </ActionBtn>
          </div>
        </InfoItem>
      </Section>
    </Container>
  );
};
