import React from "react";
import Case from "case";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import pluralize from "pluralize";

import Table from "../../../../blocks/Table";
// import Checkbox from "../../../common/Checkbox";
import {
  //   TableHeadData,
  HeaderCheckBoxBox,
  //   CheckBoxTD,
  NameText,
  NameBox,
  LocationText,
  ImageTD
  //   CheckboxLeft
} from "./elements";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false
    };
  }

  _onSelectAllToggled = () =>
    this.setState(state => ({ selectAll: !state.selectAll }));

  render() {
    const { selectAll } = this.state;
    const {
      customers: { users }
    } = this.props;

    return (
      <Table>
        <Table.Head>
          <Table.HeadRow>
            <Table.HeadData colSpan={5} hasSelect>
              <HeaderCheckBoxBox>
                <span>Showing {pluralize("customer", users.length, true)}</span>
                {/* <CheckboxLeft active={selectAll}>
									<Checkbox
                    checked={selectAll}
                    onChange={this._onSelectAllToggled}
                  />
								</CheckboxLeft> */}
                {/* {selectAll && <ActionDropdown />} */}
              </HeaderCheckBoxBox>
            </Table.HeadData>
          </Table.HeadRow>
        </Table.Head>
        <Table.Body>
          {users.map(customer => (
            <Table.BodyRow key={customer.id}>
              {/* <CheckBoxTD>
								<Checkbox />
							</CheckBoxTD> */}

              <ImageTD>
                <Link to={`/customers/${customer.id}`}>
                  <img alt="customer profile" src={customer.profile.avatar} />
                </Link>
              </ImageTD>
              <Table.BodyData>
                <Link to={`/customers/${customer.id}`}>
                  <NameBox>
                    <NameText>
                      {Case.capital(
                        `${customer.profile.first_name} ${customer.profile.last_name}`
                      )}
                    </NameText>
                    <LocationText>
                      {customer.profile.city &&
                        Case.capital(`${customer.profile.city}, `)}
                      {customer.profile.country &&
                        Case.capital(customer.profile.country)}
                    </LocationText>
                  </NameBox>
                </Link>
              </Table.BodyData>
              {/* <Table.BodyData>
                <Link to={`/customers/${customer.id}`}>
                  <Table.BodyDataBox className="align-right">
                    <span>0 Orders</span>
                  </Table.BodyDataBox>
                </Link>
              </Table.BodyData>
              <Table.BodyData>
                <Link to={`/customers/${customer.id}`}>
                  <Table.BodyDataBox className="align-right padding-right">
                    <span className="align-right">₦0.00 spent</span>
                  </Table.BodyDataBox>
                </Link>
              </Table.BodyData> */}
              <Table.BodyData>
                <Link to={`/customers/${customer.id}`}>
                  <Table.BodyDataBox className="align-right padding-right">
                    <span className="align-right">
                      {format(customer.created_at, "MMM DD, YYYY")}
                    </span>
                  </Table.BodyDataBox>
                </Link>
              </Table.BodyData>
            </Table.BodyRow>
          ))}
        </Table.Body>
      </Table>
    );
  }
}
