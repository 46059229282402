import React, { Fragment } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import qs from "qs";
import { isEqual } from "lodash";
import { isActive } from "../../../helpers/util";
import { Redirect } from "react-router-dom";
import { Spinner } from "react-activity";
import { debounce } from "lodash";

import { ReactComponent as ExportSVG } from "../Payouts/images/export.svg";
import { ReactComponent as ImportSVG } from "../Payouts/images/import.svg";
import {
  Header,
  Body,
  Card,
  HeaderRight,
  HeaderLeft,
  HeaderText,
  HeaderExportBtn,
  CardHeader,
  HeaderTextBox,
  HeaderExportBtnBox,
  CreateBtn,
  TabsBtnBox,
  TabBtnList,
  TabBtnItem,
  TabBtnLink,
  SearchAndFilterBox,
  FilterBox,
  SearchBox,
  SearchIconBox,
  SearchInput
} from "../Payouts/components/common";
import CustomersTable from "./components/CustomersTable";
import FilterDropdown from "./components/FilterDropdown";
import {
  CContainer,
  UploadBtnsBox,
  FilterAndSortBox,
  FilterSearchBox,
  SortBox,
  SortLabel,
  SortDropdownBox,
  CDropdownSelect,
  CCardBody,
  CTableBox
} from "./components/elements";
import CustomersLoader from "./components/CustomersLoader";
import { fetchCustomers, searchCustomers } from "../../../actions/customers";
import Paginator from "../../common/Paginator";
import { InnerSpinner } from "./components/elements";

class Customers extends React.Component {
  state = {
    totalEntries: 1,
    perPage: 20,
    searchQuery: "",
    waitTime: 500
  };

  componentDidMount() {
    document.title = "Customers";

    const { fetchCustomers } = this.props;

    const { page = 1, status = "all" } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    fetchCustomers(page, status, totalEntries => {
      this.setState({
        totalEntries
      });
    });
  }

  debouncedSearch = debounce(query => {
    const {
      searchCustomers,
      location: { search }
    } = this.props;

    const { page = 1, status = "all" } = qs.parse(search, {
      ignoreQueryPrefix: true
    });

    searchCustomers(
      page,
      status,
      totalEntries => {
        this.setState({
          totalEntries
        });
      },
      query
    );
  }, this.state.waitTime);

  handleChange = ({ target: { value, name } }) => {
    this.setState({
      [name]: value
    });

    const trimmedValue = value.trim();

    this.debouncedSearch(trimmedValue);
  };

  componentDidUpdate(prevProps) {
    const { fetchCustomers } = this.props;
    const { page, status = "all" } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      fetchCustomers(page, status, totalEntries => {
        this.setState({
          totalEntries
        });
      });
    }
  }

  render() {
    const {
      customers: { fetching, customers, searching, searched, fetched },
      history: {
        location: { search }
      },
      user: { payload }
    } = this.props;

    const { totalEntries, perPage } = this.state;

    return (
      <>
        {payload && !payload.permissions.includes("Customers") && (
          <Redirect to="/dashboard" />
        )}
        <CContainer>
          {fetching ? (
            <CustomersLoader />
          ) : (
            <Fragment>
              <Header>
                <HeaderLeft>
                  <HeaderTextBox>
                    <HeaderText>Customers</HeaderText>
                  </HeaderTextBox>
                  {/* <UploadBtnsBox>
										<HeaderExportBtnBox>
											<HeaderExportBtn>
												<ImportSVG />
												<span>Import Customers</span>
											</HeaderExportBtn>
										</HeaderExportBtnBox>
										<HeaderExportBtnBox>
											<HeaderExportBtn>
												<ExportSVG />
												<span>Export</span>
											</HeaderExportBtn>
										</HeaderExportBtnBox>
									</UploadBtnsBox> */}
                </HeaderLeft>
                <HeaderRight>
                  <CreateBtn>Add customer</CreateBtn>
                </HeaderRight>
              </Header>
              <Body>
                <Card>
                  <CardHeader>
                    <TabsBtnBox>
                      <TabBtnList>
                        <TabBtnItem>
                          <TabBtnLink
                            to="/customers"
                            className={
                              isActive(undefined, search) && "active-tab"
                            }
                          >
                            All
                          </TabBtnLink>
                        </TabBtnItem>
                        <TabBtnItem>
                          <TabBtnLink
                            to="/customers?status=active"
                            className={
                              isActive("active", search) && "active-tab"
                            }
                          >
                            Active
                          </TabBtnLink>
                        </TabBtnItem>
                        <TabBtnItem>
                          <TabBtnLink
                            to="/customers?status=inactive"
                            className={
                              isActive("inactive", search) && "active-tab"
                            }
                          >
                            Inactive
                          </TabBtnLink>
                        </TabBtnItem>
                      </TabBtnList>
                    </TabsBtnBox>
                  </CardHeader>
                  <CCardBody>
                    <FilterAndSortBox>
                      <FilterSearchBox>
                        <SearchAndFilterBox>
                          {/* <FilterBox flex="15%">
														<FilterDropdown smallDropdownBox />
													</FilterBox> */}
                          <SearchBox>
                            <SearchIconBox>
                              <FontAwesomeIcon icon="search" />
                            </SearchIconBox>
                            <SearchInput
                              value={this.state.searchQuery}
                              onChange={this.handleChange}
                              name="searchQuery"
                              placeholder="Search Customers"
                            />
                          </SearchBox>
                        </SearchAndFilterBox>
                      </FilterSearchBox>
                      {/* <SortBox>
												<SortLabel>
													<span>Sort By</span>
												</SortLabel>
												<SortDropdownBox>
													<CDropdownSelect>
														<option>Newest Update</option>
														<option>Status</option>
														<option>Fulfillment</option>
													</CDropdownSelect>
												</SortDropdownBox>
											</SortBox> */}
                    </FilterAndSortBox>
                    <CTableBox>
                      {searching && (
                        <InnerSpinner>
                          <Spinner size={30} color="#47C1BF" speed={2} />
                        </InnerSpinner>
                      )}
                      {(fetched || searched) && (
                        <CustomersTable customers={customers} />
                      )}
                    </CTableBox>
                  </CCardBody>
                </Card>
              </Body>

              {(fetched || searched) && totalEntries > 20 && (
                <Paginator
                  totalEntries={totalEntries}
                  perPage={perPage}
                  {...this.props}
                />
              )}
            </Fragment>
          )}
        </CContainer>
      </>
    );
  }
}

function mapSatetoProps({ customers, user }) {
  return { customers, user };
}

export default connect(
  mapSatetoProps,
  { fetchCustomers, searchCustomers }
)(Customers);
