import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: block;

  label {
    font-size: 15px;
  }
`;

const InputBox = styled.div`
  border-style: inset;
  color: black;
  padding: 5px 10px;
  border: 1px solid #c4cdd5;
  border-radius: 3px;
  font-size: 1.6rem;
  font-weight: 400;
  /* line-height: 2.4rem;
  text-transform: initial;
  letter-spacing: initial; */
  box-sizing: border-box;
  /* display: block; */

  width: 100%;
  max-width: 100%;
  position: relative;
  box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
  border-color: #c4cdd5;

  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 5px;

  input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 15px;
    padding: 0px 5px;
  }

  span {
    font-size: 15px;
    color: #95a7b7;
  }

  &:focus-within {
    border: 1px solid #5c6ac4;
    box-shadow: 0 0 0 1px #5c6ac4;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #95a7b7;
  }
`;

export default function Input({
  prefix,
  prefixIcon,
  suffix,
  label,
  name,
  ...props
}) {
  return (
    <Container>
      {label && <label htmlFor={label.toLowerCase()}>{label}</label>}
      <InputBox>
        {prefix && <span>{prefix}</span>}
        {prefixIcon && <span>{prefixIcon}</span>}
        <input name={name} {...props} />
        {suffix && <span>{suffix}</span>}
      </InputBox>
    </Container>
  );
}
