import React from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";

import {
  Table,
  TableBody,
  TableRow,
  TableData,
  TableDataBox,
  TableHead,
  TableHeadData
} from "../../Payouts/components/common";
import {
  NameText,
  NameBox,
  ImageTD as ImgTD
} from "../../Customers/components/elements";
import { moneyFormatter, round } from "../../../../helpers/util";
import { EmptyBox, ActiontBtnBox, AccountListHiddenBtn } from "./common";
import { ReactComponent as EmptyBoxSVG } from "../svgs/empty-box.svg";
import Modal from "../../../common/Modal";
import EditAccountBalanceForm from "./EditAccountBalanceForm";
import { updateCustomerAccount } from "../../../../actions/customer";

export const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border-radius: 3px;
  min-height: 100px;
`;
export const Section = styled.div`
  width: 100%;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
  padding: 20px;
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;

export const SectionBody = styled.div`
  padding: 20px 0px;
`;

export const ImageTD = styled(ImgTD)`
  img {
    height: 25px;
    width: 25px;
    vertical-align: top;
  }
`;

class AccountsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalActive: false,
      amount: "",
      action: "add",
      selectedAccount: null
    };
  }

  toggleModal = () => {
    this.setState(prevState => {
      return {
        modalActive: !prevState.modalActive
      };
    });
  };

  handleChange = e => {
    const target = e.target;
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  onEditAccount(account) {
    this.setState({ selectedAccount: account, amount: "", action: "add" }, () =>
      this.toggleModal()
    );
  }

  updateAccountBalance = () => {
    const { selectedAccount, amount, action } = this.state;
    const {
      customer: {
        customer: { id }
      }
    } = this.props;
    if (!amount || !action || !selectedAccount) return;
    const data = {
      customerId: id,
      currency: selectedAccount.currency,
      amount,
      action
    };
    this.props.updateCustomerAccount(data, this.refetchCustomerInfo.bind(this));
  };

  refetchCustomerInfo() {
    this.props.onAccountBalanceUpdated();
  }

  render() {
    const { props } = this;
    const {
      customer: { updatingAccountBalance },
      userCanEditAccount
    } = props;

    return (
      <Container>
        <Section>
          <MainHeader>
            <h3>Accounts</h3>
          </MainHeader>
          <SectionBody>
            {props.accounts && props.accounts.length > 0 ? (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableData weight="500">
                      <div className="align-right">#</div>
                    </TableData>
                    <TableData weight="500">
                      <TableDataBox>
                        <b>Account</b>
                      </TableDataBox>
                    </TableData>
                    <TableData weight="500">
                      <TableDataBox className="align-right">
                        <b>Hold</b>
                      </TableDataBox>
                    </TableData>
                    <TableData weight="500">
                      <TableDataBox className="align-right">
                        <b>Pending</b>
                      </TableDataBox>
                    </TableData>
                    <TableData weight="500">
                      <TableDataBox className="align-right">
                        <b>Available</b>
                      </TableDataBox>
                    </TableData>
                  </TableRow>
                  {props.accounts.map(account => (
                    <TableRow key={account.currency}>
                      <ImageTD>
                        <img
                          alt={account.name}
                          src={`https://res.cloudinary.com/dwoc5fknz/image/upload/v1543504221/crypto_assets/${account.currency}.svg`}
                        />
                      </ImageTD>
                      <TableData>
                        <NameBox>
                          <NameText>{account.name}</NameText>
                        </NameBox>
                      </TableData>
                      <TableData>
                        <TableDataBox className="align-right">
                          <span className="align-right">
                            {getBalance(account, "hold")}
                          </span>
                        </TableDataBox>
                      </TableData>
                      <TableData>
                        <TableDataBox className="align-right">
                          <span className="align-right">
                            {getBalance(account, "pending_balance")}
                          </span>
                        </TableDataBox>
                      </TableData>
                      <TableData>
                        <TableDataBox className="align-right">
                          {userCanEditAccount && (
                            <AccountListHiddenBtn
                              className="hidden-btn"
                              onClick={this.onEditAccount.bind(this, account)}
                            >
                              Edit Account
                            </AccountListHiddenBtn>
                          )}
                          <span className="align-right">
                            {getBalance(account)}
                          </span>
                        </TableDataBox>
                      </TableData>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <EmptyBox>
                <div>
                  <EmptyBoxSVG />
                </div>
                <div>
                  <span>This customer has no account</span>
                </div>
              </EmptyBox>
            )}
          </SectionBody>
        </Section>
        {this.state.modalActive && this.state.selectedAccount && (
          <Modal
            actions={{
              toggleModal: this.toggleModal,
              handleClick: this.updateAccountBalance
            }}
            data={{
              id: "",
              loading: updatingAccountBalance,
              title: `Update ${this.state.selectedAccount.name} Balance`,
              body: (
                <EditAccountBalanceForm
                  action={this.state.action}
                  amount={this.state.amount}
                  handleChange={this.handleChange}
                />
              ),
              buttonTxt: "Update Balance"
            }}
            meta={{
              width: "620px",
              buttonType: "blue"
            }}
          />
        )}
      </Container>
    );
  }
}

function getBalance(account, accountType = "balance") {
  if (account.type === "fiat")
    return moneyFormatter.format(account[accountType]);
  return `${round(account[accountType], 8)} ${account.currency}`;
}

function maoStateToProps({ customer }) {
  return {
    customer
  };
}

export default connect(
  maoStateToProps,
  { updateCustomerAccount }
)(AccountsCard);
