import React, { Fragment } from "react";
import { connect } from "react-redux";
import { ReactComponent as ExportSVG } from "./images/export.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import qs from "qs";

import {
  Container,
  Header,
  Body,
  Card,
  SortBox,
  SortLabel,
  SortDropdownBox,
  CDropdownSelect,
  FilterSearchBox,
  FilterAndSortBox,
  HeaderRight,
  HeaderLeft,
  HeaderText,
  CardHeader,
  HeaderTextBox,
  CreateBtn,
  TabsBtnBox,
  TabBtnList,
  TabBtnItem,
  TabBtnLink,
  CardBody,
  SearchAndFilterBox,
  SearchBox,
  SearchIconBox,
  SearchInput,
  TableBox
} from "./components/common";
import { getTrades } from "./Trades.action";
import PayoutsTable from "./components/TradesTable";
import FullTableLoader from "../../common/SVGLoaders/FullTableLoader";
import { isEqual } from "lodash";
import Paginator from "../../common/Paginator";
import { isActive, getQueryString } from "../../../helpers/util";

class Ads extends React.Component {
  state = {
    searchQuery: ""
  };

  componentDidMount() {
    document.title = "Market Place";

    this.getAllTrades();
  }

  getAllTrades = () => {
    const { page, settled } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    const queryString = { page, settled };
    this.props.getTrades(queryString);
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  handleFilter = ({ target: { name, value } }) => {
    const { side, country, asset } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    const query = getQueryString({ side, country, asset, [name]: value });
    this.props.history.push(`/marketplace/ads${query}`);
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      this.getAllTrades();
    }
  }

  render() {
    const {
      trades,
      history: {
        location: { search }
      }
    } = this.props;
    return (
      <Container>
        {trades.fetching_trades ? (
          <FullTableLoader />
        ) : (
          <Fragment>
            <Header>
              <HeaderLeft>
                <HeaderTextBox>
                  <HeaderText>Trades</HeaderText>
                </HeaderTextBox>
              </HeaderLeft>
            </Header>
            <Body>
              <Card>
                <CardHeader>
                  <TabsBtnBox>
                    <TabBtnList>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/marketplace/trades"
                          className={
                            isActive(undefined, search, "settled") &&
                            "active-tab"
                          }
                        >
                          Unsettled
                        </TabBtnLink>
                      </TabBtnItem>
                      <TabBtnItem>
                        <TabBtnLink
                          to="/marketplace/trades?settled=t"
                          className={
                            isActive("t", search, "settled") && "active-tab"
                          }
                        >
                          Settled
                        </TabBtnLink>
                      </TabBtnItem>
                    </TabBtnList>
                  </TabsBtnBox>
                </CardHeader>
                <CardBody>
                  <FilterAndSortBox>
                    <FilterSearchBox>
                      <SearchAndFilterBox>
                        <SearchBox>
                          <SearchIconBox>
                            <FontAwesomeIcon icon="search" />
                          </SearchIconBox>
                          <SearchInput
                            value={this.state.searchQuery}
                            onChange={this.handleChange}
                            name="searchQuery"
                            placeholder="Search Customers"
                          />
                        </SearchBox>
                      </SearchAndFilterBox>
                    </FilterSearchBox>
                  </FilterAndSortBox>
                  <TableBox>
                    <PayoutsTable type="fiat" ads={trades.list} />
                  </TableBox>
                </CardBody>
              </Card>
            </Body>
            {trades.fetched_trades && (
              <Paginator
                totalEntries={trades.list.meta.total_entries_size}
                perPage={trades.list.meta.per_page}
                {...this.props}
              />
            )}
          </Fragment>
        )}
      </Container>
    );
  }
}

function mapStateToProps({ trades }) {
  return {
    trades
  };
}
Ads.defaultProps = {
  trades: {
    list: {
      data: [],
      meta: {
        page: 1,
        per_page: 20,
        offset: 0,
        total_entries_size: 0,
        current_entries_size: 0,
        total_pages: 0
      }
    }
  }
};
export default connect(
  mapStateToProps,
  { getTrades }
)(Ads);
