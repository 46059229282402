import axios from "axios";

import types from "./types";
import { apiBaseURL } from "../config";

const getStaffFulfilled = payload => ({
  type: types("get_staff").fulfilled,
  payload
});

const getStaffRejected = payload => ({
  type: types("get_staff").rejected,
  payload
});

const getStaffPending = () => ({
  type: types("get_staff").pending
});

const getStaff = () => async dispatch => {
  try {
    dispatch(getStaffPending());

    const { data } = await axios.get(`${apiBaseURL}/v1/staff`);

    const accountOwners = data.filter(
      account => account.account_owner === true
    );

    const staffAccounts = data.filter(
      account => account.account_owner === false && account.active === true
    );

    const deactivatedAccounts = data.filter(
      account => account.active === false
    );

    dispatch(
      getStaffFulfilled({
        accountOwners,
        staffAccounts,
        deactivatedAccounts
      })
    );
  } catch (err) {
    dispatch(getStaffRejected());
  }
};

export default getStaff;
