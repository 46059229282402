import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import Case from "case";
import { withRouter } from "react-router-dom";

// import { EmptyBox } from "./common";
// import { ReactComponent as EmptyBoxSVG } from "../svgs/empty-box.svg";

export const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 27px;
  border-radius: 3px;
  min-height: 100px;
`;

export const BtnCont = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  button {
    border-radius: 4px;
    background: linear-gradient(180deg, #6371c7, #5563c1);
    border-color: #3f4eae;
    box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05),
      0 0 0 0 transparent;
    color: #fff;
    fill: #fff;
    ${"" /* width: 63.09px; */}
    padding: 0 10px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
`;

export const Section = styled.div`
  width: 100%;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
  padding: 20px;
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span,
  a {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
    text-decoration: none;
  }
  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
  span:hover,
  a:hover {
    text-decoration: underline;
  }
`;
export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #212b36;
    font-size: 0.6rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;

export const SectionBody = styled.div`
  padding: 0px 0px;
`;

export const Info = styled.div`
  span.action {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span.action:hover {
    text-decoration: underline;
  }
  span.card-number {
    margin-left: 5px;
  }
  p {
    font-size: 0.7rem;
    color: #212b36;
    padding: 5px 0px;
    max-width: 224px;
  }
  .transaction-hash {
    word-wrap: break-word;
    line-height: 1rem;
  }
  .empty-state {
    color: #637381;
  }
`;

const ChangeAddressForm = styled.form`
  font-size: 14px;

  textarea {
    display: block;
    width: 100%;
    padding: 10px;

    :focus {
      border: 2px solid #5c6ac4 !important;
    }
  }

  .formGroup {
    display: flex;
    padding: 0 20px;
    margin-bottom: 20px;

    .inputGroup {
      padding: 0;

      :not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .inputGroup {
    flex: 1;

    label {
      display: block;
      margin-bottom: 6px;
    }

    textarea {
      border-radius: 3px;
      height: 70px;
      box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
      border: 1px solid #c4cdd5;
      outline: none;
      font-size: inherit;
    }
  }
`;

class CustomerCard extends React.Component {
  getCardType = card_type => {
    const cardTypeStringArray = card_type.split(" ");
    return (
      Case.capital(`${cardTypeStringArray[0]}`) + ` ${cardTypeStringArray[1]}`
    );
  };
  onGetUrl = (coin, hash) => {
    switch (coin) {
      case "BTC":
        return "https://live.blockcypher.com/btc/tx/" + hash;
      case "ETH":
        return "https://etherscan.io/tx/0x" + hash;
      case "LTC":
        return "https://live.blockcypher.com/ltc/tx/" + hash;
      case "RMT":
        return "https://stellarchain.io/tx/" + hash;
      case "XLM":
        return "https://stellarchain.io/tx/" + hash;
      default:
        return "";
    }
  };

  render() {
    const {
      deposit,
      match: {
        params: { type }
      }
    } = this.props;
    return (
      <Container>
        <Section>
          <MainHeader>
            <h3>Customer</h3>
            <img
              alt="hey"
              src={
                deposit.user.profile.avatar ||
                "https://proxy.shopifycdn.com/b7ca1b34856dd99657959e715a8254d1779fd33eda4c4e47548a922b4d8e9da8/www.gravatar.com/avatar/5adc8c41ea46f09867a985e8952436d6.jpg?s=60&d=https%3A%2F%2Fcdn.shopify.com%2Fs%2Fassets%2Fadmin%2Fcustomers%2Fpolaris%2Favatar-4-2ec208d9d3bdf8bc3740e5d7c66129a927a95465546e1fca477828c35a6dcaa1_60x60.png"
              }
            />
          </MainHeader>
          <SectionBody>
            <Info>
              <p>
                <span className="action">{`${deposit.user.profile.first_name} ${
                  deposit.user.profile.last_name
                }`}</span>
              </p>
              {/* <p>1 order</p> */}
            </Info>
          </SectionBody>
        </Section>
        <Section borderTop>
          <MainHeader>
            <h3>Contact information</h3>
          </MainHeader>
          <SectionBody>
            <Info>
              <p>
                <span className="action">{`${deposit.user.email}`}</span>
              </p>
              <p>{`${deposit.user.phone.number || "No phone number"}`}</p>
            </Info>
          </SectionBody>
        </Section>
        {type === "fiat" &&
          deposit.fulfilment_status !== "unfulfilled" &&
          deposit.card_type && (
            <Section borderTop>
              <MainHeader>
                <h3>Card</h3>
              </MainHeader>
              <SectionBody>
                <Info>
                  <p>{this.getCardType(deposit.card_type)}</p>
                  <p>{`•••• •••• •••• ${deposit.last4}`}</p>
                  <p>{`${deposit.bank} (${deposit.country})`}</p>
                </Info>
              </SectionBody>
            </Section>
          )}
        {type === "crypto" && (
          <Fragment>
            <Section borderTop>
              <MainHeader>
                <h3>Transaction hash </h3>
                <a
                  href={this.onGetUrl(deposit.asset, deposit.hash)}
                  target="_blank"
                  referrer="no referrer"
                >
                  View{" "}
                </a>
              </MainHeader>
              <SectionBody>
                <Info>
                  <p
                    className={
                      deposit.hash ? "transaction-hash" : "empty-state"
                    }
                  >
                    {deposit.hash || "No transaction hash"}
                  </p>
                </Info>
              </SectionBody>
            </Section>
            <Section borderTop>
              <MainHeader>
                <h3>Recieve Address </h3>
              </MainHeader>
              <SectionBody>
                <Info>
                  <p className="transaction-hash">{deposit.address}</p>
                  <p> </p>
                </Info>
              </SectionBody>
            </Section>
            <Section borderTop>
              <MainHeader>
                <h3>Memo </h3>
              </MainHeader>
              <SectionBody>
                <Info>
                  <p className={deposit.memo_value ? "" : "empty-state"}>
                    {deposit.memo_value || "No memo"}{" "}
                  </p>
                </Info>
              </SectionBody>
            </Section>
          </Fragment>
        )}
      </Container>
    );
  }
}

export default withRouter(CustomerCard);
