import types, { NEW_TRANSACTION } from "../actions/types";

export default function(
  state = {
    fetching: false,
    fetched: false,
    transaction: null,
    error: null
  },
  action
) {
  switch (action.type) {
    case NEW_TRANSACTION.pending:
      return {
        fetching: true,
        fetched: false
      };
    case NEW_TRANSACTION.fulfilled:
      return {
        fetching: false,
        fetched: true,
        transaction: action.payload
      };
    case NEW_TRANSACTION.rejected:
      return {
        fetching: false,
        fetched: false,
        error: action.payload
      };
    case types("fetch_transaction_info").pending:
    case types("fetch_one_swap").pending:
      return {
        fetched: false,
        fetching: true
      };
    case types("fetch_transaction_info").fulfilled:
    case types("fetch_one_swap").fulfilled:
      return {
        fetching: false,
        fetched: true,
        transaction: action.payload
      };
    case types("fetch_transaction_info").rejected:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload
      };
    default:
      return state;
  }
}
