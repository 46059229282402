import React from "react";
import Moment from "moment";
// import { extendMoment } from "moment-range";

import {
  PickerButton,
  DatePickerCont,
  DropwDownContainer,
  PickerFooter,
  DateRangeSelectBox,
  DateRangeInputBox
} from "./elements";
import { ReactComponent as CalendarIcon } from "../assets/calendar.svg";
import DateRangePicker from "./DateRangePicker";
import Card from "../../../../../components/common/Card";

import Input from "../../../Transactions/NewTransaction/Input";
import Select from "../../../../common/Select";

const moment = Moment;

// const ranges = {
//   today: {
//     end: moment(),
//     start: moment(),
//     phrase: "Today"
//   },
//   yesterday: {
//     end: moment().subtract(1, "d"),
//     start: moment().subtract(1, "d"),
//     phrase: "Yesterday"
//   },
//   last_7_days: {
//     end: moment(),
//     start: moment().subtract(7, "d"),
//     phrase: "Last 7 days"
//   },
//   last_30_days: {
//     end: moment(),
//     start: moment().subtract(30, "d"),
//     phrase: "Last 30 days"
//   },
//   last_month: {
//     end: moment(),
//     start: moment().subtract(1, "M"),
//     phrase: "Last month"
//   },
//   last_year: {
//     end: moment(),
//     start: moment().subtract(1, "y"),
//     phrase: "Last year"
//   }
// };

export default class DatePicker extends React.Component {
  // dateRanges = [];

  constructor(props) {
    super(props);
    this.ranges = {
      today: {
        end: props.endDate,
        start: props.startDate,
        phrase: "Today"
      },
      yesterday: {
        end: moment().subtract(1, "d"),
        start: moment().subtract(1, "d"),
        phrase: "Yesterday"
      },
      last_7_days: {
        end: moment(),
        start: moment().subtract(7, "d"),
        phrase: "Last 7 days"
      },
      last_30_days: {
        end: moment(),
        start: moment().subtract(30, "d"),
        phrase: "Last 30 days"
      },
      last_month: {
        end: moment(),
        start: moment().subtract(1, "M"),
        phrase: "Last month"
      },
      last_year: {
        end: moment(),
        start: moment().subtract(1, "y"),
        phrase: "Last year"
      }
    };
    this.state = {
      showDropwdown: false,
      appliedDate: null,
      startDate: props.startDate,
      endDate: props.endDate,
      dateRange: this.ranges["today"],
      dateFormat: "YYYY-MM-DD",
      dateRanges: []
    };
  }
  componentDidMount() {
    this.setState({
      dateRanges: Object.keys(this.ranges).map(key => this.ranges[key].phrase)
    });
  }

  togglePopover = () => {
    this.setState(({ showDropwdown }) => ({
      showDropwdown: !showDropwdown
    }));
  };

  onDatesChange = ({ startDate, endDate }) =>
    this.setState({ startDate, endDate });

  onDateRangeSelectChange = e => {
    const dateRange = this.ranges[e.target.value.split(" ").join("_")];
    // console.log({ dateRange });
    this.setState({
      dateRange,
      startDate: dateRange.start,
      endDate: dateRange.end
    });
  };

  onApplyDate = () => {
    const { startDate, endDate } = this.state;
    const { onDatesChange } = this.props;
    const dateRange = this.findDateRange({ startDate, endDate });
    // console.log({ dateRange });
    if (dateRange) {
      // console.log("assign phrase");
      this.setState({ appliedDate: this.ranges[dateRange].phrase });
    } else {
      this.setState({
        appliedDate: "Custom"
      });
    }
    this.togglePopover();
    onDatesChange({ startDate, endDate });
  };

  findDateRange = ({ startDate, endDate }) => {
    return Object.keys(this.ranges).find(
      key =>
        this.ranges[key].start === startDate && this.ranges[key].end === endDate
    );
  };

  render() {
    const {
      appliedDate,
      showDropwdown,
      endDate,
      startDate,
      dateRange,
      dateFormat,
      dateRanges
    } = this.state;
    return (
      <DatePickerCont>
        <PickerButton onClick={this.togglePopover}>
          <CalendarIcon />
          <span>{appliedDate ? appliedDate : "Today"}</span>
        </PickerButton>

        {showDropwdown && (
          <DropwDownContainer>
            <Card style={{ margin: "10px 0px 0px" }}>
              <DateRangeSelectBox>
                <div>
                  <Select
                    name="date-range-select"
                    label="Date Range"
                    value={dateRange.phrase.toLowerCase()}
                    options={dateRanges}
                    onChange={this.onDateRangeSelectChange}
                  />
                </div>
              </DateRangeSelectBox>
              <DateRangeInputBox>
                <div>
                  <Input
                    label="Starting"
                    value={startDate.format(dateFormat)}
                    readOnly={true}
                  />
                </div>
                <div>
                  <Input
                    label="Ending"
                    value={
                      endDate
                        ? endDate.format(dateFormat)
                        : startDate.format(dateFormat)
                    }
                    readOnly={true}
                  />
                </div>
              </DateRangeInputBox>
              <div>
                <DateRangePicker
                  endDate={endDate}
                  startDate={startDate}
                  onDatesChange={this.onDatesChange}
                />
              </div>

              <PickerFooter>
                <PickerButton onClick={this.togglePopover}>Cancel</PickerButton>
                <PickerButton action="true" onClick={this.onApplyDate}>
                  Apply
                </PickerButton>
              </PickerFooter>
            </Card>
          </DropwDownContainer>
        )}
      </DatePickerCont>
    );
  }
}
