import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

/////////////
// CONTAINER
/////////////
export const Container = styled.div`
  margin: 65px 32px;
  height: 100%;
  width: 100%;
  min-height: 100vh;
`;

/////////////
// HEADER
/////////////
export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;
export const HeaderLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const HeaderTextBox = styled.div``;
export const HeaderText = styled.h1`
  font-size: 1.6rem;
  /* padding-left: 8px; */
  padding: 0;
  margin: 0;
  font-weight: 600;
  color: #212b36;
`;
export const HeaderExportBtnBox = styled.div`
  min-width: 80px;
  ${props =>
    props.smallBox &&
    css`
      width: 80px;
    `};
  margin-top: 10px;
`;
export const HeaderExportBtn = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border-radius: 3px;
  color: #637381;
  padding: 8px 8px 8px 0px;
  &:not(:first-child) {
    padding: 8px;
  }
  span {
    font-size: 0.7rem;
  }
  svg {
    color: transparent;
    fill: #637381;
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
  &:hover {
    color: #212b36;
    text-decoration: none;
  }
`;
export const HeaderRight = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 100%;
  height: 100%;
`;
export const CreateBtn = styled(Link)`
  background: linear-gradient(to bottom, #6371c7, #5563c1);
  border: 1px solid #3f4eae;
  box-shadow: inset 0 1px 0 0 #6f7bcb;
  color: #fff;
  font-weight: 400;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 0.7rem;
  border-radius: 3px;
  text-decoration: none;
  &:hover {
    background: linear-gradient(to bottom, #5c6ac4, #4959bd);
    border-color: #3f4eae;
    color: white;
  }
`;

////////////
// BODY
////////////
export const Body = styled.div`
  width: 100%;
  padding-top: 20px;
`;

////////////
// CARD
////////////
export const Card = styled.div`
  background-color: white;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  width: 100%;
  border-radius: 3px;
  /* min-height: 200px; */
`;

export const CardHeader = styled.div`
  background-color: transparent;
  border-bottom: 1px solid #dfe3e8;
  border-radius: 0;
`;

export const CardBody = styled.div`
  min-height: 200px;
  margin: 20px 0px;
  padding: 0px 20px;
`;

////////////////
// CARD HEADER TABS BUTTON
////////////////
export const TabsBtnBox = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const TabBtnList = styled.ul`
  height: 100%;
  margin: 0;
  padding: 0px;
  list-style: none;
  display: flex;
`;

export const TabBtnItem = styled.li`
  height: 100%;
  & > a {
    margin: 0px 16px;
    padding: 16px 0px;
  }
  &:not(:first-child) {
    & > a {
      padding-left: 0px;
      margin-left: 0;
    }
  }
  & > a.active-tab {
    color: #212b36;
    border-bottom-color: #5c6ac4;
  }
`;

export const TabBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  min-width: 50px;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 400;
  color: #637381;
  text-decoration: none;
  &:hover {
    border-bottom-color: #c4cdd5;
    color: #212b36;
  }
`;

///////////////
// SEARCH AND FILTER BOX
//////////////
export const SearchAndFilterBox = styled.div`
  display: flex;
  align-items: stretch;
  height: 36px;
  /* margin: 20px 0px;
  padding: 0px 20px; */
`;
export const FilterBox = styled.div`
  flex: ${props => (props.flex ? props.flex : "6%")};
  display: flex;
  align-items: stretch;
`;
export const SearchBox = styled.div`
  flex: 94%;
  display: flex;
  padding: 0px 10px;
  border: 1px solid #c4cdd5;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
`;

export const FilterBtn = styled.button.attrs({
  type: "button"
})`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: linear-gradient(to bottom, white, #f9fafb);
  outline: none;
  cursor: pointer;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border: 1px solid #c4cdd5;
  color: #212b36;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition-property: background, border, box-shadow, -webkit-box-shadow;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-duration: 200ms;
  -webkit-tap-highlight-color: transparent;
  font-size: 0.7rem;

  &:hover {
    background: linear-gradient(to bottom, #f9fafb, #f4f6f8);
  }
`;

export const SearchIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  padding-right: 5px;
  color: #95a7b7;
`;
export const SearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 0.7rem;
  color: #31373d;
  &::placeholder {
    color: #95a7b7;
  }
`;

/////////////////////
// TABLE
////////////////////
export const TableBox = styled.div`
  width: 100%;
  padding: 20px 0px;
`;

export const Table = styled.table`
  width: 100%;
  color: #212b36;
  table-layout: auto;
`;

export const TableHead = styled.thead``;
export const TableHeadRow = styled.tr`
  /* height: 51px; */
  & > th.align-right {
    text-align: right;
  }
`;
export const TableHeadData = styled.th`
  font-weight: 500;
  font-size: 0.7rem;
  text-align: left;
  color: #212b36;
  padding: 14px 10px;
  border-bottom: 1px solid #ebeef0;
  line-height: 1.5rem;
  & > span {
  }
  & > label {
    margin: 0;
  }
  ${props =>
    props.hasSelect &&
    css`
      width: 55px;
      /* max-width: 65px; */
      padding-left: 0px;
    `};
`;
export const TableHeadCheckboxBox = styled.div`
  display: flex;
`;
export const CheckboxLeft = styled.div`
  padding: 5px 5px;
  background: linear-gradient(to bottom, white, #f9fafb);
  outline: none;
  cursor: pointer;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border: 1px solid #c4cdd5;
  color: #212b36;
  border-radius: 3px;
  transition-property: background, border, box-shadow, -webkit-box-shadow;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-duration: 200ms;
  -webkit-tap-highlight-color: transparent;
  font-size: 0.7rem;

  height: 30px;
  display: flex;
  align-items: center;

  & > label {
    margin-bottom: 0;
  }

  & > svg {
    color: #939ea9;
  }

  ${props =>
    props.selectedAll &&
    css`
      background: #f4f6f8;
      border-color: #c4cdd5;
      color: #919eab;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `};
`;
export const CheckboxRight = styled.div`
  padding: 5px 10px;
  background: linear-gradient(to bottom, white, #f9fafb);
  outline: none;
  cursor: pointer;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border: 1px solid #c4cdd5;
  color: #212b36;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: none;
  transition-property: background, border, box-shadow, -webkit-box-shadow;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-duration: 200ms;
  -webkit-tap-highlight-color: transparent;
  font-size: 0.7rem;
  width: 100px;

  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TableBody = styled.tbody``;
export const TableRow = styled.tr`
  /* height: 50px; */
  span.customer-view-icon {
    margin-left: 5px;
    display: flex;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #95a7b7;
    align-items: center;
    opacity: 0;
    a {
      padding: 2px 0px 1px;
    }
    svg {
      width: 12px;
      height: 12px;
      transform: rotate(180deg);
    }
  }
  &:hover {
    background-color: #f9fafb;
    cursor: pointer;
    span.customer-view-icon {
      opacity: 1;
    }
  }
`;
export const TableData = styled.td`
  font-weight: 400;
  font-size: 0.7rem;
  text-align: left;
  color: #212b36;
  height: 50px;
  box-sizing: border-box;
  padding: 8px 10px;
  border-bottom: 1px solid #ebeef0;
  line-height: 1.2rem;
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};

  ${props =>
    props.hasSelect &&
    css`
      width: 55px;
      max-width: 55px;
    `};

  & > label {
    margin: 0;
  }

  & div.align-right {
    text-align: right;
  }

  & div.padding-right {
    padding-right: 20px;
  }
  & > a {
    text-decoration: none;
  }
`;
export const TableDataBox = styled.div`
  .customer-view-icon svg {
    fill: #ffffff;
    background: transparent;
  }
  .customer-view-icon {
    position: relative;
    .tool-tip {
      text-align: center;
      width: 100px;
      padding: 2px 4px;
      display: block;
      position: absolute;
      top: -40px;
      left: -44px;
      background-color: rgba(0, 0, 0, 0.9);
      color: #ffffff;
      z-index: 1;
      border-radius: 4px;
      visibility: hidden;
      font-size: 12px;
    }
    .tool-tip::before {
      content: "";
      background-color: rgba(0, 0, 0, 0.9);
      padding: 4px;
      position: absolute;
      top: calc(100% - 4px);
      transform: rotate(45deg);
      left: calc(50% - 4px);
    }
    &:hover {
      .tool-tip {
        visibility: visible;
      }
    }
  }
  & > span {
    background-color: transparent;
    font-size: 0.7rem;
    font-weight: 400;
    color: #212b36;
  }
  & > span.has-customer-link {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      font-size: 0.7rem;
      font-weight: 400;
      color: #212b36;
    }
  }
  & > span.status-indicator {
    padding: 3px 10px;
    border: 2px solid white;
    border-radius: 15px;
  }

  & > span.success {
    color: #576675;
    background-color: #dfe3e8;
  }
  & > span.pending {
    /* background-color: #ffc58b; */
    background-color: #FFEA8A;
    color: #212b36;
  }

  & > span.created {
    background-color: #ffea8a;
    color: #212b36;
  }
  & > span.cancelled {
    color: #d83e3e;
  }
  & > span.abandoned {
    background-color: #ffc58b;
    color: #212b36;
  }

  & > span.strikeout {
    text-decoration: line-through;
  }
`;

export const Section = styled.div`
  width: 100%;
  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #ebeef0;
    `};
  padding: 20px;
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 600;
  }
  span {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    color: #212b36;
    font-size: 0.6rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  span {
    font-size: 0.6rem;
    cursor: pointer;
    color: #007ace;
  }
  span:hover {
    text-decoration: underline;
  }

  a {
    text-decoration: none;
  }
`;

export const SectionBody = styled.div`
  padding: 10px 0px;
`;

export const Info = styled.div`
  span.action {
    font-size: 0.7rem;
    cursor: pointer;
    color: #007ace;
  }
  span.action:hover {
    text-decoration: underline;
  }
  span.card-number {
    margin-left: 5px;
  }
  p {
    font-size: 0.7rem;
    color: #212b36;
    padding: 5px 0px;
  }
`;

export const EmptyBox = styled.div`
  background: transparent;
  padding: ${props => (props.padding ? props.padding : "20px 20px 10px")};
  .text-center {
    text-align: center;
    line-height: 17px;
  }
  .empty-state {
    color: #637381;
  }
  & > div:first-child {
    padding-top: 0px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  svg {
    color: transparent;
    fill: #c3cfd8;
    width: 20px;
    height: 20px;
  }
  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
  }
`;
