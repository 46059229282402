import axios from "axios";
import { coinBarterApi } from "../../config";
import { toast } from "react-toastify";

export default async (transactionID, status = "completed", type = "trades") => {
  try {
    await axios.post(`${coinBarterApi}/fence/${type}/${transactionID}/mac`, {
      status
    });

    window.location.reload();
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
