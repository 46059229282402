import React from "react";
import styled from "styled-components";

import { ReactComponent as SucessSVG } from "../svgs/success.svg";
import { ReactComponent as PendingSVG } from "../svgs/pending.svg";

const Container = styled.div`
  background: #fff;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border-radius: 3px;
  min-height: 100px;
  padding: 10px 0px 20px;
`;

const Section = styled.div`
  div.line {
    padding-top: 5px;
  }
`;

export const ImageBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px 0px;
  svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  span {
    text-transform: capitalize;
    color: #212b36;
    font-size: 0.8rem;
    font-weight: 500;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 0px;
  .bold {
    text-transform: uppercase;
    font-weight: 700;
  }
  span {
    color: #212b36;
    font-size: 0.7rem;
    font-weight: 400;
  }
  span.bold {
    font-weight: 700;
  }
  hr {
    width: 100%;
    color: #e6e6e6;
    background-color: #e6e6e6;
    height: 1px;
    padding: 0;
    border: 0;
  }
`;

const detailInfo = ({ detail }) => {
  return (
    <Container>
      <Section>
        <ImageBox>
          {detail.status === "active" && <SucessSVG />}
          {detail.status === "inactive" && <PendingSVG />}
          <span>{detail.status}</span>
        </ImageBox>
        <InfoItem>
          <span>Minimum</span>

          <span>{detail.minimum}</span>
        </InfoItem>
        <InfoItem>
          <span>Maximum</span>

          <span>{detail.maximum}</span>
        </InfoItem>
        <InfoItem>
          <span>Reference Exchange</span>

          <span className="bold">{detail.reference_exchange}</span>
        </InfoItem>
      </Section>
    </Container>
  );
};

export default detailInfo;
