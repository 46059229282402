import { format, isToday, isYesterday } from "date-fns";

const whatDayTime = date => {
  const dateIsToday = isToday(date);
  const dateIsYesterday = isYesterday(date);

  if (dateIsToday) {
    return format(date, "[Today at] h:mm a");
  }

  if (dateIsYesterday) {
    return format(date, "[Yesterday at] h:mm a");
  }

  return format(date, "dddd [at] h:mm a");
};

export default whatDayTime;
