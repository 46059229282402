import types from "../actions/types";

const initialState = {
  trade: {}
};

const coinBarterOneTrade = (state = initialState, { type, payload }) => {
  switch (type) {
    case types("get_one_trade").fulfilled:
      return {
        ...state,
        trade: payload
      };

    default:
      return state;
  }
};

export default coinBarterOneTrade;
