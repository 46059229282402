import { toast } from "react-toastify";
import logout from "../actions/logout";

export const handleError = e => (dispatch, getState) => {
  switch (e.response.status) {
    // (user unathorized)
    // log user out
    case 401:
      console.log("token expired");
      dispatch(logout());
      break;
    case 400:
      console.log("400 error!!");
      const message = e.response.data.message || e.message;
      console.log(message);
      toast.error(message);
      break;
    case 404:
      toast.error(e.message);
      break;
    default:
      console.error(e);
      break;
  }
};

const asyncErrorCatch = store => next => action => {
  let result = next(action);
  if (result && typeof result.then !== "function") {
    return action;
  }
  return result
    ? result.catch(error => {
        console.log("GLOBAL ASYNC ERROR HANDLER", { error });
        store.dispatch(handleError(error));
        throw error;
      })
    : action;
};

export default asyncErrorCatch;
